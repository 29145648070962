import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { CvssV3 } from './CvssV3';
import {
  DecodedCvssValues,
  getDecodedCvssValues,
  renderFriendlySeverityVector,
} from '@app/shared/ui-components/cvss-calculator/cvss-calculator.utils';
import { getSeverityInfo } from '@app/domain/settings/settings.utils';
import { calcSeverityLevel } from '@app/domain/finding/findings.utils';
import { DEFAULT_SEVERITY_VECTOR } from '@app/shared/ui-components/cvss-calculator/cvss-calculator.constant';

const cvssStyles = {
  matricesTitle: {
    minWidth: 200,
    marginBottom: 16,
    fontSize: 12,
  },
  matricesItem: {
    margin: '16px 0',
  },
  severityBtn: {
    border: '2px solid #424550',
    borderRadius: 5,
    marginRight: 14,
    padding: '8px 14px',
    color: '#BABAC9',
    fontSize: 12,
    cursor: 'pointer',
  },
  selected: {
    border: '2px solid #757888',
    background: '#3B3E49',
    color: 'white',
  },
  scoreBar: {
    display: 'none',
  },
} as const;

interface Props {
  onChange?: (values: Partial<DecodedCvssValues>) => void;
  onScoreChange?: (score: number) => void;
  onSeverityVectorChange?: (vector: string) => void;
  onValidationChange?: (isValid: boolean) => void;
  defaultSeverityVector?: string;
  defaultScore?: number;
}

export const CvssCalculator: React.FC<Props> = ({
  onChange,
  onScoreChange,
  onValidationChange,
  defaultSeverityVector,
  onSeverityVectorChange,
  defaultScore,
}) => {
  const [score, setScore] = useState(defaultScore ?? 0);
  const [severityVector, setSeverityVector] = useState(
    defaultSeverityVector ? renderFriendlySeverityVector(defaultSeverityVector) : DEFAULT_SEVERITY_VECTOR
  );

  const { label, backgroundColor, borderColor } = getSeverityInfo(calcSeverityLevel(score));
  const isValid = !severityVector.includes('_');

  useEffect(() => {
    onChange && onChange(getDecodedCvssValues(severityVector));
    onValidationChange && onValidationChange(isValid);
  }, [onChange, severityVector, onValidationChange, isValid]);

  return (
    <div className="py-8 pl-12 pr-20">
      <div className="flex w-full flex-col md:flex-row">
        <div className="flex-[7]">
          <div className="mb-8 flex items-center">
            <Typography className="mr-5 text-xxl font-bold">CVSS Calculation</Typography>
            <Typography
              className="flex w-28 items-center justify-center rounded-[4px] border py-2.5 text-xs"
              style={{ backgroundColor, borderColor }}
            >
              {`${score} (${label})`}
            </Typography>
          </div>
        </div>
        <div className="mb-8 flex-[5] md:mb-0">
          <div className="flex items-end">
            <Typography className="mr-3 text-xs font-bold">CVSS v3.1 Vector:</Typography>
            <a
              href={`https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator?vector=${severityVector}&version=3.1`}
              target="_blank"
              rel="noreferrer"
              // TODO fix clsxm: "text-xxs" removed for no reason
              // className={clsxm('text-xxs text-[#6E9FFF] underline', !isValid && 'pointer-events-none text-grey4')}
              className={`text-xxs text-[#6E9FFF] underline ${!isValid && 'pointer-events-none text-grey4'}`}
            >
              {severityVector}
            </a>
          </div>
        </div>
      </div>
      <CvssV3
        styles={cvssStyles}
        severityVector={defaultSeverityVector}
        onChange={(data) => {
          setScore(Number(data.score));
          setSeverityVector(renderFriendlySeverityVector(data.scoreVector));
          onScoreChange && onScoreChange(Number(data.score));
          onSeverityVectorChange && onSeverityVectorChange(data.scoreVector);
        }}
      />
    </div>
  );
};
