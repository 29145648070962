import React from 'react';

import { FindProjectList } from '@app/domain/project/components/FindProjectList';
import { GET_NOT_ASSIGNED_PROJECTS_QUERY } from '@app/constants/query-api-configs';
import { useFindProjectsQueryControls } from '@app/domain/project/hooks/useFindProjectsQueryControls';
import { useGetNotAssignedProjects } from '@app/domain/project/api/hooks/project.api.hook';

export const AllScheduledProjectsPage: React.FC = () => {
  const {
    values: { search },
  } = useFindProjectsQueryControls();

  const { isFetching, data } = useGetNotAssignedProjects(
    { sort: GET_NOT_ASSIGNED_PROJECTS_QUERY.defaultSort, term: search },
    { keepPreviousData: true }
  );

  return (
    <div>
      <FindProjectList projects={data?.result} loading={isFetching} />
    </div>
  );
};
