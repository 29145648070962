import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { useMutation } from 'react-query';

import { GET_TESTER_INVITATIONS_QUERY } from '@app/constants/query-api-configs';

import { Table, TableColumn } from '@app/shared/ui-components';
import { EUserInvitationStatus, UserInvitationDto } from '@app/swagger-types';
import { DATE_FORMAT } from '@app/constants/date';
import { UserInvitationApi } from '@app/domain/user-invitation/api/user-invitation.api';
import { InvitationStatusButton } from '@app/shared/tables/users-table/InvitationStatusButton';
import { useUserInvitationsQueryControls } from '@app/shared/tables/users-table/useUserInvitationsQueryControls';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { useGetTesterInvitations } from '@app/domain/user-invitation/api/user-invitations.api.hook';
import { PAGE_SIZE } from '@app/constants/api';

export const TesterInvitationsTable: React.FC = () => {
  const {
    values: { page, sort = GET_TESTER_INVITATIONS_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange },
  } = useUserInvitationsQueryControls();

  const { data, isFetching, refetch } = useGetTesterInvitations(
    {
      sort,
      page: Math.ceil((page || 1) - 1),
      size: PAGE_SIZE,
      term: search,
    },
    { keepPreviousData: true }
  );

  const { mutate: cancelUserInvite, isLoading } = useMutation(
    async (invitationId: string) => {
      await UserInvitationApi.updateStatus(invitationId, { status: EUserInvitationStatus.CANCELED });
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const columns: TableColumn<UserInvitationDto>[] = useMemo(() => {
    return [
      {
        headerName: 'Email',
        field: 'email',
        align: 'left',
      },
      {
        headerName: 'Date of Invite',
        field: 'createdDate',
        align: 'left',
        render: ({ createdDate }) => format(new Date(createdDate), DATE_FORMAT),
      },
      {
        headerName: 'Invited by',
        field: 'invitedBy.firstName',
        align: 'left',
        disableSort: true,
        disableClick: true,
        render: ({ invitedBy }) => <UserBlock user={invitedBy} />,
      },
      {
        headerName: 'Actions',
        field: 'status',
        align: 'right',
        disableSort: true,
        render: ({ status, id }) => (
          <InvitationStatusButton invitationStatus={status} onCancelUserInvite={() => cancelUserInvite(id)} />
        ),
      },
    ];
  }, [cancelUserInvite]);

  return (
    <Table
      cols={columns}
      tableData={data?.result ?? []}
      notFoundMessage="No users"
      currentPage={page}
      loading={isFetching || isLoading}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
      hidePageSize
      noShadow
      wrapperClassName="!bg-transparent"
      noBorder
    />
  );
};
