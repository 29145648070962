import styles from './TableCard.module.scss';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import { useRef } from 'react';

import PlusIcon from '@mui/icons-material/AddBox';
import { Typography, SelectChangeEvent } from '@mui/material';

import { Button, Pagination, Select, OptionItem } from '@app/shared/ui-components';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';

interface PropTypes {
  wrapperClassName?: string;
  cardHeaderClassName?: string;
  cardContentClassName?: string;
  total?: number;
  currentPage?: number;
  pageSize?: number;
  headerContent?: React.ReactNode;
  rightHeaderContent?: React.ReactNode;
  leftHeaderContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  onPagination?: (page: number) => void;
  hasMore?: boolean;
  onHasMoreClick?: () => void;
  onSizeChange?: (newSize: number) => void;
  hidePageSize?: boolean;
  hideFooter?: boolean;
  noShadow?: boolean;
  transparentBackground?: boolean;
  noBorder?: boolean;
}

export const TableCard: React.FC<PropTypes> = ({
  wrapperClassName,
  cardHeaderClassName,
  cardContentClassName,
  headerContent,
  leftHeaderContent,
  rightHeaderContent,
  footerContent,
  currentPage,
  total = 0,
  pageSize = 10,
  onPagination,
  hasMore,
  onHasMoreClick,
  onSizeChange,
  children,
  hidePageSize,
  hideFooter,
  noShadow = false,
  transparentBackground = true,
  noBorder,
}) => {
  const defaultPageSize = useRef(pageSize);

  return (
    <div
      className={clsxm(
        styles.TableCardWrapper,
        !noShadow && styles.Shadow,
        wrapperClassName,
        noBorder ? undefined : sharedStyle.shadowBorder,
        {
          'bg-transparentBackground': transparentBackground,
          'bg-grey1': !transparentBackground,
        }
      )}
    >
      {(rightHeaderContent || leftHeaderContent || headerContent) && (
        <div className={clsx(styles.TableCardHeader, cardHeaderClassName)}>
          {leftHeaderContent}
          {rightHeaderContent && <div className={styles.RightContent}>{rightHeaderContent}</div>}
          {headerContent}
        </div>
      )}
      <div className={clsx(styles.tableFixHead, cardContentClassName)}>{children}</div>
      {!hideFooter && (
        <div className={clsx(styles.CardFooter, { [styles.NoPagination]: !currentPage && !total })}>
          {!!currentPage && !!total && (
            <Pagination
              currentPage={currentPage}
              pageSize={pageSize}
              total={total}
              onPagination={onPagination}
              className={clsxm({ 'mx-auto': hidePageSize })}
            />
          )}
          {!!hasMore && !!onHasMoreClick && (
            <Button
              disableRipple
              startIcon={<PlusIcon />}
              variant="text"
              color="primary"
              className={styles.LoadMoreButton}
              onClick={onHasMoreClick}
            >
              Load More
            </Button>
          )}
          {isUndefined(hasMore) && !!pageSize && !hidePageSize && (
            <div className={styles.SizeWrapper}>
              <Typography variant="subtitle2" className={styles.SizeTitle}>
                Per Page:
              </Typography>
              <Select
                value={pageSize}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  if (onSizeChange) {
                    onSizeChange(event.target.value as number);
                  }
                }}
                size="small"
                inputProps={{
                  variant: 'filled',
                  className: styles.SizeInput,
                }}
                menuClassName={styles.SizeSelect}
              >
                <OptionItem value={defaultPageSize.current}>{defaultPageSize.current} Rows</OptionItem>
                <OptionItem value={50}>50 Rows</OptionItem>
                <OptionItem value={100}>100 Rows</OptionItem>
              </Select>
            </div>
          )}
        </div>
      )}
      {footerContent && <footer>{footerContent}</footer>}
    </div>
  );
};
