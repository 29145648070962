import React, { useCallback, useMemo, useState } from 'react';
import { useTypedNavigate } from '@app/router';
import { GET_PENDING_RETEST_FINDINGS_QUERY } from '@app/constants/query-api-configs';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { Button, Table } from '@app/ui-components';
import { OverrideFindingOutDto } from '@app/swagger-override-types';
import { Typography } from '@mui/material';
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Routes } from '@app/constants/routes';
import { usePendingRetestColumns } from '@app/domain/project/hooks/usePendingRetestColumns';
import { PendingRetestDialog } from '@app/domain/project/components/PendingRetestDialog';
import { useQuery } from 'react-query';
import { FindingApi } from '@app/domain/finding/finding.api';
import { EFindingStatus } from '@app/swagger-types';

export const PendingRetestsTable: React.FC = () => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const navigate = useTypedNavigate();

  const navigateToFindingPage = useCallback(
    ({ id, project: { id: projectId } }: OverrideFindingOutDto) => {
      navigate({ to: Routes.projects.finding, params: { projectId, findingId: id } });
    },
    [navigate]
  );

  const handleCloseDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setIsOpenDialog(true);
  }, []);

  const {
    values: { page, sort = GET_PENDING_RETEST_FINDINGS_QUERY.defaultSort },
    handlers: { handleSortChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
    },
    prefix: GET_PENDING_RETEST_FINDINGS_QUERY.tablePrefix,
  });

  const { isFetching, data } = useQuery(
    [GET_PENDING_RETEST_FINDINGS_QUERY.name, { page, sort }],
    async () => {
      return await FindingApi.getFindings({
        sort,
        page: Math.ceil((page || 1) - 1),
        size: 4,
        withBonuses: true,
        findingStatus: EFindingStatus.RETEST,
      });
    },
    { keepPreviousData: true, staleTime: GET_PENDING_RETEST_FINDINGS_QUERY.config.staleTime }
  );

  const isEmpty = !Boolean(data?.total);

  const tableColumns = usePendingRetestColumns();

  const leftHeaderContent = useMemo(
    () => (
      <div className="flex items-center">
        <Typography className="text-xxl font-bold">Pending Re-tests</Typography>
        <Typography className="ml-2.5 flex h-[22px] w-[22px] items-center justify-center rounded-full bg-grey2 text-xs">
          {data?.total || 0}
        </Typography>
      </div>
    ),
    [data?.total]
  );
  const rightHeaderContent = useMemo(
    () =>
      !isEmpty ? (
        <Button
          variant="text"
          onClick={handleOpenDialog}
          className="flex items-center p-0 text-xs font-bold hover:bg-transparent hover:underline"
          endIcon={<ChevronUpIcon className="h-4 rotate-90" />}
        >
          View All
        </Button>
      ) : null,
    [handleOpenDialog, isEmpty]
  );

  return (
    <>
      <Table
        cols={tableColumns}
        leftHeaderContent={leftHeaderContent}
        rightHeaderContent={rightHeaderContent}
        tableData={data?.result || []}
        notFoundMessage="No Pending Re-tests yet"
        currentPage={page}
        loading={isFetching}
        hidePageSize
        total={data?.total}
        currentSort={sort}
        onSortChanged={handleSortChange}
        onItemSelect={navigateToFindingPage}
        cardContentClassName="h-[300px] min-h-full overflow-auto"
        hideFooter
        noShadow
      />
      {!isEmpty && <PendingRetestDialog open={isOpenDialog} onClose={handleCloseDialog} />}
    </>
  );
};
