import { UserDto } from '@app/models/auth';
import { SuperAdminDto } from '@app/models/super-admin';
import { ERole } from '@app/swagger-types';

export const isAdmin = (user: UserDto): user is SuperAdminDto => {
  return user.role === ERole.ROLE_ADMIN;
};
export const isTester = (user: UserDto) => {
  return user.role === ERole.ROLE_TESTER;
};
export const isClient = (user: UserDto) => {
  return user.role === ERole.ROLE_CLIENT;
};
export const isCollaborator = (user: UserDto) => {
  return user.role === ERole.ROLE_COLLABORATOR;
};
