import { validateAndAssert } from '@app/services/api-request/';
import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { pageableData } from '@app/utils/yup.utils';
import { ActionLogOutDtoSchema } from '@app/domain/action-log/action-log.form';
import { GetActionLogsParams } from '@app/domain/action-log/action-log.type';

export const ActionLogApi = buildApi(
  class _ActionLogApi extends ApiCollection {
    async getLogs(params: GetActionLogsParams) {
      return validateAndAssert(
        this.apiService.get('/private/action-logs', { params }),
        pageableData(ActionLogOutDtoSchema)
      );
    }
  }
);
