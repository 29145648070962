import React, { useCallback, useMemo, useState } from 'react';
import { Tabs } from '@mui/material';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import { GET_USERS_QUERY } from '@app/constants/query-api-configs';

import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';

import { Button, Table, TableColumn } from '@app/shared/ui-components';
import { useTableColumns } from './useTableColumns';
import AddIcon from '@mui/icons-material/Add';
import { InviteTesterDialog } from '@app/domain/user/InviteTesterDialog';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { TesterInvitationsTable } from '@app/shared/tables/users-table/TesterInvitationsTable';
import { ERole } from '@app/swagger-types';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { UserDto } from '@app/models/auth';
import { TableCard } from '@app/shared/ui-components/table-card';
import { useUserInvitationsQueryControls } from '@app/shared/tables/users-table/useUserInvitationsQueryControls';
import { useGetUsers, useGetUsersTotal } from '@app/domain/user/api/hooks/user.api.hook';
import { StyledTab } from '@app/shared/ui-components/tabs/StyledTab';
import { useGetTesterInvitationsTotal } from '@app/domain/user-invitation/api/user-invitations.api.hook';
import { PAGE_SIZE } from '@app/constants/api';
import { UserActionsCell } from '@app/shared/tables/users-table/UserActionsCell';
import { useActivateUser, useBlockUser } from '@app/domain/user-profile/api/user-profile.api.hook';

enum ETabs {
  ACCEPTED = 'ACCEPTED',
  INVITED = 'INVITED',
}

export const UsersTable: React.FC<{
  role: ERole;
}> = ({ role }) => {
  const [openInviteTesterDialog, setOpenInviteTesterDialog] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState<string>();
  const [query, setQuery] = useQueryParams({
    testerTab: withDefault(StringParam, ETabs.ACCEPTED),
  });

  const navigate = useTypedNavigate();
  const testersTotal = useGetUsersTotal(ERole.ROLE_TESTER);
  const invitationsTotal = useGetTesterInvitationsTotal();

  const navigateToProfile = useCallback(
    ({ id, role }: UserDto) => {
      if (role === ERole.ROLE_TESTER) {
        navigate({ to: Routes.admin.user_management.tester_profile, params: { id } });
      } else if (role === ERole.ROLE_CLIENT) {
        navigate({ to: Routes.admin.user_management.client_profile, params: { id } });
      } else if (role === ERole.ROLE_ADMIN) {
        navigate({ to: Routes.admin.user_management.admin_profile, params: { id } });
      } else if (role === ERole.ROLE_COLLABORATOR) {
        navigate({ to: Routes.admin.user_management.collaborator_profile, params: { id } });
      }
    },
    [navigate]
  );

  const {
    values: { page, sort = GET_USERS_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange: handleUsersSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      search: '',
    },
    prefix: GET_USERS_QUERY.tablePrefix,
  });

  const {
    handlers: { handleSearchChange: handleInvitedUsersSearchChange },
  } = useUserInvitationsQueryControls();

  const { mutate: blockUser, isLoading: isBlocking } = useBlockUser({
    onMutate: (userId) => setLoadingUserId(userId),
    onSettled: () => setLoadingUserId(undefined),
  });
  const { mutate: activateUser, isLoading: isActivating } = useActivateUser({
    onMutate: (userId) => setLoadingUserId(userId),
    onSettled: () => setLoadingUserId(undefined),
  });

  const { data, isFetching } = useGetUsers(
    {
      sort,
      page: Math.ceil((page || 1) - 1),
      size: PAGE_SIZE,
      role,
      term: search,
    },
    { keepPreviousData: true }
  );

  const initialColumns = useTableColumns();

  const columns: TableColumn<UserDto>[] = useMemo(() => {
    return [
      ...initialColumns,
      {
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableSort: true,
        disableClick: true,
        hide: role === ERole.ROLE_ADMIN,
        render: (user) => (
          <UserActionsCell
            user={user}
            onBlockUser={blockUser}
            onUnblockUser={activateUser}
            disabledBlock={isBlocking || isActivating}
            disabledUnblock={isBlocking || isActivating}
            isBlocking={isBlocking && loadingUserId === user.id}
            isUnblocking={isActivating && loadingUserId === user.id}
          />
        ),
      },
    ];
  }, [initialColumns, isBlocking, blockUser, loadingUserId, activateUser, isActivating, role]);

  const handleSearchChange = useCallback(
    (value: string) => {
      handleInvitedUsersSearchChange(value);
      handleUsersSearchChange(value);
    },
    [handleInvitedUsersSearchChange, handleUsersSearchChange]
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <SearchInput
          placeholder="Search by name or email"
          className="w-64"
          value={search}
          onChange={handleSearchChange}
        />
      </div>
    );
  }, [handleSearchChange, search]);

  const rightContent = useMemo(() => {
    return role === ERole.ROLE_TESTER ? (
      <div className="flex">
        <Tabs
          value={query.testerTab}
          onChange={(e, testerTab) => setQuery({ testerTab }, 'replaceIn')}
          className="mr-7 border-b border-b-grey2"
        >
          <StyledTab label="Accepted" value={ETabs.ACCEPTED} className="p-3 text-xs" count={testersTotal} />
          <StyledTab label="Invited" value={ETabs.INVITED} className="p-3 text-xs" count={invitationsTotal} />
        </Tabs>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenInviteTesterDialog(true)}
        >
          Add Tester
        </Button>
      </div>
    ) : null;
  }, [role, query.testerTab, setQuery, testersTotal, invitationsTotal]);

  return (
    <TableCard
      leftHeaderContent={leftContent}
      rightHeaderContent={rightContent}
      hidePageSize
      cardContentClassName="h-auto overflow-visible"
    >
      {role === ERole.ROLE_TESTER && query.testerTab === ETabs.INVITED ? (
        <TesterInvitationsTable />
      ) : (
        <Table
          cols={columns}
          tableData={data?.result ?? []}
          notFoundMessage="No users"
          currentPage={page}
          loading={isFetching}
          total={data?.total}
          onPagination={handlePageChange}
          currentSort={sort}
          onSortChanged={handleSortChange}
          hidePageSize
          onItemSelect={navigateToProfile}
          noShadow
          wrapperClassName="!bg-transparent"
          noBorder
        />
      )}
      <InviteTesterDialog open={openInviteTesterDialog} onClose={() => setOpenInviteTesterDialog(false)} />
    </TableCard>
  );
};
