import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { BreakUnit } from '../../PdfPager';
import styles from '../../pdf-salus.module.scss';
import clsxm from '@app/lib/clsxm';
import { SeverityBadge } from '@app/domain/finding/SeverityBadge';
import { PaginatedResponse } from '@app/api/types';
import { FindingOutSchema } from '@app/domain/finding/finding.schema';
import _ from 'lodash';

const MEDIUM_SEVERITY = 4.5;
const LOW_SEVERITY = 0.1;
const maybeIdentity = <T,>(v: T): T | undefined => v;

export const ReportSectionPostEngagementActions: React.FC<{
  project: ProjectOutDtoSchema;
  findings: PaginatedResponse<FindingOutSchema>;
}> = ({ findings }) => {
  // sort desc
  const sortedFindings = _.sortBy(findings.result, (e) => -e.cvssScore);
  const topFinding = maybeIdentity(sortedFindings[0]);
  const highestScore = topFinding?.cvssScore || LOW_SEVERITY;

  return (
    <>
      <BreakUnit pageBreak />
      <BreakUnit header>
        <div className={styles.content}>
          <div className={styles.pageHeader} id="post_engagement_actions">
            Post-Engagement Actions
          </div>
          <div className={styles.divider} />
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>Consider the following motions in order of priority.</div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={clsxm(styles.content, 'flex pt-4 font-bold text-white')}>
          <div className={'flex-[8]'}>Action</div>
          <div className={'flex-1'}>Importance</div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex py-2">
            <div className={'flex flex-[8] items-center pr-8'}>
              Consult the technical report and remediate all outstanding issues In order of importance
            </div>
            <div className={'flex flex-1 items-center justify-center'}>
              <SeverityBadge cvssScore={highestScore} />
            </div>
          </div>
          <div className={clsxm(styles.gradientDivider, 'rotate-180')} />
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex py-2">
            <div className={'flex flex-[8] items-center pr-8'}>
              Consider reviewing and improving the policies relating{' '}
              {topFinding?.methodology?.sectionTitle || 'methodology'} to improve best practices and prevent future
              potential security reports
            </div>
            <div className={'flex flex-1 items-center justify-center'}>
              <SeverityBadge cvssScore={highestScore} />
            </div>
          </div>
          <div className={clsxm(styles.gradientDivider, 'rotate-180')} />
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex py-2">
            <div className={'flex flex-[8] items-center pr-8'}>Perform regular penetration testing</div>
            <div className={'flex flex-1 items-center justify-center'}>
              <SeverityBadge cvssScore={MEDIUM_SEVERITY} />
            </div>
          </div>
        </div>
      </BreakUnit>
    </>
  );
};
