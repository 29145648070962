import { Routes } from '@app/constants/routes';
import { IS_DEV_ENV } from '@app/environment/typed-env';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';

export const TermsAndConditionsPage: React.FC = () => {
  const { isTester, isClient } = useUserRole();
  if (isTester || isClient) {
    const src = isTester ? Routes.pdf.testerTac : Routes.pdf.clientTac;
    return (
      <div className="relative h-full">
        {IS_DEV_ENV && (
          <a
            href={isTester ? Routes.pdf.testerTac : Routes.pdf.clientTac}
            className="absolute -top-6 left-2 opacity-20 hover:opacity-100"
          >
            open PDF page
          </a>
        )}
        <iframe title="terms and conditions" src={src} width="100%" height="100%" frameBorder="0" />
      </div>
    );
  }
  return null;
};
