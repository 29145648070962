import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { UIKitSection } from '../UIKitSection';
import { BonusInfoBox } from '@app/shared/ui-components/bonus-info-box/BonusInfoBox';
import { uiKitBonuses } from '@app/modules/ui-kit/pages/mock-data/bonus.data';

enum EMode {
  HAS_DATA = 'HAS_DATA',
  NO_DATA = 'NO_DATA',
}

export default function DemoForProjectBonusInfoBox() {
  const [mode, setMode] = useState(EMode.HAS_DATA);

  return (
    <UIKitSection title="BonusInfoBox">
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setMode(e.currentTarget.value as EMode);
        }}
      >
        <FormControlLabel value={EMode.HAS_DATA} control={<Radio />} label="Has data" />
        <FormControlLabel value={EMode.NO_DATA} control={<Radio />} label="No data" />
      </RadioGroup>
      {mode === EMode.HAS_DATA && (
        <BonusInfoBox
          bonus={{
            totalAmountCoin: 370,
            bonuses: uiKitBonuses,
          }}
        />
      )}
      {mode === EMode.NO_DATA && <BonusInfoBox />}
    </UIKitSection>
  );
}
