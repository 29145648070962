import { Routes } from '@app/constants/routes';

import { MyRouteObject } from '@app/models/router';

import { RootModule } from './RootModule';
import { AdminRoutes } from './admin/AdminRoutes';
import { AuthRoutes } from './auth/AuthRoutes';
import { TesterRoutes } from './tester/TesterRoutes';
import { ClientRoutes } from '@app/modules/client/ClientRoutes';
import { UIKitRoutes } from './ui-kit/UIKitRoutes';
// import { NotFoundPage } from './root-pages/NotFound';
import { TypedNavigate } from '@app/router';
import { PdfRoutes } from './pdf/PdfRoutes';
import { PdfModule } from './pdf/PdfModule';
import { CollaboratorRoutes } from '@app/modules/collaborator/CollaboratorRoutes';
import { TermsAndConditionsPage } from './pdf/pages/tac/TermsAndConditions.page';
import { ERole } from '@app/swagger-types';

const TacRoute: MyRouteObject = {
  path: Routes.tac,
  role: [ERole.ROLE_CLIENT, ERole.ROLE_TESTER],
  element: <TermsAndConditionsPage />,
};

export const RootRoutes: MyRouteObject[] = [
  {
    path: Routes.index,
    element: <RootModule />,
    children: [AdminRoutes, AuthRoutes, TesterRoutes, ClientRoutes, CollaboratorRoutes, UIKitRoutes, TacRoute],
  },
  {
    path: Routes.pdf.index,
    element: <PdfModule />,
    children: [PdfRoutes],
  },
  // IF YOU PREFER 404 ROOT PAGES => (require manual redirect after sign-in/sign-up/logout in responding pages/actions)
  // { path: '*', element: <NotFoundPage /> },
  { index: true, path: '*', element: <TypedNavigate to={Routes.index} /> },
];
