import { ValueType, OmitedProps } from './types';

export class PropsManager<T extends Record<keyof T, ValueType>> {
  private props: Partial<Record<keyof T, Omit<React.ComponentPropsWithoutRef<T[keyof T]['component']>, OmitedProps>>> =
    {};

  setProps<P extends Omit<React.ComponentPropsWithoutRef<T[keyof T]['component']>, OmitedProps>>(
    name: keyof T,
    props: P
  ) {
    this.props[name] = props;
  }

  getProps(name: keyof T) {
    return this.props[name];
  }
}
