import React from 'react';
import { Avatar, Tooltip } from '@app/ui-components';
import { TesterOutDto } from '@app/swagger-types';
import { preventEvent } from '@app/utils/event-handler.utils';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { TooltipProps } from '@app/shared/ui-components/tooltip/Tooltip';

interface Props {
  testers: TesterOutDto[];
  countTestersToShow?: number;
  tooltipPlacement?: TooltipProps['placement'];
}

export const TestersInfo: React.FC<Props> = ({ testers, countTestersToShow = 2, tooltipPlacement }) => {
  return (
    <Tooltip
      leaveDelay={100}
      onClick={preventEvent}
      placement={tooltipPlacement}
      title={
        <div onClick={preventEvent} aria-hidden className="flex flex-col gap-2 p-2">
          {testers.map((tester) => (
            <UserBlock key={tester.id} user={tester} avatarWidth={25} avatarHeight={25} />
          ))}
        </div>
      }
    >
      <div className="flex items-center justify-center">
        {testers.slice(0, countTestersToShow).map(({ avatarId, id }) => (
          <Avatar
            key={id}
            width={30}
            height={30}
            imageId={avatarId}
            className="border-2 border-grey1 even:ml-[-10px]"
          />
        ))}

        {Boolean(testers.length > countTestersToShow) && (
          <span className="pl-2">+{testers.length - countTestersToShow}</span>
        )}
      </div>
    </Tooltip>
  );
};
