import React from 'react';
import { Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ERole, TesterInDto } from '@app/swagger-types';
import { TesterDto } from '@app/models/user';
import { GET_MY_PROFILE_QUERY } from '@app/constants/query-api-configs';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { TypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { TesterForm } from '@app/domain/user/TesterForm';
import { UserDto } from '@app/models/auth';
import { UserProfileApi } from '@app/domain/user-profile/user-profile.api';
import useStore from '@app/store/useStore.hook';

export const EditMyProfilePage: React.FC = () => {
  const { setCurrentUser } = useStore();
  const queryClient = useQueryClient();

  const {
    isFetching,
    data: user,
    error,
  } = useQuery<UserDto, Error>(
    GET_MY_PROFILE_QUERY.name,
    async () => {
      const { data } = await UserProfileApi.getMe();
      return data;
    },
    { keepPreviousData: true, staleTime: GET_MY_PROFILE_QUERY.config.staleTime }
  );

  const { mutate: updateMyTesterProfile, isLoading } = useMutation<TesterDto, Error, Partial<TesterInDto>>(
    async (Dto) => {
      const { data } = await UserProfileApi.updateMyTesterProfile(Dto);
      return data;
    },
    {
      onSuccess: (newTester) => {
        queryClient.invalidateQueries(GET_MY_PROFILE_QUERY.name);
        setCurrentUser(newTester);
      },
    }
  );

  if (!user || (isFetching && !user) || error) {
    return <ContainerLoader loading={isFetching} error={error} noData={!user} />;
  }

  if (user.role === ERole.ROLE_TESTER) {
    return (
      <div>
        <Typography className=" mb-8 text-xxxl font-bold">Edit My Profile</Typography>
        <div>
          <TesterForm tester={user} onConfirm={updateMyTesterProfile} isLoading={isLoading} isMyProfile />
        </div>
      </div>
    );
  }

  return <TypedNavigate to={Routes.admin.user_management.my_profile} />;
};
