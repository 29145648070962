import React, { useState } from 'react';

import { UIKitSection } from '../UIKitSection';
import { RichEditor } from '@app/shared/ui-components/rich-editor/RichEditor';
import { RichEditorView } from '@app/shared/ui-components/rich-editor/RichEditorView';
import { Typography } from '@mui/material';

export default function DemoForRichEditor() {
  const [value, setValue] = useState('');

  return (
    <UIKitSection title="Rich Editor">
      <div className="flex gap-6 rounded-md bg-grey1 p-6">
        <div className="flex-1">
          <RichEditor value={value} onChange={setValue} placeholder="placeholder..." />
        </div>
        <div className="flex-1">
          <Typography className="p-2 text-m font-bold">Result:</Typography>
          <RichEditorView value={value} />
        </div>
      </div>
    </UIKitSection>
  );
}
