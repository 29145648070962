import { Routes } from '@app/constants/routes';
import clsxm from '@app/lib/clsxm';
import { TypedLink } from '@app/router';
import { Button } from '@app/ui-components';
import styles from '../pdf-salus.module.scss';
import { BreakUnit, IPdfRenderResult } from '../PdfPager';
import PrintIcon from '@mui/icons-material/Print';
import { ID_FOR_TABLE_OF_CONTENTS } from '../constants';

export const NoFindingDiscoveredParagraph: React.FC = () => (
  <BreakUnit>
    <div className={styles.content}>
      <div className={clsxm(styles.p)}>No finding has been discovered during this engagement.</div>
    </div>
  </BreakUnit>
);

export const TableOfContents: React.FC<{ result: IPdfRenderResult }> = ({ result }) => {
  return (
    <div>
      {result.headers.map((e, i) => {
        return (
          <a
            href={`#${e.id}`}
            key={i}
            className="flex justify-between py-2 hover:shadow-[0_1px]"
            onClick={() => {
              setTimeout(() => {
                // scroll above a bit to skip header height
                window.scrollTo({ top: window.scrollY - 120 });
              }, 0);
            }}
          >
            <div>{e.title}</div>
            <div>{e.pageNumber}</div>
          </a>
        );
      })}
    </div>
  );
};

export const FixedHeader: React.FC<{
  projectId?: string;
  currentPdf: 'tech' | 'management' | 'public';
}> = ({ projectId, currentPdf }) => {
  return (
    <>
      {/* extra gap */}
      <div className="pt-10 print:hidden"></div>
      <div className="fixed top-0 z-50 flex w-full items-center justify-center gap-4 bg-transparentBackground py-4 backdrop-blur-sm print:hidden">
        {projectId && (
          <TypedLink to={Routes.projects.project} params={{ id: projectId }}>
            <Button variant="outlined">Go To Project</Button>
          </TypedLink>
        )}
        <Button variant="outlined" color={'primary'} startIcon={<PrintIcon />} onClick={() => window.print()}>
          Print PDF
        </Button>
        <a href={`#${ID_FOR_TABLE_OF_CONTENTS}`}>
          <Button variant="outlined">Table of Contents</Button>
        </a>
        {currentPdf !== 'tech' && projectId && (
          <TypedLink to={Routes.pdf.technicalReport} params={{ projectId }}>
            <Button variant="outlined">View Tech Report</Button>
          </TypedLink>
        )}
        {currentPdf !== 'management' && projectId && (
          <TypedLink to={Routes.pdf.managementReport} params={{ projectId }}>
            <Button variant="outlined">View Management Report</Button>
          </TypedLink>
        )}
        {currentPdf !== 'public' && projectId && (
          <TypedLink to={Routes.pdf.publicReport} params={{ projectId }}>
            <Button variant="outlined">View Public Report</Button>
          </TypedLink>
        )}
      </div>
    </>
  );
};
