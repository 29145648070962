import React from 'react';
import { Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';

import { Status } from '@app/shared/ui-components/status/Status';
import { getFriendlyUserStatus } from '@app/domain/user/user.utils';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';
import { CollaboratorInDto, EUserStatus } from '@app/swagger-types';
import { CollaboratorOutSchema } from '@app/models/user';
import { GET_COLLABORATOR_QUERY } from '@app/constants/query-api-configs';
import { UserApi } from '@app/domain/user/user.api';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { UserInfoBox } from '@app/domain/user-profile/UserInfoBox';
import { AssignedProjectsTable } from '@app/domain/user-profile/AssignedProjectsTable';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { UserActions } from '@app/domain/user-profile/UserActions';
import useStore from '@app/store/useStore.hook';

export const CollaboratorProfilePage: React.FC = () => {
  const { id: collaboratorId } = useTypedParams(Routes.admin.user_management.collaborator_profile);
  const { isAdmin } = useUserRole();
  const { currentUser } = useStore();

  const isMyProfile = currentUser?.id === collaboratorId;

  const {
    data: collaborator,
    isFetching,
    error,
    refetch,
  } = useQuery<CollaboratorOutSchema | undefined, Error>(
    [GET_COLLABORATOR_QUERY.name, { collaboratorId }],
    async () => {
      if (collaboratorId) {
        const { data } = await UserApi.getCollaborator(collaboratorId);
        return data;
      }
    },
    { keepPreviousData: true, staleTime: GET_COLLABORATOR_QUERY.config.staleTime }
  );

  const { mutate: updateMyCollaboratorProfile, isLoading: isEditLoading } = useMutation<
    CollaboratorOutSchema | undefined,
    Error,
    Partial<CollaboratorInDto>
  >(
    async (Dto) => {
      if (collaboratorId) {
        const { data } = await UserApi.updateCollaboratorProfile(collaboratorId, Dto);
        return data;
      }
    },
    {
      onSuccess: async () => {
        await refetch();
      },
    }
  );

  if (!collaborator || (isFetching && !collaborator) || error) {
    return <ContainerLoader loading={isFetching} error={error} noData={!collaborator} />;
  }

  const { status, firstName, lastName } = collaborator;

  const isDisabled = [EUserStatus.BANNED, EUserStatus.LOCKED, EUserStatus.DELETED].includes(status);

  return (
    <>
      <div>
        <div className="mb-32 flex items-center justify-between">
          <div className="flex items-center">
            <Typography className="mr-3.5 text-xxxl font-bold">{`${firstName} ${lastName}`}</Typography>
            <Status {...getFriendlyUserStatus(status)} />
          </div>
          {isAdmin && <UserActions user={collaborator} />}
        </div>
        <div className={clsxm('w-80 rounded-[10px] bg-transparentBackground', sharedStyle.shadowBorder)}>
          <UserInfoBox
            user={collaborator}
            disabled={isDisabled}
            onEditCollaborator={isAdmin ? updateMyCollaboratorProfile : undefined}
            isEditLoading={isEditLoading}
          />
        </div>
        {(isAdmin || isMyProfile) && !isDisabled && (
          <div className="mt-9">
            <AssignedProjectsTable clientId={collaboratorId} />
          </div>
        )}
      </div>
    </>
  );
};
