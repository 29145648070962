import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import localforage from 'localforage';
import { set } from 'lodash';
import { stringify } from 'query-string';

import {
  ServerErrorData,
  ApiError,
  ApiValidationError,
  ApiForbiddenError,
  toastifyValidationError,
} from '@app/services/error-manager/';

import { IS_AUTH_METHOD_COOKIE, IS_AUTH_METHOD_TOKEN, typedEnv } from '@app/environment/typed-env';

import { AUTH_REF_TOKEN_KEY, AUTH_TOKEN_KEY } from '@app/auth/constant';
import { AuthApi } from '@app/auth/auth.api';
import { AxiosMetaParams } from './meta-params';
import Notification from '@app/shared/ui-components/notifications/Notifications';
import { IS_D2NA_DOMAIN, IS_LOCALHOST } from '@app/domain/d2na/domain.util';

// TODO idea: show global non-interactive overlay when count of pending requests 2+
// to avoid making new pending requests from UI

export class ApiRequestService {
  private baseAxiosConfig: AxiosRequestConfig = {
    baseURL: !IS_LOCALHOST && IS_D2NA_DOMAIN ? typedEnv.REACT_APP_API_BASE_URL_D2NA : typedEnv.REACT_APP_API_BASE_URL,
    paramsSerializer: (params) => stringify(params),
    withCredentials: IS_AUTH_METHOD_COOKIE,
  };

  public axiosInstance: AxiosInstance;

  private pendingRequests: { (token: string): void }[] = [];
  private addRequest(request: (token: string) => void) {
    this.pendingRequests.push(request);
  }

  private onNewAccessToken(access_token: string) {
    this.pendingRequests.forEach((request) => {
      request(access_token);
    });
    this.pendingRequests = [];
  }

  private countOfActiveRequests = 0;
  private isAlreadyFetchingAccessToken = false;

  private isAlreadySigningOut = false;

  private async onInvalidRefreshToken() {
    if (this.isAlreadySigningOut) {
      return;
    }
    this.isAlreadySigningOut = true;
    // TODO setCurrentUser(null);
    // TODO remove localforage from this method. AuthSlice should be responsible for that.
    await localforage.clear();
    window.location.reload();
  }

  private async onInvalidCookie() {
    if (this.isAlreadySigningOut) {
      return;
    }
    this.isAlreadySigningOut = true;
    await AuthApi.signOut();
    window.location.reload();
  }

  constructor(axiosConfig?: AxiosRequestConfig, skipInterceptors?: boolean) {
    this.baseAxiosConfig = { ...this.baseAxiosConfig, ...axiosConfig };
    this.axiosInstance = axios.create(this.baseAxiosConfig);

    if (skipInterceptors) {
      return;
    }

    // EVERY REQUEST
    this.axiosInstance.interceptors.request.use(
      async (req) => {
        this.countOfActiveRequests++;
        if (IS_AUTH_METHOD_TOKEN) {
          const token = await localforage.getItem(AUTH_TOKEN_KEY);

          if (token) {
            set(req, 'headers.Authorization', `Bearer ${token}`);
          }
        }

        if (!IS_LOCALHOST && IS_D2NA_DOMAIN) {
          set(req, 'headers.Domain', `d2na.com`);
        }

        return req;
      },
      (err) => {
        Promise.reject(err);
      }
    );

    // EVERY RESPONSE

    this.axiosInstance.interceptors.response.use(
      (response) => {
        this.countOfActiveRequests--;
        return response;
      },
      async (error: AxiosError<ServerErrorData>) => {
        const { config, response } = error;
        if (!response) {
          Notification.showFailure('No connection with the server.');
          throw new ApiError();
        }
        const params: AxiosMetaParams | undefined = config.params;

        const { status } = response;
        const originalRequest = config;
        this.countOfActiveRequests--;
        // VALIDATION SERVER ERROR
        if (status === 400) {
          const err = new ApiError(response.data);
          if (!params?.skipToaster) {
            toastifyValidationError(err);
          }
          return Promise.reject(new ApiValidationError(response.data));
        }

        if (IS_AUTH_METHOD_COOKIE) {
          if (status === 401) {
            this.onInvalidCookie();
            throw new ApiError();
          }
        }

        if (IS_AUTH_METHOD_TOKEN) {
          const refreshToken = await localforage.getItem(AUTH_REF_TOKEN_KEY);
          // REFRESH TOKEN MECHANISM
          if (status === 401) {
            if ('skip token refresh' || !refreshToken) {
              // await this.onInvalidRefreshToken();
              throw new ApiError();
            }
            // if 401 response on new-token endpoint
            if (this.isAlreadyFetchingAccessToken && config.url === '/public/auth/new-access-token') {
              await this.onInvalidRefreshToken();
              throw new ApiError();
            }

            if (!this.isAlreadyFetchingAccessToken && this.countOfActiveRequests === 0) {
              this.isAlreadyFetchingAccessToken = true;

              try {
                const {
                  data: { accessToken: newAccessToken, refreshToken: newRefreshToken },
                } = await this.axiosInstance.post(`/public/auth/new-access-token`, {
                  refreshToken,
                });

                await localforage.setItem(AUTH_TOKEN_KEY, newAccessToken);
                await localforage.setItem(AUTH_REF_TOKEN_KEY, newRefreshToken);

                this.isAlreadyFetchingAccessToken = false;

                this.onNewAccessToken(newAccessToken);

                return this.axiosInstance(originalRequest);
              } catch (error) {
                this.isAlreadyFetchingAccessToken = false;
                await this.onInvalidRefreshToken();
                return Promise.reject(error);
              }
            }

            return new Promise((resolve) => {
              this.addRequest((access_token) => {
                set(originalRequest, 'headers.Authorization', `bearer ${access_token}`);
                resolve(this.axiosInstance(originalRequest));
              });
            });
          }
        }

        if (status === 403) {
          const err = new ApiForbiddenError(response.data);
          const { errors } = err.serializeError();
          errors.forEach((err) => {
            Notification.showFailure(err.message);
          });
          return Promise.reject(err);
        }

        // ANY OTHER SERVER ERROR
        const err = new ApiError(response.data);

        if (!params?.skipToaster) {
          const { errors } = err.serializeError();

          errors.forEach((err) => {
            Notification.showFailure(err.message || 'Unknown server error');
          });
        }
        return Promise.reject(err);
      }
    );
  }

  get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get<T>(url, config);
  }

  post<ResponseDto = unknown, RequestDto = unknown>(
    url: string,
    data?: RequestDto,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseDto>> {
    return this.axiosInstance.post<RequestDto, AxiosResponse<ResponseDto>>(url, data, config);
  }

  patch<ResponseDto = unknown, RequestDto = unknown>(
    url: string,
    data?: RequestDto,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseDto>> {
    return this.axiosInstance.patch<RequestDto, AxiosResponse<ResponseDto>>(url, data, config);
  }

  put<ResponseDto = unknown, RequestDto = unknown>(
    url: string,
    data?: RequestDto,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseDto>> {
    return this.axiosInstance.put<RequestDto, AxiosResponse<ResponseDto>>(url, data, config);
  }

  delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete(url, config);
  }
}
