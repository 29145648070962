import { Routes } from '@app/constants/routes';

import { ERole } from '@app/swagger-types';
import { ClientModule } from './ClientModule';
import { TypedNavigate } from '@app/router';
import { ProjectPage } from '@app/modules/projects/pages/Project.page';
import { FindingPage } from '@app/domain/pages/FindingPage';
import { MyProfilePage } from '@app/modules/admin/modules/user-management/pages/MyProfile.page';
import { TesterProfilePage } from '@app/modules/admin/modules/user-management/pages/TesterProfile.page';
import { ClientProfilePage } from '@app/modules/admin/modules/user-management/pages/ClientProfile.page';
import { ClientDashboardPage } from '@app/modules/client/pages/ClientDashboard.page';
import { MySettingsRoutes } from '@app/modules/my-settings/MySettingsRoutes';
import { MyProjectsRoutes } from '@app/modules/client/my-projects/MyProjectsRoutes';
import { CreateProjectPage } from '@app/modules/client/pages/CreateProject.page';
import { CollaboratorProfilePage } from '@app/modules/admin/modules/user-management/pages/CollaboratorProfile.page';
import { ClientInvoicesPage } from '@app/modules/client/pages/ClientInvoices.page';
import { ProjectQuestionnairePage } from '@app/modules/projects/pages/ProjectQuestionnaire.page';

export const ClientRoutes = {
  path: Routes.index,
  element: <ClientModule />,
  role: [ERole.ROLE_CLIENT],
  children: [
    { path: Routes.projects.project, element: <ProjectPage /> },
    { path: Routes.projects.questionnaire, element: <ProjectQuestionnairePage /> },
    { path: Routes.projects.finding, element: <FindingPage /> },
    { path: Routes.projects.create, element: <CreateProjectPage /> },
    { path: Routes.admin.user_management.tester_profile, element: <TesterProfilePage /> },
    { path: Routes.admin.user_management.client_profile, element: <ClientProfilePage /> },
    { path: Routes.admin.user_management.collaborator_profile, element: <CollaboratorProfilePage /> },
    { path: Routes.admin.user_management.my_profile, element: <MyProfilePage /> },
    { path: Routes.dashboard.index, element: <ClientDashboardPage /> },
    { path: Routes.finance.index, element: <ClientInvoicesPage /> },
    MySettingsRoutes,
    MyProjectsRoutes,
    { index: true, path: '*', element: <TypedNavigate to={Routes.projects.index} /> },
  ],
};
