import clsxm from '@app/lib/clsxm';
import { BreakUnit } from '../PdfPager';
import styles from '../pdf-salus.module.scss';
import { TesterTacBody } from '@app/modules/pdf/pages/tac/TesterTacBody';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { KeyAgreementTable } from '@app/modules/pdf/pages/tac/KeyAgreementTable';
import { TesterOutDto } from '@app/swagger-types';

export const TesterInvoiceBody: React.FC<{ tester: TesterOutDto }> = ({ tester }) => {
  const { firstName, lastName, address, email } = tester;
  const dateInvoiceGenerated = format(new Date(), DATE_FORMAT);

  const renderTable = ({
    role,
    address,
    email,
    firstName,
    lastName,
  }: {
    role: string;
    address?: string;
    email: string;
    firstName: string;
    lastName: string;
  }) => (
    <table className={clsxm(styles.table, styles.left)}>
      <tbody>
        <tr>
          <th className="bg-grey3" rowSpan={3}>
            {role}
          </th>
          <td>
            <b>Address:</b> {address}
          </td>
        </tr>
        <tr>
          <td>
            <b>Key Contact:</b> {firstName} {lastName}
          </td>
        </tr>
        <tr>
          <td>
            <b>Email:</b> {email}
          </td>
        </tr>
        <tr>
          <td className="bg-grey3" colSpan={2}>
            <b>Signed</b> for and on behalf of <b>{role}</b> by a duly authorised person:
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div className="flex justify-between gap-6">
              <div className="flex-1">
                <div className={clsxm(styles.p, 'border-b')}>
                  {firstName.charAt(0)} {lastName}
                </div>
                <div className={styles.p}>Signature</div>
              </div>
              <div className="flex-1">
                <div className={clsxm(styles.p, 'border-b')}>
                  {firstName} {lastName}
                </div>
                <div className={styles.p}>Full name</div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <>
      <BreakUnit>
        <div className={clsxm(styles.content, 'text-center')}>
          <div className={styles.tacHeader}>FREELANCER AGREEMENT</div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          This freelancer agreement (“<b>Agreement</b>”) (which includes this cover page and the attached terms and
          conditions) sets out the terms under which [insert Freelancer details] (“<b>Freelancer</b>”) will provide the
          Services to Salus Experts Limited, incorporated in the Virgin Islands - UK, bearing company number 2085743,
          having its registered address at Intershore Chambers, Road Town, Tortola, Virgin Islands - UK (“
          <b>Company</b>”) as an independent contractor.
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          By signing below, the parties agree to the terms and conditions of this Agreement.
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={clsxm(styles.content, 'mt-[5mm]')}>
          <KeyAgreementTable dateInvoiceGenerated={dateInvoiceGenerated} />
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={clsxm(styles.content)}>
          Executed as an agreement on {dateInvoiceGenerated} (Commencement Date)
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={clsxm(styles.content, 'mt-[5mm]')}>
          {renderTable({
            role: 'Salus Experts Limited',
            address: 'Intershore Chambers, Road Town, Tortola, Virgin Islands - UK',
            email: 'jonpaul@salus.expert',
            firstName: 'Jonpaul',
            lastName: 'Sargent',
          })}
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={clsxm(styles.content, 'mt-[5mm]')}>
          {renderTable({
            role: 'The Freelancer',
            firstName,
            lastName,
            address,
            email,
          })}
        </div>
      </BreakUnit>
      <BreakUnit pageBreak />
      <TesterTacBody />
    </>
  );
};
