import React from 'react';
import { Tooltip } from '@app/ui-components';
import { Typography } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

interface Props {
  limit?: number;
  className?: string;
  value: string;
  placement?: TooltipProps['placement'];
}

export const TruncatedTypography: React.FC<Props> = ({ limit = 20, value, className, placement }) => {
  if (value.length > limit) {
    return (
      <Tooltip title={value} placement={placement}>
        <Typography className={className} noWrap>
          {value.slice(0, limit - 3)}...
        </Typography>
      </Tooltip>
    );
  }
  return <Typography className={className}>{value}</Typography>;
};
