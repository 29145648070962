import React from 'react';
import { Typography } from '@mui/material';
import { getBonusInfo } from '@app/shared/ui-components/bonus-info-box/bonus.utils';
import { ESeverityLevel, ExtraPayOutDto } from '@app/swagger-types';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';

export const BonusInfoBox: React.FC<{
  bonus?: ExtraPayOutDto;
}> = ({ bonus }) => {
  const detailedBonuses = bonus
    ? bonus.bonuses.filter(({ severityLevel }) => severityLevel !== ESeverityLevel.INFORMATIVE).map(getBonusInfo)
    : undefined;
  return (
    <div className={clsxm('rounded-[10px] bg-transparentBackground px-8 pt-6 pb-3', sharedStyle.shadowBorder)}>
      <div className="mb-7 flex justify-between">
        <Typography className="text-xl font-bold">My bonus</Typography>
        <Typography className="text-xl font-bold">${bonus?.totalAmountCoin || 0}</Typography>
      </div>
      {detailedBonuses && (
        <div className="flex">
          <div className="w-1/5">
            <div className="py-3.5 text-center text-s font-bold text-[#7F8086]">Severity</div>
            <div className="border-y border-grey2 py-3.5 text-center text-s font-bold text-[#7F8086]">Quantity</div>
            <div className="py-3.5 text-center text-s font-bold text-[#7F8086]">Price</div>
          </div>
          {detailedBonuses.map(({ label, color, amountCoin, quantity }, index) => (
            <div key={index} className="w-1/5">
              <div className="py-3.5 text-center text-s font-bold" style={{ color }}>
                {label}
              </div>
              <div className="border-y border-grey2 py-3.5 text-center text-s">{quantity}</div>
              <div className="py-3.5 text-center text-s font-bold">${amountCoin}</div>
            </div>
          ))}
        </div>
      )}
      {!detailedBonuses && (
        <div className="h-28">
          <Typography className="text-s font-light text-grey6">No bonus yet</Typography>
        </div>
      )}
    </div>
  );
};
