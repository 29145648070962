import { InferType, number, object, string, boolean, array } from 'yup';
import { oneOfEnum } from '@app/utils/yup.utils';
import {
  EApplicationStatus,
  EInvoiceStatus,
  EMethodologyStatus,
  EPayoutStatus,
  EPenetrationType,
  EProjectStatus,
  ESeverityLevel,
  ETypeOfAnswer,
} from '@app/swagger-types';
import { convertCentToUnit, convertUnitToCent } from '@app/utils/currency.utils';
import { ClientDto, CollaboratorOutSchema, TesterDto } from '@app/models/user';
import { DEFAULT_USD_TO_GBP_RATE } from '@app/constants/currency';

export const FindingStatsOutSchema = object({
  requiresAttention: number().defined(),
  retest: number().defined(),
  fixed: number().defined(),
  informative: number().defined(),
});

export const BonusOutDtoSchema = object({
  quantity: number().defined(),
  amountCoin: number().transform(convertCentToUnit).defined(),
  severityLevel: oneOfEnum(ESeverityLevel).defined(),
});

export type BonusOutDtoSchema = InferType<typeof BonusOutDtoSchema>;

export const ExtraPayOutDtoSchema = object({
  totalAmountCoin: number().transform(convertCentToUnit).defined(),
  bonuses: array().of(BonusOutDtoSchema).defined(),
});

export type ExtraPayOutDtoSchema = InferType<typeof ExtraPayOutDtoSchema>;

export const PredefinedReportSchema = object({
  id: string().defined(),
  createdDate: string().defined(),
  title: string().optional().default(''),
  reportDescription: string().optional().default(''),
  remediationRecommendation: string().optional().default(''),
  managementReportSummary: string().optional().default(''),
});

export type PredefinedReportSchema = InferType<typeof PredefinedReportSchema>;

export const ChecklistItemOutSchema = object({
  id: string().defined(),
  name: string().defined(),
  predefinedReportId: string().defined(),
  status: oneOfEnum(EMethodologyStatus).defined(),
  tester: TesterDto.optional().default(undefined),
  predefinedReport: PredefinedReportSchema.defined(),
});

export type ChecklistItemOutSchema = InferType<typeof ChecklistItemOutSchema>;

// TODO why not reused FindingMethodologyDtoSchema from src/domain/finding/finding.schema.ts ?
export const MethodologyDtoSchema = object({
  sectionTitle: string().defined(),
  checklist: array().of(ChecklistItemOutSchema).optional().default([]),
});

export type MethodologyDtoSchema = InferType<typeof MethodologyDtoSchema>;

export const MyApplicationOutDtoSchema = object({
  id: string().defined(),
  createdDate: string().defined(),
  workingDays: number().optional().default(0),
  extraPay: number().transform(convertCentToUnit).optional().default(0),
  jsonConfig: object().optional().default({}),
  status: oneOfEnum(EApplicationStatus).defined(),
  payoutStatus: oneOfEnum(EPayoutStatus).defined(),
});

export type MyApplicationOutDtoSchema = InferType<typeof MyApplicationOutDtoSchema>;

export const AnswerOutDtoSchema = object({
  id: string().defined(),
  title: string().defined(),
  createdDate: string().defined(),
  value: string().optional().default(''),
  jsonConfig: object().optional().default({}),
  typeOfAnswer: oneOfEnum(ETypeOfAnswer).defined(),
});

export type AnswerOutDtoSchema = InferType<typeof AnswerOutDtoSchema>;

export const InvoiceProjectDtoSchema = object({
  id: string().required(),
  createdDate: string().required(),
  /**
   * TODO refactor all transormed "Coin" suffixes to "USD" e.g. "amountCoin -> amountUSD" to avoid confusion
   * about magnitude of value
   */
  amountCoin: number().transform(convertCentToUnit).required(),
  status: oneOfEnum(EInvoiceStatus).required(),
  usdToGbpRate: number().optional().default(DEFAULT_USD_TO_GBP_RATE),
  isD2NA: boolean().optional().default(false),
});

export type InvoiceProjectDtoSchema = InferType<typeof InvoiceProjectDtoSchema>;

// TODO checkTypeCompliance for Swagger DTO
export const ProjectOutDtoSchema = object({
  id: string().defined(),
  name: string().defined(),
  desiredDeadline: string().defined(),
  desiredTesterAssignment: number().defined(),
  createdDate: string().defined(),
  comment: string().optional().default(''),
  startDate: string().optional(),
  endDate: string().optional(),
  rejectReason: string().optional().default(undefined),
  workingDays: number().optional().default(0),
  priceForClient: number().transform(convertCentToUnit).optional().default(0),
  priceForTester: number().transform(convertCentToUnit).optional().default(0),
  dayRateForClient: number().transform(convertCentToUnit).optional().default(0),
  dayRateForTester: number().transform(convertCentToUnit).optional().default(0),
  numOfRequests: number().optional().default(0),
  isNew: boolean().optional().default(false),
  isD2NA: boolean().optional().default(false),
  usdToGbpRate: number().optional().default(DEFAULT_USD_TO_GBP_RATE),
  penetrationType: oneOfEnum(EPenetrationType).defined(),
  productTitle: string().defined(),
  status: oneOfEnum(EProjectStatus).defined(),
  testers: array().of(TesterDto).optional().default([]),
  totalOtherFindings: number().default(0),
  client: ClientDto.defined(),
  collaborators: array(CollaboratorOutSchema).optional().default([]),
  answers: array().of(AnswerOutDtoSchema).optional().default([]),
  invoices: array().of(InvoiceProjectDtoSchema).optional().default([]),
  myApplication: MyApplicationOutDtoSchema.optional().default(undefined),
  myBonus: ExtraPayOutDtoSchema.optional().default(undefined),
  methodologies: array().of(MethodologyDtoSchema).optional().default([]),
  findingStats: FindingStatsOutSchema.optional().default(undefined),
});

export type ProjectOutDtoSchema = InferType<typeof ProjectOutDtoSchema>;

export const RejectProjectValidationSchema = object({
  rejectReason: string().optional().default(undefined),
});

export type RejectProjectValidationSchema = InferType<typeof RejectProjectValidationSchema>;

export const SetUpProjectValidationSchema = object({
  workingDays: number().required('Required'),
  priceForClient: number().transform(convertUnitToCent).required('Required'),
  priceForTester: number().transform(convertUnitToCent).required('Required'),
  isD2NA: boolean().optional().default(false),
  usdToGbpRate: number().optional().default(DEFAULT_USD_TO_GBP_RATE).max(3).min(0.1),
  startDate: string().required('Required'),
  endDate: string().required('Required'),
});

export type SetUpProjectValidationSchema = InferType<typeof SetUpProjectValidationSchema>;
