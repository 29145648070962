import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import clsxm from '@app/lib/clsxm';
import { BreakUnit } from '../../PdfPager';
import styles from '../../pdf-salus.module.scss';
import { MethodologyStatBox } from '@app/shared/ui-components/methodology-stat-box/MethodologyStatBox';
import { EMethodologyStatus } from '@app/swagger-types';

const sum = (a: number, b: number) => a + b;

export const ReportSectionSummaryOfStrengths: React.FC<{
  project: ProjectOutDtoSchema;
}> = ({ project }) => {
  const totalExamined = project.methodologies
    .map(
      (e) =>
        e.checklist.filter((e) => e.status !== EMethodologyStatus.N_A && e.status !== EMethodologyStatus.TODO).length
    )
    .reduce(sum, 0);
  const passedChecks = project.methodologies
    .map((e) => e.checklist.filter((e) => e.status === EMethodologyStatus.PASS).length)
    .reduce(sum, 0);

  return (
    <>
      <BreakUnit pageBreak />
      <BreakUnit header>
        <div className={styles.content}>
          <div className={styles.pageHeader} id="summary_of_strengths">
            Summary of Strengths
          </div>
          <div className={styles.divider} />
          <div className={styles.p}>
            {`${project.client.companyName}'s ${project.name} successfully passed ${passedChecks} out of ${totalExamined} checks. The diagram and table below demonstrates areas of strength within the examined scope.`}
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="h-[100mm]">
            <MethodologyStatBox
              methodologies={project.methodologies}
              disableAnimation
              hideHeader
              className="bg-transparent"
            />
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <br />
          <div className={styles.sectionHeader}>Examined Categories in Order of Strength</div>
          <div className={styles.divider} />
          <br />
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex w-full font-bold text-white">
            <div className="flex-[3]">Checklist Category</div>
            <div className="flex-1 text-center">Passed</div>
            <div className="flex-1 text-center">Failed</div>
            <div className="flex-1 whitespace-nowrap text-center">Not Applicable</div>
          </div>
        </div>
      </BreakUnit>
      {project.methodologies.map((m, i, arr) => {
        const isNotLast = i !== arr.length - 1;
        return (
          <BreakUnit key={i}>
            <div className={clsxm(styles.content)}>
              <div className="flex w-full items-center py-2">
                <div className="flex-[3]">{m.sectionTitle}</div>
                <div className={clsxm('flex-1 text-center', styles.textSuccess)}>
                  {m.checklist.filter((e) => e.status === EMethodologyStatus.PASS).length}
                </div>
                <div className={clsxm('flex-1 text-center', styles.textError)}>
                  {m.checklist.filter((e) => e.status === EMethodologyStatus.FAIL).length}
                </div>
                <div className="flex-1 text-center">
                  {m.checklist.filter((e) => e.status === EMethodologyStatus.N_A).length}
                </div>
              </div>
              {isNotLast && <div className={styles.gradientDivider} />}
            </div>
          </BreakUnit>
        );
      })}
    </>
  );
};
