import React, { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UseMutateFunction } from 'react-query/types/react/types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { Dialog } from '@app/hoc';
import { ClientDto } from '@app/models/user';
import { Button, Form, Input } from '@app/ui-components';
import { ClientProfileSchema } from '@app/domain/user-profile/user-profile.form';
import { ClientInDto } from '@app/swagger-types';

interface Props {
  open: boolean;
  onClose: () => void;
  client: ClientDto;
  onConfirm: UseMutateFunction<unknown, Error, Partial<ClientInDto>>;
  isLoading?: boolean;
}

export const EditClientDialog: React.FC<Props> = ({ open, client, onClose, onConfirm, isLoading }) => {
  const { firstName, lastName, companyName, companyAddress } = client;
  const { control, handleSubmit, register, clearErrors, reset, formState } = useForm<ClientProfileSchema>({
    resolver: yupResolver(ClientProfileSchema),
    defaultValues: {
      firstName,
      lastName,
      companyName,
      companyAddress,
    },
  });

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: isLoading,
      };
    };
    return fn;
  }, [register, formState, isLoading]);

  const handleClose = useCallback(() => {
    clearErrors();
    reset();
    onClose();
  }, [clearErrors, reset, onClose]);

  const onSubmit: SubmitHandler<ClientProfileSchema> = (formData) => {
    clearErrors();

    onConfirm(formData, {
      onSuccess: () => {
        reset(formData);
        onClose();
      },
    });
  };

  return (
    <Dialog open={open} paperClassName="p-7 w-[436px]" headerTitle="Edit Profile" onClose={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)} control={control}>
        <div className="overflow-y-auto p-7">
          <Input
            {...inputRegister('firstName')}
            label="First Name"
            placeholder="Enter First Name"
            id="name"
            className="mb-8"
          />
          <Input
            {...inputRegister('lastName')}
            label="Last Name"
            placeholder="Enter Last Name"
            id="last"
            className="mb-8"
          />
          <Input
            {...inputRegister('companyName')}
            label="Company Name"
            placeholder="Enter Company Name"
            className="mb-8"
          />
          <Input {...inputRegister('companyAddress')} label="Company Address" placeholder="Enter Company Address" />
        </div>
        <div className="flex justify-center gap-6 pb-3 pt-10">
          <Button variant="outlined" color="secondary" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="outlined" color="primary" type="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Dialog>
  );
};
