import { UserApi } from '@app/domain/user/user.api';
import { useMutation, useQueryClient } from 'react-query';
import { GET_MY_PROFILE_QUERY } from '@app/constants/query-api-configs';
import useStore from '@app/store/useStore.hook';
import { invalidateAllAndSpecificUserProfileById } from '../user-profile/util/user-profile.util';

export const useSlackSync = () => {
  const { getMe, currentUser } = useStore(({ getMe, currentUser }) => ({
    getMe,
    currentUser,
  }));

  const queryClient = useQueryClient();

  const {
    mutate: sync,
    isLoading,
    error,
  } = useMutation(async () => {
    if (!currentUser) {
      return;
    }
    await UserApi.syncMySlackSilent();
    // TODO refactor getMe to rely fully on react-query to avoid double call of AuthApi.me()
    await getMe();
    await queryClient.invalidateQueries(GET_MY_PROFILE_QUERY.name);
    await invalidateAllAndSpecificUserProfileById(queryClient, currentUser.id);
  });

  return { isLoading, error, sync };
};
