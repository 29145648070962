import React, { useCallback } from 'react';
import { useAsync } from 'react-use';

import { useTypedNavigate, useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { ProjectApi } from '@app/domain/project/project.api';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { FindingForm } from '@app/domain/finding/FindingForm';
import { useMutation, useQueryClient } from 'react-query';
import { FindingInDto } from '@app/swagger-types';
import { FindingApi } from '@app/domain/finding/finding.api';
import { GET_FINDINGS_QUERY, GET_PROJECT_QUERY, GET_PROJECTS_QUERY } from '@app/constants/query-api-configs';
import Notification from '@app/shared/ui-components/notifications/Notifications';

export const CreateFindingPage: React.FC = () => {
  const { projectId } = useTypedParams(Routes.projects.addFinding);
  const navigate = useTypedNavigate();

  const navigateToProjectPage = useCallback(() => {
    if (projectId) {
      navigate({ to: Routes.projects.project, params: { id: projectId } });
    }
  }, [navigate, projectId]);

  const {
    loading: projectLoading,
    error,
    value: project,
  } = useAsync(async () => {
    if (projectId) {
      return await ProjectApi.getProject(projectId);
    }
  }, [projectId]);

  const queryClient = useQueryClient();

  const { mutate: handleCreateFinding, isLoading: creationLoading } = useMutation<unknown, Error, FindingInDto>(
    async (Dto) => {
      if (projectId) {
        return await FindingApi.createFinding(projectId, Dto);
      }
    }
  );

  const handleSubmit = (formData: FindingInDto) => {
    handleCreateFinding(formData, {
      onSuccess: () => {
        queryClient.removeQueries(GET_FINDINGS_QUERY.name);
        queryClient.removeQueries(GET_PROJECT_QUERY.name);
        queryClient.removeQueries(GET_PROJECTS_QUERY.name);
        navigateToProjectPage();
        Notification.showSuccess('Finding has been successfully added');
      },
    });
  };

  if (!projectId || !project || projectLoading) {
    return <ContainerLoader loading={projectLoading} error={error} noData={!project} />;
  }

  return (
    <FindingForm
      project={project}
      onConfirm={handleSubmit}
      loading={creationLoading}
      title={<>Project Page &mdash; Add finding</>}
    />
  );
};
