import { InternalApiRequest, validateAndAssert } from '@app/services/api-request/';
import { PRIVATE_URL_PREFIX } from '@app/api/types';
import { ProductOutSchema } from '@app/domain/product/product.schema';
import { pageableData } from '@app/utils/yup.utils';

export abstract class ProductApi {
  static async getProducts() {
    return validateAndAssert(InternalApiRequest.get(`${PRIVATE_URL_PREFIX}/products`), pageableData(ProductOutSchema));
  }
}
