import React, { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';

import { SignInFormValidationSchema } from '@app/models/auth';

import { TypedLink } from '@app/router';
import { Input, Button, Form, Checkbox } from '@app/ui-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { GoogleIcon, KeyIcon, UserIcon } from '@app/assets/icons';
import { IS_NOT_PROD_ENV, IS_DEV_ENV } from '@app/environment/typed-env';
import { useQueryParams } from 'use-query-params';
import { StringParam } from '@app/utils/use-query-params.utils';

const GOOGLE_AUTHORIZATION_LINK = IS_DEV_ENV
  ? 'https://api-dev.salus.expert/oauth2/authorization/google'
  : 'https://api.salus.expert/oauth2/authorization/google';

interface Props {
  onSignUp: (data: SignInFormValidationSchema) => void;
  loading: boolean;
  is2FA: boolean;
  onToggle2FA: () => void;
  error?: Error | null;
}

export const SignInForm: React.FC<Props> = ({ onSignUp, loading, error, is2FA, onToggle2FA }) => {
  const { control, formState, handleSubmit, register, clearErrors } = useForm<SignInFormValidationSchema>({
    resolver: yupResolver(SignInFormValidationSchema),
  });

  const [{ errMsg }] = useQueryParams({ errMsg: StringParam });

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: loading,
      };
    };
    return fn;
  }, [register, formState, loading]);

  const onSubmit: SubmitHandler<SignInFormValidationSchema> = (formData) => {
    clearErrors();
    onSignUp(formData);
  };

  const anyError = error || (errMsg ? new Error('Error. Try again') : null);

  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <Typography variant="xxxl" className="mb-[14px] font-bold">
          Log In
        </Typography>
        {IS_NOT_PROD_ENV && <Checkbox label="2FA" color="primary" onChange={onToggle2FA} checked={is2FA} />}
      </div>
      <Typography variant="l" className="!mb-[39px] block text-grey5">
        {`Don't have an account? `}
        <TypedLink to={Routes.auth.sign_up} className="font-bold text-grey5 hover:text-grey6">
          Sign up
        </TypedLink>
      </Typography>
      <Form control={control} onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Input
          {...inputRegister('email')}
          label="Email"
          placeholder="Enter email"
          id="email"
          withBackground
          startAdornment={
            <div className={'pl-[19px]'}>
              <UserIcon />
            </div>
          }
        />
        <Input
          {...inputRegister('password')}
          label="Password"
          placeholder="Enter password"
          type="password"
          id="password"
          withBackground
          startAdornment={
            <div className={'pl-[19px]'}>
              <KeyIcon />
            </div>
          }
        />
        <TypedLink to={Routes.auth.forgot_password} className="block text-s font-bold text-grey5 hover:text-grey6">
          Forgot Password?
        </TypedLink>
        <Button type="submit" variant="outlined" color="gold" fullWidth className="!mt-9 font-medium" loading={loading}>
          Log In
        </Button>
        <a href={GOOGLE_AUTHORIZATION_LINK}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            fullWidth
            className="mt-3.5 font-medium"
            startIcon={<GoogleIcon />}
          >
            Login with Google (Salus Team Only)
          </Button>
        </a>
        {maybeRenderError(anyError)}
      </Form>
    </div>
  );
};
