import React, { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UseMutateFunction } from 'react-query/types/react/types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { Dialog } from '@app/hoc';
import { Button, Form, Input } from '@app/ui-components';
import { AdminProfileSchema } from '@app/domain/user-profile/user-profile.form';
import { UserInDto } from '@app/swagger-types';
import { SuperAdminDto } from '@app/models/super-admin';

interface Props {
  open: boolean;
  onClose: () => void;
  admin: SuperAdminDto;
  onConfirm: UseMutateFunction<unknown, Error, Partial<UserInDto>>;
  isLoading?: boolean;
}

export const EditAdminDialog: React.FC<Props> = ({ open, admin, onClose, onConfirm, isLoading }) => {
  const { firstName, lastName } = admin;
  const { control, handleSubmit, register, clearErrors, reset, formState } = useForm<AdminProfileSchema>({
    resolver: yupResolver(AdminProfileSchema),
    defaultValues: {
      firstName,
      lastName,
    },
  });

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: isLoading,
      };
    };
    return fn;
  }, [register, formState, isLoading]);

  const handleClose = useCallback(() => {
    clearErrors();
    reset();
    onClose();
  }, [clearErrors, reset, onClose]);

  const onSubmit: SubmitHandler<AdminProfileSchema> = (formData) => {
    clearErrors();

    onConfirm(formData, {
      onSuccess: () => {
        reset(formData);
        onClose();
      },
    });
  };

  return (
    <Dialog open={open} paperClassName="p-7 w-[436px]" headerTitle="Edit Profile" onClose={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)} control={control}>
        <div className="p-7">
          <Input
            {...inputRegister('firstName')}
            label="First Name"
            placeholder="Enter First Name"
            id="name"
            className="mb-8"
          />
          <Input {...inputRegister('lastName')} label="Last Name" placeholder="Enter Last Name" id="last" />
        </div>
        <div className="flex justify-center gap-6 pb-3 pt-10">
          <Button variant="outlined" color="secondary" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="outlined" color="primary" type="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Dialog>
  );
};
