import styles from './Select.module.scss';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import React from 'react';

import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';

import { Input, InputProps } from '@app/ui-components';
import clsxm from '@app/lib/clsxm';

export interface SelectProps<T> extends Omit<MuiSelectProps<T>, 'inputProps' | 'size'> {
  inputProps?: InputProps;
  size?: 'large' | 'medium' | 'small';
  withPlaceholder?: boolean;
  iconColor?: 'orange' | 'white';
  menuClassName?: string;
  noOptionsText?: string;
}

export const Select = <T,>({
  inputProps,
  children,
  disabled,
  MenuProps,
  menuClassName,
  size = 'medium',
  iconColor = 'white',
  noOptionsText = 'No options',
  ...props
}: SelectProps<T>): JSX.Element => {
  return (
    <MuiSelect
      input={<Input {...inputProps} />}
      variant={inputProps?.variant}
      disabled={disabled}
      MenuProps={{
        classes: { paper: clsxm(props.multiple ? styles.SelectPaperMultiple : styles.SelectPaper, menuClassName) },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        elevation: 1,
        ...MenuProps,
      }}
      classes={{
        select: clsx(styles.Select, styles[capitalize(size)]),
        disabled: styles.Disabled,
      }}
      IconComponent={(props) => {
        return !disabled ? (
          <div className={clsxm(styles.ArrowIconWrapper, styles[capitalize(size)])}>
            <div
              className={clsxm(
                inputProps?.variant === 'filled' ? styles.ArrowNoBg : null,
                styles.ArrowIcon,
                props.className.includes('MuiSelect-iconOpen') ? styles.Expanded : null,
                styles[capitalize(size)],
                styles[capitalize(iconColor)]
              )}
            >
              <ChevronDownIcon />
            </div>
          </div>
        ) : null;
      }}
      {...props}
    >
      {React.Children.count(children) < 1 ? <MenuItem disabled>{noOptionsText}</MenuItem> : children}
    </MuiSelect>
  );
};
