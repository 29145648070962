import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { GET_PRODUCTS_QUERY } from '@app/constants/query-api-configs';
import { ProductApi } from '@app/domain/product/product.api';
import { Table, TableColumn, Button } from '@app/ui-components';
import { MethodologyBlueprintSchema, ProductOutSchema, QuestionSchema } from '@app/domain/product/product.schema';
import { DemoQuestionnaireDialog } from '@app/domain/product/DemoQuestionnaireDialog';
import { ProductMethodologiesDialog } from '@app/domain/product/ProductMethodologiesDialog';

type MethodologyInfo = {
  methodologyTitle: string;
  methodologies: MethodologyBlueprintSchema[];
};

export const ProductsAndReportsPage: React.FC = () => {
  const [questions, setQuestions] = useState<QuestionSchema[]>();
  const [methodologyInfo, setMethodologyInfo] = useState<MethodologyInfo>();

  const handleOpenProductMethodologiesDialog = useCallback((methodologyInfo: MethodologyInfo) => {
    setMethodologyInfo(methodologyInfo);
  }, []);

  const handleCloseProductMethodologiesDialog = useCallback(() => {
    setMethodologyInfo(undefined);
  }, []);

  const handleOpenDemoQuestionnaireDialog = useCallback((questions: QuestionSchema[]) => {
    setQuestions(questions);
  }, []);

  const handleCloseDemoQuestionnaireDialog = useCallback(() => {
    setQuestions(undefined);
  }, []);

  const { isFetching, data: products } = useQuery(
    [GET_PRODUCTS_QUERY.name],
    async () => {
      const data = await ProductApi.getProducts();
      return data.result;
    },
    { keepPreviousData: true, staleTime: GET_PRODUCTS_QUERY.config.staleTime }
  );

  const columns: TableColumn<ProductOutSchema>[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'title',
        align: 'left',
      },
      {
        headerName: 'Methodology',
        field: 'methodologyTitle',
        align: 'left',
        render: ({ methodologyTitle, methodologies }) => (
          <Button
            variant="text"
            onClick={() => handleOpenProductMethodologiesDialog({ methodologyTitle, methodologies })}
            className="!bg-transparent p-0 text-xs font-normal underline"
          >
            {methodologyTitle}
          </Button>
        ),
      },
      {
        headerName: 'Questionnaire',
        field: 'questionnaireTitle',
        align: 'right',
        disableSort: true,
        render: ({ questions }) => (
          <Button
            variant="text"
            onClick={() => handleOpenDemoQuestionnaireDialog(questions)}
            className="!bg-transparent p-0 text-xs font-normal underline"
          >
            View
          </Button>
        ),
      },
    ],
    [handleOpenDemoQuestionnaireDialog, handleOpenProductMethodologiesDialog]
  );

  return (
    <div>
      <Typography className="mb-8 text-xxxl font-bold">Products & Reports</Typography>
      <Table
        cols={columns}
        tableData={products || []}
        loading={isFetching}
        hideFooter
        wrapperClassName="max-w-[930px]"
        cardContentClassName="h-auto overflow-visible"
      />
      <DemoQuestionnaireDialog
        open={Boolean(questions)}
        onClose={handleCloseDemoQuestionnaireDialog}
        questions={questions}
      />
      <ProductMethodologiesDialog
        open={Boolean(methodologyInfo)}
        onClose={handleCloseProductMethodologiesDialog}
        methodologies={methodologyInfo?.methodologies}
        methodologyTitle={methodologyInfo?.methodologyTitle}
      />
    </div>
  );
};
