import clsxm from '@app/lib/clsxm';
import { BreakUnit } from '../PdfPager';
import styles from '../pdf-salus.module.scss';
import { ClientTacBody } from '@app/modules/pdf/pages/tac/ClientTacBody';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { ReactNode } from 'react';
import { InvoiceOutDto } from '@app/swagger-types';
import { D2NA_FULL_NAME, SALUS_FULL_NAME } from '@app/domain/d2na/d2na.constants';

export const ClientInvoiceBody: React.FC<{
  invoice: InvoiceOutDto;
  lastPageIsEditable?: boolean;
}> = ({ invoice, lastPageIsEditable = false }) => {
  const {
    amountCoin,
    project: {
      productTitle,
      startDate,
      endDate,
      client: { firstName, lastName, companyName, companyAddress },
    },
    isD2NA,
    usdToGbpRate,
  } = invoice;

  const { formatGBP } = createGBPformatter(usdToGbpRate);
  const smartFormatter = isD2NA ? formatGBP : formatDollars;

  const dateInvoiceGenerated = format(new Date(invoice.createdDate), DATE_FORMAT);
  const fullName = `${firstName} ${lastName}`;

  const renderItem = (tabs: string[], { title, text }: { title?: ReactNode; text?: ReactNode }) => (
    <BreakUnit>
      <div className={clsxm(styles.tacRow, 'flex')}>
        {tabs.map((tab, index) => (
          <div key={index} className={styles.tab}>
            {tab}
          </div>
        ))}
        {title && <b>{title}</b>}
        {text && <div className="w-full">{text}</div>}
      </div>
    </BreakUnit>
  );

  return (
    <>
      {renderItem([], { title: companyName })}
      {renderItem([], { title: companyAddress })}
      {renderItem([], { title: dateInvoiceGenerated })}
      {renderItem([], { title: `Dear ${fullName}` })}
      {renderItem(['1'], {
        text: isD2NA ? (
          <>
            This engagement letter (“<b>Letter</b>”) sets out the terms and conditions (“<b>Terms</b>”) that will apply
            in relation to the engagement of D2 Network Associates Limited, incorporated in the UK, bearing company
            number 05638885, having its registered address at Unit 4 Phoenix Works 500-51 King Street, Longton,
            Stoke-On-Trent, Staffordshire, England, ST3 1EZ, (“<b>D2NA</b>” or “<b>us</b>” or “<b>we</b>” or “<b>our</b>
            ”) by {companyName} (“<b>Client</b>” or “<b>you</b>”), to provide the Services (as defined below) set out in
            the scope of work mentioned in <b>Schedule 1</b> to the Terms.
          </>
        ) : (
          <>
            This engagement letter (“<b>Letter</b>”) sets out the terms and conditions (“<b>Terms</b>”) that will apply
            in relation to the engagement of Salus Experts Limited, incorporated in the Virgin Islands - UK, bearing
            company number 2085743, having its registered address at Intershore Chambers, Road Town, Tortola, Virgin
            Islands - UK, (“<b>Salus</b>” or “<b>us</b>” or “<b>we</b>” or “<b>our</b>”) by {companyName} (“
            <b>Client</b>” or “<b>you</b>”), to provide the Services (as defined below) set out in the scope of work
            mentioned in <b>Schedule 1</b> to the Terms.
          </>
        ),
      })}
      {renderItem(['2'], {
        text: (
          <>
            We have agreed the scope of work in relation to your engagement as specified in <b>Schedule 1</b> to the
            Terms.
          </>
        ),
      })}
      {renderItem(['3'], {
        text: (
          <>
            This Letter and the Terms constitute a legally binding arrangement between the Client and us in respect of
            the engagement.
          </>
        ),
      })}
      {renderItem([], {
        text: <div className="mb-[10mm]">Yours sincerely</div>,
      })}
      {renderItem([], { title: 'Jonpaul Sargent' })}
      {renderItem([], {
        text: (
          <div className="mb-[10mm]">
            On behalf of <b className="uppercase">{isD2NA ? D2NA_FULL_NAME : SALUS_FULL_NAME}</b>
          </div>
        ),
      })}
      {renderItem([], {
        text: (
          <div className="flex w-full">
            <div className="flex-[5] text-left">Agreed and signed by:</div>
            <div className="flex-[7] text-left">
              <div className="mb-[10mm]">
                <div className="mb-[1mm] border-b border-dotted underline-offset-8">{fullName}</div>
                <div>Client</div>
              </div>
              <div>
                <div className="mb-[1mm] border-b border-dotted underline-offset-8">{companyName}</div>
                <div>On behalf of (if applicable) </div>
              </div>
            </div>
          </div>
        ),
      })}
      <BreakUnit pageBreak />
      <ClientTacBody isD2NA={isD2NA} />
      <BreakUnit pageBreak />
      <BreakUnit>
        <div className={clsxm(styles.content, 'text-center')}>
          <div className={styles.tacHeader}>SCHEDULE 1</div>
        </div>
      </BreakUnit>
      {renderItem(['1'], { text: 'Client details:' })}
      {renderItem(['', '-'], { title: companyName })}
      {renderItem(['', '-'], { title: companyAddress })}
      {renderItem(['2'], { text: 'Services and Fees:' })}
      <BreakUnit>
        <div className={clsxm(styles.content, 'mt-[5mm]')} contentEditable={lastPageIsEditable}>
          <table className={styles.table}>
            <tbody>
              <tr>
                <th>Service</th>
                <th>Timeline</th>
                <th>Fees</th>
              </tr>
              <tr>
                <td>{productTitle}</td>
                <td>
                  <div>
                    <div>Start Date: {startDate ? format(new Date(startDate), DATE_FORMAT) : '-'}</div>
                    <div>End Date: {endDate ? format(new Date(endDate), DATE_FORMAT) : '-'}</div>
                  </div>
                </td>
                <td>{smartFormatter(amountCoin)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BreakUnit>
    </>
  );
};
