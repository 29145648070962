import React from 'react';
import { Typography } from '@mui/material';

export const NoProjectsItem: React.FC = () => {
  return (
    <div className="center flex h-28 w-full items-center justify-center rounded-md border-2 border-solid border-grey4 bg-[#30313b]/30 px-7 py-6">
      <Typography className="text-l font-light italic text-grey6">No Projects</Typography>
    </div>
  );
};
