import { overwrite } from 'country-list';

overwrite([
  { code: 'BO', name: 'Bolivia' },
  { code: 'CD', name: 'Democratic Republic of the Congo' },
  { code: 'FM', name: 'Micronesia' },
  { code: 'IR', name: 'Iran' },
  { code: 'KP', name: 'North Korea' },
  { code: 'KR', name: 'South Korea' },
  { code: 'MD', name: 'Moldova' },
  { code: 'PS', name: 'Palestine' },
]);
