import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { truncate } from 'lodash';
import Draggable from 'react-draggable';
import {
  Dialog as MuiDialog,
  DialogProps,
  DialogTitle,
  Typography,
  Paper,
  PaperProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import clsxm from '@app/lib/clsxm';
import { IconButton } from '@app/ui-components';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"], .not-draggable'}>
      <Paper {...props} />
    </Draggable>
  );
}

type Props = {
  headerTitle?: string;
  headerSubtitle?: string;
  headerEntity?: string;
  paperClassName?: string;
  hideHeader?: boolean;
  isDraggable?: boolean;
  headerContent?: React.ReactNode;
} & DialogProps;

export const Dialog: React.FC<Props> = ({
  children,
  open,
  headerTitle,
  headerSubtitle,
  headerEntity,
  onClose,
  paperClassName,
  hideHeader,
  isDraggable,
  headerContent,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MuiDialog
      open={open}
      PaperProps={{
        className: clsxm(
          'rounded-[10px] bg-black bg-none shadow-[0px 75px 63px -48px rgba(0, 0, 0, 0.25)] p-7',
          paperClassName
        ),
      }}
      onClose={onClose}
      {...props}
      {...(isDraggable && !isMobile
        ? {
            PaperComponent: PaperComponent,
            'aria-labelledby': 'draggable-dialog-title',
          }
        : {})}
      disablePortal
    >
      {!hideHeader && (
        <DialogTitle
          id="draggable-dialog-title"
          className={clsxm('mb-6 border-b border-grey5/20 p-0 pb-5', {
            'cursor-move': isDraggable,
          })}
        >
          <div className="flex justify-between">
            <div>
              <Typography className="text-xs" noWrap>
                {truncate(headerTitle, { length: 120 })}
              </Typography>
              {Boolean(headerSubtitle) && (
                <Typography noWrap className="mt-4 text-xs text-grey6">
                  {truncate(headerSubtitle, { length: 120 })}
                </Typography>
              )}
            </div>
            {onClose && (
              <IconButton onClick={() => onClose({}, 'backdropClick')} className="absolute right-4 top-4">
                <CloseIcon />
              </IconButton>
            )}
          </div>

          {headerEntity && <Typography>{headerEntity}</Typography>}
          {headerContent}
        </DialogTitle>
      )}

      {children}
    </MuiDialog>
  );
};
