import { useCallback, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { UIKitSection } from '../UIKitSection';
import { TimelineBox } from '@app/shared/ui-components/timeline-box/TimelineBox';
import { createUiKitProject } from '@app/modules/ui-kit/pages/mock-data/project.data';

enum EMode {
  HAS_TIMELINE = 'HAS_TIMELINE',
  NO_TIMELINE = 'NO_TIMELINE',
}

export default function DemoForProjectTimelineBox() {
  const [mode, setMode] = useState(EMode.HAS_TIMELINE);

  const handleEdit = useCallback(() => {
    console.log('on edit');
  }, []);

  return (
    <UIKitSection title="TimelineBox">
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setMode(e.currentTarget.value as EMode);
        }}
      >
        <FormControlLabel value={EMode.HAS_TIMELINE} control={<Radio />} label="Has timeline" />
        <FormControlLabel value={EMode.NO_TIMELINE} control={<Radio />} label="No timeline" />
      </RadioGroup>
      {mode === EMode.HAS_TIMELINE && <TimelineBox project={createUiKitProject()} onEdit={handleEdit} />}
      {mode === EMode.NO_TIMELINE && (
        <TimelineBox
          project={createUiKitProject({
            startDate: undefined,
            endDate: undefined,
            workingDays: undefined,
            priceForTester: undefined,
            priceForClient: undefined,
          })}
          onEdit={handleEdit}
        />
      )}
    </UIKitSection>
  );
}
