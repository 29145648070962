import React, { useState } from 'react';
import styles from '../pdf-salus.module.scss';
import TechBg from './assets/tech-bg.png';
import { AppLogo } from '@app/assets/app-logo/AppLogo';
import clsxm from '@app/lib/clsxm';
import { useAsync } from 'react-use';
import { ProjectApi } from '@app/domain/project/project.api';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { Footer, IPdfRenderResult, PdfPager } from '../PdfPager';
import { ReportSectionEngagementDetails } from './sections/ReportSectionEngagementDetails';
import { ReportSectionExecutiveSummary } from './sections/ReportSectionExecutiveSummary';
import { ReportSectionPostEngagementActions } from './sections/ReportSectionPostEngagementActions';
import { ReportSectionSummaryOfFindings } from './sections/ReportSectionSummaryOfFindings';
import { ReportSectionSummaryOfStrengths } from './sections/ReportSectionSummaryOfStrengths';
import { ReportSectionTechnicalFindings } from './sections/ReportSectionTechnicalFindings';
import { FindingApi } from '@app/domain/finding/finding.api';
import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { ReportSectionDocumentProperties } from './sections/ReportSectionDocumentProperties';
import { ReportSectionAppendix } from './sections/ReportSectionAppendix';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { PaginatedResponse } from '@app/api/types';
import { FindingOutSchema } from '@app/domain/finding/finding.schema';
import { FixedHeader, TableOfContents } from './shared';
import { CLASSNAME_FOR_PDF_READY, ID_FOR_TABLE_OF_CONTENTS, TODAY_STRING } from '../constants';

export const TechnicalReportPage: React.FC = () => {
  const { projectId } = useTypedParams(Routes.pdf.technicalReport);
  const [result, setResult] = useState<IPdfRenderResult | null>(null);

  const { value: project, error } = useAsync(async () => {
    if (!projectId) {
      return;
    }
    const p = await ProjectApi.getProject(projectId);
    console.log('p', p);
    return p;
  }, [projectId]);

  const { value: findings, error: findingsError } = useAsync(async () => {
    if (!projectId) {
      return;
    }
    const res = await FindingApi.getFindingsByProjectId({
      projectId,
      size: 0,
    });
    console.log('findings', res);
    return res;
  }, [projectId]);
  const anyError = error || findingsError;

  if (!project || !findings || anyError) {
    return (
      <div className="h-screen">
        <ContainerLoader loading={!project || !findings} error={anyError} />
      </div>
    );
  }

  return (
    <div className={clsxm(styles.mainWrap, result && CLASSNAME_FOR_PDF_READY)}>
      <FixedHeader projectId={projectId} currentPdf="tech" />

      <div className={clsxm(styles.page)}>
        <img className="absolute top-0 z-10 h-full w-full object-cover" src={TechBg} alt="cover" />
        <div
          className={clsxm(
            styles.logoWrap,
            'absolute top-0 left-16 z-10 w-fit bg-[#171717]/95 pt-8 pb-10',
            project.isD2NA && 'px-3'
          )}
        >
          <AppLogo width={project.isD2NA ? 140 : 86} height={86} d2na={project.isD2NA} />
        </div>
        <div className="absolute top-1/3 left-0 z-20 w-full bg-[#171717]/95">
          <div className={styles.allPadding}>
            <div>{TODAY_STRING}</div>
            <div className={styles.projectName}>{project.name}</div>
            <div className={styles.reportType}>Technical Penetration Test Report</div>
            <div className={styles.divider} />
            <div className={styles.py}>{`Prepared for ${project.client.companyName}`}</div>
          </div>
        </div>
      </div>

      <div className={clsxm(styles.page)} id={ID_FOR_TABLE_OF_CONTENTS}>
        <div className={styles.content}>
          <div className={styles.pageHeader}>Table of Contents</div>
          <div className={styles.divider} />
          {result && <TableOfContents result={result} />}
          <Footer pageNumber={2} isD2NA={project.isD2NA} />
        </div>
      </div>

      <MemoPdf {...{ setResult, project, findings }} />
    </div>
  );
};

// memoization is critical to avoid infinite loop rerendering
const MemoPdf = React.memo<{
  project: ProjectOutDtoSchema;
  findings: PaginatedResponse<FindingOutSchema>;
  setResult: React.Dispatch<React.SetStateAction<IPdfRenderResult | null>>;
}>(({ setResult, project, findings }) => {
  return (
    <PdfPager indexShift={2} onResult={setResult} headerSelector={`.${styles.pageHeader}`} isD2NA={project.isD2NA}>
      <ReportSectionEngagementDetails project={project} />
      <ReportSectionSummaryOfStrengths project={project} />
      <ReportSectionSummaryOfFindings project={project} findings={findings} />
      <ReportSectionExecutiveSummary project={project} findings={findings} />
      <ReportSectionPostEngagementActions project={project} findings={findings} />
      <ReportSectionTechnicalFindings project={project} findings={findings} />
      <ReportSectionDocumentProperties project={project} />
      <ReportSectionAppendix project={project} findings={findings} />
    </PdfPager>
  );
});
