import React from 'react';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { ConfirmDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDialog';
import { Button } from '@app/ui-components';
import { EUserStatus } from '@app/swagger-types';
import { DeleteIcon, LockIcon } from '@app/assets/icons';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';
import { UserDto } from '@app/models/auth';
import { useBlockUser, useDeleteUser, useActivateUser } from '@app/domain/user-profile/api/user-profile.api.hook';

export const UserActions: React.FC<{
  user: UserDto;
}> = ({ user }) => {
  const { firstName, lastName, status } = user;
  const { mutate: deleteUser, isLoading: isDeleting } = useDeleteUser();
  const { mutate: blockUser, isLoading: isBlocking } = useBlockUser();
  const { mutate: activateUser, isLoading: isActivating } = useActivateUser();

  if (status === EUserStatus.DELETED) {
    return (
      <ConfirmDialog
        trigger={
          <Button
            variant="outlined"
            color="secondary"
            className="flex items-center text-xs text-grey6"
            loading={isActivating}
          >
            Undo Delete
          </Button>
        }
        headerTitle="Activate User"
        title={`Are you sure you want to activate ${firstName} ${lastName}?`}
        confirmText="Activate"
        onConfirm={() => activateUser(user.id)}
      />
    );
  }

  return (
    <div className="flex">
      {status === EUserStatus.BANNED || status === EUserStatus.LOCKED ? (
        <ConfirmDialog
          trigger={
            <Button
              variant="text"
              className="flex items-center text-xs text-grey6"
              startIcon={<LockOpenIcon className="!text-[13px]" />}
              loading={isActivating}
              disabled={isDeleting}
            >
              Unblock User
            </Button>
          }
          headerTitle="Unblock User"
          title={`Are you sure you want to unblock ${firstName} ${lastName}?`}
          icon={<LockOpenIcon className="!text-[16px]" />}
          confirmIcon={<LockOpenIcon className="!text-[13px]" />}
          confirmText="Unblock"
          onConfirm={() => activateUser(user.id)}
        />
      ) : (
        <ConfirmDialog
          trigger={
            <Button
              variant="text"
              className="flex items-center text-xs text-grey6"
              startIcon={<LockIcon />}
              disabled={isDeleting}
              loading={isBlocking}
            >
              Block User
            </Button>
          }
          headerTitle="Block User"
          title={`Are you sure you want to block ${firstName} ${lastName}?`}
          icon={<LockIcon width={16} height={16} />}
          confirmIcon={<LockIcon />}
          confirmText="Block"
          onConfirm={() => blockUser(user.id)}
        />
      )}
      <ConfirmDeleteDialog
        trigger={
          <Button
            variant="text"
            className="flex items-center text-xs text-error"
            startIcon={<DeleteIcon className="stroke-error" />}
            disabled={isBlocking || isActivating}
            loading={isDeleting}
          >
            Delete User
          </Button>
        }
        headerTitle="Delete User"
        title={`Are you sure you want to delete ${firstName} ${lastName}?`}
        onConfirm={() => deleteUser(user.id)}
      />
    </div>
  );
};
