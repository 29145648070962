import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Typography, LinearProgress } from '@mui/material';
import { calculateMethodologyStatistic } from './methodology.utils';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';

// TODO render BaseStatBox inside
export const MethodologyStatBox: React.FC<
  Pick<OverrideProjectOutDto, 'methodologies'> & {
    disableAnimation?: boolean;
    className?: string;
    hideHeader?: boolean;
  }
> = ({ methodologies, disableAnimation = false, className, hideHeader = false }) => {
  const calc = calculateMethodologyStatistic({ methodologies });
  const data = calc?.data;
  const progress = Math.floor((calc?.progress ?? 0) * 100);

  return (
    <div
      className={clsxm(
        'flex h-full max-h-screen w-full flex-col rounded-[10px] bg-transparentBackground',
        sharedStyle.shadowBorder,
        className
      )}
    >
      {!hideHeader && (
        <div className="flex items-center justify-between pr-14 pl-8 pb-8 pt-5">
          <Typography className="text-xl font-bold">Methodology check</Typography>
          <div className="text-right">
            <Typography className="mb-1.5 text-s font-light">{progress}% completed</Typography>
            <LinearProgress color="primary" className="h-2 w-28" variant="determinate" value={progress} />
          </div>
        </div>
      )}
      {!data && (
        <div className="flex h-full items-center justify-center text-xs font-light text-grey6">
          <Typography>No info yet</Typography>
        </div>
      )}
      {data && (
        <ResponsiveContainer width="100%" height="100%" minHeight={250}>
          <BarChart
            data={data}
            barCategoryGap="25%"
            margin={{
              top: 20,
              right: 48,
              bottom: 32,
            }}
          >
            <CartesianGrid vertical={false} stroke="#424550" />
            <XAxis
              dataKey="name"
              style={{
                fontSize: 15,
                fontWeight: 700,
              }}
              tickLine={false}
              axisLine={false}
              stroke={'#BABAC9'}
            />
            <YAxis
              allowDecimals={false}
              tickCount={8}
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: 10,
                fontWeight: 700,
              }}
              stroke={'#BABAC9'}
            />
            <Bar
              dataKey="value"
              radius={[5, 5, 0, 0]}
              label={{ fill: '#ffffff', fontSize: 10 }}
              isAnimationActive={!disableAnimation}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
