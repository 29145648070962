import { PaginatedResponse } from '@app/api/types';
import { FindingOutSchema } from '@app/domain/finding/finding.schema';
import { EFindingStatus, ERetestPaymentStatus, ESeverityLevel } from '@app/swagger-types';

export const renderFriendlyFindingStatus = (status: EFindingStatus) => {
  switch (status) {
    case EFindingStatus.REQUIRES_ATTENTION:
      return { label: 'Requires Attention', color: '#FF9141' };
    case EFindingStatus.FIXED:
      return { label: 'Fixed', color: '#6CD950' };
    case EFindingStatus.INFORMATIVE:
      return { label: 'Informative', color: '#FFFFFF' };
    case EFindingStatus.RETEST:
      return { label: 'Re-test', color: '#6E9FFF' };
    default:
      return { label: 'Unknown', color: '#BABAC9' };
  }
};

export const renderFriendlyFindingPaymentStatus = (status: ERetestPaymentStatus) => {
  switch (status) {
    case ERetestPaymentStatus.PENDING:
      return { label: 'Pending', color: '#FF9141' };
    case ERetestPaymentStatus.PAID:
      return { label: 'Paid', color: '#6CD950' };
    case ERetestPaymentStatus.NONE:
      return { label: 'None', color: '#BABAC9' };
    default:
      return { label: 'Unknown', color: '#BABAC9' };
  }
};

export const calcSeverityLevel = (score: number) => {
  if (score >= 9) {
    return ESeverityLevel.CRITICAL;
  } else if (score >= 7) {
    return ESeverityLevel.HIGH;
  } else if (score >= 4) {
    return ESeverityLevel.MEDIUM;
  } else if (score >= 0.1) {
    return ESeverityLevel.LOW;
  } else {
    return ESeverityLevel.INFORMATIVE;
  }
};

type FindingStats = {
  severityStats: {
    critical: number;
    high: number;
    medium: number;
    low: number;
    informative: number;
  };
  severityBarChart: [
    {
      name: 'Critical';
      value: number;
      color: '#682B30';
    },
    {
      name: 'High';
      value: number;
      color: '#693F44';
    },
    {
      name: 'Medium';
      value: number;
      color: '#5B4337';
    },
    {
      name: 'Low';
      value: number;
      color: '#2A4E2B';
    },
    {
      name: 'Informative';
      value: number;
      color: '#4B4D56';
    }
  ];
};

export const calculateFindingsStatistic = ({ result }: PaginatedResponse<FindingOutSchema>): FindingStats => {
  if (!result || !result.length) {
    return {
      severityStats: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        informative: 0,
      },
      severityBarChart: [
        {
          name: 'Critical',
          value: 0,
          color: '#682B30',
        },
        {
          name: 'High',
          value: 0,
          color: '#693F44',
        },
        {
          name: 'Medium',
          value: 0,
          color: '#5B4337',
        },
        {
          name: 'Low',
          value: 0,
          color: '#2A4E2B',
        },
        {
          name: 'Informative',
          value: 0,
          color: '#4B4D56',
        },
      ],
    };
  }

  const critical = result.filter((e) => e.severity === ESeverityLevel.CRITICAL).length;
  const high = result.filter((e) => e.severity === ESeverityLevel.HIGH).length;
  const medium = result.filter((e) => e.severity === ESeverityLevel.MEDIUM).length;
  const low = result.filter((e) => e.severity === ESeverityLevel.LOW).length;
  const informative = result.filter((e) => e.severity === ESeverityLevel.INFORMATIVE).length;

  return {
    severityStats: {
      critical,
      high,
      medium,
      low,
      informative,
    },
    severityBarChart: [
      {
        name: 'Critical',
        value: critical,
        color: '#682B30',
      },
      {
        name: 'High',
        value: high,
        color: '#693F44',
      },
      {
        name: 'Medium',
        value: medium,
        color: '#5B4337',
      },
      {
        name: 'Low',
        value: low,
        color: '#2A4E2B',
      },
      {
        name: 'Informative',
        value: informative,
        color: '#4B4D56',
      },
    ],
  };
};
