import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

import { TableCard } from '@app/shared/ui-components/table-card';
import { CustomPieChart } from '@app/shared/ui-components/pie-chart/CustomPieChart';
import { formatDollars } from '@app/utils/currency.utils';
import { useQuery } from 'react-query';
import { GET_FINANCE_STATISTIC_QUERY } from '@app/constants/query-api-configs';
import { DashboardApi } from '@app/domain/dashboard/dashboard.api';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';

export const FinanceStatisticBox: React.FC = () => {
  const { isFetching, data: finances } = useQuery(
    GET_FINANCE_STATISTIC_QUERY.name,
    async () => {
      return await DashboardApi.getFinanceStats();
    },
    { keepPreviousData: true, staleTime: GET_FINANCE_STATISTIC_QUERY.config.staleTime }
  );

  const data = useMemo(() => {
    const data = [];
    if (finances?.priceForTester !== undefined) {
      data.push({ name: 'Project', value: finances.priceForTester, color: '#6E9FFF' });
    }
    if (finances?.extraPay !== undefined) {
      data.push({ name: 'Bonus', value: finances.extraPay, color: '#165DE7' });
    }
    if (finances?.retestBonus !== undefined) {
      data.push({ name: 'Re-tests', value: finances.retestBonus, color: '#FF9141' });
    }
    return data;
  }, [finances?.priceForTester, finances?.extraPay, finances?.retestBonus]);

  const total = formatDollars(finances?.total || 0);

  return (
    <TableCard wrapperClassName="p-8 h-full" cardContentClassName="h-full min-h-full" hidePageSize hideFooter noShadow>
      <Typography className="mb-4 text-xxl font-bold">
        <span className="flex items-center">
          Finance
          <span className="ml-1.5 flex items-center justify-center rounded-full bg-grey2 py-1 px-2 text-xs">
            {total}
          </span>
        </span>
      </Typography>
      {isFetching && <ContainerLoader loading={isFetching} />}
      {!isFetching && <CustomPieChart data={data} label={total} height="75%" dollarsFormat />}
    </TableCard>
  );
};
