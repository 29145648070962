import clsxm from '@app/lib/clsxm';
import { Typography } from '@mui/material';
import { getSeverityInfo } from '../settings/settings.utils';
import { calcSeverityLevel } from './findings.utils';

export const SeverityBadge: React.FC<{
  cvssScore: number;
  className?: string;
}> = ({ cvssScore, className }) => {
  const { label, backgroundColor, borderColor } = getSeverityInfo(calcSeverityLevel(cvssScore));
  return (
    <Typography
      className={clsxm('flex w-[84px] items-center justify-center rounded-[4px] border py-2.5 text-xs', className)}
      style={{ backgroundColor, borderColor }}
    >
      {label}
    </Typography>
  );
};
