import clsx from 'clsx';
import { format } from 'date-fns';
import React, { forwardRef, useState, useCallback } from 'react';
import { Popover, PopoverOrigin } from '@mui/material';

import { DATE_FORMAT } from '@app/constants/date';

import { IconButton, Input, InputProps } from '@app/ui-components';
import { DatePickerProps } from '../date-picker/DatePicker';
import { DatePickerIcon } from '@app/assets/icons';
import { DatePickerRange, ISORange } from '@app/shared/ui-components/date-picker-range/DatePickerRange';
import styles from '@app/shared/ui-components/date-picker-input/DatePickerInput.module.scss';
import clsxm from '@app/lib/clsxm';

export type DatePickerRangeInputProps = DatePickerProps & {
  inputProps?: InputProps;
  position?: 'left' | 'center' | 'right';
  formatDate?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disableOpenOnFocus?: boolean;
  appliedDate?: ISORange;
  onPeriodChange?: (args: ISORange) => void;
  onConfirm?: (args: ISORange) => void;
  onReset?: () => void;
  allowPastDates?: boolean;
};

export const DatePickerRangeInput = forwardRef<HTMLInputElement, DatePickerRangeInputProps>(
  (
    {
      inputProps,
      formatDate = DATE_FORMAT,
      position = 'center',
      anchorOrigin,
      transformOrigin,
      disabled,
      disableOpenOnFocus,
      appliedDate,
      onConfirm,
      onPeriodChange,
      ...props
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const anchorProps = {
      anchorOrigin: anchorOrigin ?? {
        vertical: 'bottom',
        horizontal: position,
      },
      transformOrigin: transformOrigin ?? {
        vertical: 'top',
        horizontal: position,
      },
    };

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const handleFocus = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (disabled || disableOpenOnFocus) {
        return null;
      }
      setAnchorEl(event.currentTarget);
    };

    const handlePickerIconClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const parentInput = event.currentTarget?.parentNode?.parentNode as HTMLDivElement;

      if (parentInput) {
        setAnchorEl(parentInput);
      }
    };

    const handleConfirm = useCallback(
      (args: ISORange) => {
        onConfirm && onConfirm(args);
        handleClose();
      },
      [onConfirm, handleClose]
    );

    return (
      <>
        <Input
          ref={ref}
          onClick={handleFocus}
          inputProps={{ readOnly: true }}
          value={
            appliedDate
              ? `${format(new Date(appliedDate.startDate), formatDate)} - ${format(
                  new Date(appliedDate.endDate),
                  formatDate
                )}`
              : 'Select Date'
          }
          endAdornment={
            <IconButton
              className={clsxm(styles.DatePickerButton, 'ml-[-20px]')}
              color="primary"
              variant="shaded"
              size={inputProps?.inputSize}
              onClick={handlePickerIconClick}
            >
              <DatePickerIcon />
            </IconButton>
          }
          {...inputProps}
        />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorProps.anchorOrigin}
          transformOrigin={anchorProps.transformOrigin}
          classes={{
            paper: clsx(styles.Paper),
          }}
        >
          <DatePickerRange
            {...props}
            appliedDate={appliedDate}
            onConfirm={handleConfirm}
            onPeriodChange={onPeriodChange}
          />
        </Popover>
      </>
    );
  }
);
