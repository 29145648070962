import clsxm from '@app/lib/clsxm';
import { BreakUnit } from '@app/modules/pdf/pages/PdfPager';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.bubble.css';

import './RichEditor.scss';

interface Props {
  value: string;
  pdfFriendly?: boolean;
  className?: string;
}

// classes needed for proper styling in pdf-friendly mode
// keep CSS in sync with these values
const QL_VIEW_EDITOR_CLASSNAME = 'ql-editor ql-container pdf-friendly';

export const RichEditorView: React.FC<Props> = ({ value, pdfFriendly = false, className }) => {
  const [ref, setRef] = useState<ReactQuill | null>(null);
  const [units, setUnits] = useState<string[]>([]);

  useEffect(() => {
    if (!ref || !pdfFriendly) {
      return;
    }
    const children = [...ref.getEditor().root.children].map((e) => e.outerHTML);
    setUnits(children);
    console.log('rich editor children', children);
  }, [ref, value, pdfFriendly]);

  return (
    <>
      <ReactQuill theme="bubble" value={value} readOnly ref={setRef} className={pdfFriendly ? 'hidden' : ''} />
      {pdfFriendly &&
        units.map((e, i) => (
          <BreakUnit key={i} className={className}>
            <div className={clsxm(QL_VIEW_EDITOR_CLASSNAME)} dangerouslySetInnerHTML={{ __html: e }} />
          </BreakUnit>
        ))}
    </>
  );
};
