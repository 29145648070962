import React, { useState } from 'react';
import { Typography } from '@mui/material';

import { Button } from '@app/ui-components';
import { AssignTestersDialog } from '@app/domain/project/components/AssignTestersDialog';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { UseMutateFunction } from 'react-query/types/react/types';
import { EmailIcon, FolderIcon } from '@app/assets/icons';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { UseAssignTestersToProjectParams } from '@app/domain/project/api/hooks/project.api.hook';
import { EProjectStatus } from '@app/swagger-types';
import { TestersInfo } from '@app/shared/tables/projects-table/TestersInfo';

export const ProjectTeamBox: React.FC<{
  project: OverrideProjectOutDto;
  onAssignTester: UseMutateFunction<unknown, Error, UseAssignTestersToProjectParams>;
}> = ({ project, onAssignTester }) => {
  const { client, testers, status, numOfRequests, desiredTesterAssignment } = project;
  const [openAssignTesterDialog, setOpenAssignTesterDialog] = useState(false);

  const { isAdmin } = useUserRole();

  const canAssignTester = isAdmin && [EProjectStatus.SCHEDULED, EProjectStatus.LIVE].includes(status);
  const neededCountOfTesters = desiredTesterAssignment - (testers?.length || 0);

  if (!client) {
    return null;
  }

  return (
    <div
      className={clsxm('flex h-full justify-between rounded-[10px] bg-transparentBackground', sharedStyle.shadowBorder)}
    >
      <div className="w-1/2 border-r-2 border-white/10 py-6 px-8">
        <Typography className="mb-6 text-m font-bold text-grey7">Client</Typography>
        <UserBlock user={client} wrapperClassName="mb-3.5" />
        <div className="mb-2.5 flex items-center">
          <EmailIcon className="mr-3.5 h-[13px] w-[15px]" />
          <Typography className="text-s text-grey5">{client.email}</Typography>
        </div>
        <div className="mb-2.5 flex items-center">
          <FolderIcon className="mr-3.5 h-[13px] w-[15px]" />
          <Typography className="text-s text-grey5">{client.companyName}</Typography>
        </div>
      </div>
      <div className="w-1/2 py-6 px-8">
        <div className="mb-6 flex items-center justify-between">
          <div className="flex items-center">
            <Typography className="text-m font-bold text-grey7">Tester</Typography>
            {canAssignTester && (
              <Button className="ml-3 h-[22px] w-[22px] min-w-0 p-0" onClick={() => setOpenAssignTesterDialog(true)}>
                <AddRoundedIcon className="text-xs" />
              </Button>
            )}
          </div>
          {canAssignTester && (
            <div className="flex flex-col items-end">
              {neededCountOfTesters > 0 && (
                <Typography className="text-xs text-grey7">{`Needed: ${neededCountOfTesters}`}</Typography>
              )}
              <Typography className="mb-1 flex items-center text-xs text-grey7">
                Requests:
                {numOfRequests ? (
                  <span className="ml-0.5 flex items-center justify-center rounded-full bg-red py-0.5 px-1.5 text-xxs text-white">
                    {numOfRequests}
                  </span>
                ) : (
                  ' 0'
                )}
              </Typography>
            </div>
          )}
        </div>
        <div className="max-h-32 overflow-y-auto">
          {testers && Boolean(testers.length) ? (
            <TestersInfo testers={testers} tooltipPlacement="bottom" />
          ) : (
            <div className="flex h-24 items-center justify-center">
              <Typography className="text-xs font-light italic text-grey6">No Testers Assigned</Typography>
            </div>
          )}
        </div>
      </div>
      {canAssignTester && (
        <AssignTestersDialog
          open={openAssignTesterDialog}
          onClose={() => setOpenAssignTesterDialog(false)}
          project={project}
          onAssignTester={onAssignTester}
        />
      )}
    </div>
  );
};
