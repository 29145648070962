import React from 'react';
import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { useQuery } from 'react-query';
import { GET_INVOICE_BY_ID_QUERY } from '@app/constants/query-api-configs';
import { InvoiceApi } from '@app/domain/invoice/invoice.api';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { InvoiceOutDtoSchema } from '@app/domain/invoice/invoice.schema';
import { ClientInvoice } from '@app/modules/pdf/pages/tac/ClientInvoice';

export const ClientInvoicePage: React.FC = () => {
  const { invoiceId } = useTypedParams(Routes.pdf.clientInvoice);

  const { data: invoice, error } = useQuery<InvoiceOutDtoSchema | undefined, Error>(
    [GET_INVOICE_BY_ID_QUERY, { invoiceId }],
    async () => {
      if (invoiceId) {
        return await InvoiceApi.getInvoiceById(invoiceId);
      }
    },
    { keepPreviousData: true, staleTime: GET_INVOICE_BY_ID_QUERY.config.staleTime }
  );

  if (!invoice || error) {
    return (
      <div className="h-screen">
        <ContainerLoader loading={!invoice} error={error} />
      </div>
    );
  }

  return <ClientInvoice invoice={invoice} />;
};
