import React from 'react';
import { Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';

import { TesterInDto } from '@app/swagger-types';
import { TesterDto } from '@app/models/user';
import { GET_TESTER_QUERY } from '@app/constants/query-api-configs';
import { UserApi } from '@app/domain/user/user.api';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { TesterForm } from '@app/domain/user/TesterForm';

export const EditTesterProfilePage: React.FC = () => {
  const { id: testerId } = useTypedParams(Routes.admin.user_management.tester_profile);

  const {
    data: tester,
    isFetching,
    error,
  } = useQuery<TesterDto | undefined, Error>(
    [GET_TESTER_QUERY.name, { testerId }],
    async () => {
      if (testerId) {
        const { data } = await UserApi.getTester(testerId);
        return data;
      }
    },
    { keepPreviousData: true, staleTime: GET_TESTER_QUERY.config.staleTime }
  );

  const { mutate: updateTesterProfile, isLoading: isEditLoading } = useMutation<
    TesterDto | undefined,
    Error,
    Partial<TesterInDto>
  >(async (Dto) => {
    if (testerId) {
      const { data } = await UserApi.updateTesterProfile(testerId, Dto);
      return data;
    }
  });

  if (!tester || (isFetching && !tester) || error) {
    return <ContainerLoader loading={isFetching} error={error} noData={!tester} />;
  }

  const { firstName, lastName } = tester;

  return (
    <div>
      <Typography className=" mb-8 text-xxxl font-bold">{`Edit Tester - ${firstName} ${lastName}`}</Typography>
      <div>
        <TesterForm tester={tester} onConfirm={updateTesterProfile} isLoading={isEditLoading} />
      </div>
    </div>
  );
};
