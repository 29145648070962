import { ProjectInDto } from '@app/swagger-types';

// hardcoded fields for project form
type HardcodedFields = Pick<
  ProjectInDto,
  'name' | 'desiredDeadline' | 'comment' | 'desiredTesterAssignment'
> extends infer K
  ? {
      [Key in keyof K]-?: Key;
    }
  : never;

export const PROJECT_FIELDS: HardcodedFields = {
  name: 'name',
  desiredDeadline: 'desiredDeadline',
  comment: 'comment',
  desiredTesterAssignment: 'desiredTesterAssignment',
};
