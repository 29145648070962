import { boolean, InferType, number, object, string } from 'yup';
import { oneOfEnum } from '@app/utils/yup.utils';
import { EInvoiceStatus } from '@app/swagger-types';
import { convertCentToUnit } from '@app/utils/currency.utils';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { DEFAULT_USD_TO_GBP_RATE } from '@app/constants/currency';

export const InvoiceOutDtoSchema = object({
  id: string().defined(),
  createdDate: string().defined(),
  amountCoin: number().transform(convertCentToUnit).defined(),
  usdToGbpRate: number().optional().default(DEFAULT_USD_TO_GBP_RATE),
  isD2NA: boolean().optional().default(false),
  status: oneOfEnum(EInvoiceStatus).defined(),
  project: ProjectOutDtoSchema.defined(),
});

export type InvoiceOutDtoSchema = InferType<typeof InvoiceOutDtoSchema>;
