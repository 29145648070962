import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { GET_TESTER_INVITATIONS_QUERY } from '@app/constants/query-api-configs';

export const useUserInvitationsQueryControls = () => {
  return useBasicQueryControls({
    initialValues: {
      page: 1,
      sort: undefined,
      search: '',
    },
    prefix: GET_TESTER_INVITATIONS_QUERY.tablePrefix,
  });
};
