import { format } from 'date-fns';

export const getDateWithUTCOffset = (localTime: string | number | Date) => {
  const utcResult = new Date(localTime);
  utcResult.setMinutes(utcResult.getMinutes() + utcResult.getTimezoneOffset());
  return utcResult;
};

export const getDayDifference = (date1: Date, date2: Date, includeStartDay?: boolean) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  let difference = includeStartDay ? 1 : 0;

  if (date1 > date2) {
    difference += Math.floor((Number(date1) - Number(date2)) / _MS_PER_DAY);
  } else {
    difference += Math.floor((Number(date2) - Number(date1)) / _MS_PER_DAY);
  }

  return difference;
};

export const getPercentagesBetweenTwoDates = (startDate: Date, endDate: Date, todayDate: Date) => {
  if (todayDate > endDate) {
    return 100;
  } else if (todayDate < startDate) {
    return 0;
  }
  return Math.round(((Number(todayDate) - Number(startDate)) / (Number(endDate) - Number(startDate))) * 100);
};

export const getMaxDate = (firstDate: Date, secondDate: Date) => {
  return firstDate > secondDate ? firstDate : secondDate;
};

export const DATE_API_FORMAT = 'yyyy-MM-dd';

/**
 * Converts API suitable date string into date string for date picker
 * preserving timezone info, so date picker renders proper date.
 * @param date string in format "YYYY-MM-DD" (DATE_API_FORMAT)
 * @returns string in format "2021-11-09T05:00:00.000Z"
 */
export const getPickerDate = (date: number | Date | string) => {
  const normalDate = typeof date === 'string' ? new Date(date) : date;
  return new Date(format(normalDate, DATE_API_FORMAT));
};
