import { useEffect, useState } from 'react';
import { AnswerDto, EPenetrationType } from '@app/swagger-types';
import { matchTextByWords, parseNumbersFromString } from '@app/utils/string.utils';
import { divideTwoNumbers } from '@app/utils/number.utils';

interface Props {
  penetrationType?: EPenetrationType;
  answers?: AnswerDto[];
}

const NO_IDEA_VALUE = 'No idea!' as const;
const FUNCTIONALITIES_STEP = 15;

export const useScopingCalculator = ({ penetrationType, answers }: Props) => {
  const [duration, setDuration] = useState<number>(0);
  const [hasEnoughDataForCalculation, setHasEnoughDataForCalculation] = useState(true);

  useEffect(() => {
    if (penetrationType) {
      if (
        [EPenetrationType.AZURE_REVIEW, EPenetrationType.AWS_REVIEW, EPenetrationType.GCP_REVIEW].includes(
          penetrationType
        )
      ) {
        setDuration(3);
      } else {
        let count = 0;
        let hasNoIdeaValue = false;
        answers?.forEach((answer) => {
          if (penetrationType === EPenetrationType.MOBILE) {
            if (matchTextByWords(answer.title, ['How', 'many', 'iOS', 'apps', 'included', 'this', 'project'])) {
              count += parseNumbersFromString(answer.value || '');
            }
            if (matchTextByWords(answer.title, ['How', 'many', 'Android', 'apps', 'included', 'this', 'project'])) {
              count += parseNumbersFromString(answer.value || '');
            }
          }
          if (matchTextByWords(answer.title, ['How', 'many', 'functionalities', 'target', 'contain'])) {
            if (answer.value === NO_IDEA_VALUE) {
              hasNoIdeaValue = true;
            } else {
              const value = parseNumbersFromString(answer.value || '');
              count += value ? Math.max(divideTwoNumbers(value, FUNCTIONALITIES_STEP), 1) : 0;
            }
          }
          if (matchTextByWords(answer.title, ['How', 'many', 'roles', 'in', 'scope', 'this', 'project'])) {
            if (answer.value === NO_IDEA_VALUE) {
              hasNoIdeaValue = true;
            } else {
              count += parseNumbersFromString(answer.value || '');
            }
          }
        });
        setHasEnoughDataForCalculation(!hasNoIdeaValue);
        setDuration(hasNoIdeaValue ? 0 : count);
      }
    } else {
      setDuration(0);
      setHasEnoughDataForCalculation(false);
    }
  }, [penetrationType, answers]);

  return { duration, hasEnoughDataForCalculation };
};
