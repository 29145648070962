import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';
import { ForgotPasswordReqDto, ForgotPasswordValidationSchema } from '@app/models/auth';
import { TypedLink } from '@app/router';
import { Input, Button, Form } from '@app/ui-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserIcon } from '@app/assets/icons';
import { maybeRenderError } from '@app/utils/maybeRenderError';

interface Props {
  onForgotPassword: (data: ForgotPasswordReqDto) => void;
  loading: boolean;
  error?: Error;
}

export const ForgotPasswordForm: React.FC<Props> = ({ onForgotPassword, loading, error }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    clearErrors,
  } = useForm<ForgotPasswordValidationSchema>({
    resolver: yupResolver(ForgotPasswordValidationSchema),
  });

  const onSubmit: SubmitHandler<ForgotPasswordValidationSchema> = (formData) => {
    clearErrors();
    onForgotPassword(formData);
  };

  return (
    <div className="space-y-4">
      <Typography variant="xxxl" className="mb-[14px] font-bold">
        Forgot Password?
      </Typography>
      <Typography variant="l" className="!mb-[39px] block text-grey5">
        The code will be sent to your email
      </Typography>
      <Form control={control} onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Input
          {...register('email')}
          label="Email"
          placeholder="Enter Email"
          id="email"
          withBackground
          errorMessage={errors.email?.message}
          startAdornment={
            <div className={'pl-[19px]'}>
              <UserIcon />
            </div>
          }
        />
        <TypedLink to={Routes.auth.sign_in} className="block text-s font-bold text-grey5 hover:text-grey6">
          Back to Log In
        </TypedLink>
        <Button type="submit" variant="outlined" color="gold" fullWidth className="!mt-9 font-medium" loading={loading}>
          Send
        </Button>
        {maybeRenderError(error)}
      </Form>
    </div>
  );
};
