import { InferType, number, object, string } from 'yup';
import { oneOfEnum } from '@app/utils/yup.utils';
import { EActionLogType } from '@app/swagger-types';
import { UserBaseSchema } from '@app/models/auth/auth.shared';

export const ActionLogOutDtoSchema = object({
  id: string().required(),
  ipAddress: string().required(),
  createdDate: string().required(),
  statusCode: number().required(),
  type: oneOfEnum(EActionLogType).required(),
  user: UserBaseSchema,
  country: string().optional(),
  message: string().optional(),
  metaInfo: object().optional(),
});

export type ActionLogOutDtoSchema = InferType<typeof ActionLogOutDtoSchema>;
