import { format, isBefore, endOfDay, addDays, differenceInDays } from 'date-fns';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';

import { DatePicker, DatePickerProps } from '../date-picker/DatePicker';
import { Button } from '@app/ui-components';
import { DATE_FORMAT } from '@app/constants/date';
import { getMaxDate, getPickerDate } from '@app/utils/date.utils';
import clsxm from '@app/lib/clsxm';

export type ISORange = { startDate: string; endDate: string };

type PropTypes = Pick<DatePickerProps, 'maxDate' | 'minDate'> & {
  appliedDate?: Partial<ISORange>;
  onPeriodChange?: (args: ISORange) => void;
  calendarClassName?: string;
  contentClassName?: string;
  disabled?: boolean;
  onConfirm?: (args: ISORange) => void;
  onReset?: () => void;
  allowPastDates?: boolean;
};

const TODAY_DATE = getPickerDate(new Date());
const TOMORROW_DATE = getPickerDate(addDays(new Date(), 1));

export const DatePickerRange: React.FC<PropTypes> = ({
  appliedDate,
  calendarClassName,
  contentClassName,
  onPeriodChange,
  onConfirm,
  disabled,
  minDate,
  maxDate,
  onReset,
  allowPastDates = true,
}) => {
  const [startDate, setStartDate] = useState(appliedDate?.startDate ? new Date(appliedDate.startDate) : null);
  const [endDate, setEndDate] = useState(appliedDate?.endDate ? new Date(appliedDate.endDate) : null);

  const [showStartDate, setShowStartDate] = useState(true);

  const handleControlsChange = (showStart: boolean) => () => {
    setShowStartDate(showStart);
  };

  const handlePeriodChange = (startDate?: Date | null, endDate?: Date | null) => {
    if (startDate && endDate && onPeriodChange) {
      onPeriodChange({
        startDate: startDate.toISOString(),
        endDate: endOfDay(endDate).toISOString(),
      });
    }
  };

  const handleConfirm = (startDate?: Date | null, endDate?: Date | null) => {
    if (startDate && endDate && onConfirm) {
      onConfirm({
        startDate: startDate.toISOString(),
        endDate: endOfDay(endDate).toISOString(),
      });
    }
  };

  const handleStartToday = () => {
    setStartDate(getPickerDate(new Date()));
    setShowStartDate(false);

    handlePeriodChange(new Date(), endDate);
  };

  const handleCancel = useCallback(() => {
    onReset && onReset();
    setStartDate(null);
    setEndDate(null);
  }, [onReset]);

  useEffect(() => {
    if (!appliedDate?.startDate) {
      setStartDate(null);
    }
    if (!appliedDate?.endDate) {
      setEndDate(null);
      setShowStartDate(true);
    }
  }, [appliedDate]);

  const header = (
    <div className="mb-6 flex w-full justify-between">
      <div>
        <Typography className="mb-3.5 text-left text-xs text-white">Start Date</Typography>
        <Button
          variant="outlined"
          color="secondary"
          className={clsxm('w-28 rounded-[7px] !border p-4', !showStartDate && 'text-grey5')}
          disabled={disabled}
          onClick={handleControlsChange(true)}
        >
          <Typography noWrap className="text-xs">
            {startDate ? format(startDate, DATE_FORMAT) : <span className="text-grey5">Select Date</span>}
          </Typography>
        </Button>
      </div>
      <div>
        <Typography className="mb-3.5 text-left text-xs text-white">End Date</Typography>
        <Button
          variant="outlined"
          color="secondary"
          className={clsxm('w-28 rounded-[7px] !border p-4', showStartDate && 'text-grey5')}
          disabled={disabled}
          onClick={handleControlsChange(false)}
        >
          <Typography noWrap className="text-xs">
            {endDate ? format(endDate, DATE_FORMAT) : <span className="text-grey5">Select Date</span>}
          </Typography>
        </Button>
      </div>
    </div>
  );

  const footer = (
    <div className="flex w-full justify-between">
      <Button variant="text" className="!border-0 p-2" onClick={handleStartToday}>
        Start Today
      </Button>
      <div>
        <Button variant="text" className="!border-0 p-2" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="text"
          className="!border-0 p-2 text-orange"
          disabled={!startDate || !endDate}
          onClick={() => handleConfirm(startDate, endDate)}
        >
          Set
        </Button>
      </div>
    </div>
  );

  const minStartDate = useMemo(
    () => (allowPastDates ? minDate : getMaxDate(minDate || TOMORROW_DATE, TOMORROW_DATE)),
    [allowPastDates, minDate]
  );

  const minEndDate = useMemo(() => {
    if (startDate && !differenceInDays(startDate, TODAY_DATE)) {
      return allowPastDates ? startDate : TODAY_DATE;
    }
    return allowPastDates ? startDate || minDate : getMaxDate(startDate || minDate || TOMORROW_DATE, TOMORROW_DATE);
  }, [allowPastDates, minDate, startDate]);

  return (
    <div className={contentClassName}>
      <div>
        {showStartDate ? (
          <DatePicker
            header={header}
            footer={footer}
            selected={startDate}
            calendarClassName={calendarClassName}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={minStartDate}
            maxDate={endDate}
            onChange={(date) => {
              setStartDate(getPickerDate(date));
              if (endDate && isBefore(endDate, date)) {
                setEndDate(null);
              }
              setShowStartDate(false);

              handlePeriodChange(date, endDate);
            }}
          />
        ) : (
          <DatePicker
            header={header}
            footer={footer}
            selected={endDate}
            calendarClassName={calendarClassName}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={minEndDate}
            maxDate={maxDate}
            onChange={(date) => {
              if (startDate && isBefore(date, startDate)) {
                setStartDate(null);
              }
              setShowStartDate(true);

              setEndDate(getPickerDate(date));
              handlePeriodChange(startDate, date);
            }}
          />
        )}
      </div>
    </div>
  );
};
