import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { BreakUnit } from '../../PdfPager';
import styles from '../../pdf-salus.module.scss';
import { PaginatedResponse } from '@app/api/types';
import { FindingOutSchema } from '@app/domain/finding/finding.schema';
import { EMethodologyStatus, ESeverityLevel } from '@app/swagger-types';
import { format } from 'date-fns';
import React from 'react';
import clsxm from '@app/lib/clsxm';
import pluralize from 'pluralize';
import { PDFUtils } from '../../pdf.utils';

const run = <T,>(f: () => T) => f();
const positive = (n: number) => n > 0;
const zero = (n: number) => n === 0;
const sum = (a: number, b: number) => a + b;

interface CategoryStat {
  categoryTitle: string;
  numberOfInformative: number;
  numberOfLow: number;
  numberOfMedium: number;
  numberOfHigh: number;
  numberOfCritical: number;
}

const genEmptyStat = (title: string): CategoryStat => ({
  categoryTitle: title,
  numberOfInformative: 0,
  numberOfLow: 0,
  numberOfMedium: 0,
  numberOfHigh: 0,
  numberOfCritical: 0,
});

export const ReportSectionExecutiveSummary: React.FC<{
  project: ProjectOutDtoSchema;
  findings: PaginatedResponse<FindingOutSchema>;
}> = ({ project, findings }) => {
  const getCategory = (id: string) => project.methodologies.find((e) => e.checklist.find((c) => c.id === id));

  const allStats: CategoryStat[] = [];
  findings.result.forEach((e) => {
    const category = getCategory(e.checklistItemId);
    if (!category) {
      return;
    }
    const already =
      allStats.find((e) => e.categoryTitle === category.sectionTitle) ||
      run(() => {
        const stat = genEmptyStat(category.sectionTitle);
        allStats.push(stat);
        return stat;
      });
    if (e.severity === ESeverityLevel.INFORMATIVE) {
      already.numberOfInformative++;
    }
    if (e.severity === ESeverityLevel.LOW) {
      already.numberOfLow++;
    }
    if (e.severity === ESeverityLevel.MEDIUM) {
      already.numberOfMedium++;
    }
    if (e.severity === ESeverityLevel.HIGH) {
      already.numberOfHigh++;
    }
    if (e.severity === ESeverityLevel.CRITICAL) {
      already.numberOfCritical++;
    }
  });

  const noneOrlow = allStats.filter((e) => [e.numberOfMedium, e.numberOfHigh, e.numberOfCritical].every(zero));
  const medium = allStats.filter((e) => [e.numberOfMedium].every(positive));
  const high = allStats.filter((e) => [e.numberOfHigh].every(positive));
  const critical = allStats.filter((e) => [e.numberOfCritical].every(positive));
  const noFindings = findings.result.length < 1 || [critical.length, high.length, medium.length].every(zero);
  console.log([critical.length, high.length, medium.length], [critical.length, high.length, medium.length].every(zero));

  const totalExamined = project.methodologies
    .map(
      (e) =>
        e.checklist.filter((e) => e.status !== EMethodologyStatus.N_A && e.status !== EMethodologyStatus.TODO).length
    )
    .reduce(sum, 0);
  const passedChecks = project.methodologies
    .map((e) => e.checklist.filter((e) => e.status === EMethodologyStatus.PASS).length)
    .reduce(sum, 0);
  const failedChecks = project.methodologies
    .map((e) => e.checklist.filter((e) => e.status === EMethodologyStatus.FAIL).length)
    .reduce(sum, 0);

  const numberOfCritical = critical.map((e) => e.numberOfCritical).reduce(sum, 0);
  const numberOfHigh = high.map((e) => e.numberOfHigh).reduce(sum, 0);
  const numberOfMedium = medium.map((e) => e.numberOfMedium).reduce(sum, 0);

  const startDate =
    project.startDate &&
    format(
      new Date(project.startDate),
      // specific no-reuse format
      'MMMM yyyy'
    );

  return (
    <>
      <BreakUnit pageBreak />
      <BreakUnit header>
        <div className={styles.content}>
          <div className={styles.pageHeader} id="executive_summary">
            Executive Summary
          </div>
          <div className={styles.divider} />
          <div className={styles.p}>{`Salus engaged with ${
            project.client.companyName
          } in ${startDate} to conduct a ${PDFUtils.normalizeProductTitle(
            project.productTitle
          )} penetration test against ${
            project.name
          }. The purpose of this engagement was to investigate the environment and help uncover potential areas that require improving.`}</div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>{`A high level summary of the results are provided below.`}</div>
          <ul className={styles.ul}>
            <li>
              Areas of Strength
              <ul>
                <li>
                  {renderStatChips(noneOrlow)}
                  {` categories were thoroughly examined and had no or only low severity issues reported against them.`}
                </li>
                <li>
                  {`${project.name}'s ${PDFUtils.normalizeProductTitle(
                    project.productTitle
                  )} was examined against ${totalExamined} ${pluralize(
                    'check',
                    totalExamined
                  )} and successfully passed ${passedChecks}.`}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <ul className={styles.ul}>
            <li>
              Improvement Areas
              <ul>
                {noFindings ? (
                  <li>
                    Consider executing penetration tests regularly to maintain the security posture of the environment.
                  </li>
                ) : (
                  <>
                    <li>{`A total of ${failedChecks} ${pluralize(
                      'check',
                      failedChecks
                    )} did not pass Salus's examination.`}</li>
                    {positive(critical.length) && (
                      <li>
                        {renderStatChips(critical)}
                        {` categories had ${numberOfCritical} critical ${pluralize(
                          'issue',
                          numberOfCritical
                        )} reported.`}
                      </li>
                    )}
                    {positive(high.length) && (
                      <li>
                        {renderStatChips(high)}
                        {` categories had ${numberOfHigh} high ${pluralize('issue', numberOfHigh)} reported.`}
                      </li>
                    )}
                    {positive(medium.length) && (
                      <li>
                        {renderStatChips(medium)}
                        {` categories had ${numberOfMedium} medium ${pluralize('issue', numberOfMedium)} reported.`}
                      </li>
                    )}
                  </>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </BreakUnit>
    </>
  );
};

const renderStatChips = (stats: CategoryStat[]) =>
  stats.map((e, i) => {
    const notLast = i < stats.length - 1;
    return (
      <React.Fragment key={i}>
        <span className={clsxm('mt-1 inline-block bg-[#111219] px-1')}>{e.categoryTitle}</span>
        {notLast && <span className="mx-1">,</span>}
      </React.Fragment>
    );
  });
