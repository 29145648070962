import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Typography } from '@mui/material';

import { TypedLink, useTypedNavigate } from '@app/router';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { GET_INVOICES_QUERY } from '@app/constants/query-api-configs';
import { Tooltip, IconButton, Table, TableColumn } from '@app/ui-components';
import { DATE_TIME_FORMAT } from '@app/constants/date';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { Routes } from '@app/constants/routes';
import { InvoiceOutDto } from '@app/swagger-types';
import { Status } from '@app/shared/ui-components/status/Status';
import { getFriendlyInvoiceStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { useGetInvoices } from '@app/domain/invoice/api/hooks/invoice.api.hook';
import { PAGE_SIZE } from '@app/constants/api';
import { Visibility } from '@mui/icons-material';

export const ClientInvoicesPage: React.FC = () => {
  const navigate = useTypedNavigate();

  const {
    values: { page, sort = GET_INVOICES_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      search: '',
    },
    prefix: GET_INVOICES_QUERY.tablePrefix,
  });

  const { data, isFetching } = useGetInvoices(
    {
      size: PAGE_SIZE,
      page: Math.ceil((page || 1) - 1),
      sort,
      projectName: search,
    },
    { keepPreviousData: true }
  );

  const columns: TableColumn<InvoiceOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Date & Timeline',
        field: 'createdDate',
        align: 'left',
        render: ({ createdDate }) => format(new Date(createdDate), DATE_TIME_FORMAT),
      },
      {
        headerName: 'Project',
        field: 'project.name',
        align: 'left',
        render: ({ project: { name } }) => name,
      },
      {
        headerName: 'Project Type',
        field: 'project.productTitle',
        align: 'left',
        disableSort: true,
        render: ({ project: { productTitle } }) => productTitle,
      },
      {
        headerName: 'Amount',
        field: 'amountCoin',
        align: 'left',
        render: ({ amountCoin, usdToGbpRate, isD2NA }) =>
          isD2NA && Boolean(usdToGbpRate)
            ? createGBPformatter(usdToGbpRate).formatGBP(amountCoin)
            : formatDollars(amountCoin),
      },
      {
        headerName: 'Status',
        field: 'status',
        align: 'left',
        disableClick: true,
        render: ({ status }) => <Status {...getFriendlyInvoiceStatus(status)} />,
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'center',
        disableClick: true,
        disableSort: true,
        render: ({ id }) => (
          <Tooltip title="View PDF Invoice">
            <div>
              <TypedLink to={Routes.pdf.clientInvoice} params={{ invoiceId: id }}>
                <IconButton>
                  <Visibility />
                </IconButton>
              </TypedLink>
            </div>
          </Tooltip>
        ),
      },
    ],
    []
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <Typography className="mb-6 text-xxl font-bold">Invoices</Typography>
        <SearchInput placeholder="Search by project" className="w-64" value={search} onChange={handleSearchChange} />
      </div>
    );
  }, [handleSearchChange, search]);

  const navigateToProjectPage = ({ project: { id } }: InvoiceOutDto) => {
    navigate({ to: Routes.projects.project, params: { id } });
  };

  return (
    <div>
      <Typography className="mb-8 text-xxxl font-bold">Finance</Typography>
      <Table
        cols={columns}
        tableData={data?.result || []}
        loading={isFetching}
        notFoundMessage="No Invoices"
        leftHeaderContent={leftContent}
        currentPage={page}
        total={data?.total}
        onPagination={handlePageChange}
        currentSort={sort}
        onSortChanged={handleSortChange}
        onItemSelect={navigateToProjectPage}
        hidePageSize
      />
    </div>
  );
};
