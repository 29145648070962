import React, { useMemo } from 'react';
import { AnswerDto, ETypeOfAnswer } from '@app/swagger-types';
import { v4 as uuid } from 'uuid';
import { PROJECT_FIELDS } from '@app/domain/project/project.constant';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { Routes } from '@app/constants/routes';
import { ProjectQuestionnaire } from '@app/domain/project/components/ProjectQuestionnaire';
import { BackLink } from '@app/shared/ui-components/back-link/BackLink';
import { Typography } from '@mui/material';

export const ProjectQuestionnairePageBody: React.FC<{ project: ProjectOutDtoSchema }> = ({ project }) => {
  const answers: AnswerDto[] = useMemo(
    () =>
      project
        ? [
            {
              id: uuid(),
              title: 'What is the name of project?',
              value: project[PROJECT_FIELDS.name],
              typeOfAnswer: ETypeOfAnswer.SHORT,
              createdDate: new Date().toJSON(),
            },
            {
              id: uuid(),
              title: 'When would you like this project done by?',
              value: project.desiredDeadline ? format(new Date(project.desiredDeadline), DATE_FORMAT) : undefined,
              typeOfAnswer: ETypeOfAnswer.DATE,
              createdDate: new Date().toJSON(),
            },
            {
              id: uuid(),
              title: 'How many penetration testers would you like for this engagement?',
              value: project.desiredTesterAssignment ? String(project.desiredTesterAssignment) : undefined,
              typeOfAnswer: ETypeOfAnswer.SHORT,
              createdDate: new Date().toJSON(),
            },
            ...(project.answers || []),
            {
              id: uuid(),
              title: 'Any special instructions? ',
              value: project[PROJECT_FIELDS.comment],
              typeOfAnswer: ETypeOfAnswer.LONG,
              createdDate: new Date().toJSON(),
            },
          ]
        : [],
    [project]
  );

  return (
    <div>
      <BackLink to={Routes.projects.project} params={{ id: project.id }} />
      <Typography className="mb-8 text-xxxl font-bold">{project.name} - Questionnaire</Typography>
      <ProjectQuestionnaire title={project.productTitle} answers={answers} />
    </div>
  );
};
