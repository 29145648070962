import { toast } from 'react-toastify';
import { ToastContent, ToastOptions } from 'react-toastify/dist/types';
import { ErrorIcon, SuccessIcon } from '@app/assets/icons';

export default class Notification {
  static show = (content: ToastContent, options?: ToastOptions) =>
    toast(content, {
      hideProgressBar: true,
      closeButton: false,
      className: 'min-h-[40px] px-5 bg-grey1 border border-grey2 text-white text-xs w-fit ml-auto mr-0',
      ...options,
    });

  static showFailure = (content: ToastContent, options?: ToastOptions) => {
    return Notification.show(content, {
      type: toast.TYPE.ERROR,
      icon: <ErrorIcon className="h-5 w-5" />,
      ...options,
    });
  };

  static showSuccess = (content: ToastContent, options?: ToastOptions) =>
    Notification.show(content, {
      type: toast.TYPE.SUCCESS,
      icon: <SuccessIcon className="h-5 w-5" />,
      ...options,
    });
}
