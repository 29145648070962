import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import pluralize from 'pluralize';
import { format } from 'date-fns';
import { isNumber } from 'lodash';

import { GET_ASSIGNED_APPLICATIONS_QUERY } from '@app/constants/query-api-configs';
import { ApplicationOutDto } from '@app/swagger-types';
import { IconButton, Table, TableColumn, Tooltip } from '@app/ui-components';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { DATE_FORMAT } from '@app/constants/date';
import { formatDollars } from '@app/utils/currency.utils';
import { getFriendlyPaymentStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { Status } from '@app/shared/ui-components/status/Status';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { Routes } from '@app/constants/routes';
import { useGetAssignedApplications } from '@app/domain/application/api/hooks/application.api.hook';
import { TypedLink, useTypedNavigate } from '@app/router';
import { PAGE_SIZE } from '@app/constants/api';
import { Visibility } from '@mui/icons-material';

export const ProjectPaymentsPage: React.FC = () => {
  const navigate = useTypedNavigate();
  const {
    values: { page, sort = GET_ASSIGNED_APPLICATIONS_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      search: '',
    },
    prefix: GET_ASSIGNED_APPLICATIONS_QUERY.tablePrefix,
  });

  const { data, isFetching } = useGetAssignedApplications(
    {
      size: PAGE_SIZE,
      page: Math.ceil((page || 1) - 1),
      sort,
      projectName: search,
    },
    { keepPreviousData: true }
  );

  const columns: TableColumn<ApplicationOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Project Name',
        field: 'project.name',
        align: 'left',
        render: ({ project: { name } }) => name,
      },
      {
        headerName: 'Duration',
        field: 'project.workingDays',
        align: 'center',
        render: ({ project: { workingDays } }) => (isNumber(workingDays) ? pluralize('day', workingDays, true) : '-'),
      },
      {
        headerName: 'Timeline',
        field: 'project.startDate',
        align: 'left',
        disableSort: true,
        render: ({ project: { startDate, endDate } }) => {
          if (startDate && endDate) {
            return (
              <div>
                <div>{format(new Date(startDate), DATE_FORMAT)}</div>
                <div>{format(new Date(endDate), DATE_FORMAT)}</div>
              </div>
            );
          }

          return '-';
        },
      },
      {
        headerName: 'Company',
        field: 'project.client.companyName',
        align: 'left',
        disableSort: true,
        render: ({
          project: {
            client: { companyName },
          },
        }) => companyName,
      },
      {
        headerName: 'Amount',
        field: 'project.priceForTester',
        align: 'center',
        render: ({ project: { priceForTester }, extraPay }) => {
          let amount = priceForTester;
          if (isNumber(amount) && isNumber(extraPay)) {
            amount += extraPay;
          }
          return isNumber(amount) ? (
            <Tooltip
              title={
                <div>
                  {isNumber(priceForTester) && (
                    <Typography className="text-xs">Project: {formatDollars(priceForTester)}</Typography>
                  )}
                  {isNumber(extraPay) && <Typography className="text-xs">Bonus: {formatDollars(extraPay)}</Typography>}
                </div>
              }
            >
              <Typography className="text-xs font-medium text-green">{formatDollars(amount)}</Typography>
            </Tooltip>
          ) : (
            '-'
          );
        },
      },
      {
        headerName: 'Status',
        field: 'payoutStatus',
        align: 'left',
        width: 120,
        render: ({ payoutStatus }) => <Status {...getFriendlyPaymentStatus(payoutStatus)} />,
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'center',
        disableClick: true,
        disableSort: true,
        render: ({ project: { id: projectId } }) => (
          <Tooltip title="View PDF Invoice">
            <div>
              <TypedLink to={Routes.pdf.testerInvoice} params={{ projectId }}>
                <IconButton>
                  <Visibility />
                </IconButton>
              </TypedLink>
            </div>
          </Tooltip>
        ),
      },
    ],
    []
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <SearchInput placeholder="Search by name" className="w-64" value={search} onChange={handleSearchChange} />
      </div>
    );
  }, [handleSearchChange, search]);

  const navigateToProjectPage = ({ project: { id } }: ApplicationOutDto) => {
    navigate({ to: Routes.projects.project, params: { id } });
  };

  return (
    <div>
      <Table
        cols={columns}
        tableData={data?.result || []}
        loading={isFetching}
        notFoundMessage="No payments"
        leftHeaderContent={leftContent}
        currentPage={page}
        total={data?.total}
        onPagination={handlePageChange}
        currentSort={sort}
        onSortChanged={handleSortChange}
        onItemSelect={navigateToProjectPage}
        hidePageSize
      />
    </div>
  );
};
