import React from 'react';
import { Typography } from '@mui/material';
import { Checkbox, Input, OptionItem, Select } from '@app/ui-components';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';

export const InputExamples = () => {
  return (
    <>
      <Typography variant={'h4'} className="py-4">
        Inputs
      </Typography>
      <Input className="my-1" placeholder={'normal'} />
      <Input className="my-1" label={'with text'} placeholder={'with text'} />
      <Input className="my-1" label={'wrong input'} errorMessage={'some error'} placeholder={'wrong input'} />
      <Input className="my-1" label={'disabled input'} placeholder={'disabled input'} disabled />
      <Input className="my-1" label={'password input'} placeholder={'password input'} type="password" />
      <SearchInput label={'search input'} />
      <Checkbox className="my-1" label={'Checkbox'} color={'primary'}></Checkbox>
      <Select
        value={10}
        inputProps={{
          label: 'Select',
          className: 'my-1',
        }}
        label={'select'}
      >
        <OptionItem value={10}>10 Rows</OptionItem>
        <OptionItem value={50}>50 Rows</OptionItem>
        <OptionItem value={100}>100 Rows</OptionItem>
      </Select>
    </>
  );
};
