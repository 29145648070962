import React, { useState } from 'react';
import styles from '../pdf-salus.module.scss';
import clsxm from '@app/lib/clsxm';
import { BreakUnit, Footer, IPdfRenderResult, PdfPager } from '../PdfPager';
import { CLASSNAME_FOR_PDF_READY } from '../constants';
import { InvoiceFixedHeader } from './tac-shared';
import { ClientInvoiceBody } from '@app/modules/pdf/pages/tac/ClientInvoiceBody';
import { AppLogo } from '@app/assets/app-logo/AppLogo';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { InvoiceOutDto } from '@app/swagger-types';
import { D2NA_FULL_NAME, SALUS_FULL_NAME } from '@app/domain/d2na/d2na.constants';

export const ClientInvoice: React.FC<{ invoice: InvoiceOutDto; editableFirstPage?: boolean; showErrors?: boolean }> = ({
  invoice,
  editableFirstPage,
  showErrors = true,
}) => {
  const [result, setResult] = useState<IPdfRenderResult | null>(null);

  const {
    id: invoiceId,
    amountCoin,
    project: {
      name,
      productTitle,
      workingDays = 0,
      id: projectId,
      dayRateForClient = 0,
      desiredTesterAssignment,
      client: { firstName, lastName, companyName, companyAddress },
    },
    isD2NA,
    usdToGbpRate = 1,
  } = invoice;

  /**
   * TODO link amountPrice calculation via single function across other files
   * like timeline SetupDialog, ScopingCalculator etc
   */
  const VATfactor = isD2NA ? 1.2 : 1;
  const amountWithoutVatPrice = dayRateForClient * workingDays * desiredTesterAssignment;
  const amountPrice = amountWithoutVatPrice * VATfactor;
  const isAmountMismatch = Math.abs(amountPrice - amountCoin) > 1;
  // console.log({ amountPrice, amountCoin, isAmountMismatch });

  const dateInvoiceGenerated = format(new Date(invoice.createdDate), DATE_FORMAT);
  const fullName = `${firstName} ${lastName}`;

  const { formatGBP } = createGBPformatter(usdToGbpRate);
  const smartFormatter = isD2NA ? formatGBP : formatDollars;

  return (
    <div className={clsxm(styles.mainWrap, result && CLASSNAME_FOR_PDF_READY)}>
      <InvoiceFixedHeader projectId={projectId} />

      <div className={clsxm(styles.page, 'bg-white pt-0')} contentEditable={editableFirstPage}>
        <BreakUnit>
          <div className={clsxm(styles.content, 'bg-[#191819] py-[15mm]')}>
            <div className="flex justify-between gap-3">
              <div className="flex-1">
                {isD2NA ? (
                  <AppLogo withName height={160} width={160} d2na />
                ) : (
                  <AppLogo withName height={160} width={72} d2na={false} />
                )}
              </div>
              <div className="flex-1">
                <b className={styles.tacHeader}>Invoice</b>
                <div className="py-3">
                  <b className="text-white">Invoice Number:</b>
                  <div>{invoiceId}</div>
                </div>
                <div className="py-3">
                  <b className="text-white">Date:</b>
                  <div>{dateInvoiceGenerated}</div>
                </div>
              </div>
            </div>
          </div>
        </BreakUnit>
        <BreakUnit>
          <div className={clsxm(styles.content, 'text-grey5')}>
            <div className="flex justify-between gap-3 py-5">
              <div className="flex-1">
                <b className={clsxm(styles.p, 'text-black')}>Bill To:</b>
                <div className="text-justify">
                  <b>Contact Name:</b> {fullName}
                </div>
                <div className="text-justify">{companyName}</div>
                <div className="text-justify">{companyAddress}</div>
              </div>
              <div className="flex-1">
                <b className={clsxm(styles.p, 'text-black')}>Bill From:</b>
                <div className="text-justify">{isD2NA ? D2NA_FULL_NAME : SALUS_FULL_NAME}</div>
                {isD2NA ? (
                  <div className="text-justify">
                    Unit 4 Phoenix Works 500-51 King Street, Longton, Stoke-On-Trent, Staffordshire, England, ST3 1EZ
                  </div>
                ) : (
                  <>
                    <div className="text-justify">Intershore Chambers</div>
                    <div className="text-justify">Road Town, Tortola, Virgin Islands - UK</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </BreakUnit>
        <BreakUnit>
          <div
            className={clsxm(
              styles.content,
              'flex justify-between gap-3 bg-[#F8F7FA] py-3 font-bold uppercase text-black'
            )}
          >
            <div className="flex-1">Item</div>
            <div className="grid flex-1 grid-cols-[1fr_40px_80px_1fr] gap-1">
              <div className="text-left">Unit Price</div>
              <div className="text-center">Qty</div>
              <div className="text-center">Testers</div>
              <div className="text-right">Total</div>
            </div>
          </div>
        </BreakUnit>
        <BreakUnit>
          <div className={clsxm(styles.content, 'flex justify-between gap-3 py-3 text-black')}>
            <div className="flex-1">
              {`${name}’s ${productTitle} penetration test including:`}
              <ul className={clsxm(styles.ul, 'text-[15px]')}>
                <li className={clsxm(styles.li, 'relative')}>
                  Unlimited Re-test
                  <div className={'absolute -right-[19mm] top-0 text-grey5'}>included</div>
                </li>
                <li className={clsxm(styles.li, 'relative')}>
                  Unlimited Advise on Findings
                  <div className={'absolute -right-[19mm] top-0 text-grey5'}>included</div>
                </li>
                <li className={clsxm(styles.li, 'relative')}>
                  Unlimited Rescheduling of Penetration Tests
                  <div className={'absolute -right-[19mm] top-0 text-grey5'}>included</div>
                </li>
                <li className={clsxm(styles.li, 'relative')}>
                  Out of Hours Work Included Free of Charge
                  <div className={'absolute -right-[19mm] top-0 text-grey5'}>included</div>
                </li>
                <li className={clsxm(styles.li, 'relative')}>
                  No Cancellation Charges
                  <div className={'absolute -right-[19mm] top-0 text-grey5'}>included</div>
                </li>
                <li className={clsxm(styles.li, 'relative')}>
                  1x Technical Report
                  <div className={'absolute -right-[19mm] top-0 text-grey5'}>included</div>
                </li>
                <li className={clsxm(styles.li, 'relative')}>
                  1x Management Report
                  <div className={'absolute -right-[19mm] top-0 text-grey5'}>included</div>
                </li>
                <li className={clsxm(styles.li, 'relative')}>
                  1x Public Report
                  <div className={'absolute -right-[19mm] top-0 text-grey5'}>included</div>
                </li>
                {isD2NA && (
                  <li className={clsxm(styles.li, 'relative')}>
                    20% VAT
                    <div className={'absolute -right-[91mm] top-0'}>{formatGBP(amountWithoutVatPrice * 0.2)}</div>
                  </li>
                )}
              </ul>
            </div>
            <div className="grid flex-1 grid-cols-[1fr_40px_80px_1fr] gap-1">
              <div className="text-left">{smartFormatter(dayRateForClient)}</div>
              <div className="text-center">{workingDays}</div>
              <div className="text-center">{desiredTesterAssignment}</div>
              <div className="text-right">{smartFormatter(amountWithoutVatPrice)}</div>
            </div>
          </div>
        </BreakUnit>
        <BreakUnit>
          <div className={clsxm(styles.content, 'flex justify-between gap-3 py-3 text-black')}>
            <div className="flex-1"></div>
            <div className="flex-1">
              <hr className="mb-5" />
              <div className="flex justify-between font-bold text-black">
                <div>Amount Due</div>
                {isD2NA ? <div>{formatGBP(amountPrice)} GBP</div> : <div>{formatDollars(amountPrice)} USD</div>}
              </div>
            </div>
          </div>
        </BreakUnit>
        {isAmountMismatch && showErrors && (
          <BreakUnit>
            <div className={clsxm(styles.content, 'bg-[#efb3b3] py-3 text-black')}>
              <div>
                <b>Error:</b> calculated amount mismatch with invoice data. Please, consult with Admin for
                clarifications.
              </div>
            </div>
          </BreakUnit>
        )}
        <BreakUnit>
          <div className={clsxm(styles.content, 'bg-[#F8F7FA] py-3 font-bold uppercase text-black')}>
            <div>Bank Details</div>
          </div>
        </BreakUnit>
        <BreakUnit>
          {isD2NA ? (
            <div className={clsxm(styles.content, 'py-3 text-black')}>
              <div>Beneficiary Name: D2 Network Associates Ltd</div>
              <div>Bank Name: Lloyds Bank</div>
              <div>Account Number: 37779668</div>
              <div>Sort Code: 30-93-83</div>
            </div>
          ) : (
            <div className={clsxm(styles.content, 'py-3 text-black')}>
              <div>Beneficiary Name: NEAT Salus Experts Limited</div>
              <div>Account Number: 478798137442</div>
              <div>BIC/SWIFT: DHBKHKHHXXX</div>
              <div>Bank Code: 016</div>
            </div>
          )}
        </BreakUnit>
        <Footer pageNumber={1} isD2NA={invoice.isD2NA} />
      </div>

      <MemoPdf {...{ setResult, invoice, lastPageIsEditable: editableFirstPage }} />
    </div>
  );
};

// memoization is critical to avoid infinite loop rerendering
const MemoPdf = React.memo<{
  setResult: React.Dispatch<React.SetStateAction<IPdfRenderResult | null>>;
  invoice: InvoiceOutDto;
  lastPageIsEditable?: boolean;
}>(({ setResult, invoice, lastPageIsEditable }) => {
  return (
    <PdfPager indexShift={1} onResult={setResult} headerSelector={`.${styles.pageHeader}`} isD2NA={invoice.isD2NA}>
      <ClientInvoiceBody invoice={invoice} lastPageIsEditable={lastPageIsEditable} />
    </PdfPager>
  );
});
