import React, { useState } from 'react';
import { OverrideApplicationPayoutStatusInDto } from '@app/swagger-override-types';
import { Menu, MenuItem, Typography } from '@mui/material';
import styles from '@app/shared/ui-components/layout/header/user-header-info/UserHeaderInfo.module.scss';
import { Status } from '@app/shared/ui-components/status/Status';
import { KeyboardArrowDown } from '@mui/icons-material';
import { getFriendlyPaymentStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { EPayoutStatus } from '@app/swagger-types';

interface Props {
  id: string;
  onChangeStatus: (applicationId: string, Dto: OverrideApplicationPayoutStatusInDto) => void;
  activeStatus: EPayoutStatus;
}

export const AppPaymentStatusDropdown: React.FC<Props> = ({ id, onChangeStatus, activeStatus }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        elevation={0}
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.Paper }}
      >
        {Object.keys(EPayoutStatus).map((optionStatus, index) => {
          const isActive = activeStatus === optionStatus;
          return (
            <MenuItem
              key={`${index}_${id}`}
              className={styles.MenuItem}
              value={optionStatus}
              onClick={() => {
                if (!isActive) {
                  onChangeStatus(id, { status: optionStatus as EPayoutStatus });
                }
                handleClose();
              }}
            >
              <Status {...getFriendlyPaymentStatus(optionStatus as EPayoutStatus)} />
            </MenuItem>
          );
        })}
      </Menu>
      <Typography onClick={handleClick} className="flex cursor-pointer items-center">
        <Status {...getFriendlyPaymentStatus(activeStatus)} />
        <KeyboardArrowDown className="ml-2 h-auto w-3" />
      </Typography>
    </>
  );
};
