import { string, object, InferType } from 'yup';

import { password, email } from '@app/utils/yup.utils';

export const SignInFormValidationSchema = object({
  email: email(),
  password: password(),
});
export type SignInFormValidationSchema = InferType<typeof SignInFormValidationSchema>;

export const SignUpFormValidationSchema = object({
  firstName: string().required('Required Field'),
  lastName: string().required('Required Field'),
  email: email(),
  password: password(),
});
export type SignUpFormValidationSchema = InferType<typeof SignUpFormValidationSchema>;

export const SignUpInvitedUserFormValidationSchema = object({
  firstName: string().required('Required Field'),
  lastName: string().required('Required Field'),
  password: password(),
});
export type SignUpInvitedUserFormValidationSchema = InferType<typeof SignUpInvitedUserFormValidationSchema>;

export const ConfirmCodeValidationSchema = object({
  confirmCode: string().required('Required Field'),
});
export type ConfirmCodeValidationSchema = InferType<typeof ConfirmCodeValidationSchema>;

export const ForgotPasswordValidationSchema = object({
  email: string().required('Required Field'),
});
export type ForgotPasswordValidationSchema = InferType<typeof ForgotPasswordValidationSchema>;

export const ConfirmPasswordValidationSchema = object({
  confirmCode: string().required('Required Field'),
  newPassword: password(),
});
export type ConfirmPasswordValidationSchema = InferType<typeof ConfirmPasswordValidationSchema>;
