import { EDailyRateFor, ESeverityLevel } from '@app/swagger-types';

export const renderFriendlyDailyRateFor = (dailyRateFor: EDailyRateFor) => {
  switch (dailyRateFor) {
    case EDailyRateFor.CLIENT:
      return 'Client';
    case EDailyRateFor.TESTER:
      return 'Tester';
    default:
      return 'Unknown';
  }
};

export const renderFriendlySeverityLabel = (severity: ESeverityLevel) => {
  switch (severity) {
    case ESeverityLevel.CRITICAL:
      return 'Critical';
    case ESeverityLevel.HIGH:
      return 'High';
    case ESeverityLevel.MEDIUM:
      return 'Medium';
    case ESeverityLevel.LOW:
      return 'Low';
    case ESeverityLevel.INFORMATIVE:
      return 'Informative';
    default:
      return 'Uknown';
  }
};

export const renderSeverityColor = (severity: ESeverityLevel) => {
  switch (severity) {
    case ESeverityLevel.CRITICAL:
      return '#FF5454';
    case ESeverityLevel.HIGH:
      return '#F5797A';
    case ESeverityLevel.MEDIUM:
      return '#FF9141';
    case ESeverityLevel.LOW:
      return '#39AF26';
    case ESeverityLevel.INFORMATIVE:
      return '#7F8086';
    default:
      return '#7F8086';
  }
};

export const renderSeverityBorderColor = (severity: ESeverityLevel) => {
  switch (severity) {
    case ESeverityLevel.CRITICAL:
      return 'rgba(202, 52, 52, 0.8)';
    case ESeverityLevel.HIGH:
      return 'rgba(255, 120, 120, 0.61)';
    case ESeverityLevel.MEDIUM:
      return '#926242';
    case ESeverityLevel.LOW:
      return '#376B35';
    case ESeverityLevel.INFORMATIVE:
      return '#63656B';
    default:
      return '#63656B';
  }
};
export const renderSeverityBackgroundColor = (severity: ESeverityLevel) => {
  switch (severity) {
    case ESeverityLevel.CRITICAL:
      return 'rgba(63, 43, 51, 0.6)';
    case ESeverityLevel.HIGH:
      return 'rgba(63, 43, 43, 0.49)';
    case ESeverityLevel.MEDIUM:
      return '#3A3630';
    case ESeverityLevel.LOW:
      return '#2D3A36';
    case ESeverityLevel.INFORMATIVE:
      return '#42444C';
    default:
      return '#42444C';
  }
};

export const getSeverityInfo = (severityLevel: ESeverityLevel) => {
  return {
    label: renderFriendlySeverityLabel(severityLevel),
    color: renderSeverityColor(severityLevel),
    borderColor: renderSeverityBorderColor(severityLevel),
    backgroundColor: renderSeverityBackgroundColor(severityLevel),
  };
};
