import React, { useEffect, useState } from 'react';
import { AnswerDto, EDailyRateFor, EPenetrationType } from '@app/swagger-types';
import { Typography } from '@mui/material';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import pluralize from 'pluralize';
import { useQuery } from 'react-query';
import { DailyRateConfigDto } from '@app/domain/settings/settings.form';
import { GET_SETTINGS_WORKING_DAYS_QUERY } from '@app/constants/query-api-configs';
import { SettingsApi } from '@app/domain/settings/settings.api';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { useScopingCalculator } from '@app/modules/admin/create-project/useScopingCalculator';

interface Props {
  penetrationType: EPenetrationType;
  countOfTesters: number;
  answers?: AnswerDto[];
  overrideDuration?: number;
  usdToGbpRate?: number;
  isD2NA: boolean;
}

const TBC_UPON_REVIEW = 'TBC Upon Review';

export const ScopingCalculator: React.FC<Props> = ({
  penetrationType,
  answers,
  countOfTesters,
  usdToGbpRate = 0,
  overrideDuration,
  isD2NA,
}) => {
  const { duration: _duration, hasEnoughDataForCalculation } = useScopingCalculator({ penetrationType, answers });
  const [priceForClient, setPriceForClient] = useState(0);

  const duration = overrideDuration || _duration;

  const { data, isFetching, error } = useQuery<DailyRateConfigDto, Error>(
    GET_SETTINGS_WORKING_DAYS_QUERY.name,
    async () => {
      return await SettingsApi.getDailyRates();
    },
    { keepPreviousData: true, staleTime: GET_SETTINGS_WORKING_DAYS_QUERY.config.staleTime }
  );

  useEffect(() => {
    data?.configs.forEach(({ dailyRateFor, priceCoin }) => {
      if (dailyRateFor === EDailyRateFor.CLIENT) {
        setPriceForClient(priceCoin);
      }
    });
  }, [data]);

  if (error) {
    return null;
  }

  const isDefined = Boolean(overrideDuration);
  const vatMultiplier = isD2NA ? 1.2 : 1;
  const renderPriceForClient = duration * priceForClient * countOfTesters * vatMultiplier;

  return (
    <div className="my-8 flex justify-center gap-20">
      {isFetching && !data && <ContainerLoader loading={isFetching} />}
      {!isFetching && data && (
        <>
          <div className="max-w-[115px] text-center">
            <Typography className="mb-2 text-xs text-grey6">
              {isDefined ? 'Defined' : 'Approximate'} project duration:
            </Typography>
            <Typography className="min-h-[35px] text-m font-bold">
              {hasEnoughDataForCalculation ? pluralize('day', duration, true) : TBC_UPON_REVIEW}
            </Typography>
          </div>
          <div className="max-w-[115px] text-center">
            <Typography className="mb-2 text-xs text-grey6">
              {isDefined ? 'Defined' : 'Approximate'} project price:
            </Typography>
            <div className="flex justify-between gap-2">
              <Typography className="min-h-[35px] text-m font-bold">
                {hasEnoughDataForCalculation ? formatDollars(renderPriceForClient) : TBC_UPON_REVIEW}
              </Typography>
              {hasEnoughDataForCalculation && Boolean(usdToGbpRate) && (
                <div className="text-grey6">
                  <Typography className="min-h-[35px] text-m font-bold">
                    {createGBPformatter(usdToGbpRate).formatGBP(renderPriceForClient)}
                  </Typography>
                </div>
              )}
            </div>
            {isD2NA && (
              <div className="text-grey6">
                <Typography className="text-s font-bold">20% VAT included</Typography>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
