import styles from './NotFound.module.scss';
import clsx from 'clsx';

import { Typography } from '@mui/material';

interface PropTypes {
  className?: string;
  notFoundMessage?: string;
}

export const NotFound: React.FC<PropTypes> = ({ className, notFoundMessage = 'No data to show' }) => {
  return (
    <div className={clsx(styles.Wrapper, className)}>
      <Typography align="center" className={styles.Text}>
        {notFoundMessage}
      </Typography>
    </div>
  );
};
