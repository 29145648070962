import { Outlet } from 'react-router-dom';

const baseRawCss = `
@page {
  margin: 0cm;

  size: A4 portrait;
  width: auto;
  max-width: none;
  height: auto;
  max-height: none;
}

@page {
  @top-right {
      content: "Page " counter(page) " of " counter(pages);
   }
}
`;

export const PdfModule: React.FC = () => {
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: baseRawCss }} />
      <Outlet />
    </>
  );
};
