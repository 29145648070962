import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { Avatar, Button, Form, Input } from '@app/ui-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CreateCommentValidationSchema } from '@app/domain/finding-comment/api/finding-comment.form';
import { format } from 'date-fns';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { getDateWithUTCOffset } from '@app/utils/date.utils';
import {
  useCreateFindingComment,
  useFindingComments,
} from '@app/domain/finding-comment/api/hooks/finding-comment.api.hook';

export const CommentsBlock: React.FC<{ findingId: string }> = ({ findingId }) => {
  const { control, formState, handleSubmit, register, clearErrors, reset } = useForm<CreateCommentValidationSchema>({
    resolver: yupResolver(CreateCommentValidationSchema),
  });

  const { isLoading: createCommentLoading, mutate: createFindingComment } = useCreateFindingComment();

  const { isFetching: commentsLoading, data: comments, error } = useFindingComments({ findingId });
  const onSubmit: SubmitHandler<CreateCommentValidationSchema> = (formData) => {
    clearErrors();
    createFindingComment({ Dto: formData, findingId });
    reset();
  };

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: createCommentLoading,
      };
    };
    return fn;
  }, [register, formState, createCommentLoading]);

  return (
    <Form control={control} onSubmit={handleSubmit(onSubmit)}>
      <Typography className="mb-6 text-xxl font-bold">Comments</Typography>
      {comments?.result?.map(({ id, user, text, createdDate }) => (
        <div key={id} className="mb-9">
          <div className="mb-6 flex justify-between">
            <div className="flex items-center">
              <Avatar className="mr-3.5" id="header-avatar-filter" width={36} height={36} imageId={user.avatarId} />
              <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
            </div>
            <div className="flex items-center">
              <Typography className="text-l font-bold text-white/60">
                {format(getDateWithUTCOffset(new Date(createdDate)), 'd MMM, HH:mm')} UTC
              </Typography>
            </div>
          </div>
          <Typography className="text-xs text-grey6">{text}</Typography>
        </div>
      ))}
      {(commentsLoading || createCommentLoading || error) && (
        <div className="mb-9">
          <ContainerLoader loading={commentsLoading || createCommentLoading} error={error} />
        </div>
      )}
      <Input {...inputRegister('text')} placeholder="Write a comment" multiline rows={5} />
      <div className="mt-8 w-full text-right">
        <Button
          className="min-w-[110px]"
          variant="outlined"
          color="secondary"
          size="large"
          type="submit"
          loading={createCommentLoading}
          disabled={commentsLoading}
        >
          Send
        </Button>
      </div>
    </Form>
  );
};
