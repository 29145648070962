import { mixed, MixedSchema, AnyObject, string, lazy, object, array, number, AnyObjectSchema } from 'yup';
import { URL_VALIDATOR_REGEX } from '@app/utils/validators';

export const oneOfEnum = <T>(enumObject: { [s: string]: T } | ArrayLike<T>): MixedSchema<T, AnyObject, undefined, ''> =>
  mixed<T>().oneOf(Object.values(enumObject));

export const password = () =>
  string()
    .required('Required Field')
    .min(8, 'Min 8 characters')
    .max(71, 'Max 71 characters')
    .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/, 'Invalid password');
export const email = () => string().email('Invalid email address.').required('Required Field');
export const url = () => string().matches(URL_VALIDATOR_REGEX, 'Invalid URL');

const lazyAny = lazy(() => mixed());

export type AnyLazyType = typeof lazyAny;

export const pageableData = <T extends AnyObjectSchema | AnyLazyType, V extends T['__outputType']>(data: T) => {
  return object({
    result: array().of<V>(data).required(),
    total: number().required(),
  });
};
