import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Chip, Typography } from '@mui/material';

import { Table, TableColumn, Button, Tooltip } from '@app/ui-components';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { preventEvent } from '@app/utils/event-handler.utils';
import { ConfirmDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDialog';
import { AppTesterOutDto } from '@app/domain/application/application.type';
import { UseMutateFunction } from 'react-query/types/react/types';
import { EApplicationStatus } from '@app/swagger-types';
import { CountryInfo } from '@app/shared/ui-components/country-info/CountryInfo';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { UseAssignTestersToProjectParams } from '@app/domain/project/api/hooks/project.api.hook';

export const RequestedTestersTable: React.FC<{
  testers: AppTesterOutDto[];
  loading: boolean;
  projectId: string;
  onAssignTester: UseMutateFunction<unknown, Error, UseAssignTestersToProjectParams>;
  rightHeaderContent?: React.ReactNode;
}> = ({ onAssignTester, testers, loading, projectId, rightHeaderContent }) => {
  const [renderList, setRenderList] = useState(testers);
  const [assignId, setAssignId] = useState<string>();

  useEffect(() => {
    setRenderList(testers);
  }, [testers]);

  const handleAssignTester = useCallback(
    (testerId: string) => {
      setAssignId(testerId);
      onAssignTester(
        { projectId, testerId },
        {
          onSuccess: () => {
            setRenderList((testers) =>
              testers.map((tester) =>
                tester.id === testerId ? { ...tester, appStatus: EApplicationStatus.ASSIGNED } : tester
              )
            );
          },
          onSettled: () => {
            setAssignId(undefined);
          },
        }
      );
    },
    [onAssignTester, projectId]
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <Typography className="text-xl font-bold">Tester&apos;s Requests</Typography>
      </div>
    );
  }, []);

  const columns: TableColumn<AppTesterOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'firstName',
        align: 'left',
        render: (tester) => <UserBlock user={tester} />,
      },
      {
        headerName: 'Certifications',
        field: 'certifications',
        align: 'left',
        render: ({ certifications }) => (
          <div className="flex items-center">
            {certifications?.slice(0, 2).map(({ name, id, abbreviation }) => (
              <Tooltip key={id} title={name}>
                <Chip label={abbreviation} className="rounded-[4px]" />
              </Tooltip>
            ))}
            {certifications && Boolean(certifications.length > 2) && (
              <Chip label={`+${certifications.length - 2}`} className="rounded-[4px]" />
            )}
          </div>
        ),
      },
      {
        headerName: 'Country',
        field: 'country',
        align: 'left',
        disableSort: true,
        render: ({ country }) => (country ? <CountryInfo countryName={country} /> : null),
      },
      {
        headerName: 'Skills',
        field: 'skills',
        align: 'left',
        render: ({ skills }) => (
          <div className="flex items-center">
            {skills?.slice(0, 2).map(({ name, id }) => (
              <Chip key={id} label={name} />
            ))}
            {skills && Boolean(skills.length > 2) && <Chip label={`+${skills.length - 2}`} />}
          </div>
        ),
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableSort: true,
        render: ({ id, firstName, lastName, appStatus }) =>
          appStatus === 'ASSIGNED' ? (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              className="cursor-default border-green/30 bg-green/5 text-green"
              onClick={preventEvent}
            >
              Assigned
            </Button>
          ) : (
            <ConfirmDialog
              trigger={
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<AddRoundedIcon className="text-xs" />}
                  size="small"
                  loading={assignId === id}
                  disabled={Boolean(assignId) && assignId !== id}
                >
                  Assign
                </Button>
              }
              onConfirm={() => handleAssignTester(id)}
              headerTitle="Assign tester"
              title={`Do you want to assign ${firstName} ${lastName}?`}
            />
          ),
      },
    ],
    [handleAssignTester, assignId]
  );

  return (
    <Table
      cols={columns}
      tableData={renderList}
      leftHeaderContent={leftContent}
      rightHeaderContent={rightHeaderContent}
      notFoundMessage="No testers"
      loading={loading}
      cardHeaderClassName="flex items-start"
      hidePageSize
      noShadow
      wrapperClassName="min-w-[999px] min-h-[700px]"
      transparentBackground={false}
    />
  );
};
