import { ReactNode } from 'react';
import clsxm from '@app/lib/clsxm';
import { BreakUnit } from '../PdfPager';
import styles from '../pdf-salus.module.scss';
import { D2NA_FULL_NAME, D2NA_SHORT_NAME, SALUS_FULL_NAME, SALUS_SHORT_NAME } from '@app/domain/d2na/d2na.constants';

export const ClientTacBody: React.FC<{
  isD2NA: boolean;
}> = ({ isD2NA }) => {
  const renderItem = (tabs: string[], { title, text }: { title?: ReactNode; text?: ReactNode }) => (
    <BreakUnit>
      <div className={clsxm(styles.tacRow, 'flex')}>
        {tabs.map((tab, index) => (
          <div key={index} className={styles.tab}>
            {tab}
          </div>
        ))}
        {title && <b>{title}</b>}
        {text && <div>{text}</div>}
      </div>
    </BreakUnit>
  );

  const shortName = isD2NA ? D2NA_SHORT_NAME : SALUS_SHORT_NAME;
  const fullName = isD2NA ? D2NA_FULL_NAME : SALUS_FULL_NAME;

  return (
    <>
      <BreakUnit>
        <div className={clsxm(styles.content, 'text-center')}>
          <div className={styles.tacHeader}>TERMS AND CONDITIONS</div>
        </div>
      </BreakUnit>
      {renderItem([], { title: 'What are these Terms and Conditions about?' })}
      {renderItem([], {
        text: (
          <>
            These Terms and Conditions (“<b>Terms</b>”) apply when you sign our Letter.
          </>
        ),
      })}
      {renderItem([], {
        text: (
          <>
            We value transparency. These Terms are drafted to ensure that you are clear on what you are engaging us for,
            the scope of Services and our obligations to you.
          </>
        ),
      })}
      {renderItem([], { title: 'Key Words used in these Terms' })}
      {renderItem([], {
        text: (
          <>
            The capitalised words and phrases are defined in bold and in brackets after the concepts are first
            mentioned.
          </>
        ),
      })}
      {renderItem([], { title: 'Interpretation' })}
      {renderItem(['(a)'], {
        text: (
          <>
            (<b>singular and plural</b>) words in the singular includes the plural (and vice versa);
          </>
        ),
      })}
      {renderItem(['(b)'], {
        text: (
          <>
            (<b>defined terms</b>) if a word or phrase is given a defined meaning, any other part of speech or
            grammatical form of that word or phrase has a corresponding meaning;
          </>
        ),
      })}
      {renderItem(['(c)'], {
        text: (
          <>
            (<b>person</b>) a reference to “person” includes an individual, a corporation, an authority, an association,
            consortium or joint venture (whether incorporated or unincorporated), a partnership, a trust and any other
            entity;
          </>
        ),
      })}
      {renderItem(['(d)'], {
        text: (
          <>
            (<b>party</b>) a reference to a party includes that party’s permitted assigns, including persons taking by
            way of novation;
          </>
        ),
      })}
      {renderItem(['(e)'], {
        text: (
          <>
            (<b>this Letter</b>) a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure is
            a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure to or of this Letter,
            and a reference to this Letter includes all schedules, exhibits, attachments and annexures to it;
          </>
        ),
      })}
      {renderItem(['(f)'], {
        text: (
          <>
            (<b>document</b>) a reference to a document (including this Letter) is to that document as varied, novated,
            ratified or replaced from time to time;
          </>
        ),
      })}
      {renderItem(['(g)'], {
        text: (
          <>
            (<b>headings</b>) headings and words in bold type are for convenience only and do not affect interpretation
            of this Letter and the Terms;
          </>
        ),
      })}
      {renderItem(['(h)'], {
        text: (
          <>
            (<b>includes</b>) the word {'"includes"'} and similar words in any form is not a word of limitation; and
          </>
        ),
      })}
      {renderItem(['(i)'], {
        text: (
          <>
            (<b>adverse interpretation</b>) no provision of this Agreement will be interpreted adversely to a party
            because that party was responsible for the preparation of this Agreement or that provision;
          </>
        ),
      })}
      {renderItem(['(j)'], {
        text: (
          <>
            (<b>in writing or written</b>) includes email; and
          </>
        ),
      })}
      {renderItem(['(k)'], {
        text: (
          <>
            (<b>currency</b>) a reference to a currency is in $ or United States Dollars, unless otherwise agreed in
            writing between the parties.
          </>
        ),
      })}
      {renderItem(['1'], { title: 'SCOPE OF ENGAGEMENT' })}
      {renderItem(['1.1'], {
        text: (
          <>
            You will first be required to sign the Letter and pay the Fees (as defined in clause 2) as set out by us. We
            reserve the right to decline to act if the Fees are not paid before the commencement of the Services.
          </>
        ),
      })}
      {renderItem(['1.2'], { title: 'Services' })}
      {renderItem([''], {
        text: (
          <>
            We will provide the Services (“<b>Services</b>”) mentioned in as per terms drafted per project.
          </>
        ),
      })}
      {renderItem(['1.3'], { title: 'Outcome Report ' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            We will provide you with a detailed report (covering technical, management and public aspects) in .pdf
            format on the outcome of the Services (“<b>Outcome Report</b>”), which will entail highlighting any
            vulnerabilities, security risks and failures.{' '}
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The contents and format of the Outcome Report will be determined by {shortName} at its sole discretion, and
            the Outcome Report shall not be customisable. By using our Services, you acknowledge that we acts as a
            third-party assessor and possess a degree of independence in formulating our findings and as such, we will
            not remove or minimize findings in an Outcome Report at your request without sufficient factual basis for
            doing so.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            The Client may remove {shortName}&apos; logo and any other marks from an Outcome Report and use it for their
            own, non-commercial, business purposes.
          </>
        ),
      })}
      {renderItem(['', '(d)'], {
        text: (
          <>
            The Client agrees and acknowledges that the Outcome Report will be available to download via our online
            platform. {shortName} will store the Outcome Reports on its servers. The Outcome Report will be available to
            you and anyone invited by you to our online platform and to our authorised Personnel (as defined below).
          </>
        ),
      })}
      {renderItem(['1.4'], { title: 'Services Timeline' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            We will use our best efforts to perform the Services and deliver the Outcome Report within the timeline
            mentioned per project.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            {shortName} will use its best efforts to meet the Timeline, however these are subject to change if any
            unforeseen complexities arise. {shortName} will use its best efforts to minimise the impact of such changes
            and notify the Client if more than 24-hour variation is required.
          </>
        ),
      })}
      {renderItem(['1.5'], { title: 'Membership Concept of Days' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            With effect from the date of this Letter, your account with us will be updated to reflect the amount of days
            purchased.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The scope of Services is based on a projected level of effort expressed in the number of days. The level of
            Services associated with the required days to execute engagements varies depending on the product type, size
            and complexity of the target. The price and other applicable details associated with a purchased day will be
            set forth on your sales order and invoice.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            Please note that the Services for which days may be used excludes certain professional services offerings
            (such as, without limitation, red team services, IOT/device testing, etc.), unless otherwise expressly
            agreed to by us (and subject to such additional terms and conditions as we may require relative to
            performing professional services).
          </>
        ),
      })}
      {renderItem(['1.6'], { title: 'Client Feedback' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            Upon delivery of the Outcome Report, the Client agrees to provide feedback or comments on the Outcome
            Report, if any, within 5 business days from the date of delivery of the Outcome Report (“
            <b>Feedback Timeline</b>”).
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The Client shall send any feedback to, or request any minor amendments from, {shortName}, in one email (“
            <b>Feedback</b>”).
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            After receiving the Feedback, {shortName} will incorporate the Feedback into the Outcome Report, and provide
            an updated version of the Outcome Report to the Client.
          </>
        ),
      })}
      {renderItem(['', '(d)'], {
        text: (
          <>
            {shortName} will only allow for one round of Feedback. Any feedback request in addition to the one round is
            not included in the Service or the scope of work under this Letter and may be performed by {shortName} at
            its sole discretion. If any additional changes require additional work to be done by {shortName}, then{' '}
            {shortName} will charge separately for such additional services.
          </>
        ),
      })}
      {renderItem(['1.7'], { title: 'Third Party Contractors' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            You acknowledge and agree that we may engage independent third party contractors (“<b>Personnel</b>”) to
            assist us in providing the Services and you consent to such engagement
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The Client may request the name of any Personnel working on the Services, however, we shall not provide
            copies of agreements entered between us and the Personnel.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            Where we subcontract any Services, it shall be our continuing obligation to ensure that the subcontracted
            and/or delegated services are and continue to be performed at all times in accordance with the requirements
            of these Terms.
          </>
        ),
      })}
      {renderItem(['', '(d)'], {
        text: (
          <>
            The Personnel shall not sublet or delegate any Services delegated or subcontracted to it to any third
            parties.
          </>
        ),
      })}
      {renderItem(['1.8'], { title: 'Third Party Terms and Conditions' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            The Client acknowledges and agrees that the terms and conditions of third party service providers (“
            <b>Third Party Provider</b>”) may apply to any part or all of the Services.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The Client accepts and agrees to comply with the terms of use and policies of the Third Party Provider that
            apply to the Services.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            The Client acknowledges and agrees that if the Client does not agree to any Third Party Terms, this may
            affect the Company’s ability to meet delivery of the Services.
          </>
        ),
      })}
      {renderItem(['2'], { title: 'FEES' })}
      {renderItem(['2.1'], { title: 'Payment of Fees' })}
      {renderItem(['', '(a)'], {
        text: <>For us to commence the Services, you must first pay the fees as set out by us.</>,
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            {shortName} will issue a valid tax invoice (“<b>Invoice</b>”) to the Client for payment of the Fees.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            The Client must pay the Fees through normal banking channels via bank transfer to the Company’s designated
            bank account, the details of which will be provided by the Company to the Client.
          </>
        ),
      })}
      {renderItem(['', '(d)'], {
        text: (
          <>
            We reserve the right to charge credit card surcharges in the event that payment of the Fees is made using a
            credit, debit or charge card (including Visa, MasterCard or American Express).
          </>
        ),
      })}
      {renderItem(['', '(e)'], {
        text: <>The Fees paid under this Letter are not refundable except as provided in clause 8.2.</>,
      })}
      {renderItem(['', '(f)'], {
        text: (
          <>
            Unless otherwise indicated, all Fees exclude applicable goods and service tax which shall be payable at the
            applicable rate.
          </>
        ),
      })}
      {renderItem(['3'], { title: 'RETESTING' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            {shortName} may offer retesting of security vulnerabilities and failures. Only Clients with active accounts
            or subscriptions at the time of a retest request are eligible for retesting.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            Retesting is applicable only to those security vulnerabilities or failures that the Client have taken steps
            to remediate and the intent of retesting is to validate the efficacy of the Client’s remediation efforts.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            Retesting involves our Personnel re-engaging via the same or similar methodologies in testing of specified
            security vulnerabilities, risks and failures that were originally identified in the Outcome Report.
          </>
        ),
      })}
      {renderItem(['', '(d)'], {
        text: (
          <>
            The timing for retesting is at our sole discretion. Retesting is subject to the same terms and conditions
            applicable to the provision of Services by {fullName} to you including payment of Fees.
          </>
        ),
      })}
      {renderItem(['4'], { title: 'INTELLECTUAL PROPERTY' })}
      {renderItem(['4.1'], { title: 'New Material' })}
      {renderItem([''], {
        text: (
          <>
            Intellectual Property Rights in New Material are immediately assigned to and vest in the Company as those
            rights are created.
          </>
        ),
      })}
      {renderItem(['4.2'], { title: 'Existing Material' })}
      {renderItem([''], {
        text: (
          <>
            Each party retains ownership of Intellectual Property Rights in Existing Material and nothing in this
            Agreement transfers ownership or assigns any Intellectual Property Rights in Existing Material of a party to
            the other party.
          </>
        ),
      })}
      {renderItem(['4.3'], { title: 'Licence' })}
      {renderItem([''], {
        text: (
          <>
            The Client must not use {shortName}&apos; Existing Material or New Material in any way without {shortName}
            &apos; prior written consent, including for marketing activities (such as social media communications).
          </>
        ),
      })}
      {renderItem(['4.4'], { title: 'Third Party Intellectual Property' })}
      {renderItem([''], {
        text: (
          <>
            The Client warrants that {shortName}&apos; receipt and use of the Services, New Materials and the
            Client&apos;s Existing Materials will not infringe the Intellectual Property Rights of any third party.
          </>
        ),
      })}
      {renderItem(['4.5'], { title: 'Intellectual Property Rights' })}
      {renderItem([''], {
        text: (
          <>
            “<b>Intellectual Property Rights</b>” means all copyright, trademark, design, patent, trade and business
            information, company and domain names, confidential and other proprietary rights, intangible property rights
            in documents, samples, drafts, templates, reports, software (including source and object code), inventions,
            data and other materials in any media whatsoever and any other rights to registration of such rights whether
            created before or after the date of this Letter throughout the world.
          </>
        ),
      })}
      {renderItem(['5'], { title: 'CONFIDENTIALITY' })}
      {renderItem(['5.1'], { title: 'Confidential Information' })}
      {renderItem([''], {
        text: (
          <>
            The Client shall not, during, or at any time after, the engagement under these Terms, disclose Confidential
            Information directly or indirectly to any third party, except:
          </>
        ),
      })}
      {renderItem(['', '(a)'], {
        text: <>with {shortName} prior written consent;</>,
      })}
      {renderItem(['', '(b)'], {
        text: <>as required by Law; or</>,
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            to their Personnel strictly on a need to know basis for the purposes of performing its obligations under
            this Letter, or to their advisors and consultants for obtaining professional advice (“
            <b>Additional Disclosees</b>”).
          </>
        ),
      })}
      {renderItem(['5.2'], { title: 'Breach' })}
      {renderItem([''], {
        text: (
          <>
            If either party becomes aware of a suspected or actual breach of clause 5.1 by that party or an Additional
            Disclosee, that party will immediately notify the other party and take reasonable steps required to prevent,
            stop or mitigate the suspected or actual breach. The parties agree that damages may not be a sufficient
            remedy for a breach of clause 5.1.
          </>
        ),
      })}
      {renderItem(['5.3'], { title: 'Permitted Use' })}
      {renderItem([''], {
        text: (
          <>
            A party may only use the Confidential Information of the other party for the purposes of exercising its
            rights or performing its obligations under this Letter.
          </>
        ),
      })}
      {renderItem(['5.4'], { title: 'Return' })}
      {renderItem([''], {
        text: (
          <>
            On termination or expiration of this engagement, each party agrees to immediately return to the other party,
            or (if requested by the other party) destroy, any documents or Material in its possession or control
            containing Confidential Information of the other party.
          </>
        ),
      })}
      {renderItem(['5.5'], { title: 'Additional Disclosees' })}
      {renderItem([''], {
        text: (
          <>
            Each party will ensure that Additional Disclosees keep the Confidential Information confidential on the
            terms provided in this clause 5. Each party will, when requested by the other party, arrange for an
            Additional Disclosure to execute a document in a form reasonably required by the other party to protect
            Confidential Information.
          </>
        ),
      })}
      {renderItem(['5.6'], { title: 'Confidential Information' })}
      {renderItem([''], {
        text: (
          <>
            “<b>Confidential Information</b>” means information of, or provided by, a party that is by its nature
            confidential information, is designated as confidential, or that the recipient of the information knows or
            ought to know is confidential (including all commercial information exchanged between the parties), but does
            not include information which is, or becomes, without a breach of confidentiality, public knowledge.
          </>
        ),
      })}
      {renderItem(['6'], { title: 'SECURITY' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            While we will use our best efforts to ensure that your information, data or other electronic materials (“
            <b>Data</b>”) that is being backed-up or stored as part of the Services will be stored securely, we will not
            be liable for any unauthorised use, destruction, loss, damage or alteration to the Data, including due to
            hacking, malware, ransomware, viruses, malicious computer code or other forms of interference.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            We do not accept responsibility for loss or damage to computer systems, mobile phones or other electronic
            devices arising in connection with the Services.
          </>
        ),
      })}
      {renderItem(['7'], { title: 'INDEMNIFICATION' })}
      {renderItem(['7.1'], {
        text: (
          <>
            The Client represents and warrants that it owns or have all necessary right, title and interest in the
            Application(s)/Network(s) in scope for the Services and that all the material and Application(s)/Network(s)
            submitted by it or on its behalf do not infringe upon or violate any third party proprietary rights,
            intellectual property rights, industrial property rights, personal or moral rights or any other rights,
            including without limitation, copyright, trademark, patent, trade secret, privacy, publicity or
            confidentiality obligations or violate any person’s rights of privacy or otherwise violate any applicable
            law.
          </>
        ),
      })}
      {renderItem(['7.2'], {
        text: (
          <>
            To the maximum extent permitted by applicable law, the Client agrees to indemnify and hold harmless us at
            all times from and against any liability, claims, demands, losses, damages, costs and expenses (including
            reasonable attorney’s fees) arising out of or relating to (i) the Client’s improper or unlawful use of the
            Services; (ii) the Client’s failure to properly perform its obligations under these Terms; (iii) the
            Client’s negligence or wilful misconduct; (iv) the Client’s infringement or violation of applicable law; (v)
            any misrepresentation made by the Client in connection with the Services; and (vi) the Client’s breach,
            default or violation of this Letter and the Terms (collectively as “<b>Indemnified Claims</b>”).
          </>
        ),
      })}
      {renderItem(['8'], { title: 'TERMINATION' })}
      {renderItem(['8.1'], { title: 'Termination by the Client' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            The Client may, by providing an immediate written notice to u, terminate this Letter within 24 hours of the
            receipt of the Invoice. Any payment received during this time period shall be refunded in full.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            In case the Client terminates this Letter after the expiry of 24 hours from the receipt of the Invoice, then
            {shortName} shall be entitled to forfeit the Fees.
          </>
        ),
      })}
      {renderItem(['8.2'], { title: 'Termination by us' })}
      {renderItem(['', '(a)'], {
        text: <>We may at any time terminate this engagement by providing a written notice to the Client.</>,
      })}
      {renderItem(['', '(b)'], {
        text: <>Upon such termination, we shall refund the Client the Fees paid to us.</>,
      })}
      {renderItem(['9'], { title: 'LIMITATION OF LIABILITY' })}
      {renderItem(['9.1'], {
        text: (
          <>
            The Client agrees and acknowledges that the nature of Services under this Letter may cause harm or
            disruption to an application and/or networks of the Client and that neither {shortName} nor any of its
            Personnel shall have any liability of any kind arising out of such testing activities unless the Personnel
            has committed gross negligence or committed wilful misconduct in performance of the Services.
          </>
        ),
      })}
      {renderItem(['9.2'], {
        text: (
          <>
            We do not give a guarantee against any malfunction of the entire test site or any late, lost, damaged,
            misdirected, incomplete, illegible, undeliverable, or destroyed Outcome Report submissions due to system
            errors, failed, incomplete or distorted computer or other telecommunication transmission malfunctions,
            hardware or software failures of any kind, lost or unavailable network connections, typographical or
            system/human errors and failures, technical malfunction(s) of any telephone network or lines, cable
            connections, satellite transmissions, servers or providers, or computer equipment, traffic congestion on the
            Internet or at the test site, or any combination thereof, including other telecommunication, cable, digital
            or satellite malfunctions which may limit the period a program is listed on our website. The Outcome Report
            highlights and reflects the issues relating to security of the application or network of the Client only and
            should not be construed as providing any guarantee of safety or security of that application or network.
          </>
        ),
      })}
      {renderItem(['10'], { title: 'DISPUTE RESOLUTION' })}
      {renderItem(['10.1'], { title: 'Injunctive Relief' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            Any dispute, claim or controversy arising out of or relating to this Letter and the Terms ({'"'}
            <b>Dispute</b>
            {'"'}) will be settled by binding arbitration, except that each party retains the right to seek injunctive
            or other equitable relief in a court of competent jurisdiction.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The parties acknowledge and agree that each party waives their right to a trial by jury or to participate as
            a plaintiff or class member in any purported class action or representative proceeding.
          </>
        ),
      })}
      {renderItem(['10.2'], { title: 'Arbitration' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            Any dispute arising out of or in connection with this Letter and the Terms, including any question regarding
            its existence, validity or termination, shall be referred to and finally resolved by arbitration under the
            Rules of the London Court of International Arbitration (“<b>LCIA</b>”), which Rules are deemed to be
            incorporated by reference into this clause.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: <>The number of arbitrators shall be one.</>,
      })}
      {renderItem(['', '(c)'], {
        text: <>The seat, or legal place, of arbitration shall be London, United Kingdom.</>,
      })}
      {renderItem(['', '(d)'], {
        text: <>The language to be used in the arbitration shall be English.</>,
      })}
      {renderItem(['', '(e)'], {
        text: <>The governing law of the contract shall be the substantive law of England.</>,
      })}
      {renderItem(['11'], { title: 'MISCELLANEOUS PROVISIONS' })}
      {renderItem(['11.1'], { title: 'Governing Law and Jurisdiction' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            This Letter and the Terms and any non-contractual obligations arising out of or in connection with this
            Letter and the Terms shall be governed by and shall be construed in accordance with English law.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            Subject to Clause 10.2, both parties hereto hereby irrevocably submit to the exclusive jurisdiction of the
            courts of England to settle any Dispute.
          </>
        ),
      })}
      {renderItem(['11.2'], { title: 'Entire Agreement' })}
      {renderItem([''], {
        text: (
          <>
            This Letter and the Terms constitute the entire agreement between the parties and supersedes any prior
            negotiation, conduct, arrangement, understanding or agreement, express or implied, in relation to the
            subject matter mentioned herein.
          </>
        ),
      })}
      {renderItem(['11.3'], { title: 'Amendments' })}
      {renderItem([''], {
        text: <>This Agreement may only be amended by mutual agreement in writing signed by both parties.</>,
      })}
      {renderItem(['11.4'], { title: 'Waiver' })}
      {renderItem([''], {
        text: (
          <>
            No party to this Agreement may rely on the words or conduct of any other party as a waiver of any right
            unless the waiver is in writing and signed by the party granting the waiver.
          </>
        ),
      })}
      {renderItem(['11.5'], { title: 'Severance' })}
      {renderItem([''], {
        text: (
          <>
            Any term of this Agreement which is wholly or partially void or unenforceable is severed to the extent that
            it is void or unenforceable. The validity and enforceability of the remainder of this Agreement is not
            limited or otherwise affected.
          </>
        ),
      })}
      {renderItem(['11.6'], { title: 'Third Party Rights' })}
      {renderItem([''], {
        text: (
          <>
            This Agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to
            enforce any term of this Agreement.
          </>
        ),
      })}
      {renderItem(['11.7'], { title: 'Assignment' })}
      {renderItem([''], {
        text: (
          <>
            A party cannot assign, novate or otherwise transfer any of its rights or obligations under this Agreement
            without the prior written consent of the other party.
          </>
        ),
      })}
    </>
  );
};
