import { DEFAULT_USD_TO_GBP_RATE } from '@app/constants/currency';
import { usePublicGPBRateQuery, useSetGPBRateMutation } from '@app/domain/settings/currency/currency.query';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { Button, Input } from '@app/ui-components';
import { createGBPformatter, formatDollars, numberWithCommas } from '@app/utils/currency.utils';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

export const SettingsCurrency: React.FC = () => {
  const { data, isLoading, error } = usePublicGPBRateQuery();
  const setMutation = useSetGPBRateMutation();
  const { control, handleSubmit } = useForm<Form>();

  if (isLoading || !data || error) {
    return <ContainerLoader noData={!data} loading={isLoading} error={error ? new Error('failed') : undefined} />;
  }

  const submitHandler = (form: Form) => {
    setMutation.mutate(form.toUsdRate || DEFAULT_USD_TO_GBP_RATE);
  };

  const { usdToGbpRate } = data;

  return (
    <div className="flex max-w-xs flex-col gap-4">
      <div className="flex flex-col gap-4 rounded p-2 text-gray ring-1 ring-darkText">
        <div>100 USD = {createGBPformatter(usdToGbpRate).formatGBP(100)}</div>
        <div>100 GBP = {formatDollars(100 * data.toUsdRate)}</div>
        <div>USD to GBP rate: {numberWithCommas(usdToGbpRate, 2)}</div>
      </div>
      <form onSubmit={handleSubmit(submitHandler)} className="flex items-end justify-around gap-4">
        <Controller
          name="toUsdRate"
          control={control}
          render={({ field }) => (
            <NumberFormat
              {...field}
              customInput={Input}
              disabled={setMutation.isLoading}
              decimalScale={2}
              defaultValue={data.toUsdRate}
              placeholder={`${DEFAULT_USD_TO_GBP_RATE}`}
              label="GBP to USD rate"
              allowNegative={false}
            />
          )}
        />
        <Button loading={setMutation.isLoading} type="submit" className="min-h-[46px]">
          Save
        </Button>
      </form>
    </div>
  );
};

type Form = {
  toUsdRate: number;
};
