import { OpenableEntityManager } from '@app/services/openable-entity-manager';

import { VerificationDialog } from './general/verification-dialog/VerificationDialog';

export const dialogManager = new OpenableEntityManager({
  VERIFICATION_DIALOG: {
    component: VerificationDialog,
  },
});
declare global {
  type DialogManagerType = typeof dialogManager;
}
