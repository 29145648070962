import React from 'react';
import { EUserStatus } from '@app/swagger-types';
import { ConfirmDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDialog';
import { IconButton } from '@app/ui-components';
import { LockIcon } from '@app/assets/icons';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { UserDto } from '@app/models/auth';

export const UserActionsCell: React.FC<{
  user: UserDto;
  onBlockUser: (userId: string) => void;
  onUnblockUser: (userId: string) => void;
  disabledBlock?: boolean;
  disabledUnblock?: boolean;
  isBlocking?: boolean;
  isUnblocking?: boolean;
}> = ({
  user: { id, firstName, lastName, status },
  onBlockUser,
  onUnblockUser,
  disabledBlock,
  disabledUnblock,
  isBlocking,
  isUnblocking,
}) => {
  return (
    <div>
      {status === EUserStatus.ACTIVE && (
        <ConfirmDialog
          trigger={
            <IconButton disabled={disabledBlock} loading={isBlocking}>
              <LockIcon />
            </IconButton>
          }
          headerTitle="Block User"
          title={`Are you sure you want to block ${firstName} ${lastName}?`}
          icon={<LockIcon width={16} height={16} />}
          confirmIcon={<LockIcon />}
          confirmText="Block"
          onConfirm={() => onBlockUser(id)}
        />
      )}
      {status === EUserStatus.BANNED && (
        <ConfirmDialog
          trigger={
            <IconButton disabled={disabledUnblock} loading={isUnblocking}>
              <LockOpenIcon className="fill-[#DBDDEA] stroke-transparent" />
            </IconButton>
          }
          headerTitle="Unblock User"
          title={`Are you sure you want to unblock ${firstName} ${lastName}?`}
          icon={<LockOpenIcon className="!text-[16px]" />}
          confirmIcon={<LockOpenIcon className="!text-[13px]" />}
          confirmText="Unblock"
          onConfirm={() => onUnblockUser(id)}
        />
      )}
    </div>
  );
};
