import React from 'react';
import clsxm from '@app/lib/clsxm';
import styles from '@app/modules/pdf/pages/pdf-salus.module.scss';

export const KeyAgreementTable: React.FC<{ dateInvoiceGenerated: string }> = ({ dateInvoiceGenerated }) => {
  const items = [
    { title: 'Commencement Date', data: dateInvoiceGenerated },
    { title: 'Services', data: 'Penetration Testing' },
    { title: 'Work Times', data: 'Dependent on the project requirements, including weekends' },
    { title: 'Fees', data: 'Variable per project' },
    { title: 'Termination Notice Period', data: 'Immediate' },
    { title: 'Exclusivity', data: 'No' },
    { title: 'Payment Date(s)', data: '1 month after services rendered' },
  ];

  return (
    <table className={clsxm(styles.table, styles.left)}>
      <thead>
        <tr className="bg-grey4">
          <th colSpan={3}>Key Agreement Details </th>
        </tr>
      </thead>
      <tbody>
        {items.map(({ title, data }, index) => (
          <tr key={index}>
            <td className="bg-grey3 font-bold">{title}</td>
            <td colSpan={2}>{data}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
