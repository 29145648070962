import React, { useMemo } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Typography } from '@mui/material';

import { ConfirmPasswordReqDto, ConfirmPasswordValidationSchema } from '@app/models/auth';
import { Input, Button, Form } from '@app/ui-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { isString } from 'lodash';
import { CustomPasswordChecklist } from '@app/modules/auth/components/CustomPasswordChecklist';

interface Props {
  onConfirmPassword: (data: ConfirmPasswordReqDto) => void;
  loading: boolean;
  email: string;
  error?: Error;
}

export const ConfirmPasswordForm: React.FC<Props> = ({ onConfirmPassword, loading, error, email }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    clearErrors,
  } = useForm<ConfirmPasswordValidationSchema>({
    resolver: yupResolver(ConfirmPasswordValidationSchema),
  });

  const onSubmit: SubmitHandler<ConfirmPasswordValidationSchema> = (formData) => {
    clearErrors();
    onConfirmPassword({ ...formData, email });
  };

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: errors[key]?.message,
        disabled: loading,
      };
    };
    return fn;
  }, [register, errors, loading]);

  return (
    <div className="space-y-4">
      <Typography variant="xxxl" className="mb-[14px] font-bold">
        Restore Password
      </Typography>
      <Typography variant="l" className="!mb-[39px] block text-grey5">
        {`We sent the code to the ${email}`}
      </Typography>
      <Form control={control} onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Input {...inputRegister('confirmCode')} label="Code" placeholder="Enter Code" id="code" withBackground />
        <Controller
          name="newPassword"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Input
                {...field}
                label="Password"
                placeholder="Enter Password"
                id="password"
                type="password"
                autoComplete="new-password"
                withBackground
                disabled={loading}
                error={Boolean(error)}
              />
              {isString(field.value) && <CustomPasswordChecklist value={field.value} />}
            </>
          )}
        />
        <TypedLink to={Routes.auth.sign_in} className="block text-s font-bold text-grey5 hover:text-grey6">
          Back to Log In
        </TypedLink>
        <Button type="submit" variant="outlined" color="gold" fullWidth className="!mt-9 font-medium" loading={loading}>
          Confirm
        </Button>
        {maybeRenderError(error)}
      </Form>
    </div>
  );
};
