import { ERole, EUserStatus } from '@app/swagger-types';

export const renderFullName = ({ firstName, lastName }: { firstName: string; lastName: string }) =>
  `${firstName} ${lastName}`;

export const renderFriendlyRole = (role: ERole) => {
  switch (role) {
    case ERole.ROLE_ADMIN:
      return 'Admin';
    case ERole.ROLE_CLIENT:
      return 'Client';
    case ERole.ROLE_TESTER:
      return 'Tester';
    case ERole.ROLE_COLLABORATOR:
      return 'Collaborator';
    default:
      return 'Uknown';
  }
};

export const getFriendlyUserStatus = (status: EUserStatus) => {
  switch (status) {
    case EUserStatus.INACTIVE:
      return { label: 'Pending', color: '#6E9FFF' };
    case EUserStatus.ACTIVE:
      return { label: 'Verified', color: '#39AF26' };
    case EUserStatus.LOCKED:
      return { label: 'Rejected', color: '#ff6b6b' };
    case EUserStatus.DELETED:
      return { label: 'Deleted', color: '#BABAC9' };
    case EUserStatus.BANNED:
      return { label: 'Disabled', color: '#BABAC9' };
    default:
      return { label: 'Unknown', color: '#BABAC9' };
  }
};
