import React, { useCallback } from 'react';

import { Dialog } from '@app/hoc';
import { Button } from '@app/ui-components';
import { useRenderProductQuestion } from '@app/domain/product/useRenderProductQuestion';
import { useForm } from 'react-hook-form';
import { QuestionSchema } from '@app/domain/product/product.schema';
import { ProductQuestionnaireForm } from '@app/domain/product/product.form';

interface Props {
  open: boolean;
  onClose: () => void;
  questions?: QuestionSchema[];
}

export const DemoQuestionnaireDialog: React.FC<Props> = ({ open, onClose, questions = [] }) => {
  const { control, formState, register, clearErrors, reset } = useForm<ProductQuestionnaireForm>();
  const renderProductQuestion = useRenderProductQuestion({
    control,
    formState,
    register,
    questions,
  });

  const handleClose = useCallback(() => {
    onClose();
    clearErrors();
    reset();
  }, [onClose, clearErrors, reset]);

  return (
    <Dialog
      open={open}
      paperClassName="w-[730px] p-7 max-w-none"
      headerTitle="Questionnaire"
      headerSubtitle="Interactive demo. Questionnaire for clients looks like below."
      onClose={handleClose}
    >
      <div className="max-h-[70vh] overflow-y-auto rounded-md bg-grey1 p-7">
        {questions?.map((q) => {
          return (
            <div key={q.id} className="last:md-0 mb-4">
              {renderProductQuestion(q)}
            </div>
          );
        })}
      </div>
      <div className="mb-3 mt-10 flex justify-center">
        <Button onClick={handleClose} variant="outlined" color="secondary" size="large">
          Close
        </Button>
      </div>
    </Dialog>
  );
};
