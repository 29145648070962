import {
  GET_USERS_QUERY,
  GET_CLIENT_QUERY,
  GET_ADMIN_QUERY,
  GET_TESTER_QUERY,
  GET_COLLABORATOR_QUERY,
} from '@app/constants/query-api-configs';
import { QueryClient } from 'react-query';

export const invalidateAllAndSpecificUserProfileById = async (queryClient: QueryClient, userId: string) => {
  await queryClient.invalidateQueries(GET_USERS_QUERY.name);
  await queryClient.invalidateQueries([GET_CLIENT_QUERY.name, { clientId: userId }]);
  await queryClient.invalidateQueries([GET_ADMIN_QUERY.name, { adminId: userId }]);
  await queryClient.invalidateQueries([GET_TESTER_QUERY.name, { testerId: userId }]);
  await queryClient.invalidateQueries([GET_COLLABORATOR_QUERY.name, { collaboratorId: userId }]);
};
