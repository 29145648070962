import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { UserDto } from '@app/models/auth';
import { ClientInDto, CollaboratorInDto, TesterInDto, UserInDto } from '@app/swagger-types';
import { ClientDto, CollaboratorOutSchema, TesterDto } from '@app/models/user';
import { SuperAdminDto } from '@app/models/super-admin';

export const UserProfileApi = buildApi(
  class _UserProfileApi extends ApiCollection {
    async getMe() {
      return this.apiService.get<UserDto>('/private/auth/me');
    }
    async updateMyTesterProfile(Dto: Partial<TesterInDto>) {
      return this.apiService.patch<TesterDto, Partial<TesterInDto>>('/private/auth/tester/me', Dto);
    }
    async updateMyClientProfile(Dto: Partial<ClientInDto>) {
      return this.apiService.patch<ClientDto, Partial<ClientInDto>>('/private/auth/client/me', Dto);
    }
    async updateMyAdminProfile(Dto: Partial<UserInDto>) {
      return this.apiService.patch<SuperAdminDto, Partial<UserInDto>>('/private/auth/me', Dto);
    }
    async updateMyCollaboratorProfile(Dto: Partial<CollaboratorInDto>) {
      return this.apiService.patch<CollaboratorOutSchema>('/private/auth/collaborator/me', Dto);
    }
  }
);
