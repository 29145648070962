import { EAuthMethod } from '@app/auth/constant';
import { object, string } from 'yup';
import { oneOfEnum } from '../utils/yup.utils';

const EnvSchema = object({
  REACT_APP_ENV_NAME: string().required(),
  REACT_APP_API_BASE_URL: string().required(),
  REACT_APP_API_BASE_URL_D2NA: string().required(),
  NODE_ENV: string().required(),
  REACT_APP_AUTH_METHOD: oneOfEnum(EAuthMethod).required(),
});

export const typedEnv = EnvSchema.cast(process.env, { stripUnknown: true });

export const IS_AUTH_METHOD_COOKIE = typedEnv.REACT_APP_AUTH_METHOD === EAuthMethod.COOKIE;
export const IS_AUTH_METHOD_TOKEN = typedEnv.REACT_APP_AUTH_METHOD === EAuthMethod.TOKEN;

export const IS_PROD_ENV = typedEnv.REACT_APP_ENV_NAME === 'production';
export const IS_NOT_PROD_ENV = !IS_PROD_ENV;

export const IS_DEV_ENV = typedEnv.REACT_APP_ENV_NAME === 'development';
