import { useMount } from 'react-use';

import { AuthStatus } from '@app/models/auth';

import useStore from '@app/store/useStore.hook';

import { RootRoutes } from './RootRoutes';
import { useFiltredRoutes } from '@app/router';
import { useSlackSync } from '@app/domain/user/useSlackSync.hook';
import { useEffect } from 'react';

export const RootLayoutElements = () => {
  const elements = useFiltredRoutes(RootRoutes);

  return <>{elements}</>;
};

export const RootLayout = () => {
  const { getMe, authStatus } = useStore(({ currentUser, getMe, authStatus }) => ({
    currentUser,
    getMe,
    authStatus,
  }));

  const { sync } = useSlackSync();

  useMount(() => {
    getMe();
  });

  useEffect(() => {
    if (authStatus === AuthStatus.SIGNED_IN || authStatus === AuthStatus.SIGNED_IN_WELCOME) {
      sync();
    }
  }, [authStatus, sync]);

  if (authStatus === AuthStatus.UNKNOWN) {
    return null;
  }

  return <RootLayoutElements />;
};
