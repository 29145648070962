import { Outlet } from 'react-router-dom';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { Tabs, Typography } from '@mui/material';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { OutlinedTab } from '@app/shared/ui-components/tabs/OutlinedTab';
import React from 'react';
import { useGetUsersTotal } from '@app/domain/user/api/hooks/user.api.hook';
import { ERole } from '@app/swagger-types';

const tabRoutes = mutable([
  Routes.admin.user_management.clients,
  Routes.admin.user_management.testers,
  Routes.admin.user_management.admins,
  Routes.admin.user_management.collaborators,
] as const);

export const UserManagementModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);
  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to });
  };

  const clientsTotal = useGetUsersTotal(ERole.ROLE_CLIENT);
  const testersTotal = useGetUsersTotal(ERole.ROLE_TESTER);
  const adminsTotal = useGetUsersTotal(ERole.ROLE_ADMIN);
  const collaboratorsTotal = useGetUsersTotal(ERole.ROLE_COLLABORATOR);

  return (
    <>
      <div>
        <Typography className="mb-8 text-xxxl font-bold">User management</Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="mb-8"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <OutlinedTab label="Clients" value={tabRoutes[0]} count={clientsTotal} />
          <OutlinedTab label="Testers" value={tabRoutes[1]} count={testersTotal} />
          <OutlinedTab label="Admins" value={tabRoutes[2]} count={adminsTotal} />
          <OutlinedTab label="Collaborators" value={tabRoutes[3]} count={collaboratorsTotal} />
        </Tabs>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};
