import { AppLogo } from '@app/assets/app-logo/AppLogo';
import { IS_DEV_ENV } from '@app/environment/typed-env';
import clsxm from '@app/lib/clsxm';
import { useEffect, useMemo, useState } from 'react';
import styles from './pdf-salus.module.scss';

const UNIT_CLASSNAME = 'break-unit';
const UNIT_LOADING_CLASSNAME = 'break-unit-loading';
const INTERVAL_FOR_UNITS_LOOKUP = 300;

export const BreakUnit: React.FC<{
  header?: boolean;
  pageBreak?: boolean;
  loading?: boolean;
  className?: string;
}> = ({ children, pageBreak = false, header, loading, className }) => {
  let dataType = EUnitType.ELEMENT;
  if (header) {
    dataType = EUnitType.HEADER;
  } else if (pageBreak) {
    dataType = EUnitType.PAGE_BREAK;
  }
  return (
    <div className={clsxm(UNIT_CLASSNAME, loading && UNIT_LOADING_CLASSNAME, className)} data-type={dataType}>
      {children}
    </div>
  );
};

// guard for data type
const getDataType = (dom: HTMLElement) => {
  const type = dom.dataset.type;
  if (type === EUnitType.PAGE_BREAK) {
    return EUnitType.PAGE_BREAK;
  }
  if (type === EUnitType.HEADER) {
    return EUnitType.HEADER;
  }
  return EUnitType.ELEMENT;
};

enum EUnitType {
  ELEMENT = 'ELEMENT',
  PAGE_BREAK = 'PAGE_BREAK',
  HEADER = 'HEADER',
}

interface IUnit {
  dom: HTMLDivElement;
  height: number;
  type: EUnitType;
}

interface IHeader {
  title: string;
  // attr for #scroll on link click
  id: string;
  // shifted by indexShift
  pageNumber: number;
}

export interface IPdfRenderResult {
  units: IUnit[];
  headers: IHeader[];
}

export const PdfPager: React.FC<{
  indexShift?: number;
  // wait specified delay before processing break units, for example to complete animations inside
  delay?: number;
  onResult?: (result: IPdfRenderResult) => void;
  headerSelector?: string;
  isD2NA: boolean;
}> = ({ children, indexShift = 0, delay = 0, onResult, headerSelector = '.header', isD2NA }) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [units, setUnits] = useState<IUnit[] | null>(null);
  // innerRef used to calc page inner width and height (without padding)
  const [innerRef, setInnerRef] = useState<HTMLDivElement | null>(null);
  const [innerSize, setInnerSize] = useState<{ width: number; height: number } | null>(null);
  const [trigger, setTrigger] = useState(1);
  // wait specified delay before processing break units
  const [wait, setWait] = useState(true);

  useEffect(() => {
    let mounted = true;
    const timer = setTimeout(() => {
      if (!mounted) {
        return;
      }
      setWait(false);
    }, delay);
    return () => {
      clearTimeout(timer);
      mounted = false;
    };
  }, [delay, trigger]);

  useEffect(() => {
    // trigger recalc of chunks and units, needed for dev purposes to rerender after HMR
    return () => {
      setUnits(null);
      setInnerSize(null);
      setTrigger((i) => i + 1);
      setWait(true);
    };
  }, []);

  useEffect(() => {
    if (!ref || wait) {
      return;
    }
    console.log('trigger', trigger);
    let mounted = true;
    let tick = 0;
    console.log('schedule interval');
    const cleanup = () => {
      console.log('clear interval', tick);
      clearInterval(interval);
    };
    const interval = setInterval(() => {
      console.log('tick interval', ++tick);
      if (!mounted) {
        cleanup();
        return;
      }
      // wait while at least one unit is still loading
      if (ref.querySelector(`.${UNIT_LOADING_CLASSNAME}`)) {
        return;
      }
      const list = (
        [...ref.querySelectorAll(`.${UNIT_CLASSNAME}`)].filter((e) => e instanceof HTMLDivElement) as HTMLDivElement[]
      ).map((e) => ({
        dom: e,
        height: e.offsetHeight,
        type: getDataType(e),
      }));
      setUnits(list);
      cleanup();
    }, INTERVAL_FOR_UNITS_LOOKUP);

    return () => {
      cleanup();
      mounted = false;
      setUnits(null);
    };
  }, [ref, trigger, wait]);

  useEffect(() => {
    if (!innerRef) {
      return;
    }
    setInnerSize({
      width: innerRef.offsetWidth,
      height: innerRef.offsetHeight,
    });
  }, [innerRef]);

  const { pages, headers } = useMemo(() => {
    if (!innerSize || !units) {
      return { pages: [], headers: [] };
    }
    const maxHeight = innerSize.height;
    const pages: HTMLDivElement[] = [];
    const headers: IHeader[] = [];
    const generateChunk = () => {
      const div = document.createElement('div');
      pages.push(div);
      return div;
    };
    let curHeight = 0;
    let curChunk = generateChunk();
    for (const u of units) {
      const h = u.height;
      if (curHeight + h > maxHeight || u.type === EUnitType.PAGE_BREAK) {
        curHeight = 0;
        curChunk = generateChunk();
      }
      curChunk.append(u.dom);
      curHeight += h;
      if (u.type === EUnitType.HEADER) {
        const h = u.dom.querySelector(headerSelector);
        if (!h) {
          throw new Error('header not found');
        }
        headers.push({
          title: h.textContent || '',
          id: h.id || '',
          pageNumber: pages.length + indexShift,
        });
      }
    }
    return { pages, headers };
  }, [innerSize, units, headerSelector, indexShift]);
  console.log('pages', pages, headers);

  const renderContent = () => {
    if (!units || !innerSize) {
      return (
        <>
          <div className={clsxm(styles.page)}>{children}</div>
          {/* render one more page to calc its inner size available for content */}
          <div className={clsxm(styles.page)}>
            <div ref={setInnerRef} className="h-full w-full" />
          </div>
        </>
      );
    }

    onResult?.({ units, headers });
    return (
      <>
        {/* render children for dev env, so hot reload works */}
        {IS_DEV_ENV && <div className="hidden">{children}</div>}
        {/* TODO render via Portal to avoid issues with hot reload and node mismatch */}
        {pages.map((e, i) => {
          return <DomPageContainer key={i} content={e} index={i + indexShift} isD2NA={isD2NA} />;
        })}
      </>
    );
  };

  return <div ref={setRef}>{renderContent()}</div>;
};

export const DomPageContainer: React.FC<{
  content: Element;
  index: number;
  isD2NA: boolean;
}> = ({ content, index, isD2NA }) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!ref) {
      return;
    }
    ref.append(content);
  }, [content, ref]);

  return (
    <div ref={setRef} className={clsxm(styles.page)}>
      <Footer pageNumber={index + 1} isD2NA={isD2NA} />
    </div>
  );
};

export const Footer: React.FC<{
  pageNumber: number;
  isD2NA: boolean;
}> = ({ pageNumber, isD2NA }) => {
  return (
    <div className={clsxm(styles.footer, isD2NA && styles.footerSalus, 'z-0 flex items-center justify-end pr-4')}>
      CONFIDENTIAL{' '}
      <AppLogo
        className={clsxm(styles.footerLogo, 'ml-2', isD2NA && 'mt-1')}
        width={isD2NA ? 80 : 32}
        height={32}
        d2na={isD2NA}
      />
      <div className="pl-2">{pageNumber}</div>
    </div>
  );
};
