import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { OverrideInvoiceStatusInDto } from '@app/swagger-override-types';
import { InvoiceOutDtoSchema } from '@app/domain/invoice/invoice.schema';
import { validateAndAssert } from '@app/services/api-request';
import { pageableData } from '@app/utils/yup.utils';
import { InvoiceAPIQueryParams } from '@app/domain/invoice/invoice.type';

export const InvoiceApi = buildApi(
  class _InvoiceApi extends ApiCollection {
    async updateStatus(invoiceId: string, Dto: OverrideInvoiceStatusInDto) {
      return this.apiService.patch<never>(`/private/invoices/${invoiceId}/status`, Dto);
    }
    async getInvoices(params: InvoiceAPIQueryParams) {
      return validateAndAssert(
        this.apiService.get('/private/invoices/', { params }),
        pageableData(InvoiceOutDtoSchema)
      );
    }
    async getInvoiceById(invoiceId: string) {
      return validateAndAssert(this.apiService.get(`/private/invoices/${invoiceId}`), InvoiceOutDtoSchema);
    }
    async delete(invoiceId: string) {
      return this.apiService.delete(`/private/invoices/${invoiceId}`);
    }
  }
);
