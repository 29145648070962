import React, { useMemo } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { format } from 'date-fns';
import pluralize from 'pluralize';

import { DATE_FORMAT } from '@app/constants/date';
import { getPercentagesBetweenTwoDates } from '@app/utils/date.utils';
import { IconButton } from '@app/ui-components';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { EditIcon } from '@app/assets/icons';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';

export const TimelineBox: React.FC<{
  project: OverrideProjectOutDto;
  onEdit: () => void;
}> = ({ project, onEdit }) => {
  const { startDate, endDate, workingDays } = project;
  const hasDateRange = startDate && endDate && workingDays;

  const { isAdmin } = useUserRole();

  const percentages = useMemo(() => {
    const todayDate = new Date();
    if (startDate && endDate) {
      return getPercentagesBetweenTwoDates(new Date(startDate), new Date(endDate), todayDate);
    }
    return 0;
  }, [startDate, endDate]);

  return (
    <div className={clsxm('relative rounded-[10px] bg-transparentBackground py-6 px-8', sharedStyle.shadowBorder)}>
      <Typography className="mb-5 text-xl font-bold">
        Timeline {hasDateRange && <span className="text-grey7">({pluralize('day', workingDays, true)})</span>}
      </Typography>
      {isAdmin && ['SCHEDULED', 'LIVE', 'COMPLETED'].includes(project.status) && (
        <IconButton onClick={onEdit} className="absolute top-[12px] right-[18px] bg-grey2">
          <EditIcon />
        </IconButton>
      )}
      <div className="flex justify-between pb-14">
        <div>
          <Typography className="mb-1.5 text-m text-grey7">Start Date</Typography>
          <Typography className="text-m font-bold">
            {hasDateRange ? format(new Date(startDate), DATE_FORMAT) : '-'}
          </Typography>
        </div>
        <div>
          <Typography className="mb-1.5 text-m text-grey7">End Date</Typography>
          <Typography className="text-m font-bold">
            {hasDateRange ? format(new Date(endDate), DATE_FORMAT) : '-'}
          </Typography>
        </div>
      </div>
      <LinearProgress color="primary" className="h-3 w-full" variant="determinate" value={percentages} />
    </div>
  );
};
