import React from 'react';
import { DeleteIcon } from '@app/assets/icons';
import { ConfirmDialog, ConfirmDialogProps } from '@app/shared/ui-components/confirm-dialog/ConfirmDialog';

export const ConfirmDeleteDialog: React.FC<ConfirmDialogProps> = (props) => {
  return (
    <ConfirmDialog
      icon={<DeleteIcon width={16} height={16} />}
      confirmIcon={<DeleteIcon />}
      confirmText="Delete"
      {...props}
    />
  );
};
