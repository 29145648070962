import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Chip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getNames } from 'country-list';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { UseMutateFunction } from 'react-query/types/react/types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useQuery, useQueryClient } from 'react-query';

import { TesterDto } from '@app/models/user';
import { Button, Checkbox, Form, IconButton, Input, OptionItem, Select, Tooltip } from '@app/ui-components';
import { CountryInfo } from '@app/shared/ui-components/country-info/CountryInfo';
import { GitHubIcon, LinkedinIcon } from '@app/assets/icons';
import { TesterProfileSchema } from '@app/domain/user-profile/user-profile.form';
import {
  GET_CERTIFICATES_QUERY,
  GET_MY_PROFILE_QUERY,
  GET_SKILLS_QUERY,
  GET_TESTER_QUERY,
} from '@app/constants/query-api-configs';
import { DictionaryApi } from '@app/domain/dictionary/dictionary.api';
import { TesterInDto, CertificationDto, SkillDto } from '@app/swagger-types';
import { Autocomplete } from '@app/shared/ui-components/fields/autocomplete/Autocomplete';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';

interface Props {
  tester: TesterDto;
  onConfirm: UseMutateFunction<unknown, Error, Partial<TesterInDto>>;
  isMyProfile?: boolean;
  isLoading?: boolean;
}

const JSX_COUNTRIES = getNames().map((country) => (
  <OptionItem key={country} value={country}>
    <CountryInfo countryName={country} maxLength={41} placement="right" />
  </OptionItem>
));

export const TesterForm: React.FC<Props> = ({ tester, onConfirm, isLoading, isMyProfile }) => {
  const [certifications, setCertifications] = useState<CertificationDto[]>(tester.certifications || []);
  const [skills, setSkills] = useState<SkillDto[]>(tester.skills || []);
  const navigate = useTypedNavigate();
  const queryClient = useQueryClient();

  const { firstName, lastName, country, paypalEmail, github, linkedin, id: testerId, address } = tester;

  const { control, handleSubmit, register, clearErrors, formState } = useForm<TesterProfileSchema>({
    resolver: yupResolver(TesterProfileSchema),
    defaultValues: {
      firstName,
      lastName,
      country,
      paypalEmail,
      github,
      linkedin,
      address,
    },
  });

  useEffect(() => {
    setCertifications(tester.certifications || []);
  }, [tester.certifications]);

  useEffect(() => {
    setSkills(tester.skills || []);
  }, [tester.skills]);

  const { data: certList } = useQuery(
    GET_CERTIFICATES_QUERY.name,
    async () => {
      const { data } = await DictionaryApi.getCertificates();
      return data;
    },
    { keepPreviousData: true, staleTime: GET_CERTIFICATES_QUERY.config.staleTime }
  );
  const { data: skillList } = useQuery(
    GET_SKILLS_QUERY.name,
    async () => {
      const { data } = await DictionaryApi.getSkills();
      return data;
    },
    { keepPreviousData: true, staleTime: GET_SKILLS_QUERY.config.staleTime }
  );

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: isLoading,
      };
    };
    return fn;
  }, [register, formState, isLoading]);

  const navigateToTesterPage = useCallback(() => {
    if (isMyProfile) {
      navigate({ to: Routes.admin.user_management.my_profile });
    } else {
      navigate({ to: Routes.admin.user_management.tester_profile, params: { id: testerId } });
    }
  }, [navigate, testerId, isMyProfile]);

  const handleCancel = useCallback(() => {
    navigateToTesterPage();
  }, [navigateToTesterPage]);

  const onSubmit: SubmitHandler<TesterProfileSchema> = (formData) => {
    clearErrors();
    const Dto = { ...formData, certifications: certifications.map(({ id }) => id), skills: skills.map(({ id }) => id) };
    onConfirm(Dto, {
      onSuccess: () => {
        queryClient.removeQueries(isMyProfile ? GET_MY_PROFILE_QUERY.name : GET_TESTER_QUERY.name);
        navigateToTesterPage();
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} control={control}>
      <div className={clsxm('rounded-[10px] bg-transparentBackground', sharedStyle.shadowBorder)}>
        <div className="flex">
          <div className="flex-[4] p-7">
            <Typography className="mb-9 text-m">Personal Info</Typography>
            <Input
              {...inputRegister('firstName')}
              label="First Name"
              placeholder="Enter First Name"
              id="name"
              className="mb-8"
            />
            <Input
              {...inputRegister('lastName')}
              label="Last Name"
              placeholder="Enter Last Name"
              id="last"
              className="mb-8"
            />
            <Controller
              name="country"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  displayEmpty
                  disabled={isLoading}
                  inputProps={{
                    label: 'Country',
                    className: 'mb-8',
                    errorMessage: fieldState.error?.message,
                  }}
                  renderValue={(value) => {
                    if (!value) {
                      return <span className="text-xs text-grey6">Country</span>;
                    }

                    return <CountryInfo countryName={value} maxLength={41} />;
                  }}
                  // TODO: improve performance via virtualization
                >
                  {JSX_COUNTRIES}
                </Select>
              )}
            />
            <Input
              {...inputRegister('paypalEmail')}
              label="Bank Account"
              placeholder="PayPal account"
              className="mb-8"
            />
            <Input {...inputRegister('address')} label="Address" placeholder="Enter Address" className="mb-8" />
          </div>
          <div className="flex-[8] p-7">
            <Typography className="mb-9 text-m">Social Info</Typography>
            <div className="flex gap-6">
              <div className="flex-[6]">
                <Input
                  {...inputRegister('linkedin')}
                  label={
                    <div className="flex items-center">
                      <LinkedinIcon className="mr-2" />
                      <Typography className="text-xs font-bold">LinkedIn</Typography>
                    </div>
                  }
                  labelProps={{
                    className: 'mb-3',
                  }}
                  placeholder="Link"
                  className="mb-8"
                />
              </div>
              <div className="flex-[6]">
                <Input
                  {...inputRegister('github')}
                  label={
                    <div className="flex items-center">
                      <GitHubIcon className="mr-2" />
                      <Typography className="text-xs font-bold">GitHub</Typography>
                    </div>
                  }
                  labelProps={{
                    className: 'mb-3',
                  }}
                  placeholder="Link"
                  className="mb-8"
                />
              </div>
            </div>
            <div>
              <div className="mb-16">
                <Typography className="mb-7 text-m">Certifications</Typography>
                <div className="flex gap-6">
                  <div className="flex-[6]">
                    {Boolean(certifications.length) ? (
                      certifications.map((cert) => (
                        <Tooltip key={cert.id} title={cert.name}>
                          <Chip
                            label={cert.abbreviation}
                            className="mb-2 rounded-[4px]"
                            deleteIcon={
                              <IconButton className="h-2 w-2">
                                <CloseIcon className="h-2 w-2 fill-white" />
                              </IconButton>
                            }
                            onDelete={() => setCertifications((prevCerts) => prevCerts.filter((c) => c.id !== cert.id))}
                          />
                        </Tooltip>
                      ))
                    ) : (
                      <Typography className="mt-2 text-xs text-grey5">No certifications yet</Typography>
                    )}
                  </div>
                  <div className="flex-[6]">
                    {/*TODO: fix performance*/}
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      renderTags={() => null}
                      disabled={isLoading}
                      browserAutocompleteOff
                      asyncSearch
                      fetchOnOpen
                      value={certifications}
                      isOptionEqualToValue={({ id: optionId }, { id: valueId }) => optionId === valueId}
                      onChange={(_, value) => {
                        setCertifications(value || []);
                      }}
                      getOptions={async () => certList || []}
                      getOptionLabel={({ abbreviation }) => abbreviation}
                      InputProps={{
                        placeholder: 'Add',
                      }}
                      renderOption={(props, option, { selected }) => (
                        <Tooltip key={option.id} title={option.name} placement="left">
                          <OptionItem {...props} value={option} className="bg-inherit duration-300 hover:bg-white/10">
                            <span className="flex items-center">
                              <Checkbox checked={selected} disableRipple />
                              {option.abbreviation}
                            </span>
                          </OptionItem>
                        </Tooltip>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div>
                <Typography className="mb-7 text-m">Skills</Typography>
                <div className="flex gap-6">
                  <div className="flex-[6]">
                    {Boolean(skills.length) ? (
                      skills.map((skill) => (
                        <Chip
                          key={skill.id}
                          label={skill.name}
                          className="mb-2"
                          deleteIcon={
                            <IconButton className="h-2 w-2">
                              <CloseIcon className="h-2 w-2 fill-white" />
                            </IconButton>
                          }
                          onDelete={() => setSkills((prevSkills) => prevSkills.filter((s) => s.id !== skill.id))}
                        />
                      ))
                    ) : (
                      <Typography className="mt-2 text-xs text-grey5">No skills yet</Typography>
                    )}
                  </div>
                  <div className="flex-[6]">
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      renderTags={() => null}
                      disabled={isLoading}
                      browserAutocompleteOff
                      asyncSearch
                      fetchOnOpen
                      value={skills}
                      onChange={(_, value) => setSkills(value || [])}
                      getOptions={async () => skillList || []}
                      getOptionLabel={({ name }) => name}
                      isOptionEqualToValue={({ id: optionId }, { id: valueId }) => optionId === valueId}
                      InputProps={{
                        placeholder: 'Search skills',
                      }}
                      renderOption={(props, option, { selected }) => (
                        <OptionItem
                          key={option.id}
                          {...props}
                          value={option}
                          className="bg-inherit duration-300 hover:bg-white/10"
                        >
                          <span className="flex items-center">
                            <Checkbox checked={selected} disableRipple />
                            {option.name}
                          </span>
                        </OptionItem>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-6 pb-8">
          <Button variant="outlined" color="secondary" size="large" onClick={handleCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="outlined" color="primary" size="large" type="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
};
