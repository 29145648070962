import React from 'react';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';

import { Status } from '@app/shared/ui-components/status/Status';
import { GET_MY_PROFILE_QUERY } from '@app/constants/query-api-configs';
import { getFriendlyUserStatus } from '@app/domain/user/user.utils';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';
import { UserInDto, EUserStatus } from '@app/swagger-types';
import { UserProfileApi } from '@app/domain/user-profile/user-profile.api';
import useStore from '@app/store/useStore.hook';
import { UserInfoBox } from '@app/domain/user-profile/UserInfoBox';
import { SuperAdminDto } from '@app/models/super-admin';

export const MyAdminProfile: React.FC<{ admin: SuperAdminDto }> = ({ admin }) => {
  const { status } = admin;
  const { setCurrentUser } = useStore();

  const queryClient = useQueryClient();

  const { mutate: updateMyAdminProfile, isLoading } = useMutation<SuperAdminDto, Error, Partial<UserInDto>>(
    async (Dto) => {
      const { data } = await UserProfileApi.updateMyAdminProfile(Dto);
      return data;
    },
    {
      onSuccess: (newTester) => {
        queryClient.invalidateQueries(GET_MY_PROFILE_QUERY.name);
        setCurrentUser(newTester);
      },
    }
  );

  const isDisabled = [EUserStatus.BANNED, EUserStatus.LOCKED, EUserStatus.DELETED].includes(status);

  return (
    <>
      <div>
        <div className="mb-32 flex items-center">
          <Typography className="mr-3.5 text-xxxl font-bold">My Profile</Typography>
          <Status {...getFriendlyUserStatus(status)} />
        </div>
        <div className={clsxm('w-80 rounded-[10px] bg-transparentBackground', sharedStyle.shadowBorder)}>
          <UserInfoBox
            user={admin}
            disabled={isDisabled}
            onAdminClient={updateMyAdminProfile}
            isEditLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
