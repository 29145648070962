import { generatePath, Link, LinkProps } from 'react-router-dom';

import { CheckParamReq, ExtractParams } from '../types';
import { ConditionalWrapper } from '@app/hoc';

export interface TypedLinkProps<TPath extends string> extends Omit<LinkProps, 'to'> {
  to: TPath;
  params?: ExtractParams<TPath>;
  queryParams?: string | Record<string, string>;
  stopPropagation?: boolean;
  disabled?: boolean;
}

export function TypedLink<TPath extends string>({
  to,
  params,
  queryParams,
  stopPropagation,
  disabled,
  ...rest
}: CheckParamReq<TypedLinkProps<TPath>>) {
  return (
    <ConditionalWrapper
      condition={Boolean(disabled)}
      wrapper={(children) => <span className="pointer-events-none">{children}</span>}
    >
      <Link
        {...rest}
        to={{
          pathname: generatePath(to, params),
          search: typeof queryParams === 'string' ? queryParams : `?${new URLSearchParams(queryParams).toString()}`,
        }}
        {...(stopPropagation
          ? {
              onClick: (e) => {
                e.stopPropagation();
              },
            }
          : {})}
      />
    </ConditionalWrapper>
  );
}
