import { Routes } from '@app/constants/routes';

import { TypedNavigate } from '@app/router';
import { ERole } from '@app/swagger-types';
import { FindProjectsModule } from '@app/modules/tester/modules/find-projects/FindProjectsModule';
import { AllScheduledProjectsPage } from '@app/modules/tester/modules/find-projects/pages/AllScheduledProjects.page';
import { AllAppliedProjectsPage } from '@app/modules/tester/modules/find-projects/pages/AllAppliedProjects.page';

export const FindProjectsRoutes = {
  path: Routes.findProjects.index,
  element: <FindProjectsModule />,
  role: [ERole.ROLE_TESTER],
  children: [
    { path: Routes.findProjects.all, element: <AllScheduledProjectsPage /> },
    { path: Routes.findProjects.applied, element: <AllAppliedProjectsPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.findProjects.all} /> },
  ],
};
