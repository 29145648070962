import React, { useCallback } from 'react';
import { CircularProgress, Typography } from '@mui/material';

import { renderFriendlyFindingStatus } from '@app/domain/finding/findings.utils';
import { Button } from '@app/ui-components';
import { OverrideFindingOutDto } from '@app/swagger-override-types';
import { Status } from '@app/shared/ui-components/status/Status';
import { CommentsBlock } from '@app/domain/finding-comment/CommentsBlock';
import { useFindingTransition } from '@app/domain/finding/finding.hook';
import { EFindingStatus } from '@app/swagger-types';
import Notification from '@app/shared/ui-components/notifications/Notifications';
import { Routes } from '@app/constants/routes';
import { TypedLink, useTypedNavigate } from '@app/router';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { renderFriendlySeverityVector } from '@app/shared/ui-components/cvss-calculator/cvss-calculator.utils';
import { FindingActions } from '@app/domain/finding/FindingActions';
import useStore from '@app/store/useStore.hook';
import { SeverityBadge } from './SeverityBadge';
import { normalizeUrlWithProtocol } from '@app/utils/api.util';
import { RichEditorView } from '@app/shared/ui-components/rich-editor/RichEditorView';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';
import { useDeleteFinding, useUpdateFindingStatus } from '@app/domain/finding/api/hooks/finding.api.hook';

interface Props {
  projectId: string;
  finding: OverrideFindingOutDto;
}

export const FindingPageBody: React.FC<Props> = ({ finding, projectId }) => {
  const findingTransitions = useFindingTransition(finding.isStatusChangedByClient);
  const navigate = useTypedNavigate();
  const currentUser = useStore.useCurrentUser();
  const { isTester } = useUserRole();

  const {
    title,
    id: findingId,
    affectedHost,
    reportDescription,
    stepsReplicate,
    remediationRecommendation,
    // managementReportSummary,
    affectedHttpRequest,
    methodology,
    cvssScore,
    severityVector,
    tester,
  } = finding;

  const navigateToProjectPage = useCallback(() => {
    navigate({ to: Routes.projects.project, params: { id: projectId } });
  }, [navigate, projectId]);

  const navigateToEditFindingPage = useCallback(
    (findingId: string) => {
      if (projectId) {
        navigate({ to: Routes.projects.editFinding, params: { projectId, findingId } });
      }
    },
    [projectId, navigate]
  );

  const { mutate: handleDelete, isLoading: isDeleting } = useDeleteFinding({
    onSuccess: () => {
      navigateToProjectPage();
      Notification.showSuccess('Finding has been successfully deleted');
    },
  });

  const { mutate: handleStatusChange, isLoading: isStatusChanging } = useUpdateFindingStatus();

  const renderSeverity = () => {
    return <SeverityBadge cvssScore={finding.cvssScore} />;
  };

  const renderStatus = (status: EFindingStatus) => {
    const { to, from } = findingTransitions;
    const { label, color } = renderFriendlyFindingStatus(status);
    const isActive = status === finding.status;
    const isDisableForCurrentUser = isTester ? currentUser?.id !== tester.id : false;

    return (
      <Button
        className="!border"
        style={isActive ? { borderColor: color } : {}}
        disabled={
          (!isActive && (!to.includes(status) || !from.includes(finding.status) || isDisableForCurrentUser)) ||
          isStatusChanging ||
          isDeleting
        }
        onClick={() => {
          if (!isActive) {
            handleStatusChange({ status, projectId, findingId });
          }
        }}
      >
        <Status label={label} color={color} />
      </Button>
    );
  };

  const renderInfoBlock = ({
    title,
    value,
    isCodeBlock,
  }: {
    title: string;
    value: React.ReactNode;
    isCodeBlock?: boolean;
  }) => (
    <div className="gap-1 border-b border-grey4 py-8 pl-12 pr-20">
      <div className="flex w-full items-center">
        <div className="flex-[3]">
          <Typography className="text-xl font-bold">{title}</Typography>
        </div>
        {isCodeBlock ? (
          <pre className="flex-[9] whitespace-pre-wrap break-all rounded-[8px] border-2 border-[#383949] bg-[#1d1e26] p-4 text-m">
            {value}
          </pre>
        ) : (
          <div className="flex-[9]">{value}</div>
        )}
      </div>
    </div>
  );

  const renderDescriptionBlock = ({ title, value }: { title: string; value: string }) => (
    <div className="border-b border-grey4 py-8 pl-12 pr-20">
      <div>
        <Typography className="mb-6 text-xxl font-bold">{title}</Typography>
        <RichEditorView value={value} />
      </div>
    </div>
  );

  return (
    <div className={clsxm('rounded-xl bg-transparentBackground', sharedStyle.shadowBorder)}>
      <div className="border-b border-grey4 py-8 pl-12 pr-14">
        <div className="mb-10 flex justify-between">
          <Typography className="text-xxl font-bold">{title}</Typography>
          {isTester && (
            <FindingActions
              findingTitle={title}
              tester={tester}
              onEdit={() => navigateToEditFindingPage(findingId)}
              onDelete={() => handleDelete({ findingId, projectId })}
              disabled={isDeleting || isStatusChanging}
              isDeleting={isDeleting}
            />
          )}
        </div>
      </div>
      {renderInfoBlock({
        title: 'Status',
        value: (
          <div className="flex items-center justify-between">
            <div className="flex gap-8">
              {renderStatus(EFindingStatus.REQUIRES_ATTENTION)}
              {renderStatus(EFindingStatus.RETEST)}
              {renderStatus(EFindingStatus.FIXED)}
              {renderStatus(EFindingStatus.INFORMATIVE)}
            </div>
            {isStatusChanging && <CircularProgress size={30} />}
          </div>
        ),
      })}
      {renderInfoBlock({ title: 'Severity', value: renderSeverity() })}
      {renderInfoBlock({ title: 'CVSS Score', value: cvssScore })}
      {severityVector &&
        renderInfoBlock({
          title: 'CVSS v3.1 Vector',
          value: (
            <a
              href={`https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator?vector=${renderFriendlySeverityVector(
                severityVector
              )}&version=3.1`}
              target="_blank"
              rel="noreferrer"
              className={`text-l text-[#6E9FFF] underline`}
            >
              {renderFriendlySeverityVector(severityVector)}
            </a>
          ),
        })}
      {renderInfoBlock({ title: 'Finding Type', value: methodology?.sectionTitle || 'Other' })}
      {affectedHost &&
        renderInfoBlock({
          title: 'Affected Host',
          value: (
            <a
              href={normalizeUrlWithProtocol(affectedHost)}
              target="_blank"
              rel="noreferrer"
              className="text-m text-[#6E9FFF] underline"
            >
              {affectedHost}
            </a>
          ),
        })}
      {affectedHttpRequest && renderInfoBlock({ title: 'Http Request', value: affectedHttpRequest, isCodeBlock: true })}
      {reportDescription && renderDescriptionBlock({ title: 'Finding Description', value: reportDescription })}
      {stepsReplicate && renderDescriptionBlock({ title: 'Steps to Replicate', value: stepsReplicate })}
      {remediationRecommendation &&
        renderDescriptionBlock({ title: 'Remediation Recommendation', value: remediationRecommendation })}
      {/*{managementReportSummary &&*/}
      {/*  renderDescriptionBlock({ title: 'Management Finding Summary', value: managementReportSummary })}*/}
      <div className="py-8 pl-12 pr-20">
        <CommentsBlock findingId={findingId} />
      </div>
      <div className="flex justify-end pr-20 pb-9 pt-14">
        <TypedLink to={Routes.projects.project} params={{ id: projectId }}>
          <Button variant="outlined" color="primary" size="large" className="min-w-[110px]">
            Close
          </Button>
        </TypedLink>
      </div>
    </div>
  );
};
