import { oneOfEnum } from '@app/utils/yup.utils';
import { object, InferType, string, boolean } from 'yup';
import { QUESTION_CONFIG_VERSION } from '../product.type';

export const JsonConfigBaseSchema = object({
  version: oneOfEnum([QUESTION_CONFIG_VERSION] as const).required(),
  type: string().required(),
  inputType: oneOfEnum(['text', 'number'] as const).optional(),
  isOptional: boolean(),
  allowNegative: boolean(),
  defaultValue: string(),
});
export type JsonConfigBaseSchema = InferType<typeof JsonConfigBaseSchema>;

export const baseJsonConfig: JsonConfigBaseSchema = {
  version: QUESTION_CONFIG_VERSION,
  type: 'base',
};
