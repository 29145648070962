import React, { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useQueryClient } from 'react-query';

import { GET_PROJECTS_QUERY } from '@app/constants/query-api-configs';

import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';

import { IconButton, Table, TableColumn } from '@app/shared/ui-components';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { DATE_FORMAT } from '@app/constants/date';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { Typography } from '@mui/material';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { Status } from '@app/shared/ui-components/status/Status';
import { getFriendlyProjectStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { Tooltip } from '@app/shared/ui-components/';
import { RejectProjectDialog } from '@app/domain/project/components/RejectProjectDialog';
import { SetUpProjectDialog } from '@app/domain/project/components/SetUpProjectDialog';
import { EProjectStatus, ProjectOutDto } from '@app/swagger-types';
import { AssignTestersDialog } from '@app/domain/project/components/AssignTestersDialog';
import { formatDollars } from '@app/utils/currency.utils';
import { ProjectStatusButtons } from '@app/domain/project/components/ProjectStatusButtons';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { FindingStatsCell } from '@app/shared/tables/projects-table/FindingStatsCell';
import {
  useAssignTestersToProject,
  useDeleteProject,
  useGetProjects,
  useRejectProject,
  useSetProjectTimeline,
} from '@app/domain/project/api/hooks/project.api.hook';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';
import { DeleteIcon } from '@app/assets/icons';
import { PAGE_SIZE } from '@app/constants/api';
import { AssignTestersCell } from '@app/shared/tables/projects-table/AssignTestersCell';
import { PaginatedResponse } from '@app/api/types';
import { TestersInfo } from '@app/shared/tables/projects-table/TestersInfo';

export const AdminProjectsTable: React.FC<{
  status?: OverrideProjectOutDto['status'];
}> = ({ status: filterStatus }) => {
  const [idForRejecting, setIdForRejecting] = useState<string>();
  const [projectForAssignTester, setProjectForAssignTester] = useState<OverrideProjectOutDto>();
  const [projectForSetUp, setProjectForSetUp] = useState<OverrideProjectOutDto>();
  const navigate = useTypedNavigate();
  const queryClient = useQueryClient();

  const navigateToProjectPage = ({ id }: OverrideProjectOutDto) => {
    navigate({ to: Routes.projects.project, params: { id } });
  };

  const { mutate: deleteProject, isLoading: isDeletingProject } = useDeleteProject();

  const {
    values: { page, sort = GET_PROJECTS_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      search: '',
    },
    prefix: GET_PROJECTS_QUERY.tablePrefix,
  });

  const { data, isFetching } = useGetProjects(
    {
      sort,
      page: Math.ceil((page || 1) - 1),
      size: PAGE_SIZE,
      status: filterStatus,
      term: search,
    },
    {
      refetchOnMount: 'always',
    }
  );

  const { mutate: handleAssignTestersToProject } = useAssignTestersToProject({
    onSettled: () => setProjectForAssignTester(undefined),
  });

  const { mutate: handleTimelineChange, isLoading: isSettingLoading } = useSetProjectTimeline();

  const { mutate: handleRejectProject, isLoading: isRejectLoading } = useRejectProject({
    onSuccess: (_, { id, Dto }) => {
      // Invalidate data in list view
      queryClient.removeQueries({
        predicate: (query) =>
          query.queryKey[0] === GET_PROJECTS_QUERY.name && !(query.queryKey[1] as { size?: number })?.size,
      });

      queryClient.setQueryData<PaginatedResponse<ProjectOutDto>>(
        [
          GET_PROJECTS_QUERY.name,
          {
            sort,
            page: Math.ceil((page || 1) - 1),
            size: PAGE_SIZE,
            status: filterStatus,
            term: search,
          },
        ],
        (data) =>
          data
            ? {
                ...data,
                result: data.result.map((project) => {
                  if (project.id === id) {
                    return { ...project, ...Dto };
                  }
                  return project;
                }),
              }
            : {
                result: [],
                total: 0,
              }
      );
    },
  });

  const leftContent = useMemo(() => {
    return (
      <div>
        <SearchInput placeholder="Search by name" className="w-64" value={search} onChange={handleSearchChange} />
      </div>
    );
  }, [handleSearchChange, search]);

  const renderTimelineCol = ({ startDate, endDate }: OverrideProjectOutDto) => {
    if (startDate && endDate) {
      return (
        <div>
          <div>{format(new Date(startDate), DATE_FORMAT)}</div>
          <div>{format(new Date(endDate), DATE_FORMAT)}</div>
        </div>
      );
    }

    return '-';
  };

  const renderApplyDateCol = ({ myApplication }: OverrideProjectOutDto) => {
    if (myApplication) {
      return (
        <div>
          <div>{format(new Date(myApplication.createdDate), DATE_FORMAT)}</div>
        </div>
      );
    }

    return '-';
  };

  const columns: TableColumn<OverrideProjectOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Company',
        field: 'client.companyName',
        align: 'left',
        render: ({ client }) => client.companyName,
      },
      {
        headerName: 'Stats',
        field: 'findingStats',
        align: 'center',
        disableSort: true,
        render: ({ findingStats }) => <FindingStatsCell findingStats={findingStats} />,
      },
      {
        headerName: 'Client',
        field: 'client.firstName',
        align: 'left',
        disableClick: true,
        render: ({ client }) => <UserBlock user={client} hideName />,
      },
      {
        headerName: 'Name',
        field: 'name',
        align: 'left',
      },
      {
        headerName: 'Project Type',
        field: 'productTitle',
        align: 'left',
      },
      {
        headerName: 'Cost',
        field: 'priceForClient',
        align: 'center',
        hide: !filterStatus || filterStatus === 'PENDING',
        render: ({ priceForClient, priceForTester }) => (
          <div>
            <Tooltip title="Price for client" variant="dark" placement="right">
              <Typography>{priceForClient ? formatDollars(priceForClient) : '-'}</Typography>
            </Tooltip>
            <Tooltip title="Price for tester" variant="dark" placement="right">
              <Typography>{priceForTester ? formatDollars(priceForTester) : '-'}</Typography>
            </Tooltip>
          </div>
        ),
      },
      {
        headerName: filterStatus
          ? filterStatus === 'PENDING'
            ? 'Date of apply'
            : 'Timeline'
          : 'Date of apply/Timeline',
        headerNameJsx: !filterStatus && (
          <>
            Date of apply /
            <br />
            Timeline
          </>
        ),
        field: 'startDate',
        align: 'center',
        render: (project) =>
          project.status === EProjectStatus.PENDING || filterStatus === EProjectStatus.PENDING
            ? renderApplyDateCol(project)
            : renderTimelineCol(project),
      },
      {
        headerName: 'Testers',
        field: 'testers',
        align: 'center',
        disableSort: true,
        hide: !filterStatus || filterStatus === EProjectStatus.PENDING,
        render: (project) =>
          filterStatus === EProjectStatus.COMPLETED ? (
            project.testers?.length ? (
              <TestersInfo testers={project.testers} />
            ) : (
              '-'
            )
          ) : (
            <AssignTestersCell testers={project.testers} onAssignTesters={() => setProjectForAssignTester(project)} />
          ),
      },
      {
        headerName: 'Status',
        field: 'status',
        align: 'left',
        width: 130,
        hide: Boolean(filterStatus),
        render: ({ status }) => <Status {...getFriendlyProjectStatus(status)} />,
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableSort: true,
        disableClick: true,
        hide: filterStatus !== EProjectStatus.PENDING,
        render: (project) => {
          const { id } = project;

          return (
            <ProjectStatusButtons
              project={project}
              onApprove={() => setProjectForSetUp(project)}
              onReject={() => setIdForRejecting(id)}
              loadingApprove={isSettingLoading && projectForSetUp?.id === id}
              loadingReject={isRejectLoading && idForRejecting === id}
              disabledApprove={isRejectLoading || isSettingLoading}
              disabledReject={isRejectLoading || isSettingLoading}
            />
          );
        },
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        width: 130,
        hide: !filterStatus || filterStatus === EProjectStatus.PENDING,
        disableSort: true,
        disableClick: true,
        render: ({ id, name }) => (
          // TODO: add edit button
          <ConfirmDeleteDialog
            trigger={
              <IconButton>
                <DeleteIcon />
              </IconButton>
            }
            onConfirm={() => deleteProject(id)}
            headerTitle={`Delete ${name}`}
            title={`Are you sure you want to delete ${name}?`}
          />
        ),
      },
    ],
    [filterStatus, isSettingLoading, isRejectLoading, idForRejecting, projectForSetUp, deleteProject]
  );

  return (
    <>
      <Table
        cols={columns}
        tableData={data?.result || []}
        leftHeaderContent={leftContent}
        notFoundMessage="No projects"
        currentPage={page}
        loading={isFetching || isDeletingProject}
        total={data?.total}
        onPagination={handlePageChange}
        currentSort={sort}
        onSortChanged={handleSortChange}
        hidePageSize
        onItemSelect={navigateToProjectPage}
      />
      {idForRejecting && (
        <RejectProjectDialog
          open={Boolean(idForRejecting)}
          projectId={idForRejecting}
          onClose={() => setIdForRejecting(undefined)}
          onConfirm={handleRejectProject}
          isLoading={isRejectLoading}
        />
      )}
      <SetUpProjectDialog
        open={Boolean(projectForSetUp)}
        project={projectForSetUp}
        onClose={() => setProjectForSetUp(undefined)}
        onConfirm={handleTimelineChange}
        isLoading={isSettingLoading}
      />
      {projectForAssignTester && (
        <AssignTestersDialog
          open={Boolean(projectForAssignTester)}
          onClose={() => setProjectForAssignTester(undefined)}
          project={projectForAssignTester}
          onAssignTester={handleAssignTestersToProject}
        />
      )}
    </>
  );
};
