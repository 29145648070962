import React from 'react';
import { Button } from '@app/ui-components';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { EProjectStatus } from '@app/swagger-types';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { RejectedProjectBadge } from '@app/domain/project/components/RejectedProjectBadge';
import clsxm from '@app/lib/clsxm';
import { preventEvent } from '@app/utils/event-handler.utils';

interface Props {
  onApprove: () => void;
  onReject: () => void;
  project: OverrideProjectOutDto;
  loadingApprove?: boolean;
  loadingReject?: boolean;
  disabledApprove?: boolean;
  disabledReject?: boolean;
  wrapperClassName?: string;
  className?: string;
  showRejectedBadge?: boolean;
}

export const ProjectStatusButtons: React.FC<Props> = ({
  project,
  onApprove,
  onReject,
  loadingApprove,
  loadingReject,
  disabledApprove,
  disabledReject,
  wrapperClassName,
  className,
  showRejectedBadge = true,
}) => {
  const { isAdmin } = useUserRole();

  if (project.status === EProjectStatus.REJECTED && showRejectedBadge) {
    return <RejectedProjectBadge rejectReason={project.rejectReason} className={className} />;
  }

  if (isAdmin && project.status === EProjectStatus.PENDING) {
    return (
      <div className={clsxm('flex gap-3', wrapperClassName)}>
        <Button
          variant="outlined"
          color="secondary"
          className={clsxm(
            'flex-1 cursor-pointer !border border-green/30 bg-green/5 p-3 text-green hover:bg-green/30',
            className
          )}
          disabled={disabledApprove}
          loading={loadingApprove}
          onClick={(e) => {
            preventEvent(e);
            onApprove();
          }}
        >
          Approve
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          className={clsxm(
            'flex-1 cursor-pointer !border border-[#CA3434]/80 bg-[#3F2B33]/60 p-3 text-error hover:bg-[#CA3434]/30',
            className
          )}
          loading={loadingReject}
          disabled={disabledReject}
          onClick={(e) => {
            preventEvent(e);
            onReject();
          }}
        >
          Reject
        </Button>
      </div>
    );
  }
  return null;
};
