import React from 'react';
import { Typography } from '@mui/material';
import clsxm from '@app/lib/clsxm';

export const Status: React.FC<{ label: string; color: string; className?: string }> = ({ label, color, className }) => {
  return (
    <Typography className={clsxm('flex items-center text-xxs', className)} style={{ color }}>
      <span className="mr-2.5 rounded-full" style={{ height: 6, width: 6, backgroundColor: color }} />
      {label}
    </Typography>
  );
};
