import NumberFormat from 'react-number-format';

export const convertCentToUnit = (cents: number) => cents * 0.01;
export const convertUnitToCent = (usd: number) => usd * 100;

const createFormatter = (fractions = 0) => new Intl.NumberFormat('en-US', { maximumFractionDigits: fractions });
const nf0 = createFormatter(0);
const nf1 = createFormatter(1);
const nf2 = createFormatter(2);
export const numberWithCommas = (x: number | string, fractions = 0) => {
  if (fractions === 0) {
    return nf0.format(+x);
  }
  if (fractions === 1) {
    return nf1.format(+x);
  }
  if (fractions === 2) {
    return nf2.format(+x);
  }
  return createFormatter(fractions);
};

export const formatDollars = (usdAmount: number | string, fractions = 0) => {
  let num = +usdAmount;
  if (Number.isNaN(num)) {
    num = 0;
  }
  return `$${numberWithCommas(num.toFixed(fractions), 0)}`;
};

export const createGBPformatter = (usdToGbpRate = 1) => {
  const formatGBP = (usdAmount: number | string, fractions = 0) => {
    let num = +usdAmount * usdToGbpRate;
    if (Number.isNaN(num)) {
      num = 0;
    }
    return `£${numberWithCommas(num.toFixed(fractions), 0)}`;
  };
  return { formatGBP };
};

export const renderDollarAmount = (amount?: number | null) => {
  return amount ? <NumberFormat value={amount} displayType="text" prefix="$" thousandSeparator /> : '-';
};
