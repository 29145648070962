import React, { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { IconButton, Table, TableColumn, Tooltip } from '@app/shared/ui-components';
import { DeleteIcon } from '@app/assets/icons';
import { CollaboratorOutSchema } from '@app/models/user';
import { ProjectApi } from '@app/domain/project/project.api';
import { GET_COLLABORATOR_INVITATIONS_QUERY, GET_PROJECT_QUERY } from '@app/constants/query-api-configs';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { UserInvitationApi } from '@app/domain/user-invitation/api/user-invitation.api';
import { ERole, EUserInvitationStatus, UserInvitationDto } from '@app/swagger-types';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { ConfirmDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDialog';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';

interface PendingUserInvitationDto extends UserInvitationDto {
  status: EUserInvitationStatus.PENDING;
}

export const TeamMembersTable: React.FC<{ members: CollaboratorOutSchema[]; projectId: string }> = ({
  members,
  projectId,
}) => {
  const queryClient = useQueryClient();
  const { isClient } = useUserRole();

  const { data, isFetching, refetch } = useQuery(
    [GET_COLLABORATOR_INVITATIONS_QUERY.name, { projectId }],
    async () => {
      if (isClient) {
        const { data } = await UserInvitationApi.getInvitations({
          role: ERole.ROLE_COLLABORATOR,
          status: EUserInvitationStatus.PENDING,
          projectId,
        });
        return data;
      }
    },
    { keepPreviousData: true, staleTime: GET_COLLABORATOR_INVITATIONS_QUERY.config.staleTime }
  );

  const { mutate: deleteCollaborator, isLoading: isDeleteCollaboratorLoading } = useMutation(
    async (collaboratorId: string) => {
      await ProjectApi.deleteCollaboratorFromProject(projectId, collaboratorId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_PROJECT_QUERY.name);
      },
    }
  );

  const { mutate: cancelUserInvite, isLoading: isCancelInviteLoading } = useMutation(
    async (invitationId: string) => {
      await UserInvitationApi.updateStatus(invitationId, { status: EUserInvitationStatus.CANCELED });
    },
    {
      onSuccess: async () => {
        await refetch();
      },
    }
  );

  const columns = useMemo(() => {
    const cols: TableColumn<CollaboratorOutSchema | PendingUserInvitationDto>[] = [
      {
        headerName: 'Name',
        field: 'id',
        align: 'left',
        disableSort: true,
        render: (item) => {
          if (item.status === EUserInvitationStatus.PENDING) {
            return (
              <Tooltip title="Pending invitation" placement="right" className="cursor-help">
                <InfoIcon className="fill-[#757575]" />
              </Tooltip>
            );
          } else {
            return <UserBlock user={item} />;
          }
        },
      },
      {
        headerName: 'Email',
        field: 'email',
        align: 'left',
        disableSort: true,
      },
    ];

    if (isClient) {
      cols.push({
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableSort: true,
        render: (item) => {
          if (item.status === EUserInvitationStatus.PENDING) {
            return (
              <ConfirmDialog
                trigger={
                  <IconButton>
                    <CloseIcon />
                  </IconButton>
                }
                headerTitle="Cancel Invite"
                title={`Are you sure you want to cancel the invite for ${item.email}?`}
                onConfirm={() => cancelUserInvite(item.id)}
              />
            );
          } else {
            return (
              <ConfirmDeleteDialog
                trigger={
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                }
                headerTitle="Delete Member"
                title={`Are you sure you want to delete ${item.firstName} ${item.lastName} from project?`}
                onConfirm={() => deleteCollaborator(item.id)}
              />
            );
          }
        },
      });
    }

    return cols;
  }, [deleteCollaborator, cancelUserInvite, isClient]);

  const tableData = useMemo(
    () =>
      [...members, ...((data?.result as PendingUserInvitationDto[]) || [])].sort(
        (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      ),
    [members, data?.result]
  );

  const [page, setPage] = useState(1);
  const slicedTableData = useMemo(() => {
    return tableData.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  }, [tableData, page]);

  return (
    <Table<CollaboratorOutSchema | PendingUserInvitationDto>
      cols={columns}
      tableData={isFetching ? [] : slicedTableData}
      notFoundMessage="No members"
      loading={isDeleteCollaboratorLoading || isFetching || isCancelInviteLoading}
      hidePageSize
      noShadow
      currentPage={page}
      pageSize={PAGE_SIZE}
      total={tableData.length}
      onPagination={setPage}
      wrapperClassName="min-h-[310px]"
      cardContentClassName="min-h-[310px]"
      transparentBackground={false}
    />
  );
};

const PAGE_SIZE = 4;
