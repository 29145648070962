import { generatePath, useNavigate } from 'react-router-dom';

import { CheckParamReq, ExtractParams } from '../types';

interface TypedOptions<TPath extends string> {
  to: TPath;
  params: ExtractParams<TPath, string>;
  queryParams?: string | Record<string, string>;
  replace?: boolean;
  state?: unknown;
}

export interface TypedNavigateFunction {
  <TPath extends string>(options: CheckParamReq<TypedOptions<TPath>>): void;
}

export const useTypedNavigate = (): TypedNavigateFunction => {
  const navigate = useNavigate();
  return (options) => {
    return navigate(
      {
        pathname: generatePath(options.to, options.params),
        search:
          typeof options.queryParams === 'string'
            ? options.queryParams
            : `?${new URLSearchParams(options.queryParams).toString()}`,
      },
      { replace: options.replace, state: options.state }
    );
  };
};
