import React, { useState } from 'react';
import { ProjectItem } from '@app/domain/project/components/ProjectItem';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { GET_PROJECTS_QUERY } from '@app/constants/query-api-configs';
import { useDeleteProject, useInfinityGetMyProjects } from '@app/domain/project/api/hooks/project.api.hook';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { Button } from '@app/ui-components';
import AddIcon from '@mui/icons-material/Add';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { EProjectStatus } from '@app/swagger-types';
import { NoProjectsItem } from '@app/domain/project/components/NoProjectsItem';
import InfiniteScroll from 'react-infinite-scroller';

export const MyProjectList: React.FC<{ filterStatus?: EProjectStatus }> = ({ filterStatus }) => {
  const [projectIdForDeleting, setProjectIdForDeleting] = useState<string>();
  const { isClient } = useUserRole();

  const {
    values: { sort = GET_PROJECTS_QUERY.defaultSort, search },
    handlers: { handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      search: '',
    },
    prefix: GET_PROJECTS_QUERY.tablePrefix,
  });

  const { data, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useInfinityGetMyProjects(
    {
      sort,
      status: filterStatus,
      term: search,
    },
    {
      refetchOnMount: 'always',
    }
  );

  const { mutate: handleDeleteProject } = useDeleteProject({
    onSettled: () => setProjectIdForDeleting(undefined),
    onMutate: (projectId) => setProjectIdForDeleting(projectId),
  });

  const isEmpty = !data || data.pages.length === 0 || (data.pages[0] && data.pages[0].result.length === 0);

  const renderContent = () => {
    if (isFetching && !data) {
      return <ContainerLoader loading />;
    }

    if (isEmpty || !data) {
      return <NoProjectsItem />;
    }

    return (
      <InfiniteScroll
        hasMore={!isFetchingNextPage && hasNextPage}
        loadMore={() => fetchNextPage()}
        loader={<ContainerLoader loading />}
      >
        {data.pages.map(({ result }, i) => (
          <React.Fragment key={i}>
            {result.map((project) => (
              <ProjectItem
                key={project.id}
                project={project}
                isDeleting={project.id === projectIdForDeleting}
                onDelete={handleDeleteProject}
              />
            ))}
          </React.Fragment>
        ))}
        {isFetchingNextPage && <ContainerLoader loading />}
      </InfiniteScroll>
    );
  };

  return (
    <div>
      <div className="mb-5 flex justify-between">
        <SearchInput
          placeholder="Search by name"
          className="w-64"
          value={search}
          onChange={handleSearchChange}
          inputBaseClassName="bg-[rgba(36,37,45,.60)]"
        />
        {isClient && (
          <TypedLink to={Routes.projects.create}>
            <Button variant="outlined" color={'primary'} startIcon={<AddIcon />}>
              Add a Project
            </Button>
          </TypedLink>
        )}
      </div>
      <div>{renderContent()}</div>
    </div>
  );
};
