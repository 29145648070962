import React, { useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { Tabs, Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { OutlinedTab } from '@app/shared/ui-components/tabs/OutlinedTab';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { NoProjectsBlock } from '@app/domain/project/components/NoProjectsBlock';
import { ProjectApi } from '@app/domain/project/project.api';
import { useQuery } from 'react-query';
import { CHECK_ANY_PROJECT_CREATED_QUERY } from '@app/constants/query-api-configs';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { useGetMyProjectsTotal } from '@app/domain/project/api/hooks/project.api.hook';
import { EProjectStatus } from '@app/swagger-types';
import { ToggleViewButtons } from '@app/shared/ui-components/toggle-view-buttons/ToggleViewButtons';

const tabRoutes = mutable([
  Routes.projects.allProjects,
  Routes.projects.pendingProjects,
  Routes.projects.scheduledProjects,
  Routes.projects.liveProjects,
  Routes.projects.completedProjects,
] as const);

export const MyProjectsModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);
  const { isCollaborator } = useUserRole();

  const allProjectsTotal = useGetMyProjectsTotal();
  const pendingProjectsTotal = useGetMyProjectsTotal(EProjectStatus.PENDING);
  const scheduledProjectsTotal = useGetMyProjectsTotal(EProjectStatus.SCHEDULED);
  const liveProjectsTotal = useGetMyProjectsTotal(EProjectStatus.LIVE);
  const completedProjectsTotal = useGetMyProjectsTotal(EProjectStatus.COMPLETED);

  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to });
  };

  const { isFetching: checkAnyProjectLoading, data: hasAnyProjectCreated } = useQuery(
    CHECK_ANY_PROJECT_CREATED_QUERY.name,
    ProjectApi.checkAnyProjectCreated,
    {
      keepPreviousData: true,
      staleTime: CHECK_ANY_PROJECT_CREATED_QUERY.config.staleTime,
    }
  );

  const navigateToCreateProjectPage = useCallback(() => {
    navigate({ to: Routes.projects.create });
  }, [navigate]);

  return (
    <>
      <div>
        <Typography className="mb-8 text-xxxl font-bold">My Projects</Typography>
        {checkAnyProjectLoading && (
          <div className="h-[70vh]">
            <ContainerLoader loading={checkAnyProjectLoading} />
          </div>
        )}
        {!checkAnyProjectLoading && !hasAnyProjectCreated && !isCollaborator && (
          <NoProjectsBlock onCreateProject={navigateToCreateProjectPage} />
        )}
        {!checkAnyProjectLoading && (isCollaborator || hasAnyProjectCreated) && (
          <>
            <div className="my-5 flex items-end justify-between">
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    display: 'none',
                  },
                }}
              >
                <OutlinedTab label="All" value={tabRoutes[0]} count={allProjectsTotal} />
                <OutlinedTab label="Pending" value={tabRoutes[1]} count={pendingProjectsTotal} />
                <OutlinedTab label="Scheduled" value={tabRoutes[2]} count={scheduledProjectsTotal} />
                <OutlinedTab label="Live" value={tabRoutes[3]} count={liveProjectsTotal} />
                <OutlinedTab label="Completed" value={tabRoutes[4]} count={completedProjectsTotal} />
              </Tabs>
              <ToggleViewButtons />
            </div>
            <div>
              <Outlet />
            </div>
          </>
        )}
      </div>
    </>
  );
};
