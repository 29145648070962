import React, { useCallback } from 'react';
import { Typography, Box } from '@mui/material';

import { Avatar, Tooltip } from '@app/ui-components';
import clsxm from '@app/lib/clsxm';
import { ERole, UserOutDto } from '@app/swagger-types';
import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { SlackIcon } from '@app/assets/icons';

interface Props {
  //TODO: use schema
  user: UserOutDto;
  wrapperClassName?: string;
  disableNavigation?: boolean;
  navigateToMyProfile?: boolean;
  hideName?: boolean;
  avatarWidth?: number;
  avatarHeight?: number;
}

export const UserBlock: React.FC<Props> = ({
  user,
  wrapperClassName,
  navigateToMyProfile,
  disableNavigation,
  hideName,
  avatarWidth = 36,
  avatarHeight = 36,
}) => {
  const { id, role, avatarId, firstName, lastName, slackName } = user;
  const navigate = useTypedNavigate();

  const navigateToProfile = useCallback(() => {
    if (navigateToMyProfile) {
      navigate({ to: Routes.admin.user_management.my_profile });
    } else if (role === ERole.ROLE_TESTER) {
      navigate({ to: Routes.admin.user_management.tester_profile, params: { id } });
    } else if (role === ERole.ROLE_CLIENT) {
      navigate({ to: Routes.admin.user_management.client_profile, params: { id } });
    } else if (role === ERole.ROLE_ADMIN) {
      navigate({ to: Routes.admin.user_management.admin_profile, params: { id } });
    } else if (role === ERole.ROLE_COLLABORATOR) {
      navigate({ to: Routes.admin.user_management.collaborator_profile, params: { id } });
    }
  }, [navigate, id, role, navigateToMyProfile]);

  if (hideName) {
    return (
      <Tooltip title={`${firstName} ${lastName}`}>
        <Box
          className={clsxm(
            {
              'cursor-pointer': !Boolean(disableNavigation),
            },
            wrapperClassName
          )}
          onClick={disableNavigation ? undefined : navigateToProfile}
        >
          <Avatar width={36} height={36} imageId={avatarId} />
        </Box>
      </Tooltip>
    );
  }

  return (
    <Box
      className={clsxm(
        'flex items-center duration-300',
        {
          'cursor-pointer': !Boolean(disableNavigation),
        },
        wrapperClassName
      )}
      onClick={disableNavigation ? undefined : navigateToProfile}
    >
      <Avatar width={avatarWidth} height={avatarHeight} imageId={avatarId} className="mr-3" />
      <div>
        <Typography
          className={clsxm('text-xs', {
            'hover:underline': !Boolean(disableNavigation),
          })}
        >{`${firstName} ${lastName}`}</Typography>
        {Boolean(slackName) && (
          <div className="mt-0.5 flex items-center">
            <SlackIcon className="mr-1.5 h-[10px] w-[10px]" />
            <Typography className="text-xxs font-medium text-grey5">{slackName}</Typography>
          </div>
        )}
      </div>
    </Box>
  );
};
