import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import clsxm from '@app/lib/clsxm';
import { useHandler } from '@app/hooks/useHandler.hook';
import { Typography } from '@mui/material';
import { EView, useToggleViewButtons } from '@app/shared/ui-components/toggle-view-buttons/useToggleViewButtons';

export const ToggleViewButtons: React.FC = () => {
  const [view, setView] = useToggleViewButtons();

  const handleChange = useHandler((event: React.MouseEvent<HTMLElement>, value: EView | undefined) => {
    value && setView(value);
  });

  return (
    <div className="flex flex-col items-end">
      <Typography className="mb-1 text-xxs font-medium">View:</Typography>
      <ToggleButtonGroup className="h-8" value={view} exclusive onChange={handleChange}>
        <ToggleButton
          value={EView.LIST}
          className={clsxm('rounded-l-lg rounded-r-none border-grey3 text-xxs capitalize transition-all duration-200', {
            'bg-black/70': view === EView.LIST,
          })}
        >
          List
        </ToggleButton>
        <ToggleButton
          value={EView.TABLE}
          className={clsxm('rounded-r-lg rounded-l-none border-grey3 text-xxs capitalize transition-all duration-200', {
            'bg-black/70': view === EView.TABLE,
          })}
        >
          Table
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
