import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RootLayout } from '@app/modules/RootLayout';
import { QueryParamProvider, ExtendedStringifyOptions, transformSearchStringJsonSafe } from 'use-query-params';
import { DialogManagerProvider } from '@app/context/dialog-manager.context';
import { ThemeWrapper, ResponsiveManager } from '@app/hoc';
import { dialogManager } from '@app/shared/dialogs';
import 'react-toastify/dist/ReactToastify.css';
import { RouteQueryParamsAdapter } from './RouteAdapter';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

function App() {
  return (
    <ThemeWrapper>
      <Router>
        <QueryParamProvider
          ReactRouterRoute={RouteQueryParamsAdapter as unknown as React.FunctionComponent}
          stringifyOptions={queryStringifyOptions}
        >
          <QueryClientProvider client={queryClient}>
            <DialogManagerProvider dialogManager={dialogManager}>
              <ResponsiveManager>
                <RootLayout />
              </ResponsiveManager>
            </DialogManagerProvider>
          </QueryClientProvider>
        </QueryParamProvider>
      </Router>
      <ToastContainer className="w-fit" />
    </ThemeWrapper>
  );
}

export default App;
