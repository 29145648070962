import React, { createContext, useContext } from 'react';

import { OpenableEntityRenderComponent } from '@app/services/openable-entity-manager';

const DialogManagerContext = createContext<DialogManagerType | undefined>(undefined);

export function useDialogManager() {
  const c = useContext(DialogManagerContext);
  if (c === undefined) {
    throw new Error('useDialogManager must be inside a Provider with a DialogManager');
  }
  return c;
}

interface PropTypes {
  dialogManager: DialogManagerType;
}
export const DialogManagerProvider: React.FC<PropTypes> = ({ dialogManager, children }) => {
  return (
    <DialogManagerContext.Provider value={dialogManager}>
      <OpenableEntityRenderComponent manager={dialogManager} />
      {children}
    </DialogManagerContext.Provider>
  );
};
