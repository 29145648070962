import React, { useCallback, useMemo, useState } from 'react';

import { GET_USERS_QUERY } from '@app/constants/query-api-configs';

import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';

import { Table, TableColumn } from '@app/shared/ui-components';
import { useTableColumns } from './useTableColumns';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { useUserInvitationsQueryControls } from '@app/shared/tables/users-table/useUserInvitationsQueryControls';
import { ClientDto } from '@app/models/user';
import { useGetClients } from '@app/domain/user/api/hooks/user.api.hook';
import { PAGE_SIZE } from '@app/constants/api';
import { useActivateUser, useBlockUser } from '@app/domain/user-profile/api/user-profile.api.hook';
import { UserActionsCell } from '@app/shared/tables/users-table/UserActionsCell';
import { BadgeD2NA } from '@app/domain/d2na/BadgeD2NA';

export const ClientsTable: React.FC = () => {
  const [loadingUserId, setLoadingUserId] = useState<string>();
  const navigate = useTypedNavigate();

  const navigateToProfile = useCallback(
    ({ id }: ClientDto) => {
      navigate({ to: Routes.admin.user_management.client_profile, params: { id } });
    },
    [navigate]
  );

  const {
    values: { page, sort = GET_USERS_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange: handleUsersSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      search: '',
    },
    prefix: GET_USERS_QUERY.tablePrefix,
  });

  const {
    handlers: { handleSearchChange: handleInvitedUsersSearchChange },
  } = useUserInvitationsQueryControls();

  const { mutate: blockUser, isLoading: isBlocking } = useBlockUser({
    onMutate: (userId) => setLoadingUserId(userId),
    onSettled: () => setLoadingUserId(undefined),
  });
  const { mutate: activateUser, isLoading: isActivating } = useActivateUser({
    onMutate: (userId) => setLoadingUserId(userId),
    onSettled: () => setLoadingUserId(undefined),
  });

  const { data, isFetching } = useGetClients(
    {
      sort,
      page: Math.ceil((page || 1) - 1),
      size: PAGE_SIZE,
      term: search,
    },
    { keepPreviousData: true }
  );

  const initialColumns = useTableColumns();

  const columns: TableColumn<ClientDto>[] = useMemo(() => {
    return [
      {
        headerName: 'Company',
        field: 'companyName',
        align: 'left',
        render: (user) => (
          <div className="flex items-center justify-between gap-2">
            {user.companyName} {user.isD2NA && <BadgeD2NA />}
          </div>
        ),
      },
      ...initialColumns,
      {
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableSort: true,
        disableClick: true,
        render: (user) => (
          <UserActionsCell
            user={user}
            onBlockUser={blockUser}
            onUnblockUser={activateUser}
            disabledBlock={isBlocking || isActivating}
            disabledUnblock={isBlocking || isActivating}
            isBlocking={isBlocking && loadingUserId === user.id}
            isUnblocking={isActivating && loadingUserId === user.id}
          />
        ),
      },
    ];
  }, [initialColumns, isBlocking, blockUser, loadingUserId, activateUser, isActivating]);

  const handleSearchChange = useCallback(
    (value: string) => {
      handleInvitedUsersSearchChange(value);
      handleUsersSearchChange(value);
    },
    [handleInvitedUsersSearchChange, handleUsersSearchChange]
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <SearchInput
          placeholder="Search by name or email"
          className="w-64"
          value={search}
          onChange={handleSearchChange}
        />
      </div>
    );
  }, [handleSearchChange, search]);

  return (
    <Table
      leftHeaderContent={leftContent}
      cols={columns}
      tableData={data?.result ?? []}
      notFoundMessage="No users"
      currentPage={page}
      loading={isFetching}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
      hidePageSize
      onItemSelect={navigateToProfile}
      cardContentClassName="pt-5"
      noShadow
    />
  );
};
