import { AxiosRequestConfig } from 'axios';
import { ApiCollection } from './api-collection';
import { ApiRequestService } from './api-request';
import { SKIP_TOASTER_PARAMS } from './meta-params';

type RemapKeysWithSuffix<O extends object, Suffix extends string> = {
  [K in keyof O as `${string & K}${Suffix}`]: O[K];
};

const def: Record<'Silent', AxiosRequestConfig> = {
  Silent: {
    params: SKIP_TOASTER_PARAMS,
  },
};

// Use for any suffixes. No use cases for now, so not exported.
const baseApiBuilder = <A extends ApiCollection, Configs extends Record<string, AxiosRequestConfig>>(
  Collection: new (service?: ApiRequestService) => A,
  configs: Configs
): A & RemapKeysWithSuffix<A, string & keyof Configs> => {
  const result: Record<string, any> = new Collection();
  Object.keys(configs).forEach((suffix) => {
    const config = configs[suffix];
    const service = new ApiRequestService(config);
    const configuredApi: Record<string, any> = new Collection(service);
    Object.getOwnPropertyNames(Collection.prototype).forEach((key) => {
      if (key === 'constructor') {
        return;
      }
      const method = configuredApi[key];
      // console.log('conf', key, suffix, method);
      if (typeof method !== 'function') {
        return;
      }
      result[key + suffix] = method.bind(configuredApi);
    });
  });
  return result as any;
};

export const buildApi = <A extends ApiCollection>(Collection: new (service?: ApiRequestService) => A) =>
  baseApiBuilder(Collection, def);
