import { useMaybeCurrentClient } from '@app/domain/user/user.hook';
import { ReactComponent as AppLogoSvg } from './app_logo.svg';
import { ReactComponent as AppLogoWithNameSvg } from './app_logo_with_name.svg';
import { ReactComponent as AppLogoD2NASvg } from './app_logo_d2na.svg';
import { IS_D2NA_DOMAIN } from '@app/domain/d2na/domain.util';

export const AppLogo: React.FC<
  React.ComponentProps<typeof AppLogoSvg> & {
    d2na?: boolean;
    withName?: boolean;
  }
> = ({ width, height, withName = false, d2na = IS_D2NA_DOMAIN, ...rest }) => {
  const client = useMaybeCurrentClient();
  const isD2NA = Boolean(d2na || client?.isD2NA);
  width = width ?? (isD2NA ? 56 : 48);
  height = height ?? (isD2NA ? 56 : 48);

  const Component = isD2NA ? AppLogoD2NASvg : withName ? AppLogoWithNameSvg : AppLogoSvg;
  return <Component width={width} height={height} {...rest} />;
};
