import { AuthResDto, ConfirmPasswordReqDto, ForgotPasswordReqDto, SignUpReqDto, UserDto } from '@app/models/auth';
import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { InvitationSignUpDto, SignInDto, SignInStepTwoInDto, SignUpStepTwoDto } from '@app/swagger-types';
import { CollaboratorOutSchema, TesterDto } from '@app/models/user';

export const AuthApi = buildApi(
  class AuthApi extends ApiCollection {
    async me() {
      return this.apiService.get<UserDto>('/private/auth/me');
    }

    async signIn(dto: SignInDto) {
      return this.apiService.post<AuthResDto | never>('/public/auth/sign-in/', dto);
    }

    async signIn2FA(dto: SignInStepTwoInDto) {
      return this.apiService.post<AuthResDto>('/public/auth/sign-in/2fa', dto);
    }

    async signOut() {
      return this.apiService.post<AuthResDto>('/private/auth/sign-out/');
    }

    async signUpStep1(dto: SignUpReqDto) {
      return this.apiService.post<never, SignUpReqDto>('/public/auth/sign-up/step-1', dto);
    }

    async signUpStep2(dto: SignUpStepTwoDto) {
      return this.apiService.post<AuthResDto>('/public/auth/sign-up/step-2', dto);
    }

    async forgotPassword1(dto: ForgotPasswordReqDto) {
      return this.apiService.post<ForgotPasswordReqDto>('/public/auth/forgot-password/step-1', dto);
    }

    async forgotPassword2(dto: ConfirmPasswordReqDto) {
      return this.apiService.post<ConfirmPasswordReqDto>('/public/auth/forgot-password/step-2', dto);
    }
    async signUpTester(Dto: InvitationSignUpDto) {
      return this.apiService.post<TesterDto>('/public/auth/invitation/tester-sign-up', Dto);
    }
    async signUpCollaborator(Dto: InvitationSignUpDto) {
      return this.apiService.post<CollaboratorOutSchema>('/public/auth/invitation/collaborator-sign-up', Dto);
    }
  }
);
