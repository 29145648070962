import useStore from '@app/store/useStore.hook';
import { EFindingStatus } from '@app/swagger-types';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';

export const useFindingTransition = (isStatusChangedByClient: boolean) => {
  let from: EFindingStatus[] = [];
  let to: EFindingStatus[] = [];
  const currentUser = useStore.useCurrentUser();
  const { isAdmin, isClient, isTester, isCollaborator } = useUserRole();

  if (currentUser) {
    if (isAdmin) {
      from = [
        EFindingStatus.FIXED,
        EFindingStatus.RETEST,
        EFindingStatus.INFORMATIVE,
        EFindingStatus.REQUIRES_ATTENTION,
      ];
      to = [EFindingStatus.FIXED, EFindingStatus.RETEST, EFindingStatus.INFORMATIVE, EFindingStatus.REQUIRES_ATTENTION];
    } else if (isTester) {
      from = [EFindingStatus.FIXED, EFindingStatus.RETEST, EFindingStatus.REQUIRES_ATTENTION];
      to = [EFindingStatus.REQUIRES_ATTENTION];
      if (isStatusChangedByClient) {
        to.push(EFindingStatus.FIXED);
      }
    } else if (isClient || isCollaborator) {
      from = [EFindingStatus.RETEST, EFindingStatus.INFORMATIVE, EFindingStatus.REQUIRES_ATTENTION];
      to = [EFindingStatus.RETEST, EFindingStatus.INFORMATIVE, EFindingStatus.REQUIRES_ATTENTION];
    }
  }

  return {
    to,
    from,
  };
};
