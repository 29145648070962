import { array, InferType, number, object, string } from 'yup';

import { EBonusType, EDailyRateFor, ESeverityLevel } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { convertCentToUnit } from '@app/utils/currency.utils';

export const DailyRateConfigSchema = object({
  id: string().required(),
  dailyRateFor: oneOfEnum(EDailyRateFor).required(),
  priceCoin: number().transform(convertCentToUnit).required(),
});

export type DailyRateConfigSchema = InferType<typeof DailyRateConfigSchema>;

export const DailyRateConfigDto = object({
  configs: array().of(DailyRateConfigSchema).required(),
});

export type DailyRateConfigDto = InferType<typeof DailyRateConfigDto>;

export const FindingConfigSchema = object({
  id: string().required(),
  severityLevel: oneOfEnum(ESeverityLevel).required(),
  priceCoin: number().transform(convertCentToUnit).required(),
});

export type FindingConfigSchema = InferType<typeof FindingConfigSchema>;

export const FindingConfigDto = object({
  configs: array().of(FindingConfigSchema).required(),
});

export type FindingConfigDto = InferType<typeof FindingConfigDto>;

export const FindingBonusSchema = object({
  id: string().required(),
  bonusType: oneOfEnum(EBonusType).required(),
  priceCoin: number().transform(convertCentToUnit).required(),
});

export type FindingBonusSchema = InferType<typeof FindingBonusSchema>;

export const FindingBonusDto = object({
  bonuses: array().of(FindingBonusSchema).required(),
});

export type FindingBonusDto = InferType<typeof FindingBonusDto>;
