import React from 'react';
import { Typography, Grid } from '@mui/material';

import { CreateProjectStep } from '@app/modules/admin/create-project/CreateProjectStep';
import { Button } from '@app/ui-components';
import { ChevronRightRounded } from '@mui/icons-material';
import { ProjectApi } from '@app/domain/project/project.api';
import { ProjectInDto } from '@app/swagger-types';
import { ProductOutSchema } from '@app/domain/product/product.schema';
import { useMutation } from 'react-query';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';

export const SubmitProject: React.FC<{
  currentProduct: ProductOutSchema;
  onBack: () => void;
  activeStep: number;
  onReview: () => void;
  onReset: () => void;
  onSubmit: () => void;
  onSetProjectId: (projectId: string | undefined) => void;
  projectPayload?: ProjectInDto;
}> = ({ currentProduct, activeStep, onBack, onReview, projectPayload, onReset, onSubmit, onSetProjectId }) => {
  const {
    error,
    isLoading,
    mutate: action,
  } = useMutation<ProjectOutDtoSchema | undefined, Error>(
    async () => {
      if (projectPayload) {
        return await ProjectApi.create(projectPayload);
      }
    },
    {
      onSuccess: (project) => {
        onReset();
        onSubmit();
        onSetProjectId(project?.id);
      },
    }
  );

  return (
    <CreateProjectStep
      activeStep={activeStep}
      title="Submit the project for evaluation"
      subTitle="By clicking on submit, you agree to our terms and conditions and authorise us to conduct a penetration test against your environment."
      onCancel={onBack}
      onConfirm={action}
      disabledCancel={isLoading}
      confirmLoading={isLoading}
      error={error}
      cancelText="Back"
      confirmText="Submit"
      render={({ header, actionButtons }) => (
        <>
          {header}
          <Grid container justifyContent="space-around" className="mb-16 min-w-[470px]">
            <Grid
              item
              xs={5}
              className="rounded-md  border-2 border-solid border-grey4 bg-[#3E414F]/30 p-8"
              justifyContent="center"
            >
              <Typography align="center" className="text-m text-grey5">
                Penetration test
              </Typography>
              <Typography align="center" className="mt-9 text-xl">
                {currentProduct.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              justifyContent="center"
              className="rounded-md border-2 border-solid border-grey4 bg-[#3E414F]/30 p-8 text-center"
            >
              <Typography align="center" className="text-center text-m  text-grey5">
                Questionnaire
              </Typography>
              <Button
                onClick={onReview}
                variant="outlined"
                endIcon={<ChevronRightRounded />}
                color="secondary"
                size="large"
                className="mt-6 rounded-3xl !border-0 bg-grey3 underline hover:bg-grey3/80"
              >
                Review
              </Button>
            </Grid>
          </Grid>
          {actionButtons}
        </>
      )}
    />
  );
};
