import React from 'react';
import { Typography } from '@mui/material';

import { Button } from '@app/ui-components';
import { TypedLink } from '@app/router';
import { CreateProjectStep } from '@app/modules/admin/create-project/CreateProjectStep';
import { Routes } from '@app/constants/routes';

export const ProjectSubmittedStepProject: React.FC<{
  activeStep: number;
  projectId?: string;
}> = ({ activeStep, projectId }) => {
  return (
    <CreateProjectStep
      activeStep={activeStep}
      isFinalStep
      render={({ header }) => (
        <>
          {header}
          <div className="flex min-h-[50vh] flex-col items-center justify-center">
            <Typography className="mb-14 text-xxxl font-bold">Project submitted!</Typography>
            {projectId && (
              <TypedLink to={Routes.projects.project} params={{ id: projectId }}>
                <Button className="w-[260px]">Go to project page</Button>
              </TypedLink>
            )}
          </div>
        </>
      )}
    />
  );
};
