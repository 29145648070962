import { BonusOutDto, ESeverityLevel } from '@app/swagger-types';

export const createUiKitBonus = (override?: Partial<BonusOutDto>): BonusOutDto => ({
  severityLevel: ESeverityLevel.CRITICAL,
  quantity: 2,
  amountCoin: 200,
  ...override,
});

export const uiKitBonuses: BonusOutDto[] = [
  createUiKitBonus({
    severityLevel: ESeverityLevel.CRITICAL,
    amountCoin: 200,
  }),
  createUiKitBonus({
    severityLevel: ESeverityLevel.HIGH,
    amountCoin: 100,
  }),
  createUiKitBonus({
    severityLevel: ESeverityLevel.MEDIUM,
    amountCoin: 50,
  }),
  createUiKitBonus({
    severityLevel: ESeverityLevel.LOW,
    amountCoin: 20,
  }),
];
