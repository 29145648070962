import React from 'react';

import { LogsTable } from '@app/domain/action-log/LogsTable';
import { ERole } from '@app/swagger-types';

export const TestersLoggingPage: React.FC = () => {
  return (
    <div>
      <LogsTable title="Testers" role={ERole.ROLE_TESTER} />
    </div>
  );
};
