export const MIN_PASSWORD_LENGTH = 6;

export const PASSWORD_VALIDATOR_REGEX = new RegExp(`^[A-Za-z\\d[\\]{};:=<>_+^#$@!%*?&]{${MIN_PASSWORD_LENGTH},}`);

export const URL_VALIDATOR_REGEX = new RegExp(/^(?!(javascript:|data:))+/);

export const ALL_WHITESPACES = /^(?!\s+$).*/;

export const LETTERS_SPACE_HYPHEN = /^[a-zA-Z-\s]*$/;

export const LATIN_ONLY = /^[A-z0-9\u00C0-\u00ff\s'\.,-\/#!$%\^&\*\[\]\?><@+;:{}=\-_`~()]+$/;
