import React, { useState } from 'react';
import styles from '../pdf-salus.module.scss';
import clsxm from '@app/lib/clsxm';
import { IPdfRenderResult, PdfPager } from '../PdfPager';
import { CLASSNAME_FOR_PDF_READY } from '../constants';
import { TesterTacBody } from './TesterTacBody';
import { TacFixedHeader } from './tac-shared';

// TODO fetch and render signed details
export const TesterTermsAndConditionsPage: React.FC = () => {
  const [result, setResult] = useState<IPdfRenderResult | null>(null);

  return (
    <div className={clsxm(styles.mainWrap, result && CLASSNAME_FOR_PDF_READY)}>
      <TacFixedHeader />

      <MemoPdf {...{ setResult }} />
    </div>
  );
};

// memoization is critical to avoid infinite loop rerendering
const MemoPdf = React.memo<{
  setResult: React.Dispatch<React.SetStateAction<IPdfRenderResult | null>>;
}>(({ setResult }) => {
  return (
    <PdfPager indexShift={0} onResult={setResult} headerSelector={`.${styles.pageHeader}`} isD2NA={false}>
      <TesterTacBody />
    </PdfPager>
  );
});
