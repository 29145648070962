import React, { useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import styles from '@app/shared/ui-components/layout/header/user-header-info/UserHeaderInfo.module.scss';
import { Status } from '@app/shared/ui-components/status/Status';
import { KeyboardArrowDown } from '@mui/icons-material';
import { getFriendlyInvoiceStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { EInvoiceStatus } from '@app/swagger-types';
import { OverrideInvoiceStatusInDto } from '@app/swagger-override-types';

interface Props {
  id: string;
  onChangeStatus: (payload: { id: string; Dto: OverrideInvoiceStatusInDto }) => void;
  activeStatus: EInvoiceStatus;
  wrapperClassName?: string;
}

export const InvoiceStatusDropdown: React.FC<Props> = ({ id, onChangeStatus, activeStatus, wrapperClassName }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options =
    activeStatus === EInvoiceStatus.CANCELED
      ? Object.keys(EInvoiceStatus)
      : [EInvoiceStatus.PENDING, EInvoiceStatus.PAID];

  return (
    <div className={wrapperClassName}>
      <Menu
        elevation={0}
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.Paper }}
      >
        {options.map((optionStatus, index) => {
          const isActive = activeStatus === optionStatus;
          return (
            <MenuItem
              key={`${index}_${id}`}
              className={styles.MenuItem}
              value={optionStatus}
              onClick={() => {
                if (!isActive) {
                  onChangeStatus({ id, Dto: { status: optionStatus as EInvoiceStatus } });
                }
                handleClose();
              }}
            >
              <Status {...getFriendlyInvoiceStatus(optionStatus as EInvoiceStatus)} />
            </MenuItem>
          );
        })}
      </Menu>
      <Typography onClick={handleClick} className="flex cursor-pointer items-center">
        <Status {...getFriendlyInvoiceStatus(activeStatus)} />
        <KeyboardArrowDown className="ml-2 h-auto w-3" />
      </Typography>
    </div>
  );
};
