import { Outlet } from 'react-router-dom';

import AuthBackgroundImage from '@app/assets/images/auth-background.jpg';
import { AppLogo } from '@app/assets/app-logo/AppLogo';
import { IS_D2NA_DOMAIN } from '@app/domain/d2na/domain.util';

export const AuthModule = () => {
  return (
    <div
      className={`flex min-h-screen w-full max-w-full justify-center overflow-hidden bg-cover bg-scroll bg-top bg-no-repeat`}
      style={{
        backgroundImage: `url(${AuthBackgroundImage})`,
      }}
    >
      <div className="flex w-[350px] items-center justify-center">
        <div className="w-full py-20">
          <AppLogo
            className="m-auto mb-16"
            width={IS_D2NA_DOMAIN ? 350 : 128}
            height={128}
            withName
            d2na={IS_D2NA_DOMAIN}
          />
          <Outlet />
        </div>
      </div>
    </div>
  );
};
