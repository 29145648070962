import React from 'react';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';
import { Typography } from '@mui/material';
import { useQuery } from 'react-query';
import {
  GET_SETTINGS_FINDING_BONUSES_QUERY,
  GET_SETTINGS_FINDING_CONFIGS_QUERY,
} from '@app/constants/query-api-configs';
import { SettingsApi } from '@app/domain/settings/settings.api';
import { formatDollars } from '@app/utils/currency.utils';
import { getSeverityInfo } from '@app/domain/settings/settings.utils';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { FindingBonusDto, FindingConfigDto } from '@app/domain/settings/settings.form';

export const SettingFindings: React.FC<{
  showDescription?: boolean;
}> = ({ showDescription = true }) => {
  const {
    data: configsData,
    isFetching: isFetchingConfigs,
    error: configsError,
  } = useQuery<FindingConfigDto, Error>(
    GET_SETTINGS_FINDING_CONFIGS_QUERY.name,
    async () => {
      return await SettingsApi.getFindingConfigs();
    },
    { keepPreviousData: true, staleTime: GET_SETTINGS_FINDING_CONFIGS_QUERY.config.staleTime }
  );

  const {
    data: bonusesData,
    isFetching: isFetchingBonuses,
    error: bonusesError,
  } = useQuery<FindingBonusDto, Error>(
    GET_SETTINGS_FINDING_BONUSES_QUERY.name,
    async () => {
      return await SettingsApi.getFindingBonuses();
    },
    { keepPreviousData: true, staleTime: GET_SETTINGS_FINDING_BONUSES_QUERY.config.staleTime }
  );

  if (
    !configsData ||
    !bonusesData ||
    !Boolean(configsData?.configs.length) ||
    configsError ||
    isFetchingConfigs ||
    isFetchingBonuses ||
    bonusesError
  ) {
    return (
      <ContainerLoader
        loading={isFetchingConfigs || isFetchingBonuses}
        noData={!configsData || !bonusesData || !Boolean(configsData?.configs.length)}
        error={configsError || bonusesError}
      />
    );
  }

  const retestBonus = bonusesData.bonuses[0];

  return (
    <div
      className={clsxm('max-w-[538px] rounded-[10px] bg-transparentBackground py-10 px-9', sharedStyle.shadowBorder)}
    >
      <Typography className="mb-4 text-xl font-bold">Findings Prices</Typography>
      {showDescription && (
        <Typography className="mb-9 max-w-[350px] text-xs">
          You can pay testers extra for each finding they find. The price of the finding depends on the Severity level.
          Prices of findings of different levels you determine by yourself
        </Typography>
      )}
      <div className="mb-5 flex items-center gap-9">
        <Typography className="text-xs font-bold text-grey6">Severity level</Typography>
        <Typography className="text-xs font-bold text-grey6">Price for 1 report</Typography>
      </div>
      {configsData?.configs.map(({ id, priceCoin, severityLevel }) => {
        const { backgroundColor, borderColor, label } = getSeverityInfo(severityLevel);
        return (
          <div key={id} className="mb-3.5 flex items-center gap-9">
            <div>
              <Typography
                className="flex w-[84px] items-center justify-center rounded-[4px] border py-2.5 text-xxs"
                style={{ backgroundColor, borderColor }}
              >
                {label}
              </Typography>
            </div>
            <div>
              <Typography className="text-m font-medium">{formatDollars(priceCoin)}</Typography>
            </div>
          </div>
        );
      })}
      {retestBonus && (
        <div className="mb-3.5 flex items-center gap-9">
          <div>
            <Typography
              className="flex w-[84px] items-center justify-center rounded-[4px] border py-2.5 text-xxs"
              style={{ backgroundColor: '#3e4f72', borderColor: '#6E9FFF' }}
            >
              Re-test Bonus
            </Typography>
          </div>
          <div>
            <Typography className="text-m font-medium">{formatDollars(retestBonus.priceCoin)}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};
