import React, { useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import styles from '@app/shared/ui-components/layout/header/user-header-info/UserHeaderInfo.module.scss';
import { Status } from '@app/shared/ui-components/status/Status';
import { renderFriendlyFindingStatus } from '@app/domain/finding/findings.utils';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useFindingTransition } from '@app/domain/finding/finding.hook';
import { EFindingStatus } from '@app/swagger-types';
import { UseMutateFunction } from 'react-query/types/react/types';

interface Props {
  id: string;
  projectId: string;
  isStatusChangedByClient: boolean;
  onChangeStatus: UseMutateFunction<unknown, unknown, { findingId: string; projectId: string; status: EFindingStatus }>;
  activeStatus: EFindingStatus;
}

export const StatusCell: React.FC<Props> = ({
  id,
  onChangeStatus,
  activeStatus,
  projectId,
  isStatusChangedByClient,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { to, from } = useFindingTransition(isStatusChangedByClient);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        elevation={0}
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.Paper }}
      >
        {Object.keys(EFindingStatus).map((optionStatus, index) => {
          const isActive = activeStatus === optionStatus;
          return (
            <MenuItem
              key={`${index}_${id}`}
              className={styles.MenuItem}
              value={optionStatus}
              disabled={!isActive && (!to.includes(optionStatus as EFindingStatus) || !from.includes(activeStatus))}
              onClick={() => {
                if (!isActive) {
                  onChangeStatus({
                    findingId: id,
                    projectId,
                    status: optionStatus as EFindingStatus,
                  });
                }
                handleClose();
              }}
            >
              <Status {...renderFriendlyFindingStatus(optionStatus as EFindingStatus)} />
            </MenuItem>
          );
        })}
      </Menu>
      <Typography onClick={handleClick} className="flex cursor-pointer items-center">
        <Status {...renderFriendlyFindingStatus(activeStatus)} />
        <KeyboardArrowDown className="ml-2 w-3" />
      </Typography>
    </>
  );
};
