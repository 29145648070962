import useStore from '@app/store/useStore.hook';
import { isAdmin, isClient, isCollaborator, isTester } from '@app/utils/auth.utils';

export const useUserRole = () => {
  const currentUser = useStore.useCurrentUser();

  if (!currentUser) {
    return {
      isAdmin: false,
      isTester: false,
      isClient: false,
      isCollaborator: false,
    };
  }

  return {
    isAdmin: isAdmin(currentUser),
    isTester: isTester(currentUser),
    isClient: isClient(currentUser),
    isCollaborator: isCollaborator(currentUser),
  };
};
