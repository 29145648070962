import React, { useMemo } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import TimeAgo from 'react-timeago';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { capitalize } from 'lodash';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { EProjectStatus } from '@app/swagger-types';
import { StyledStepper } from '@app/shared/ui-components/stepper/StyledStepper';
import { IconButton, Tooltip } from '@app/ui-components';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';
import { DeleteIcon } from '@app/assets/icons';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { FindingStatsCell } from '@app/shared/tables/projects-table/FindingStatsCell';
import { ProjectStatusButtons } from '@app/domain/project/components/ProjectStatusButtons';
import { AssignTestersCell } from '@app/shared/tables/projects-table/AssignTestersCell';
import { RejectedProjectBadge } from '@app/domain/project/components/RejectedProjectBadge';
import clsxm from '@app/lib/clsxm';
import { BadgeD2NA } from '@app/domain/d2na/BadgeD2NA';

interface Props {
  project: OverrideProjectOutDto;
  onDelete?: (projectId: string) => void;
  onApprove?: () => void;
  onReject?: () => void;
  onAssignTesters?: () => void;
  isDeleting?: boolean;
  isApproving?: boolean;
  isRejecting?: boolean;
  disabledApprove?: boolean;
  disabledReject?: boolean;
}

export const ProjectItem: React.FC<Props> = ({
  project,
  onDelete,
  isDeleting,
  isApproving,
  isRejecting,
  onApprove,
  onReject,
  onAssignTesters,
  disabledApprove,
  disabledReject,
}) => {
  const {
    name,
    productTitle,
    startDate,
    endDate,
    createdDate,
    priceForTester,
    priceForClient,
    id,
    status,
    findingStats,
    testers,
    isD2NA,
    usdToGbpRate,
  } = project;
  const { isClient, isTester, isAdmin } = useUserRole();

  const steps = useMemo(
    () =>
      [EProjectStatus.PENDING, EProjectStatus.SCHEDULED, EProjectStatus.LIVE, EProjectStatus.COMPLETED].map(capitalize),
    []
  );
  const activeStep = useMemo(() => steps.indexOf(capitalize(status)), [steps, status]);

  return (
    <TypedLink
      to={Routes.projects.project}
      params={{ id }}
      className={clsxm(
        'mb-6 flex w-full items-center justify-between rounded-md border border-solid border-grey4 bg-[#00000024] px-7 py-6 transition-colors',
        'hover:bg-[#6a6a6a24]'
      )}
    >
      <div>
        <div className="mb-3 flex items-center">
          <Typography className="text-xxxl font-bold">{name}</Typography>
          <Typography className="ml-2.5 text-xs text-grey5">{createdDate && <TimeAgo date={createdDate} />}</Typography>
          <div className="ml-5">
            <FindingStatsCell findingStats={findingStats} showEmpty={false} />
          </div>
        </div>
        <div className="flex items-center">
          <Typography className="text-l">{productTitle}</Typography>
          {(isAdmin || isTester) && priceForTester ? (
            <div className="flex items-center">
              <span className="mx-2.5 rounded-full bg-grey4" style={{ height: 4, width: 4 }} />
              <Tooltip title="Price for tester">
                <Typography className="text-l">{formatDollars(priceForTester)}</Typography>
              </Tooltip>
            </div>
          ) : null}
          {(isAdmin || isClient) && priceForClient ? (
            <div className="flex items-center">
              <span className="mx-2.5 rounded-full bg-grey4" style={{ height: 4, width: 4 }} />
              <Tooltip title="Price for client">
                <Typography className="text-l">
                  {isD2NA ? createGBPformatter(usdToGbpRate).formatGBP(priceForClient) : formatDollars(priceForClient)}
                </Typography>
              </Tooltip>
            </div>
          ) : null}
          {startDate && endDate && (
            <Typography className="flex items-center text-l">
              <span className="mx-2.5 rounded-full bg-grey4" style={{ height: 4, width: 4 }} />
              {`${format(new Date(startDate), DATE_FORMAT)} - ${format(new Date(endDate), DATE_FORMAT)}`}
            </Typography>
          )}
          {isAdmin && project.isD2NA && (
            <div className="inline-flex items-center">
              <span className="mx-2.5 rounded-full bg-grey4" style={{ height: 4, width: 4 }} />
              <BadgeD2NA />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-3">
        {status === EProjectStatus.REJECTED ? (
          <RejectedProjectBadge />
        ) : (
          <div className="min-w-[250px]">
            <StyledStepper steps={steps} activeStep={activeStep} />
          </div>
        )}
        {onApprove && onReject && (
          <ProjectStatusButtons
            project={project}
            onApprove={onApprove}
            onReject={onReject}
            loadingApprove={isApproving}
            loadingReject={isRejecting}
            disabledApprove={disabledApprove}
            disabledReject={disabledReject}
            showRejectedBadge={false}
            wrapperClassName="w-[150px] text-right"
          />
        )}
        {onAssignTesters && (
          <div className="w-[90px]">
            <AssignTestersCell testers={testers} onAssignTesters={onAssignTesters} />
          </div>
        )}
        {onDelete && (
          <div className="my-auto w-[110px] text-right">
            {isDeleting ? (
              <CircularProgress size={27} />
            ) : (
              <ConfirmDeleteDialog
                trigger={
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                }
                onConfirm={() => onDelete(id)}
                headerTitle={`Delete ${name}`}
                title={`Are you sure you want to delete ${name}?`}
              />
            )}
          </div>
        )}
      </div>
    </TypedLink>
  );
};
