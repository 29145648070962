import React, { useMemo } from 'react';
import { Box, Step, StepLabel, Stepper, StepConnector } from '@mui/material';
import { ProjectStepIcon } from '@app/modules/admin/create-project/stepper/ProjectStepIcon';

import styles from './ProjectStepper.module.scss';
import { FinalProjectStepIcon } from '@app/assets/icons';

interface Props {
  activeStep: number;
  className?: string;
  isFinalStep?: boolean;
}

export const ProjectStepper: React.FC<Props> = ({ activeStep, className, isFinalStep }) => {
  const FinalStepIcon = () => (
    <div className="flex h-6 items-center">
      <FinalProjectStepIcon />
    </div>
  );

  const StepIconComponent = useMemo(() => (isFinalStep ? FinalStepIcon : ProjectStepIcon), [isFinalStep]);

  return (
    <Box className={className}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={
          <StepConnector
            classes={{
              root: isFinalStep ? styles.finalStep : undefined,
              active: styles.active,
              completed: styles.completed,
              line: styles.line,
            }}
          />
        }
      >
        <Step active={activeStep === 1} completed>
          <StepLabel StepIconComponent={StepIconComponent} />
        </Step>
        <Step active={activeStep === 2} completed={activeStep > 1}>
          <StepLabel StepIconComponent={StepIconComponent} />
        </Step>
      </Stepper>
    </Box>
  );
};
