import React, { useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType, object, string } from 'yup';
import { useMutation, useQueryClient } from 'react-query';

import { Dialog } from '@app/hoc';
import { ProjectApi } from '@app/domain/project/project.api';
import { Button, Form, Input } from '@app/ui-components';
import { ProjectInDto } from '@app/swagger-types';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { GET_PROJECT_QUERY } from '@app/constants/query-api-configs';

interface Props {
  open: boolean;
  onClose: () => void;
  id: string;
  project?: OverrideProjectOutDto;
}

export const EditValidationSchema = object({
  name: string().required('Required Field'),
});
export type EditValidationSchema = InferType<typeof EditValidationSchema>;

export const EditProjectDialog: React.FC<Props> = ({ open, onClose, id, project }) => {
  const { control, formState, handleSubmit, register, clearErrors, reset } = useForm<EditValidationSchema>({
    resolver: yupResolver(EditValidationSchema),
    defaultValues: project && {
      name: project.name,
    },
  });

  const queryClient = useQueryClient();

  const {
    mutate: editProject,
    isLoading,
    error,
    reset: resetMutation,
  } = useMutation<unknown, Error, Partial<ProjectInDto>>(
    async (Dto) => {
      return await ProjectApi.editProjectSilent(id, Dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PROJECT_QUERY.name);
      },
    }
  );

  const handleClose = useCallback(() => {
    clearErrors();
    onClose();
    resetMutation();
  }, [onClose, clearErrors, resetMutation]);

  const handleCloseWithReset = useCallback(() => {
    handleClose();
    reset();
  }, [reset, handleClose]);

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: isLoading,
      };
    };
    return fn;
  }, [register, formState, isLoading]);

  const onSubmit: SubmitHandler<EditValidationSchema> = (formData) => {
    editProject(formData, {
      onSuccess: (_, formData) => {
        handleClose();
        reset(formData);
      },
    });
  };

  return (
    <Dialog
      open={open}
      paperClassName="w-[440px] p-7 max-w-none"
      onClose={handleCloseWithReset}
      headerTitle="Edit Project info"
    >
      <Form control={control} onSubmit={handleSubmit(onSubmit)} className="px-10">
        <Input placeholder="Project name" label="Project name" {...inputRegister('name')} />
        <Typography className="mt-8 text-center text-xs text-grey5">
          You cannot change the type of project. Because project type is tightly related with its questionnaire.
        </Typography>
        <div className="mb-3 mt-12 flex justify-evenly">
          <Button
            onClick={handleCloseWithReset}
            variant="outlined"
            color="secondary"
            size="large"
            type="submit"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button variant="outlined" color="primary" size="large" type="submit" loading={isLoading}>
            Save
          </Button>
        </div>
        {maybeRenderError(error)}
      </Form>
    </Dialog>
  );
};
