import React, { useState } from 'react';
import styles from '../pdf-salus.module.scss';
import clsxm from '@app/lib/clsxm';
import { IPdfRenderResult, PdfPager } from '../PdfPager';
import { CLASSNAME_FOR_PDF_READY } from '../constants';
import { TacFixedHeader } from './tac-shared';
import { ClientTacBody } from '@app/modules/pdf/pages/tac/ClientTacBody';
import { useMaybeCurrentClient } from '@app/domain/user/user.hook';
import { IS_D2NA_DOMAIN } from '@app/domain/d2na/domain.util';

export const ClientTermsAndConditionsPage: React.FC = () => {
  const [result, setResult] = useState<IPdfRenderResult | null>(null);

  return (
    <div className={clsxm(styles.mainWrap, result && CLASSNAME_FOR_PDF_READY)}>
      <TacFixedHeader />

      <MemoPdf {...{ setResult }} />
    </div>
  );
};

// memoization is critical to avoid infinite loop rerendering
const MemoPdf = React.memo<{
  setResult: React.Dispatch<React.SetStateAction<IPdfRenderResult | null>>;
}>(({ setResult }) => {
  const client = useMaybeCurrentClient();

  return (
    <PdfPager
      indexShift={0}
      onResult={setResult}
      headerSelector={`.${styles.pageHeader}`}
      isD2NA={client?.isD2NA || IS_D2NA_DOMAIN}
    >
      <ClientTacBody isD2NA={client?.isD2NA || IS_D2NA_DOMAIN} />
    </PdfPager>
  );
});
