import React from 'react';
import { TableCard } from '@app/shared/ui-components/table-card';
import { Typography } from '@mui/material';
import { AddFilesIcon } from '@app/assets/icons';
import { Button } from '@app/ui-components';
import AddIcon from '@mui/icons-material/Add';

export const NoProjectsBlock: React.FC<{
  onCreateProject: () => void;
}> = ({ onCreateProject }) => {
  return (
    <>
      <TableCard hasMore cardContentClassName="h-full">
        <div className="flex flex-col items-center justify-center p-[60px]">
          <Typography className="text-xxxl font-bold">Protect your product</Typography>
          <Typography className="mb-14 mt-3 max-w-xs text-center text-xs text-subtitle">
            Create a project and track the progress of your penetration test engagement
          </Typography>
          <AddFilesIcon />
          <Button
            variant="outlined"
            color="primary"
            className="mt-14"
            startIcon={<AddIcon />}
            onClick={onCreateProject}
          >
            Create a project
          </Button>
        </div>
      </TableCard>
    </>
  );
};
