import { EMethodologyStatus, MethodologyDto } from '@app/swagger-types';

const predefinedReport = { id: '1', createdDate: new Date().toJSON() };

export const uiKitMockMethodologies: MethodologyDto[] = [
  {
    sectionTitle: 'Information Gathering',
    checklist: [
      {
        name: 'Manually explore the API',
        status: EMethodologyStatus.PASS,
        id: '1',
        predefinedReportId: '1',
        predefinedReport,
      },
    ],
  },
  {
    sectionTitle: 'Authentication and Authorization',
    checklist: [
      {
        name: 'Sign Up',
        status: EMethodologyStatus.PASS,
        id: '2',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Sign In',
        status: EMethodologyStatus.TODO,
        id: '3',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Sign Out',
        status: EMethodologyStatus.N_A,
        id: '4',
        predefinedReportId: '1',
        predefinedReport,
      },
    ],
  },
  {
    sectionTitle: 'Profile',
    checklist: [
      {
        name: 'See Profile',
        status: EMethodologyStatus.TODO,
        id: '5',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Edit Profile',
        status: EMethodologyStatus.FAIL,
        id: '6',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Delete Profile',
        status: EMethodologyStatus.TODO,
        id: '7',
        predefinedReportId: '1',
        predefinedReport,
      },
    ],
  },
  {
    sectionTitle: 'Demo Profile',
    checklist: [
      {
        name: 'See Profile',
        status: EMethodologyStatus.TODO,
        id: '8',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Edit Profile',
        status: EMethodologyStatus.FAIL,
        id: '9',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Delete Profile',
        status: EMethodologyStatus.TODO,
        id: '10',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Edit Profile',
        status: EMethodologyStatus.FAIL,
        id: '11',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Delete Profile',
        status: EMethodologyStatus.PASS,
        id: '12',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Edit Profile',
        status: EMethodologyStatus.FAIL,
        id: '13',
        predefinedReportId: '1',
        predefinedReport,
      },
      {
        name: 'Delete Profile',
        status: EMethodologyStatus.FAIL,
        id: '14',
        predefinedReportId: '1',
        predefinedReport,
      },
    ],
  },
];
