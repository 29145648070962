import { InferType, number, object } from 'yup';
import { convertCentToUnit } from '@app/utils/currency.utils';

export const FinanceStatsSchema = object({
  extraPay: number().transform(convertCentToUnit).optional().default(0),
  priceForTester: number().transform(convertCentToUnit).optional().default(0),
  retestBonus: number().transform(convertCentToUnit).optional().default(0),
  total: number().transform(convertCentToUnit).optional().default(0),
});

export type FinanceStatsSchema = InferType<typeof FinanceStatsSchema>;

export const FindingStatsSchema = object({
  requiresAttention: number().optional().default(0),
  retest: number().optional().default(0),
  fixed: number().optional().default(0),
  informative: number().optional().default(0),
  total: number().optional().default(0),
});

export type FindingStatsSchema = InferType<typeof FindingStatsSchema>;
