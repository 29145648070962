import React from 'react';
import { SettingsApi } from '@app/domain/settings/settings.api';
import { useQuery } from 'react-query';
import { GET_SETTINGS_WORKING_DAYS_QUERY } from '@app/constants/query-api-configs';
import { Typography } from '@mui/material';
import { formatDollars } from '@app/utils/currency.utils';
import { renderFriendlyDailyRateFor } from '@app/domain/settings/settings.utils';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { DailyRateConfigDto } from '@app/domain/settings/settings.form';

export const SettingWorkingDays: React.FC = () => {
  const { data, isFetching, error } = useQuery<DailyRateConfigDto, Error>(
    GET_SETTINGS_WORKING_DAYS_QUERY.name,
    async () => {
      return await SettingsApi.getDailyRates();
    },
    { keepPreviousData: true, staleTime: GET_SETTINGS_WORKING_DAYS_QUERY.config.staleTime }
  );

  if (!data || !Boolean(data?.configs.length) || error || isFetching) {
    return <ContainerLoader loading={isFetching} noData={!data || !Boolean(data?.configs.length)} error={error} />;
  }

  return (
    <div className="flex">
      {data?.configs.map(({ dailyRateFor, priceCoin, id }) => (
        <div
          key={id}
          className="mr-9 rounded-[10px] border-[3px] border-[#7E848C] bg-[rgba(255,255,255,0.04)] p-6 backdrop-blur-lg"
        >
          <Typography className="mb-1 text-xxl font-bold">{formatDollars(priceCoin)}</Typography>
          <Typography className="text-xs text-grey6">
            1 working day for <strong>{renderFriendlyDailyRateFor(dailyRateFor)}</strong>
          </Typography>
        </div>
      ))}
    </div>
  );
};
