import React from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { StyledStepIcon } from '@app/shared/ui-components/stepper/StyledStepIcon';

interface Props {
  activeStep: number;
  steps: string[];
}

export const StyledStepper: React.FC<Props> = ({ activeStep, steps }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StyledStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
