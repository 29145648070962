export interface DecodedCvssValues {
  cvssAttackVector?: string;
  cvssAttackComplexity?: string;
  privilegesRequired?: string;
  cvssUserInteraction?: string;
  cvssScope?: string;
  cvssConfidentiality?: string;
  cvssIntegrity?: string;
  cvssAvailability?: string;
}

const getCvssValue = (value: string, key: keyof DecodedCvssValues) => {
  if (key !== 'cvssAttackVector') {
    switch (value) {
      case 'L':
        return 'LOW';
      case 'H':
        return 'HIGH';
      case 'N':
        return 'NONE';
      case 'R':
        return 'REQUIRED';
      case 'U':
        return 'UNCHANGED';
      case 'C':
        return 'CHANGED';
    }
  } else {
    switch (value) {
      case 'N':
        return 'NETWORK';
      case 'A':
        return 'ADJACENT';
      case 'L':
        return 'LOCAL';
      case 'P':
        return 'PHYSICAL';
    }
  }
  return undefined;
};

export const getDecodedCvssValues = (value: string) => {
  const valuesObj: Record<string, string> = {};
  value.split('/').forEach((prop) => {
    const arr = prop.split(':');
    valuesObj[arr[0]] = arr[1];
  });
  const DecodedValues: Partial<DecodedCvssValues> = {};

  Object.keys(valuesObj).forEach((key) => {
    switch (key) {
      case 'AV':
        DecodedValues.cvssAttackVector = getCvssValue(valuesObj[key], 'cvssAttackVector');
        break;
      case 'AC':
        DecodedValues.cvssAttackComplexity = getCvssValue(valuesObj[key], 'cvssAttackComplexity');
        break;
      case 'PR':
        DecodedValues.privilegesRequired = getCvssValue(valuesObj[key], 'privilegesRequired');
        break;
      case 'UI':
        DecodedValues.cvssUserInteraction = getCvssValue(valuesObj[key], 'cvssUserInteraction');
        break;
      case 'S':
        DecodedValues.cvssScope = getCvssValue(valuesObj[key], 'cvssScope');
        break;
      case 'C':
        DecodedValues.cvssConfidentiality = getCvssValue(valuesObj[key], 'cvssConfidentiality');
        break;
      case 'I':
        DecodedValues.cvssIntegrity = getCvssValue(valuesObj[key], 'cvssIntegrity');
        break;
      case 'A':
        DecodedValues.cvssAvailability = getCvssValue(valuesObj[key], 'cvssAvailability');
        break;
    }
  });

  return DecodedValues;
};

export const renderFriendlySeverityVector = (vector: string) => vector.replace('CVSS:3.1/', '');
