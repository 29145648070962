import { EInvoiceStatus, InvoiceProjectDto } from '@app/swagger-types';

export const createUiKitInvoice = (overrides?: Partial<InvoiceProjectDto>): InvoiceProjectDto => ({
  id: '1',
  createdDate: new Date().toJSON(),
  amountCoin: 100,
  status: EInvoiceStatus.PAID,
  isD2NA: false,
  ...overrides,
});
