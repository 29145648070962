import React, { FocusEvent, useState } from 'react';
import { FormControlLabel } from '@mui/material';
import { Input, InputProps } from '@app/ui-components';
import { StyledRadio } from '@app/shared/ui-components/radio';

interface Props extends Omit<InputProps, 'ref'> {
  inputClassName?: string;
  groupValue?: string;
  onSetValue: (e: FocusEvent) => void;
}

export const RadioInput = React.forwardRef<HTMLLabelElement, Props>(
  ({ className, inputClassName, groupValue, onSetValue, ...props }, ref) => {
    const [value, setValue] = useState('');

    return (
      <FormControlLabel
        ref={ref}
        className={className}
        value={value}
        control={<StyledRadio checked={groupValue === value} />}
        label={
          <Input
            {...props}
            className={inputClassName}
            onFocus={onSetValue}
            onBlur={(e) => {
              onSetValue(e);
              setValue(e.target.value);
            }}
          />
        }
      />
    );
  }
);
