import { useQuery, UseQueryOptions, useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { CommentInDto, CommentOutDto } from '@app/swagger-types';
import { GET_FINDING_COMMENTS_QUERY } from '@app/constants/query-api-configs';
import { PaginatedResponse } from '@app/api/types';
import { FindingCommentApi } from '@app/domain/finding-comment/api/finding-comment.api';

export const useFindingComments = (
  params: { findingId: string },
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<CommentOutDto>,
    (typeof GET_FINDING_COMMENTS_QUERY.name | { findingId: string })[]
  >
) => {
  return useQuery(
    [GET_FINDING_COMMENTS_QUERY.name, params],
    async () => {
      const { data } = await FindingCommentApi.getComments(params.findingId);
      return data;
    },
    {
      staleTime: GET_FINDING_COMMENTS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useCreateFindingComment = (
  options: Omit<UseMutationOptions<unknown, Error, { findingId: string; Dto: CommentInDto }>, 'onSuccess'> = {}
) => {
  const queryClient = useQueryClient();
  return useMutation((args) => FindingCommentApi.create(args), {
    onSuccess: async (_, { findingId }) => {
      await queryClient.invalidateQueries([GET_FINDING_COMMENTS_QUERY.name, { findingId }]);
    },
    ...options,
  });
};
