import styles from './TableLoader.module.scss';

import { CircularProgress } from '@mui/material';

export const TableLoader = () => {
  return (
    <div className={styles.OverlayLoader}>
      <CircularProgress size={35} />
    </div>
  );
};
