import React, { useMemo } from 'react';

import { SignUpInvitedUserFormValidationSchema } from '@app/models/auth';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Typography } from '@mui/material';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { Button, Form, Input } from '@app/ui-components';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { isString } from 'lodash';
import { CustomPasswordChecklist } from '@app/modules/auth/components/CustomPasswordChecklist';

interface Props {
  onSignUp: (Dto: SignUpInvitedUserFormValidationSchema) => void;
  error?: Error | null;
  loading?: boolean;
}

export const SignUpFromInvitationForm: React.FC<Props> = ({ onSignUp, error, loading }) => {
  const { control, formState, handleSubmit, register, clearErrors } = useForm<SignUpInvitedUserFormValidationSchema>({
    resolver: yupResolver(SignUpInvitedUserFormValidationSchema),
  });

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: loading,
      };
    };
    return fn;
  }, [register, formState, loading]);

  const onSubmit: SubmitHandler<SignUpInvitedUserFormValidationSchema> = (formData) => {
    clearErrors();
    onSignUp(formData);
  };

  return (
    <div className="space-y-4">
      <Typography variant="xxxl" className="mb-[14px] font-bold">
        Sign Up
      </Typography>
      <Typography variant="l" className="!mb-[39px] block text-grey5">
        {'Already have an account? '}
        <TypedLink to={Routes.auth.sign_in} className="font-bold text-grey5 hover:text-grey6">
          Log in
        </TypedLink>
      </Typography>
      <Form control={control} onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Input
          {...inputRegister('firstName')}
          label="First Name"
          placeholder="Enter First Name"
          id="name"
          withBackground
        />
        <Input
          {...inputRegister('lastName')}
          label="Last Name"
          placeholder="Enter Last Name"
          id="last"
          withBackground
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Input
                {...field}
                label="Password"
                placeholder="Enter Password"
                type="password"
                id="password"
                withBackground
                disabled={loading}
                error={Boolean(error)}
              />
              {isString(field.value) && <CustomPasswordChecklist value={field.value} />}
            </>
          )}
        />
        <Button type="submit" variant="outlined" color="gold" fullWidth className="!mt-9 font-medium" loading={loading}>
          Sign Up
        </Button>
        {maybeRenderError(error)}
      </Form>
    </div>
  );
};
