import { useMemo } from 'react';
import { Toolbar } from '@mui/material';

import useStore from '@app/store/useStore.hook';
import { UserHeaderInfo } from './user-header-info/UserHeaderInfo';
import { HeaderClock } from '@app/shared/ui-components/layout/header/header-clock/HeaderClock';

export const Header: React.FC = ({ children }) => {
  const { currentUser: user } = useStore(({ currentUser, currentBreakpointDevice, navMobileOpen, setNavMobile }) => ({
    currentUser,
    currentBreakpointDevice,
    navMobileOpen,
    setNavMobile,
  }));

  const desktopHeaderContent = useMemo(() => {
    return (
      <>
        <div className="flex items-center">
          <div className="flex items-center">
            <HeaderClock />
          </div>
        </div>
        <div className="flex">
          {user && (
            <div>
              <UserHeaderInfo user={user} />
            </div>
          )}
        </div>
      </>
    );
  }, [user]);

  return <Toolbar className="flex justify-between px-0">{children ? children : desktopHeaderContent}</Toolbar>;
};
