import { StoreSlice } from '../storeTypes';
import { MainUISlice, DeviceBreakpointType } from './types';

const createMainUISlice: StoreSlice<MainUISlice> = (set) => ({
  isGlobalLoading: false,
  setIsGlobalLoading: (isLoading: boolean) => set({ isGlobalLoading: isLoading }),

  // theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  theme: 'dark',
  setTheme: (theme) => set({ theme }),

  // NAV MENU
  navMobileOpen: false,
  setNavMobile: (navMobileOpen) => set({ navMobileOpen }),

  // FOR RESPONSIVE DETECTION
  currentBreakpointDevice: {
    deviceType: DeviceBreakpointType.UNKNOWN,
    isMobile: false,
    isDesktop: false,
    isTablet: false,
  },
  updateBreakpoint: (deviceType) =>
    set({
      currentBreakpointDevice: {
        deviceType,
        isDesktop: deviceType === DeviceBreakpointType.DESKTOP,
        isMobile: deviceType === DeviceBreakpointType.PHONE,
        isTablet: deviceType === DeviceBreakpointType.TABLET,
      },
    }),
});

export default createMainUISlice;
