import { object, string, InferType } from 'yup';

import { UserBaseSchema } from '@app/models/auth/auth.shared';
import { ERole } from '@app/swagger-types';

export const SuperAdminDto = UserBaseSchema.concat(
  object({
    role: string().oneOf([ERole.ROLE_ADMIN]).defined(),
  })
);

export type SuperAdminDto = InferType<typeof SuperAdminDto>;
