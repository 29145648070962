import './apply-side-effects';
import './apply-dirty-hacks';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import '@app/styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
