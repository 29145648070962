import React from 'react';
import styles from './ContainerLoader.module.scss';
import { CircularProgress } from '@mui/material';
import { Button } from '..';

type Props = {
  loading?: boolean;
  error?: Error | null;
  retryHandler?: () => void;
  rounded?: boolean;
  noData?: boolean;
};

export const ContainerLoader: React.FC<Props> = ({ loading, error, retryHandler, rounded, noData }) => {
  const containerStyle = rounded ? `${styles.container} ${styles.rounded}` : styles.container;

  if (loading) {
    return (
      <div className={containerStyle}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    // TODO parse error to show error-specific message when possible.
    return (
      <div className={containerStyle}>
        <div>Oops, something went wrong</div>
        {retryHandler && (
          <div className={styles.retryContainer}>
            <Button onClick={retryHandler}>Retry</Button>
          </div>
        )}
      </div>
    );
  }

  if (noData) {
    return (
      <div className={containerStyle}>
        <div>No data to display</div>
        {retryHandler && (
          <div className={styles.retryContainer}>
            <Button onClick={retryHandler}>Retry</Button>
          </div>
        )}
      </div>
    );
  }

  return null;
};
