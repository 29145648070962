import { IMaskMixin, IMask } from 'react-imask';

import { Input, InputProps } from '../input/Input';

//FIXME: remove ts-ignores

/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-ignore
const MixinInput = IMaskMixin((props) => <Input {...props} />);

type DateInputProps = {
  handleDateChange: (date: Date | null) => void;
  maxDate?: any;
};
export const DateInput: React.FC<InputProps & DateInputProps> = ({ handleDateChange, maxDate, ...props }) => {
  return (
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    //@ts-ignore
    <MixinInput
      mask={Date}
      autofix
      pattern="m/`d/`Y"
      lazy={false}
      overwrite
      blocks={{
        m: { mask: IMask.MaskedRange, placeholderChar: 'M', from: 1, to: 12, maxLength: 2 },
        d: { mask: IMask.MaskedRange, placeholderChar: 'D', from: 1, to: 31, maxLength: 2 },
        Y: { mask: IMask.MaskedRange, placeholderChar: 'Y', from: 1900, to: 2999, maxLength: 4 },
      }}
      format={(date) => {
        let day: string | number = date.getDate();
        let month: string | number = date.getMonth() + 1;
        const year = date.getFullYear();

        if (day < 10) {
          day = `0${day}`;
        }
        if (month < 10) {
          month = `0${month}`;
        }

        return [month, day, year].join('/');
      }}
      parse={(str) => {
        const monthDayYear = str.split('/');
        return new Date(Number(monthDayYear[2]), Number(monthDayYear[0]) - 1, Number(monthDayYear[1]));
      }}
      onComplete={(str) => {
        const monthDayYear = str.split('/');
        handleDateChange(new Date(Number(monthDayYear[2]), Number(monthDayYear[0]) - 1, Number(monthDayYear[1])));
      }}
      max={maxDate}
      {...props}
    />
  );
};
