import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { format } from 'date-fns';

import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { StyledStepper } from '@app/shared/ui-components/stepper/StyledStepper';
import { EProjectStatus } from '@app/swagger-types';
import { DATE_FORMAT } from '@app/constants/date';
import { Tooltip } from '@app/ui-components';
import { TestersInfo } from '@app/shared/tables/projects-table/TestersInfo';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';

export const ProjectProgressBox: React.FC<{
  project: OverrideProjectOutDto;
}> = ({ project }) => {
  const { status, startDate, endDate, testers } = project;

  const steps = useMemo(
    () =>
      [EProjectStatus.PENDING, EProjectStatus.SCHEDULED, EProjectStatus.LIVE, EProjectStatus.COMPLETED].map(capitalize),
    []
  );
  const activeStep = useMemo(() => steps.indexOf(capitalize(status)), [steps, status]);

  const progressDescription = useMemo(() => {
    if (status === EProjectStatus.PENDING) {
      return 'Your project is being reviewed by our team. We will shortly update you on the next steps.';
    } else if (status === EProjectStatus.SCHEDULED && startDate) {
      return `Work on your project is scheduled and will begin ${format(new Date(startDate), DATE_FORMAT)}.`;
    } else if (status === EProjectStatus.LIVE && endDate) {
      return `Testers are working on your project. Work on the project will end on ${format(
        new Date(endDate),
        DATE_FORMAT
      )}.`;
    } else if (status === EProjectStatus.COMPLETED) {
      return 'Testing has now completed. All findings submitted and final report is ready for download. We look forward to collaborate with you again soon. Thank you!';
    }
    return null;
  }, [status, endDate, startDate]);

  return (
    <div
      className={clsxm('rounded-[10px] bg-transparentBackground py-6 px-8 backdrop-blur-lg', sharedStyle.shadowBorder)}
    >
      <div className="flex">
        <div className="flex flex-[7] flex-col justify-between">
          <div>
            <Typography className="mr-3 mb-3 text-xxxl font-bold">Progress</Typography>
            <Typography className="text-xxs text-grey6">{progressDescription}</Typography>
          </div>
          <div>
            <StyledStepper steps={steps} activeStep={activeStep} />
          </div>
        </div>
        <div className="flex-[5]">
          <div className="flex flex-col items-end">
            <Typography className="mb-4 text-xs font-bold text-grey5">Testers</Typography>
            <div className="mb-2 h-12">
              {testers && Boolean(testers.length) ? (
                <TestersInfo testers={testers} tooltipPlacement="bottom" />
              ) : (
                <Typography className="text-xxs font-light italic text-grey6">No Testers</Typography>
              )}
            </div>
          </div>
          <Tooltip title="Coming Soon..." followCursor placement="top">
            <div className="mt-2.5 ml-2.5 flex cursor-help flex-col items-end rounded-md opacity-50">
              <div className="mb-4 flex items-center">
                <span className="mr-2 rounded-[27px] bg-error/20 px-1.5 py-0.5 align-middle text-[8px] leading-[12px] text-error">
                  Off
                </span>
                <Typography className="text-xs">Bank of days</Typography>
                <span className="ml-2 flex h-3.5 w-3.5 items-center justify-center rounded-full bg-grey3 align-middle text-xxs">
                  i
                </span>
              </div>
              <div className="flex">
                {[
                  { value: 0, label: 'Available' },
                  { value: 0, label: 'On hold' },
                  { value: 0, label: 'Used' },
                ].map(({ value, label }, index) => (
                  <div key={index} className="mr-4 last:mr-0">
                    <Typography className="mb-1 text-m">{value}</Typography>
                    <Typography className="text-xs font-bold text-grey5">{label}</Typography>
                  </div>
                ))}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
