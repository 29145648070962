import styles from './IconButton.module.scss';
import clsx from 'clsx';
import { get } from 'lodash';
import React from 'react';

import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps, CircularProgress } from '@mui/material';

import { ButtonColor } from '../ButtonColors';

export type IconButtonProps = {
  color?: ButtonColor;
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'shaded' | 'opacity' | 'transparent';
  loading?: boolean;
} & Omit<MuiIconButtonProps, 'color' | 'size'>;

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  color,
  className,
  variant,
  loading,
  size,
  disabled,
  ...props
}) => {
  const classNames = clsx(styles.button, get(styles, `${variant}-${color}`), get(styles, `${size}`), className);

  return (
    <MuiIconButton {...props} className={classNames} disabled={disabled || loading}>
      {children}
      {loading && (
        <div className={styles['loader-container']}>
          <CircularProgress className={clsx(styles.loader)} />
        </div>
      )}
    </MuiIconButton>
  );
};
