/* eslint-disable brace-style */
import { PropsManager } from './props-manager';
import { TypedListener } from './typed-listener';
import { OmitedProps, ValueType, OpenEntityType, OpenableEntityManagerInterface } from './types';

export class OpenableEntityManager<EntitiesListType extends Record<keyof EntitiesListType, ValueType>>
  implements OpenableEntityManagerInterface<EntitiesListType>
{
  readonly entities: EntitiesListType;
  private readonly propsManager = new PropsManager<EntitiesListType>();
  private openEntities: OpenEntityType<keyof EntitiesListType>[] = [];
  private readonly openEntitiesListener = new TypedListener<OpenEntityType<keyof EntitiesListType>[]>();

  constructor(importedEntities: EntitiesListType) {
    this.entities = importedEntities;
  }

  open<T extends keyof EntitiesListType>(
    entityName: T,
    props: Omit<React.ComponentPropsWithoutRef<EntitiesListType[T]['component']>, OmitedProps>,
    actionType: 'push' | 'replace' = 'replace'
  ) {
    this.propsManager.setProps(entityName, props);
    if (actionType === 'replace') {
      this.openEntities = [{ entityName, entityIsOpen: true }];
      this.openEntitiesListener.emit(this.openEntities);
      return;
    }
    const entityExistHiddenIndex = this.openEntities.findIndex((d) => d.entityName === entityName);
    if (entityExistHiddenIndex !== -1) {
      this.openEntities = this.openEntities.map((e, i) =>
        i === entityExistHiddenIndex ? { ...e, entityIsOpen: true } : e
      );
    } else {
      this.openEntities = [...this.openEntities, { entityName, entityIsOpen: true }];
    }
    this.openEntitiesListener.emit(this.openEntities);
  }

  close<T extends keyof EntitiesListType>(entityName?: T) {
    this.openEntities = this.openEntities.map((entity) => {
      if (!entityName) {
        return { ...entity, entityIsOpen: false };
      }

      if (entityName && entityName === entity.entityName) {
        return { ...entity, entityIsOpen: false };
      }

      return entity;
    });
    this.openEntitiesListener.emit(this.openEntities);
  }

  getEntityProps<T extends keyof EntitiesListType>(
    entityName: T
  ): undefined | Omit<React.ComponentPropsWithoutRef<EntitiesListType[T]['component']>, OmitedProps> {
    return this.propsManager.getProps(entityName);
  }

  on = this.openEntitiesListener.on;

  off = this.openEntitiesListener.off;
}
