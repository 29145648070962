import styles from './VerificationInput.module.scss';
import React, { useEffect, useMemo, useState, createRef } from 'react';
import { useUpdateEffect, usePrevious } from 'react-use';

import clsxm from '@app/lib/clsxm';

import { Input } from '../input/Input';

const KEY = {
  BACKSPACE: 'Backspace',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  DELETE: 'Delete',
  TAB: 'Tab',
};

type PropTypes = {
  autoFocus?: boolean;
  length?: number;
  onChange?: (data: string) => void;
  onCompleted?: (data: string) => void;
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  value?: string;
  onlyNumbers?: boolean;
};

export const VerificationInput = ({
  autoFocus = false,
  length = 4,
  onChange = () => {},
  className,
  placeholder = '',
  value: propValue,
  containerClassName,
  onlyNumbers,
}: PropTypes) => {
  const emptyValue = new Array(length).fill(placeholder);

  const [activeIndex, setActiveIndex] = useState(-1);

  const [value, setValue] = useState<string[]>(propValue ? propValue.split('') : emptyValue);

  const itemsRef = useMemo(() => new Array(length).fill(null).map(() => createRef<HTMLDivElement>()), [length]);
  const codeInputRef = createRef<HTMLInputElement>();

  const isCodeRegex = new RegExp(`^[${onlyNumbers ? '' : 'a-zA-Z'}0-9]{${length}}$`);

  const getItem = (index: number) => itemsRef[index]?.current;
  const focusItem = (index: number): void => getItem(index)?.focus();
  const blurItem = (index: number): void => getItem(index)?.blur();

  const onItemFocus = (index: number) => () => {
    setActiveIndex(index);
    if (codeInputRef.current) {
      codeInputRef.current.focus();
    }
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.code === KEY.TAB) {
      e.preventDefault();
    }
  };

  const onInputKeyUp = ({ key, code }: React.KeyboardEvent) => {
    const newValue = [...value];
    const nextIndex = activeIndex + 1;
    const prevIndex = activeIndex - 1;

    const codeInput = codeInputRef.current;
    const currentItem = getItem(activeIndex);

    const isFirst = activeIndex === 0;
    const isLast = nextIndex === length;
    const isDeleting = code === KEY.DELETE || code === KEY.BACKSPACE;

    if (code === KEY.ARROW_LEFT) {
      if (isFirst) {
        setActiveIndex(length - 1);
      } else {
        setActiveIndex(prevIndex);
      }
      return;
    }

    if (code === KEY.TAB || code === KEY.ARROW_RIGHT) {
      if (isLast) {
        setActiveIndex(0);
      } else {
        setActiveIndex(nextIndex);
      }

      return;
    }

    // keep items focus in sync
    onItemFocus(activeIndex);

    // on delete, replace the current value
    // and focus on the previous item
    if (isDeleting) {
      newValue[activeIndex] = placeholder;
      setValue(newValue);

      if (activeIndex > 0) {
        setActiveIndex(prevIndex);
        focusItem(prevIndex);
      }

      return;
    }

    // if the key pressed is not a number
    // don't do anything

    if ((onlyNumbers && Number.isNaN(+key)) || key.length > 1) {
      return;
    }

    // reset the current value
    // and set the new one
    if (codeInput) {
      codeInput.value = '';
    }
    newValue[activeIndex] = key;
    setValue(newValue);

    if (!isLast) {
      setActiveIndex(nextIndex);
      focusItem(nextIndex);
      return;
    }

    if (codeInput) {
      codeInput.blur();
    }
    if (currentItem) {
      currentItem.blur();
    }

    setActiveIndex(-1);
  };

  //  mobile autocompletion
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: changeValue } = e.target;
    const isCode = isCodeRegex.test(changeValue);

    if (!isCode) {
      return;
    }

    setValue(changeValue.split(''));
    blurItem(activeIndex);
  };

  const onInputBlur = () => {
    if (activeIndex === -1) {
      return;
    }

    blurItem(activeIndex);
    setActiveIndex(-1);
  };

  // autoFocus
  useEffect(() => {
    if (autoFocus && itemsRef[0].current) {
      itemsRef[0].current.focus();
    }
  }, [autoFocus, itemsRef]);

  // handle pasting
  useEffect(() => {
    const codeInput = codeInputRef.current;
    if (!codeInput) {
      return;
    }

    const onPaste = (e: ClipboardEvent) => {
      e.preventDefault();

      let pastedString = e.clipboardData?.getData('text');
      if (!pastedString) {
        return;
      }

      pastedString = pastedString.substr(0, length);

      const isCode = new RegExp(`^[${onlyNumbers ? '' : 'a-zA-Z'}0-9]+$`).test(pastedString);

      if (isCode) {
        const pasted = pastedString.split('');
        setValue((prevValue) => {
          if (pasted.length === length) {
            return pasted;
          } else {
            const newValue = [...prevValue];
            pasted.forEach((v, i) => {
              const pasteIndex = i + activeIndex;
              if (pasteIndex < length) {
                newValue[pasteIndex] = v;
              }
            });

            return newValue;
          }
        });
      }
    };

    codeInput.addEventListener('paste', onPaste);
    return () => codeInput.removeEventListener('paste', onPaste);
  }, [activeIndex, codeInputRef, length, onlyNumbers]);

  const prevStringValue = usePrevious(value.join(''));

  useEffect(() => {
    const stringValue = value.join('');

    if (prevStringValue !== stringValue) {
      onChange(stringValue);
    }
  }, [onChange, prevStringValue, value]);

  useUpdateEffect(() => {
    if (typeof propValue !== 'string') {
      return;
    }

    // avoid infinite loop
    if (propValue === '' && value.join('') === emptyValue.join('')) {
      return;
    }

    // keep internal and external states in sync
    if (propValue !== value.join('')) {
      setValue(propValue.split(''));
    }
  }, [propValue]);

  return (
    <>
      <Input
        ref={codeInputRef}
        className="absolute opacity-0"
        autoComplete="one-time-code"
        type={onlyNumbers ? 'tel' : 'text'}
        inputMode={onlyNumbers ? 'decimal' : 'text'}
        id="one-time-code"
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
        onKeyUp={onInputKeyUp}
        onBlur={onInputBlur}
        onFocus={() => {
          window.scrollBy(0, 200);
        }}
      />
      <div className={clsxm('relative flex justify-between', containerClassName)}>
        {itemsRef.map((ref, i) => {
          const codeValue = value[i];
          const isActive = i === activeIndex;

          let renderValue = <div className={styles['number-value']}>{codeValue || placeholder}</div>;

          if (isActive && !codeValue) {
            renderValue = <div className={styles.caret}></div>;
          }
          return (
            <div
              role="button"
              key={i}
              ref={ref}
              tabIndex={i}
              className={clsxm(styles.item, isActive ? styles.active : null, className)}
              onFocus={onItemFocus(i)}
            >
              {renderValue}
            </div>
          );
        })}
      </div>
    </>
  );
};
