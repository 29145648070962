import { Routes } from '@app/constants/routes';

import { SettingsModule } from './SettingsModule';
import { TypedNavigate } from '@app/router';
import { SettingWorkingDays } from '@app/modules/admin/modules/settings/pages/SettingWorkingDays';
import { SettingFindings } from '@app/modules/admin/modules/settings/pages/SettingFindings';
import { SettingsLoggingRoutes } from '@app/modules/admin/modules/settings/nested/setting-logging/SettingLoggingRoutes';
import { ERole } from '@app/swagger-types';
import { SettingsCurrency } from './pages/SettingsCurrency';

export const SettingsRoutes = {
  path: Routes.settings.index,
  element: <SettingsModule />,
  role: [ERole.ROLE_ADMIN],
  children: [
    { path: Routes.settings.workingDays, element: <SettingWorkingDays /> },
    { path: Routes.settings.findings, element: <SettingFindings /> },
    { path: Routes.settings.currency, element: <SettingsCurrency /> },
    SettingsLoggingRoutes,
    { index: true, path: '*', element: <TypedNavigate to={Routes.settings.logging} /> },
  ],
};
