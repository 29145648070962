import React, { useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import styles from '@app/shared/ui-components/layout/header/user-header-info/UserHeaderInfo.module.scss';
import { Status } from '@app/shared/ui-components/status/Status';
import { renderFriendlyFindingPaymentStatus } from '@app/domain/finding/findings.utils';
import { KeyboardArrowDown } from '@mui/icons-material';
import { ERetestPaymentStatus } from '@app/swagger-types';
import { UseMutateFunction } from 'react-query/types/react/types';

interface Props {
  id: string;
  projectId: string;
  onChangeStatus: UseMutateFunction<
    unknown,
    unknown,
    { findingId: string; projectId: string; status: ERetestPaymentStatus }
  >;
  activeStatus: ERetestPaymentStatus;
}

export const PaymentStatusDropdown: React.FC<Props> = ({ id, onChangeStatus, activeStatus, projectId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        elevation={0}
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.Paper }}
      >
        <MenuItem
          key={`${ERetestPaymentStatus.PENDING}_${id}`}
          className={styles.MenuItem}
          value={ERetestPaymentStatus.PENDING}
          onClick={() => {
            if (activeStatus !== ERetestPaymentStatus.PENDING) {
              onChangeStatus({
                findingId: id,
                projectId,
                status: ERetestPaymentStatus.PENDING,
              });
            }
            handleClose();
          }}
        >
          <Status {...renderFriendlyFindingPaymentStatus(ERetestPaymentStatus.PENDING)} />
        </MenuItem>
        <MenuItem
          key={`${ERetestPaymentStatus.PAID}_${id}`}
          className={styles.MenuItem}
          value={ERetestPaymentStatus.PAID}
          onClick={() => {
            if (activeStatus !== ERetestPaymentStatus.PAID) {
              onChangeStatus({
                findingId: id,
                projectId,
                status: ERetestPaymentStatus.PAID,
              });
            }
            handleClose();
          }}
        >
          <Status {...renderFriendlyFindingPaymentStatus(ERetestPaymentStatus.PAID)} />
        </MenuItem>
      </Menu>
      <Typography onClick={handleClick} className="flex cursor-pointer items-center">
        <Status {...renderFriendlyFindingPaymentStatus(activeStatus)} />
        <KeyboardArrowDown className="ml-2 w-3" />
      </Typography>
    </>
  );
};
