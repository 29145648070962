import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { CertificationDto, SkillDto } from '@app/swagger-types';

export const DictionaryApi = buildApi(
  class _DictionaryApi extends ApiCollection {
    async getCertificates() {
      return this.apiService.get<CertificationDto[]>('/private/certifications');
    }
    async getSkills() {
      return this.apiService.get<SkillDto[]>('/private/skills');
    }
  }
);
