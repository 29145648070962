import { useLocation, matchPath } from 'react-router-dom';

function findFirstMatchingRoute(routes: string[], currentPathname: string) {
  for (const route of routes) {
    const result = matchPath({ path: route, end: false }, currentPathname);
    if (result) {
      return result;
    }
  }
  return null;
}

export const useMatchActiveRoute = <TPath extends string>(routes: TPath[], defaultRoute?: string): string | boolean => {
  const location = useLocation();
  const match = findFirstMatchingRoute(routes, location.pathname);
  if (match?.pathname) {
    return match.pathname;
  }
  return defaultRoute ? defaultRoute : false;
};
