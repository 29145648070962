import React, { useCallback, useMemo } from 'react';
import { Dialog } from '@app/hoc';
import { GET_INVOICES_QUERY, GET_PROJECT_QUERY } from '@app/constants/query-api-configs';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { useMutation, useQueryClient } from 'react-query';
import { InvoiceApi } from '@app/domain/invoice/invoice.api';
import { OverrideInvoiceStatusInDto } from '@app/swagger-override-types';
import { IconButton, Table, TableColumn } from '@app/ui-components';
import { InvoiceOutDto } from '@app/swagger-types';
import { format } from 'date-fns';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@app/constants/date';
import { Typography } from '@mui/material';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { InvoiceStatusDropdown } from '@app/domain/invoice/InvoiceStatusDropdown';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';
import { DeleteIcon } from '@app/assets/icons';
import { Status } from '@app/shared/ui-components/status/Status';
import { getFriendlyInvoiceStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { useGetInvoices } from '@app/domain/invoice/api/hooks/invoice.api.hook';
import { PAGE_SIZE } from '@app/constants/api';

interface Props {
  open: boolean;
  projectId?: string;
  title: string;
  onClose: () => void;
}

export const ProjectInvoicesDialog: React.FC<Props> = ({ open, onClose, title, projectId }) => {
  const { isAdmin, isClient } = useUserRole();
  const queryClient = useQueryClient();
  const navigate = useTypedNavigate();

  const {
    values: { page, sort = GET_INVOICES_QUERY.defaultSort },
    handlers: { handlePageChange, handleSortChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
    },
    prefix: GET_INVOICES_QUERY.tablePrefix,
  });

  const { data, isFetching } = useGetInvoices(
    {
      size: PAGE_SIZE,
      page: Math.ceil((page || 1) - 1),
      sort,
      projectId,
    },
    { keepPreviousData: true }
  );

  const { mutate: updateInvoiceStatus, isLoading: invoiceStatusLoading } = useMutation(
    async ({ invoiceId, Dto }: { invoiceId: string; Dto: OverrideInvoiceStatusInDto }) => {
      return await InvoiceApi.updateStatus(invoiceId, Dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PROJECT_QUERY.name);
        queryClient.invalidateQueries(GET_INVOICES_QUERY.name);
      },
    }
  );
  const { mutate: deleteInvoice, isLoading: deleteInvoiceLoading } = useMutation(
    async (invoiceId: string) => {
      return await InvoiceApi.delete(invoiceId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PROJECT_QUERY.name);
        queryClient.invalidateQueries(GET_INVOICES_QUERY.name);
      },
    }
  );

  const handleInvoiceStatusChange = useCallback(
    ({ id: invoiceId, Dto }: { id: string; Dto: OverrideInvoiceStatusInDto }) => {
      updateInvoiceStatus({ invoiceId, Dto });
    },
    [updateInvoiceStatus]
  );

  const navigateToClientInvoice = useCallback(
    ({ id: invoiceId }: InvoiceOutDto) => {
      navigate({ to: Routes.pdf.clientInvoice, params: { invoiceId } });
    },
    [navigate]
  );

  const columns = useMemo(() => {
    const cols: TableColumn<InvoiceOutDto>[] = [
      {
        headerName: 'Date & Timeline',
        field: 'createdDate',
        align: 'left',
        render: ({ createdDate }) => format(new Date(createdDate), DATE_TIME_FORMAT),
      },
      {
        headerName: 'Amount',
        field: 'amountCoin',
        align: 'left',
        render: ({ amountCoin, usdToGbpRate, isD2NA }) =>
          isD2NA && usdToGbpRate ? createGBPformatter(usdToGbpRate).formatGBP(amountCoin) : formatDollars(amountCoin),
      },
      {
        headerName: 'Status',
        field: 'status',
        align: 'left',
        render: ({ status, id }) =>
          isAdmin ? (
            <InvoiceStatusDropdown id={id} activeStatus={status} onChangeStatus={handleInvoiceStatusChange} />
          ) : (
            <Status {...getFriendlyInvoiceStatus(status)} />
          ),
      },
    ];

    if (isAdmin) {
      cols.push({
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableSort: true,
        render: ({ id, createdDate }) => (
          <ConfirmDeleteDialog
            trigger={
              <IconButton>
                <DeleteIcon />
              </IconButton>
            }
            onConfirm={() => deleteInvoice(id)}
            headerTitle="Delete Invoice"
            title={`Are you sure you want to delete Invoice for ${format(new Date(createdDate), DATE_FORMAT)}?`}
          />
        ),
      });
    }

    return cols;
  }, [handleInvoiceStatusChange, deleteInvoice, isAdmin]);

  const leftContent = useMemo(() => {
    return (
      <div>
        <Typography className="mb-6 text-xxl font-bold">{title}</Typography>
      </div>
    );
  }, [title]);

  return (
    <Dialog open={open} onClose={onClose} headerTitle={title} paperClassName="max-w-[600px] w-full">
      <Table
        cols={columns}
        tableData={data?.result || []}
        loading={isFetching || invoiceStatusLoading || deleteInvoiceLoading}
        notFoundMessage="No Invoices"
        leftHeaderContent={leftContent}
        currentPage={page}
        total={data?.total}
        onPagination={handlePageChange}
        currentSort={sort}
        onSortChanged={handleSortChange}
        onItemSelect={isClient || isAdmin ? navigateToClientInvoice : undefined}
        hidePageSize
        transparentBackground={false}
      />
    </Dialog>
  );
};
