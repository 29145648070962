import { BonusOutDto } from '@app/swagger-types';
import { renderFriendlySeverityLabel, renderSeverityColor } from '@app/domain/settings/settings.utils';

export const getBonusInfo = (bonus: BonusOutDto) => {
  return {
    ...bonus,
    label: renderFriendlySeverityLabel(bonus.severityLevel),
    color: renderSeverityColor(bonus.severityLevel),
  };
};
