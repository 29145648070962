import React, { useState } from 'react';
import { ProjectItem } from '@app/domain/project/components/ProjectItem';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { GET_PROJECTS_QUERY } from '@app/constants/query-api-configs';
import {
  useAssignTestersToProject,
  useDeleteProject,
  useInfinityGetProjects,
  useRejectProject,
  useSetProjectTimeline,
} from '@app/domain/project/api/hooks/project.api.hook';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { EProjectStatus, ProjectOutDto } from '@app/swagger-types';
import { NoProjectsItem } from '@app/domain/project/components/NoProjectsItem';
import InfiniteScroll from 'react-infinite-scroller';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { RejectProjectDialog } from '@app/domain/project/components/RejectProjectDialog';
import { SetUpProjectDialog } from '@app/domain/project/components/SetUpProjectDialog';
import { AssignTestersDialog } from '@app/domain/project/components/AssignTestersDialog';
import { PaginatedResponse } from '@app/api/types';
import { InfiniteData } from 'react-query/types/core/types';
import { useQueryClient } from 'react-query';
import { PAGE_SIZE } from '@app/constants/api';

export const AdminProjectList: React.FC<{ filterStatus?: EProjectStatus }> = ({ filterStatus }) => {
  const [projectIdForDeleting, setProjectIdForDeleting] = useState<string>();
  const [idForRejecting, setIdForRejecting] = useState<string>();
  const [projectForAssignTester, setProjectForAssignTester] = useState<OverrideProjectOutDto>();
  const [projectForSetUp, setProjectForSetUp] = useState<OverrideProjectOutDto>();

  const queryClient = useQueryClient();

  const {
    values: { sort = GET_PROJECTS_QUERY.defaultSort, search },
    handlers: { handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      search: '',
    },
    prefix: GET_PROJECTS_QUERY.tablePrefix,
  });

  const { data, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useInfinityGetProjects(
    {
      sort,
      status: filterStatus,
      term: search,
    },
    {
      refetchOnMount: 'always',
    }
  );

  const { mutate: handleDeleteProject } = useDeleteProject({
    onSettled: () => setProjectIdForDeleting(undefined),
    onMutate: (projectId) => setProjectIdForDeleting(projectId),
  });

  const { mutate: handleAssignTestersToProject } = useAssignTestersToProject();

  const { mutate: handleTimelineChange, isLoading: isApproveLoading } = useSetProjectTimeline();

  const { mutate: handleRejectProject, isLoading: isRejectLoading } = useRejectProject({
    onSuccess: (_, { id, Dto }) => {
      queryClient.setQueryData<InfiniteData<PaginatedResponse<ProjectOutDto>>>(
        [
          GET_PROJECTS_QUERY.name,
          {
            sort,
            status: filterStatus,
            term: search,
          },
        ],
        (data) =>
          data
            ? {
                ...data,
                pages: data.pages.map((page) => ({
                  ...page,
                  result: page.result.map((project) => {
                    if (project.id === id) {
                      return { ...project, ...Dto };
                    }
                    return project;
                  }),
                })),
              }
            : { pages: [], pageParams: [] }
      );
      // Invalidate data in table
      queryClient.removeQueries({
        predicate: (query) =>
          query.queryKey[0] === GET_PROJECTS_QUERY.name && (query.queryKey[1] as { size: number })?.size === PAGE_SIZE,
      });
    },
  });

  const isEmpty = !data || data.pages.length === 0 || (data.pages[0] && data.pages[0].result.length === 0);
  const isPendingTab = filterStatus === EProjectStatus.PENDING;
  const isScheduledTab = filterStatus === EProjectStatus.SCHEDULED;
  const isLiveTab = filterStatus === EProjectStatus.LIVE;

  const renderContent = () => {
    if (isFetching && !data) {
      return <ContainerLoader loading />;
    }

    if (isEmpty || !data) {
      return <NoProjectsItem />;
    }

    return (
      <InfiniteScroll
        hasMore={!isFetchingNextPage && hasNextPage}
        loadMore={() => fetchNextPage()}
        loader={<ContainerLoader loading />}
      >
        {data.pages.map(({ result }, i) => (
          <React.Fragment key={i}>
            {result.map((project) => (
              <ProjectItem
                key={project.id}
                project={project}
                isDeleting={project.id === projectIdForDeleting}
                onApprove={isPendingTab ? () => setProjectForSetUp(project) : undefined}
                onReject={isPendingTab ? () => setIdForRejecting(project.id) : undefined}
                onDelete={!isPendingTab && !isScheduledTab && !isLiveTab ? handleDeleteProject : undefined}
                onAssignTesters={isScheduledTab || isLiveTab ? () => setProjectForAssignTester(project) : undefined}
                disabledApprove={isApproveLoading || isRejectLoading}
                disabledReject={isApproveLoading || isRejectLoading}
                isApproving={isApproveLoading && projectForSetUp?.id === project.id}
                isRejecting={isRejectLoading && idForRejecting === project.id}
              />
            ))}
          </React.Fragment>
        ))}
        {isFetchingNextPage && <ContainerLoader loading />}
      </InfiniteScroll>
    );
  };

  return (
    <>
      <div>
        <div className="mb-5">
          <SearchInput
            placeholder="Search by name"
            className="w-64"
            value={search}
            onChange={handleSearchChange}
            inputBaseClassName="bg-[rgba(36,37,45,.60)]"
          />
        </div>
        {renderContent()}
      </div>
      {idForRejecting && (
        <RejectProjectDialog
          open={Boolean(idForRejecting)}
          projectId={idForRejecting}
          onClose={() => setIdForRejecting(undefined)}
          onConfirm={handleRejectProject}
          isLoading={isRejectLoading}
        />
      )}
      <SetUpProjectDialog
        open={Boolean(projectForSetUp)}
        project={projectForSetUp}
        onClose={() => setProjectForSetUp(undefined)}
        onConfirm={handleTimelineChange}
        isLoading={isApproveLoading}
      />
      {projectForAssignTester && (
        <AssignTestersDialog
          open={Boolean(projectForAssignTester)}
          onClose={() => setProjectForAssignTester(undefined)}
          project={projectForAssignTester}
          onAssignTester={handleAssignTestersToProject}
        />
      )}
    </>
  );
};
