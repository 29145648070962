import styles from './UserHeaderInfo.module.scss';
import { capitalize } from 'lodash';
import { useState, useMemo, useCallback } from 'react';

import { useQueryClient } from 'react-query';

import { UserDto } from '@app/models/auth/auth.dto';
import useStore from '@app/store/useStore.hook';
import clsxm from '@app/lib/clsxm';

import { ArrowDropDown, ArrowDropUp, Logout as LogoutIcon } from '@mui/icons-material/';
import { Typography, Menu, MenuItem, ListItemIcon, Chip } from '@mui/material';

import { ConditionalWrapper } from '@app/shared/hoc';
import { Button, Avatar } from '@app/shared/ui-components/';
import { UserIcon } from '@app/assets/icons';
import { renderFriendlyRole } from '@app/domain/user/user.utils';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';

type InjectedProps = {
  user: UserDto;
  isMobileLayout?: boolean;
};

export const UserHeaderInfo: React.FC<InjectedProps> = ({ user, isMobileLayout }) => {
  const queryClient = useQueryClient();
  const { signOut } = useStore();
  const navigate = useTypedNavigate();

  const [firstName, lastName] = useMemo(() => {
    const { firstName, lastName } = user;

    return [capitalize(firstName), capitalize(lastName)];
  }, [user]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
    queryClient.removeQueries();

    //   replace({ path: Routes.SIGN_IN }); IF 404 solution used
  };

  const navigateToMyProfile = useCallback(() => {
    navigate({
      to: Routes.admin.user_management.my_profile,
    });
    handleClose();
  }, [navigate]);

  return (
    <div className={styles.Container}>
      <Chip label={renderFriendlyRole(user.role)} className="mr-1 h-6 text-xxs text-white/60" />
      <Menu
        elevation={0}
        id="simple-menu"
        anchorEl={anchorEl}
        PaperProps={{ sx: { width: anchorEl?.clientWidth } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.Paper }}
        TransitionProps={{ exit: false }}
      >
        <MenuItem onClick={navigateToMyProfile} className={styles.MenuItem}>
          <ListItemIcon className={styles.ListIcon}>
            <UserIcon />
          </ListItemIcon>
          Go to profile
        </MenuItem>
        <MenuItem onClick={handleLogout} className={clsxm(styles.MenuItem, styles.Error)}>
          <ListItemIcon className={styles.ListIcon}>
            <LogoutIcon />
          </ListItemIcon>
          Log out
        </MenuItem>
      </Menu>
      <Button
        variant="text"
        disableRipple
        className={styles.MenuButton}
        endIcon={
          anchorEl ? <ArrowDropUp className={styles.ButtonIcon} /> : <ArrowDropDown className={styles.ButtonIcon} />
        }
        onClick={handleClick}
      >
        <ConditionalWrapper
          condition={!!isMobileLayout}
          wrapper={(children) => {
            return <div className={styles.NameGroup}>{children}</div>;
          }}
        >
          <Typography noWrap className="text-xs">
            {`${firstName} ${lastName}`}
          </Typography>
        </ConditionalWrapper>
        <Avatar className={styles.Avatar} id="header-avatar-filter" width={36} height={36} imageId={user.avatarId} />
      </Button>
    </div>
  );
};
