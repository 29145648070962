import React from 'react';
import { Outlet } from 'react-router-dom';
import { Tabs, Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { OutlinedTab } from '@app/shared/ui-components/tabs/OutlinedTab';
import { useGetRetestBonusesTotal } from '@app/domain/finding/api/hooks/finding.api.hook';
import { useGetInvoicesTotal } from '@app/domain/invoice/api/hooks/invoice.api.hook';
import { EPayoutStatus } from '@app/swagger-types';
import { useGetFinancesTotal } from '@app/domain/application/api/hooks/application.api.hook';

const tabRoutes = mutable([
  Routes.finance.invoices,
  Routes.finance.needToPay,
  Routes.finance.paidOut,
  Routes.finance.retestBonuses,
] as const);

export const FinanceModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);
  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to });
  };

  const invoicesTotal = useGetInvoicesTotal();
  const pendingTesterPayments = useGetFinancesTotal(EPayoutStatus.PENDING);
  const paidTesterPayments = useGetFinancesTotal(EPayoutStatus.PAID);
  const retestBonusesTotal = useGetRetestBonusesTotal();

  return (
    <>
      <div>
        <Typography className="mb-8 text-xxxl font-bold">Finance</Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="mb-8"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <OutlinedTab label="Client Invoices" value={tabRoutes[0]} count={invoicesTotal} />
          <OutlinedTab label="Pending Tester Payments" value={tabRoutes[1]} count={pendingTesterPayments} />
          <OutlinedTab label="Paid Tester Payments" value={tabRoutes[2]} count={paidTesterPayments} />
          <OutlinedTab label="Tester Re-test Bonus" value={tabRoutes[3]} count={retestBonusesTotal} />
        </Tabs>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};
