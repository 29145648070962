import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { BreakUnit } from '../../PdfPager';
import styles from '../../pdf-salus.module.scss';
import { BaseStatBox } from '@app/shared/ui-components/methodology-stat-box/BaseStatBox';
import { PaginatedResponse } from '@app/api/types';
import { FindingOutSchema } from '@app/domain/finding/finding.schema';
import { calculateFindingsStatistic } from '@app/domain/finding/findings.utils';
import { SeverityBadge } from '@app/domain/finding/SeverityBadge';
import clsxm from '@app/lib/clsxm';
import _ from 'lodash';
import { NoFindingDiscoveredParagraph } from '../shared';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { PDFUtils } from '../../pdf.utils';

export const ReportSectionSummaryOfFindings: React.FC<{
  project: ProjectOutDtoSchema;
  findings: PaginatedResponse<FindingOutSchema>;
  hideVulnerabilityReports?: boolean;
}> = ({ findings, project, hideVulnerabilityReports = false }) => {
  const calc = calculateFindingsStatistic(findings);
  const data = calc.severityBarChart;

  const hasFindings = findings.result.length > 0;

  // sort desc
  const sortedFindings = _.sortBy(findings.result, (e) => -e.cvssScore);
  const highestScore = sortedFindings[0]?.cvssScore || 0;

  const renderStatsText = () => {
    const res: string[] = [];
    if (calc.severityStats.critical) {
      res.push(`${calc.severityStats.critical} Critical`);
    }
    if (calc.severityStats.high) {
      res.push(`${calc.severityStats.high} High`);
    }
    if (calc.severityStats.medium) {
      res.push(`${calc.severityStats.medium} Medium`);
    }
    if (calc.severityStats.low) {
      res.push(`${calc.severityStats.low} Low`);
    }
    if (!res.length) {
      return 'no';
    }
    if (res.length === 1) {
      return res.join(', ');
    }
    const tail = res.pop() || '';
    return `${res.join(', ')} and ${tail}`;
  };

  return (
    <>
      <BreakUnit pageBreak />
      <BreakUnit header>
        <div className={styles.content}>
          <div className={styles.pageHeader} id="summary_of_findings">
            Summary of Findings
          </div>
          <div className={styles.divider} />
          <div className={styles.p}>{`Salus discovered ${renderStatsText()} severity issues on ${
            project.client.companyName
          }'s ${project.name} ${PDFUtils.normalizeProductTitle(project.productTitle)} target.`}</div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="h-[100mm]">
            <BaseStatBox data={data} disableAnimation hideHeader className="bg-transparent" />
          </div>
        </div>
      </BreakUnit>
      {!hideVulnerabilityReports && (
        <>
          <BreakUnit>
            <div className={styles.content}>
              <div className={clsxm(styles.p, 'font-bold')}>Vulnerability Reports in Order of Importance</div>
            </div>
          </BreakUnit>
          {hasFindings ? (
            <>
              <BreakUnit>
                <div className={clsxm(styles.content, 'flex pt-6 font-bold text-white')}>
                  <div className={'flex-[6]'}>Vulnerability Title</div>
                  <div className={'flex-1 text-center'}>Status</div>
                </div>
              </BreakUnit>
              {sortedFindings.map((e, i, arr) => {
                const isNotLast = i !== arr.length - 1;
                return (
                  <BreakUnit key={i}>
                    <div className={clsxm(styles.content)}>
                      <div className="flex w-full items-center py-2">
                        <div className="flex-[6] items-center">
                          <TypedLink
                            params={{ projectId: project.id, findingId: e.id }}
                            to={Routes.projects.finding}
                            className="hover:underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {e.title}
                          </TypedLink>
                        </div>
                        <div className="flex flex-1 justify-center">
                          <SeverityBadge cvssScore={e.cvssScore} />
                        </div>
                      </div>
                      {isNotLast && <div className={clsxm(styles.gradientDivider, 'rotate-180')} />}
                    </div>
                  </BreakUnit>
                );
              })}
            </>
          ) : (
            <NoFindingDiscoveredParagraph />
          )}
        </>
      )}
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.sectionHeader}>Risk Rating</div>
          <div className={styles.divider} />
          <div className={clsxm(styles.p, 'flex items-center')}>
            {`Salus determined that the risk rating of the examined scope is `}
            <SeverityBadge cvssScore={highestScore} className="ml-2" />
          </div>
        </div>
      </BreakUnit>
    </>
  );
};
