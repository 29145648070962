import React from 'react';
import { Typography } from '@mui/material';

import { Status } from '@app/shared/ui-components/status/Status';
import { getFriendlyUserStatus } from '@app/domain/user/user.utils';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';
import { EUserStatus } from '@app/swagger-types';
import { TesterDto } from '@app/models/user';
import { AssignedProjectsTable } from '@app/domain/user-profile/AssignedProjectsTable';
import { UserInfoBox } from '@app/domain/user-profile/UserInfoBox';
import { TesterAdditionalInfoBox } from '@app/domain/user-profile/TesterAdditionalInfoBox';

export const MyTesterProfile: React.FC<{ tester: TesterDto }> = ({ tester }) => {
  const { status } = tester;

  const isDisabled = [EUserStatus.BANNED, EUserStatus.LOCKED, EUserStatus.DELETED].includes(status);

  return (
    <>
      <div>
        <div className="mb-32 flex items-center">
          <Typography className="mr-3.5 text-xxxl font-bold">My Profile</Typography>
          <Status {...getFriendlyUserStatus(status)} />
        </div>
        <div className={clsxm('rounded-[10px] bg-transparentBackground', sharedStyle.shadowBorder)}>
          <div className="flex">
            <div className="flex-[3] border-r border-r-grey4">
              <UserInfoBox user={tester} disabled={isDisabled} />
            </div>
            <div className="flex-[9]">
              <TesterAdditionalInfoBox tester={tester} disabled={isDisabled} isMyProfile />
            </div>
          </div>
        </div>
        <div className="mt-9">
          <AssignedProjectsTable />
        </div>
      </div>
    </>
  );
};
