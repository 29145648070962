import { InternalApiRequest, validateAndAssert } from '@app/services/api-request/';
import { FindingInDto, EFindingStatus, ERetestPaymentStatus } from '@app/swagger-types';
import { FindingByProjectIdParams, FindingParams } from '@app/domain/finding/findings.type';
import { OverrideFindingOutDto } from '@app/swagger-override-types';
import { FindingOutSchema } from '@app/domain/finding/finding.schema';
import { pageableData } from '@app/utils/yup.utils';

export abstract class FindingApi {
  static async getFindings(params: FindingParams) {
    return validateAndAssert(
      InternalApiRequest.get('/private/findings', {
        params,
      }),
      pageableData(FindingOutSchema)
    );
  }

  static async getFindingsByProjectId(params: FindingByProjectIdParams) {
    const { projectId, ...restParams } = params;
    return validateAndAssert(
      InternalApiRequest.get(`/private/projects/${projectId}/findings`, {
        params: restParams,
      }),
      pageableData(FindingOutSchema)
    );
  }

  static async deleteFinding(id: string, projectId: string) {
    return InternalApiRequest.delete(`/private/projects/${projectId}/findings/${id}`);
  }

  static async createFinding(projectId: string, Dto: FindingInDto) {
    return InternalApiRequest.post<OverrideFindingOutDto, FindingInDto>(`/private/projects/${projectId}/findings`, Dto);
  }

  static async editFinding(projectId: string, findingId: string, Dto: FindingInDto) {
    return InternalApiRequest.put<OverrideFindingOutDto, FindingInDto>(
      `/private/projects/${projectId}/findings/${findingId}`,
      Dto
    );
  }

  static async getFinding(projectId: string, findingId: string) {
    return validateAndAssert(
      InternalApiRequest.get<OverrideFindingOutDto>(`/private/projects/${projectId}/findings/${findingId}`),
      FindingOutSchema
    );
  }

  static async changeStatus(projectId: string, findingId: string, status: EFindingStatus) {
    return InternalApiRequest.patch<never, { status: EFindingStatus }>(
      `/private/projects/${projectId}/findings/${findingId}/status`,
      { status }
    );
  }

  static async changePaymentStatus(projectId: string, findingId: string, retestPaymentStatus: ERetestPaymentStatus) {
    return InternalApiRequest.patch<never, { retestPaymentStatus: ERetestPaymentStatus }>(
      `/private/projects/${projectId}/findings/${findingId}/payment-status`,
      { retestPaymentStatus }
    );
  }
}
