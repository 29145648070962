import { useState, useEffect } from 'react';

import { Header } from '@app/ui-components';
import { SidebarMenu } from '@app/shared/ui-components/layout/sidebar-menu/SidebarMenu';
import AuthBackgroundImage from '@app/assets/images/auth-background.jpg';
import styles from './AppModule.module.scss';

export const AppModule: React.FC = ({ children }) => {
  const [showBgImage, setShowBgImage] = useState(true);

  useEffect(() => {
    const toggleBgImage = (e: KeyboardEvent) => {
      if (e.altKey && e.key === '∫') {
        setShowBgImage((prevState) => !prevState);
      }
    };

    document.addEventListener('keydown', toggleBgImage, true);
    return () => document.removeEventListener('keydown', toggleBgImage, true);
  }, []);

  return (
    <div>
      {showBgImage ? (
        <img
          src={AuthBackgroundImage}
          className="pointer-events-none fixed -z-10 h-screen w-full object-cover"
          alt=""
        />
      ) : null}
      <div className="mx-auto 2xl:container">
        <div className={styles.root}>
          {!showBgImage && (
            <>
              <div className="pointer-events-none fixed top-[100px] -left-[120px] -z-50 h-[200px] w-[200px] bg-[#0b0d18] blur-[130px]" />
              <div className="pointer-events-none fixed top-[250px] left-[320px] -z-50 h-[100px] w-[100px] bg-[#b95926] blur-[100px]" />
            </>
          )}
          <div className={styles['sidebar']}>
            <SidebarMenu />
          </div>
          <div className={styles['header']} style={showBgImage ? undefined : { background: 'rgba(36, 37, 45, 0.6)' }}>
            <Header />
          </div>
          <div className={styles['content']}>{children}</div>
        </div>
      </div>
    </div>
  );
};
