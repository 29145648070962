import React, { useCallback } from 'react';
import { Button } from '@app/ui-components';
import { preventEvent } from '@app/utils/event-handler.utils';
import { EProjectStatus, MyApplicationOutDto } from '@app/swagger-types';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { RejectedProjectBadge } from '@app/domain/project/components/RejectedProjectBadge';

interface Props {
  onApply: (id: string) => void;
  onUnapply: (id: string) => void;
  project: OverrideProjectOutDto;
  appStatus?: MyApplicationOutDto['status'];
  loading?: boolean;
  disabled?: boolean;
}

export const ProjectApplicationStatusButton: React.FC<Props> = ({
  appStatus,
  project,
  onApply,
  onUnapply,
  loading,
  disabled,
}) => {
  const projectId = project.id;
  const role = useUserRole();

  const handleApply = useCallback(async () => {
    onApply(projectId);
  }, [projectId, onApply]);

  const handleUnapply = useCallback(async () => {
    onUnapply(projectId);
  }, [projectId, onUnapply]);

  if (!role.isTester) {
    if (project.status === EProjectStatus.REJECTED) {
      return <RejectedProjectBadge rejectReason={project.rejectReason} />;
    }
    return null;
  }

  if (!appStatus) {
    return (
      <Button
        variant="outlined"
        color="secondary"
        className="w-28"
        onClick={handleApply}
        loading={loading}
        disabled={disabled}
      >
        Apply
      </Button>
    );
  } else if (appStatus === 'REQUESTED') {
    return (
      <Button
        variant="outlined"
        color="secondary"
        className="w-28 !border border-[#CA3434]/80 bg-[#3F2B33]/60 text-error hover:bg-[#CA3434]/30"
        onClick={handleUnapply}
        loading={loading}
        disabled={disabled}
      >
        Cancel
      </Button>
    );
  } else if (appStatus === 'REJECTED') {
    return (
      <Button
        variant="outlined"
        color="secondary"
        className="w-28 cursor-default !border border-[#CA3434]/80 bg-[#3F2B33]/60 text-error"
        onClick={preventEvent}
      >
        Rejected
      </Button>
    );
  }
  return null;
};
