import React from 'react';
import { Outlet } from 'react-router-dom';
import { Tabs, Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { OutlinedTab } from '@app/shared/ui-components/tabs/OutlinedTab';
import { useGetMyProjectsTotal } from '@app/domain/project/api/hooks/project.api.hook';
import { EProjectStatus } from '@app/swagger-types';
import { ToggleViewButtons } from '@app/shared/ui-components/toggle-view-buttons/ToggleViewButtons';

const tabRoutes = mutable([
  Routes.projects.allProjects,
  Routes.projects.scheduledProjects,
  Routes.projects.liveProjects,
  Routes.projects.completedProjects,
] as const);

export const TesterProjectsModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);
  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to });
  };

  const allProjectsTotal = useGetMyProjectsTotal();
  const scheduledProjectsTotal = useGetMyProjectsTotal(EProjectStatus.SCHEDULED);
  const liveProjectsTotal = useGetMyProjectsTotal(EProjectStatus.LIVE);
  const completedProjectsTotal = useGetMyProjectsTotal(EProjectStatus.COMPLETED);

  return (
    <>
      <div>
        <Typography className="mb-8 text-xxxl font-bold">Project Management</Typography>
        <div className="my-5 flex items-end justify-between">
          <Tabs
            value={value}
            onChange={handleChange}
            className="my-5"
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <OutlinedTab label="All" value={tabRoutes[0]} count={allProjectsTotal} />
            <OutlinedTab label="Scheduled" value={tabRoutes[1]} count={scheduledProjectsTotal} />
            <OutlinedTab label="Live" value={tabRoutes[2]} count={liveProjectsTotal} />
            <OutlinedTab label="Completed" value={tabRoutes[3]} count={completedProjectsTotal} />
          </Tabs>
          <ToggleViewButtons />
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
