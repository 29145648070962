import { EActionLogType } from '@app/swagger-types';

export const renderFriendlyActionLogType = (type: EActionLogType) => {
  switch (type) {
    case EActionLogType.SIGN_IN:
      return 'Sign in';
    case EActionLogType.FP_PASSWORD:
      return 'Forgot password';
    case EActionLogType.GENERATE_REPORT:
      return 'Generate report';
    case EActionLogType.CHANGE_PASSWORD:
      return 'Change password';
    default:
      return 'Unknown';
  }
};
