import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { validateAndAssert } from '@app/services/api-request';
import { FinanceStatsSchema, FindingStatsSchema } from '@app/domain/dashboard/dashboard.schema';

export const DashboardApi = buildApi(
  class _DashboardApi extends ApiCollection {
    async getFinanceStats() {
      return validateAndAssert(this.apiService.get('/private/finance-stats'), FinanceStatsSchema);
    }
    async getFindingStats() {
      return validateAndAssert(this.apiService.get('/private/finding-stats'), FindingStatsSchema);
    }
  }
);
