import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';

import { ConfirmCodeValidationSchema } from '@app/models/auth';

import { TypedLink } from '@app/router';
import { Input, Button, Form } from '@app/ui-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { maybeRenderError } from '@app/utils/maybeRenderError';

interface Props {
  onSubmitCode: (code: string) => void;
  loading: boolean;
  error?: Error | null;
  email: string;
  onBackToLogInPage?: () => void;
}

export const ConfirmCodeForm: React.FC<Props> = ({ onSubmitCode, loading, error, email, onBackToLogInPage }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    clearErrors,
  } = useForm<ConfirmCodeValidationSchema>({
    resolver: yupResolver(ConfirmCodeValidationSchema),
  });

  const onSubmit: SubmitHandler<ConfirmCodeValidationSchema> = ({ confirmCode }) => {
    clearErrors();
    onSubmitCode(confirmCode);
  };

  return (
    <div className="space-y-4">
      <Typography variant="xxxl" className="mb-[14px] font-bold">
        Enter a code
      </Typography>
      <Typography variant="l" className="!mb-[39px] block text-grey5">
        {`We sent the code to the ${email}`}
      </Typography>
      <Form control={control} onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Input
          {...register('confirmCode')}
          label="Code"
          placeholder="Enter Code"
          id="code"
          errorMessage={errors.confirmCode?.message}
          disabled={loading}
          withBackground
        />
        <TypedLink
          to={Routes.auth.sign_in}
          className="block text-s font-bold text-grey5 hover:text-grey6"
          onClick={onBackToLogInPage}
        >
          Back to Log in
        </TypedLink>
        <Button type="submit" variant="outlined" color="gold" fullWidth className="!mt-9 font-medium" loading={loading}>
          Verify Code
        </Button>
        {maybeRenderError(error)}
      </Form>
    </div>
  );
};
