import React from 'react';
import { IconButton, Tooltip } from '@app/ui-components';
import { DeleteIcon, EditIcon } from '@app/assets/icons';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';
import useStore from '@app/store/useStore.hook';
import { TesterOutDto } from '@app/swagger-types';

interface Props {
  findingTitle: string;
  onEdit: () => void;
  onDelete: () => void;
  tester: TesterOutDto;
  disabled?: boolean;
  isDeleting?: boolean;
}

export const FindingActions: React.FC<Props> = ({ findingTitle, onEdit, onDelete, tester, disabled, isDeleting }) => {
  const currentUser = useStore.useCurrentUser();

  if (tester.id !== currentUser?.id) {
    return (
      <div>
        <Tooltip title="You can edit only your own finding" className="cursor-not-allowed">
          <span>
            <IconButton disabled>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="You can delete only your own finding" className="cursor-not-allowed">
          <span>
            <IconButton disabled>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  }

  return (
    <div>
      <IconButton onClick={onEdit} disabled={disabled}>
        <EditIcon />
      </IconButton>
      <ConfirmDeleteDialog
        trigger={
          <IconButton disabled={disabled} loading={isDeleting}>
            <DeleteIcon />
          </IconButton>
        }
        onConfirm={onDelete}
        headerTitle="Delete Finding"
        title={`Are you sure you want to delete “${findingTitle}”?`}
      />
    </div>
  );
};
