import React from 'react';
import { Tooltip } from '@app/ui-components';
import { FindingStatsOutDto } from '@app/swagger-types';
import clsxm from '@app/lib/clsxm';

export const FindingStatsCell: React.FC<{ findingStats?: FindingStatsOutDto; showEmpty?: boolean }> = ({
  findingStats,
  showEmpty = true,
}) => {
  const filtered = [
    { count: findingStats?.requiresAttention, title: 'Requires Attention', color: 'text-orange' },
    { count: findingStats?.informative, title: 'Informative', color: 'text-white' },
    { count: findingStats?.retest, title: 'Re-Test', color: 'text-blue-400' },
    { count: findingStats?.fixed, title: 'Fixed', color: 'text-green' },
  ].filter((e) => Boolean(e.count));
  const preview = filtered.slice(0, 2);
  const nonZero = preview.length > 0;
  const hasMore = filtered.length > preview.length;
  return nonZero ? (
    <Tooltip
      title={
        <div className="flex flex-col justify-center gap-2">
          {filtered.map((e, i) => {
            return (
              <div key={i} className="flex items-center gap-2">
                <div
                  className={clsxm(
                    'flex h-[24px] min-w-[24px] items-center justify-center rounded-full bg-black/60 p-1 font-sans text-xs ring-1 ring-grey4',
                    e.color
                  )}
                >
                  {e.count}
                </div>
                <div className={e.color}>{e.title}</div>
              </div>
            );
          })}
        </div>
      }
    >
      <div className="flex items-center justify-center">
        {preview.map((e, i) => {
          return (
            <div key={i} className={clsxm('first:ml-0', hasMore ? '-ml-1' : 'ml-2')}>
              <div
                className={clsxm(
                  'flex h-[24px] min-w-[24px] items-center justify-center rounded-full bg-black p-1 font-sans text-xs ring-1 ring-grey4',
                  e.color
                )}
              >
                {e.count}
              </div>
            </div>
          );
        })}
        {hasMore && (
          <div
            className={clsxm(
              'flex h-[16px] w-[16px] items-center justify-center rounded-full bg-black p-1 font-sans text-xs leading-zero ring-1 ring-grey4',
              'first:ml-0" -ml-1',
              'pb-[7px]'
            )}
          >
            +
          </div>
        )}
      </div>
    </Tooltip>
  ) : showEmpty ? (
    <Tooltip title="No Findings">
      <div>-</div>
    </Tooltip>
  ) : null;
};
