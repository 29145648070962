import { ApplicationParams } from '@app/domain/application/application.type';
import { OverrideApplicationPayoutStatusInDto } from '@app/swagger-override-types';
import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { validateAndAssert } from '@app/services/api-request';
import { pageableData } from '@app/utils/yup.utils';
import { ApplicationOutDtoSchema } from '@app/domain/application/application.schema';

export const ApplicationApi = buildApi(
  class _ApplicationApi extends ApiCollection {
    async getApplications(params: ApplicationParams) {
      return validateAndAssert(
        this.apiService.get('/private/applications', { params }),
        pageableData(ApplicationOutDtoSchema)
      );
    }
    async updatePayoutStatus(applicationId: string, Dto: OverrideApplicationPayoutStatusInDto) {
      return this.apiService.patch<never, OverrideApplicationPayoutStatusInDto>(
        `/private/applications/${applicationId}/payout-status`,
        Dto
      );
    }
    async delete(applicationId: string) {
      return this.apiService.delete(`/private/applications/${applicationId}`);
    }
  }
);
