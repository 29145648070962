import { Typography } from '@mui/material';

export const UIKitSection: React.FC<{
  title: string;
  wrapperClassName?: string;
}> = ({ title, children, wrapperClassName }) => {
  return (
    <div className={wrapperClassName}>
      <Typography variant={'h4'} className="py-4">
        {title}
      </Typography>
      {children}
    </div>
  );
};
