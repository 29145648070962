import React from 'react';
import PasswordChecklist from 'react-password-checklist';
import clsxm from '@app/lib/clsxm';

export const CustomPasswordChecklist: React.FC<{ value: string }> = ({ value }) => {
  return (
    <PasswordChecklist
      rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
      minLength={8}
      value={value}
      className={clsxm('text-xs', { grayscale: !value })}
    />
  );
};
