import React from 'react';
import clsx from 'clsx';
import { get } from 'lodash';

import clsxm from '@app/lib/clsxm';

import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';

import { ButtonColor } from '../ButtonColors';
import styles from './Button.module.scss';

export type ButtonProps = { color?: ButtonColor; loading?: boolean } & Omit<MuiButtonProps, 'color'>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, color, className, disabled, loading, size, ...props }, ref) => {
    return (
      <MuiButton
        ref={ref}
        disableTouchRipple
        disabled={disabled}
        size={size}
        {...props}
        className={clsxm(
          styles.button,
          get(styles, `${props.variant}-${color}`),
          get(styles, `${size}`),
          get(styles, `${props.variant}`),
          get(styles, clsx({ disabled: disabled })),
          get(styles, clsx({ loading: loading })),
          className
        )}
      >
        {loading && (
          <CircularProgress
            className="visible absolute left-[50%-8px]"
            size={16}
            variant="indeterminate"
            data-loading
          />
        )}
        {children}
      </MuiButton>
    );
  }
);
