import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import clsxm from '@app/lib/clsxm';
import { BreakUnit } from '../../PdfPager';
import styles from '../../pdf-salus.module.scss';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { PDFUtils } from '../../pdf.utils';
import { matchTextByWords } from '@app/utils/string.utils';

export const ReportSectionEngagementDetails: React.FC<{
  project: ProjectOutDtoSchema;
}> = ({ project }) => {
  const startDate = project.startDate ? format(new Date(project.startDate), DATE_FORMAT) : '-';
  const endDate = project.endDate ? format(new Date(project.endDate), DATE_FORMAT) : '-';

  const urlsInScope = project.answers.find((e) => matchTextByWords(e.title, ['url', 'in', 'scope']));
  const targets = urlsInScope ? urlsInScope.value.split(/\s+/) : [project.name];

  return (
    <>
      {/* Don't render page break because it is first page inside PdfPager */}
      {/* <BreakUnit pageBreak /> */}
      <BreakUnit header>
        <div className={styles.content}>
          <div className={styles.pageHeader} id="engagement_details">
            Engagement Details
          </div>
          <div className={styles.divider} />
          <div className={styles.p}>
            {`This document describes the ${PDFUtils.normalizeProductTitle(
              project.productTitle
            )} penetration testing results of ${
              project.client.companyName
            }. The purpose of this engagement was to examine the security posture of ${project.client.companyName}'s ${
              project.name
            } from the view point of a malicious actors and identify potential weaknesses that could undermine the targets security and integrity.`}
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.sectionHeader}>Scope of Work</div>
          <div className={styles.divider} />
          <div className={styles.p}>
            {`This security assessment covers ${PDFUtils.normalizeProductTitle(
              project.productTitle
            )} penetration testing of the following targets.`}
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <table className={styles.table}>
            <tbody>
              <tr>
                <th>Target</th>
                <th>Type</th>
              </tr>
              {targets.map((e, i) => (
                <tr key={i}>
                  <td>{e}</td>
                  <td>{project.productTitle}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.sectionHeader}>Rules of Engagement</div>
          <div className={styles.divider} />
          <div className={styles.p}>{`The following rules were followed during the execution of this engagement.`}</div>
          <div className={styles.p}>
            <ul className={styles.ul}>
              <li>Denial of Service testing was out of scope.</li>
              <li>
                Attacks involving contacting of employees, customers and vendors and any vector relating to social
                engineering was not in scope.
              </li>
              <li>
                Penetration testing traffic was originated from the following IP addresses:
                <ul>
                  <li>66.115.166.181</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.sectionHeader}>Constraints</div>
          <div className={styles.divider} />
          <div className={styles.p}>
            {project.comment || `No constraints were encountered during the course of this engagement`}
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.sectionHeader}>Timeline</div>
          <div className={styles.divider} />
          <br />
          <div className={styles.py}>
            <div className={clsxm(styles.timeline)}>
              <div className="flex justify-evenly text-center">
                <div className="relative">
                  <div className={styles.circle}>
                    <div />
                  </div>
                  <div>Start Date</div>
                  <div className="font-bold text-white">{startDate}</div>
                </div>
                <div className="relative">
                  <div className={styles.circle}>
                    <div />
                  </div>
                  <div>End Date</div>
                  <div className="font-bold text-white">{endDate}</div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </BreakUnit>
    </>
  );
};
