import React from 'react';

import { InputExamples } from '@app/modules/ui-kit/pages/components/InputExamples';
import { ButtonExamples } from '@app/modules/ui-kit/pages/components/ButtonExamples';
import DemoForMethodologyStatBox from './project-components/DemoForMethodologyStatBox';
import DemoForProjectTeamBox from '@app/modules/ui-kit/pages/project-components/DemoForProjectTeamBox';
import DemoForProjectTimelineBox from '@app/modules/ui-kit/pages/project-components/DemoForProjectTimelineBox';
import DemoForProjectBonusInfoBox from '@app/modules/ui-kit/pages/project-components/DemoForProjectBonusInfoBox';
import DemoForProjectInfoBox from '@app/modules/ui-kit/pages/project-components/DemoForProjectInfoBox';
import DemoForDatePickerRange from '@app/modules/ui-kit/pages/components/DemoForDatePickerRange';
import { CvssCalculator } from '@app/shared/ui-components/cvss-calculator/CvssCalculator';
import { UIKitSection } from '@app/modules/ui-kit/pages/UIKitSection';
import DemoForClientsPaymentsBox from '@app/modules/ui-kit/pages/project-components/DemoForClientsPaymentsBox';
import { DemoForProjectProgressBox } from '@app/modules/ui-kit/pages/project-components/DemoForProjectProgressBox';
import DemoForPieChart from '@app/modules/ui-kit/pages/project-components/DemoForPieChart';
import DemoForRichEditor from '@app/modules/ui-kit/pages/project-components/DemoForRichEditor';

export const UIKitPage = () => {
  return (
    <div>
      <DemoForRichEditor />
      <UIKitSection title="Cvss Calculator">
        <CvssCalculator />
      </UIKitSection>
      <div className={'max-w-lg'}>
        <DemoForPieChart />
        <DemoForProjectProgressBox />
        <DemoForProjectInfoBox />
        <DemoForDatePickerRange />
        <ButtonExamples />
        <div className={'mb-4 max-w-sm'}>
          <InputExamples />
        </div>
        <DemoForProjectTimelineBox />
        <DemoForMethodologyStatBox />
        <DemoForProjectTeamBox />
        <DemoForProjectBonusInfoBox />
        <DemoForClientsPaymentsBox />
      </div>
    </div>
  );
};
