import { Routes } from '@app/constants/routes';

import { ERole } from '@app/swagger-types';
import { MyRouteObject } from '@app/models/router';
import { TypedNavigate } from '@app/router';
import { AdminModule } from './AdminModule';
import { UserManagementRoutes } from './modules/user-management/UserManagementRoutes';
import { ProjectPage } from '@app/modules/projects/pages/Project.page';
import { FindingPage } from '@app/domain/pages/FindingPage';
import { FinanceRoutes } from '@app/modules/admin/modules/finance/FinanceRoutes';
import { MyProfilePage } from '@app/modules/admin/modules/user-management/pages/MyProfile.page';
import { TesterProfilePage } from '@app/modules/admin/modules/user-management/pages/TesterProfile.page';
import { ClientProfilePage } from '@app/modules/admin/modules/user-management/pages/ClientProfile.page';
import { AdminProfilePage } from '@app/modules/admin/modules/user-management/pages/AdminProfile.page';
import { SettingsRoutes } from '@app/modules/admin/modules/settings/SettingsRoutes';
import { AdminProjectsRoutes } from '@app/modules/admin/modules/projects/AdminProjectsRoutes';
import { CollaboratorProfilePage } from '@app/modules/admin/modules/user-management/pages/CollaboratorProfile.page';
import { ProductsAndReportsPage } from '@app/modules/admin/pages/ProductsAndReports.page';
import { EditTesterProfilePage } from '@app/modules/admin/modules/user-management/pages/EditTesterProfile.page';
import { ProjectQuestionnairePage } from '@app/modules/projects/pages/ProjectQuestionnaire.page';

export const AdminRoutes: MyRouteObject = {
  path: Routes.index,
  element: <AdminModule />,
  role: [ERole.ROLE_ADMIN],
  children: [
    { path: Routes.projects.project, element: <ProjectPage /> },
    { path: Routes.projects.questionnaire, element: <ProjectQuestionnairePage /> },
    { path: Routes.projects.finding, element: <FindingPage /> },
    { path: Routes.admin.user_management.tester_profile, element: <TesterProfilePage /> },
    { path: Routes.admin.user_management.client_profile, element: <ClientProfilePage /> },
    { path: Routes.admin.user_management.collaborator_profile, element: <CollaboratorProfilePage /> },
    { path: Routes.admin.user_management.admin_profile, element: <AdminProfilePage /> },
    { path: Routes.admin.user_management.my_profile, element: <MyProfilePage /> },
    { path: Routes.productsReports.index, element: <ProductsAndReportsPage /> },
    { path: Routes.admin.user_management.edit_tester_profile, element: <EditTesterProfilePage /> },
    SettingsRoutes,
    FinanceRoutes,
    UserManagementRoutes,
    AdminProjectsRoutes,
    { index: true, path: '*', element: <TypedNavigate to={Routes.projects.allProjects} /> },
  ],
};
