import React, { useState } from 'react';
import { FindProjectItem } from '@app/domain/project/components/FindProjectItem';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { useQueryClient } from 'react-query';
import { GET_NOT_ASSIGNED_PROJECTS_QUERY, GET_APPLIED_PROJECTS_QUERY } from '@app/constants/query-api-configs';
import { mutable } from '@app/utils/type.utils';
import { Routes } from '@app/constants/routes';
import { useMatchActiveRoute } from '@app/router';
import { NoProjectsItem } from '@app/domain/project/components/NoProjectsItem';
import { useApplyToProject, useUnapplyFromProject } from '@app/domain/project/api/hooks/project.api.hook';

const routes = mutable([Routes.findProjects.all, Routes.findProjects.applied] as const);

export const FindProjectList: React.FC<{
  projects?: OverrideProjectOutDto[];
  loading?: boolean;
}> = ({ projects, loading }) => {
  const [projectLoadingId, setProjectLoadingId] = useState<string>();
  const activeRoute = useMatchActiveRoute(routes);

  const queryClient = useQueryClient();

  const handleApplyOrUnapplySuccess = async () => {
    if (activeRoute === Routes.findProjects.all) {
      // invalidate data in 'Applied tab
      await queryClient.invalidateQueries(GET_APPLIED_PROJECTS_QUERY.name);
      await queryClient.invalidateQueries(GET_NOT_ASSIGNED_PROJECTS_QUERY.name);
      queryClient.removeQueries(GET_APPLIED_PROJECTS_QUERY.name);
    } else {
      await queryClient.invalidateQueries(GET_APPLIED_PROJECTS_QUERY.name);
      queryClient.removeQueries(GET_NOT_ASSIGNED_PROJECTS_QUERY.name);
    }
  };

  const { mutate: applyProject } = useApplyToProject({
    onSuccess: handleApplyOrUnapplySuccess,
    onMutate: (id) => {
      setProjectLoadingId(id);
    },
    onSettled: () => {
      setProjectLoadingId(undefined);
    },
  });

  const { mutate: unapplyProject } = useUnapplyFromProject({
    onSuccess: handleApplyOrUnapplySuccess,
    onMutate: (id) => {
      setProjectLoadingId(id);
    },
    onSettled: () => {
      setProjectLoadingId(undefined);
    },
  });

  if (loading && !projects) {
    return <ContainerLoader loading={loading} />;
  }

  return (
    <div>
      {Boolean(projects?.length) ? (
        projects?.map((project) => (
          <FindProjectItem
            key={project.id}
            project={project}
            onApply={applyProject}
            onUnapply={unapplyProject}
            loading={project.id === projectLoadingId}
            disabled={project.id !== projectLoadingId && Boolean(projectLoadingId)}
          />
        ))
      ) : (
        <NoProjectsItem />
      )}
    </div>
  );
};
