import { useState } from 'react';
import axios from 'axios';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { UIKitSection } from '../UIKitSection';
import { ProjectTeamBox } from '@app/shared/ui-components/project-team-box/ProjectTeamBox';
import { createUiKitClient } from '@app/modules/ui-kit/pages/mock-data/team.data';
import { createUiKitProject } from '@app/modules/ui-kit/pages/mock-data/project.data';

enum EMode {
  HAS_TESTERS = 'HAS_TESTERS',
  NO_TESTERS = 'NO_TESTERS',
}

export default function DemoForProjectTeamBox() {
  const [mode, setMode] = useState(EMode.HAS_TESTERS);

  const mockAssignTester = () => axios.get('/');

  return (
    <UIKitSection title="ProjectTeamBox">
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setMode(e.currentTarget.value as EMode);
        }}
      >
        <FormControlLabel value={EMode.HAS_TESTERS} control={<Radio />} label="Has testers" />
        <FormControlLabel value={EMode.NO_TESTERS} control={<Radio />} label="No testers" />
      </RadioGroup>
      {mode === EMode.HAS_TESTERS && (
        <ProjectTeamBox
          project={createUiKitProject({
            client: createUiKitClient(),
          })}
          onAssignTester={mockAssignTester}
        />
      )}
      {mode === EMode.NO_TESTERS && (
        <ProjectTeamBox
          project={createUiKitProject({ client: createUiKitClient(), testers: [] })}
          onAssignTester={mockAssignTester}
        />
      )}
    </UIKitSection>
  );
}
