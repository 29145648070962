import React from 'react';
import { Chip, Link, Typography } from '@mui/material';

import { Button, IconButton, Tooltip } from '@app/ui-components';
import clsxm from '@app/lib/clsxm';
import { EditIcon, GitHubIcon, LinkedinIcon } from '@app/assets/icons';
import { TesterDto } from '@app/models/user';
import AddIcon from '@mui/icons-material/Add';
import { normalizeUrlWithProtocol } from '@app/utils/api.util';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';

interface Props {
  tester: TesterDto;
  disabled?: boolean;
  isMyProfile?: boolean;
}

export const TesterAdditionalInfoBox: React.FC<Props> = ({ tester, disabled, isMyProfile }) => {
  const { isAdmin } = useUserRole();
  const { github, linkedin, certifications, skills, id: testerId } = tester;

  const canEditProfile = isAdmin || isMyProfile;

  const wrapInLinkToTesterPage = (children: React.ReactNode) => {
    if (isMyProfile) {
      return <TypedLink to={Routes.admin.user_management.edit_my_profile}>{children}</TypedLink>;
    }
    return (
      <TypedLink to={Routes.admin.user_management.edit_tester_profile} params={{ id: testerId }}>
        {children}
      </TypedLink>
    );
  };

  const isEmptyProfile =
    !linkedin && !github && (!skills || !skills.length) && (!certifications || !certifications.length);

  const renderSocialMedia = () => (
    <div className="mb-12">
      <Typography className="mb-7 text-xl font-bold">Social Media</Typography>
      <div className="flex gap-6">
        {linkedin && (
          <div className="flex-[6]">
            <div className="mb-2 flex items-center">
              <LinkedinIcon className="mr-2" />
              <Typography className="text-xs font-bold">LinkedIn</Typography>
            </div>
            <Link
              href={normalizeUrlWithProtocol(linkedin)}
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer truncate text-xs font-light"
            >
              {linkedin}
            </Link>
          </div>
        )}
        {github && (
          <div className="flex-[6]">
            <div className="mb-2 flex items-center">
              <GitHubIcon className="mr-2" />
              <Typography className="text-xs font-bold">GitHub</Typography>
            </div>
            <Link
              href={normalizeUrlWithProtocol(github)}
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer truncate text-xs font-light"
            >
              {github}
            </Link>
          </div>
        )}
      </div>
    </div>
  );

  const renderEmptyInfo = () => {
    if (!canEditProfile) {
      return (
        <>
          <Typography className="mb-7 text-xl font-bold">Profile info</Typography>
          <Typography className="mb-5 max-w-[372px] text-xs text-grey5">No info yet</Typography>
        </>
      );
    }
    return (
      <div>
        <Typography className="mb-7 text-xl font-bold">Profile info</Typography>
        <Typography className="mb-5 max-w-[360px] text-xs text-grey5">
          Your profile is <span className="font-bold">incomplete</span>.<br /> Please fill out the profile so the admin
          is more likely to <span className="font-bold">approve</span> you and you can work on projects
        </Typography>
        {wrapInLinkToTesterPage(
          <Button variant="outlined" color="primary" size="medium" startIcon={<AddIcon />}>
            Fill in profile
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={clsxm('  relative py-10 px-14', {
          'pointer-events-none opacity-20': disabled,
        })}
      >
        {canEditProfile &&
          wrapInLinkToTesterPage(
            <IconButton className="absolute top-[24px] right-[44px] bg-grey2">
              <EditIcon />
            </IconButton>
          )}
        {isEmptyProfile ? (
          <>{renderEmptyInfo()}</>
        ) : (
          <>
            {(github || linkedin) && renderSocialMedia()}
            <div className="flex gap-6">
              {certifications && Boolean(certifications.length) && (
                <div className="flex-[6]">
                  <Typography className="mb-7 text-xl font-bold">Certifications</Typography>
                  {certifications.map(({ name, id, abbreviation }) => (
                    <Tooltip key={id} title={name}>
                      <Chip label={abbreviation} className="mb-2 rounded-[4px]" />
                    </Tooltip>
                  ))}
                </div>
              )}
              {skills && Boolean(skills.length) && (
                <div className="flex-[6]">
                  <Typography className="mb-7 text-xl font-bold">Skills</Typography>
                  {skills.map(({ name, id }) => (
                    <Chip key={id} label={name} className="mb-2" />
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
