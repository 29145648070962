import React, { useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { SelectChangeEvent, Typography } from '@mui/material';

import { GET_ACTION_LOGS_QUERY } from '@app/constants/query-api-configs';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { OptionItem, Select, Table, TableColumn } from '@app/ui-components';
import { ActionLogOutDtoSchema } from '@app/domain/action-log/action-log.form';
import { DATE_TIME_FORMAT } from '@app/constants/date';
import { renderFriendlyActionLogType } from '@app/domain/action-log/action-log.utils';
import { EActionLogType, ERole } from '@app/swagger-types';
import { useQueryParams } from 'use-query-params';
import { StringParam } from '@app/utils/use-query-params.utils';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { useGetActionLogs } from '@app/domain/action-log/api/hooks/action-log.api.hook';
import { PAGE_SIZE } from '@app/constants/api';

const ALL_ACTIONS_OPTION = {
  label: 'All actions',
  value: 'ALL_ACTIONS',
};

export const LogsTable: React.FC<{
  title: string;
  myLogs?: boolean;
  role?: ERole;
}> = ({ myLogs, title, role }) => {
  const [{ actionType }, setQuery] = useQueryParams({
    actionType: StringParam,
  });

  const {
    values: { page, sort = GET_ACTION_LOGS_QUERY.defaultSort, size },
    handlers: { handlePageChange, handleSortChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      size: PAGE_SIZE,
    },
    prefix: GET_ACTION_LOGS_QUERY.tablePrefix,
  });

  const { data, isFetching } = useGetActionLogs(
    {
      page: Math.ceil((page || 1) - 1),
      sort,
      type: actionType,
      size,
      role,
    },
    { keepPreviousData: true }
  );

  const columns: TableColumn<ActionLogOutDtoSchema>[] = useMemo(
    () => [
      {
        headerName: 'Date & Time',
        field: 'createdDate',
        align: 'left',
        render: ({ createdDate }) => format(new Date(createdDate), DATE_TIME_FORMAT),
      },
      {
        headerName: 'Action',
        field: 'type',
        align: 'left',
        width: 650,
        render: ({ type, message }) => {
          let value = renderFriendlyActionLogType(type);
          if (message) {
            value = `${value}: ${message}`;
          }
          return value;
        },
      },
      {
        headerName: 'User',
        field: 'user.firstName',
        align: 'left',
        render: ({ user }) => <UserBlock user={user} navigateToMyProfile={myLogs} />,
      },
    ],
    [myLogs]
  );

  const leftHeaderContent = useMemo(() => <Typography className="text-xxl font-bold">{title}</Typography>, [title]);

  const handleActionChange = useCallback(
    (e: SelectChangeEvent) => {
      if (e.target.value === ALL_ACTIONS_OPTION.value) {
        setQuery({ actionType: undefined }, 'replaceIn');
        return;
      }
      setQuery({ actionType: e.target.value }, 'replaceIn');
    },
    [setQuery]
  );

  const rightHeaderContent = useMemo(
    () => (
      <Select
        defaultValue={ALL_ACTIONS_OPTION.value}
        value={actionType}
        onChange={handleActionChange}
        className="min-w-[172px]"
      >
        <OptionItem value={ALL_ACTIONS_OPTION.value}>{ALL_ACTIONS_OPTION.label}</OptionItem>
        <OptionItem value={EActionLogType.SIGN_IN}>{renderFriendlyActionLogType(EActionLogType.SIGN_IN)}</OptionItem>
        <OptionItem value={EActionLogType.FP_PASSWORD}>
          {renderFriendlyActionLogType(EActionLogType.FP_PASSWORD)}
        </OptionItem>
        <OptionItem value={EActionLogType.CHANGE_PASSWORD}>
          {renderFriendlyActionLogType(EActionLogType.CHANGE_PASSWORD)}
        </OptionItem>
        <OptionItem value={EActionLogType.GENERATE_REPORT}>
          {renderFriendlyActionLogType(EActionLogType.GENERATE_REPORT)}
        </OptionItem>
      </Select>
    ),
    [actionType, handleActionChange]
  );

  return (
    <Table
      cols={columns}
      tableData={data?.result || []}
      total={data?.total}
      loading={isFetching}
      onPagination={handlePageChange}
      onSortChanged={handleSortChange}
      currentSort={sort}
      currentPage={page}
      pageSize={size}
      hidePageSize
      wrapperClassName="max-w-[930px]"
      leftHeaderContent={leftHeaderContent}
      rightHeaderContent={rightHeaderContent}
    />
  );
};
