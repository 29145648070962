import { Routes } from '@app/constants/routes';

import { FinanceModule } from './FinanceModule';
import { TypedNavigate } from '@app/router';
import { InvoicesPage } from '@app/modules/admin/modules/finance/pages/InvoicesPage';
import { NeedToPayPage } from '@app/modules/admin/modules/finance/pages/NeedToPayPage';
import { PaidOutPage } from '@app/modules/admin/modules/finance/pages/PaidOutPage';
import { ReTestBonusesPage } from '@app/domain/finding/ReTestBonuses.page';

export const FinanceRoutes = {
  path: Routes.finance.index,
  element: <FinanceModule />,
  children: [
    { path: Routes.finance.invoices, element: <InvoicesPage /> },
    { path: Routes.finance.needToPay, element: <NeedToPayPage /> },
    { path: Routes.finance.paidOut, element: <PaidOutPage /> },
    { path: Routes.finance.retestBonuses, element: <ReTestBonusesPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.finance.invoices} /> },
  ],
};
