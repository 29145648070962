import NumberFormat from 'react-number-format';

/**
 * Workaround to avoid call crash when event undefined due to https://github.com/mui/material-ui/issues/16402
 */
{
  const proto: { onBlur?: (e?: React.SyntheticEvent) => void } = NumberFormat.prototype as any;
  const originalBlur = proto.onBlur;
  proto.onBlur = function (e) {
    e && originalBlur?.call(this, e);
  };
}
