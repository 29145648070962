import React from 'react';
import { useQuery } from 'react-query';

import { GET_MY_PROFILE_QUERY } from '@app/constants/query-api-configs';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { UserProfileApi } from '@app/domain/user-profile/user-profile.api';
import { ERole } from '@app/swagger-types';
import { UserDto } from '@app/models/auth';
import { MyTesterProfile } from '@app/domain/user-profile/MyTesterProfile';
import { MyClientProfile } from '@app/domain/user-profile/MyClientProfile';
import { MyAdminProfile } from '@app/domain/user-profile/MyAdminProfile';
import { MyCollaboratorProfile } from '@app/domain/user-profile/MyCollaboratorProfile';

export const MyProfilePage: React.FC = () => {
  const {
    isFetching,
    data: user,
    error,
  } = useQuery<UserDto, Error>(
    GET_MY_PROFILE_QUERY.name,
    async () => {
      const { data } = await UserProfileApi.getMe();
      return data;
    },
    { keepPreviousData: true, staleTime: GET_MY_PROFILE_QUERY.config.staleTime }
  );

  if (!user || (isFetching && !user) || error) {
    return <ContainerLoader loading={isFetching} error={error} noData={!user} />;
  }

  if (user.role === ERole.ROLE_TESTER) {
    return <MyTesterProfile tester={user} />;
  }
  if (user.role === ERole.ROLE_CLIENT) {
    return <MyClientProfile client={user} />;
  }
  if (user.role === ERole.ROLE_ADMIN) {
    return <MyAdminProfile admin={user} />;
  }
  if (user.role === ERole.ROLE_COLLABORATOR) {
    return <MyCollaboratorProfile collaborator={user} />;
  }

  return <div>Profile</div>;
};
