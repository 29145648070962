import { EInvoiceStatus, EPayoutStatus } from '@app/swagger-types';

import { OverrideProjectOutDto } from '@app/swagger-override-types';

export const getFriendlyProjectStatus = (status: NonNullable<OverrideProjectOutDto['status']>) => {
  switch (status) {
    case 'PENDING':
      return { label: 'Pending', color: '#6E9FFF' };
    case 'APPROVED':
      return { label: 'Approved', color: '#6E9FFF' };
    case 'REJECTED':
      return { label: 'Rejected', color: '#ff6b6b' };
    case 'COMPLETED':
      return { label: 'Completed', color: '#BABAC9' };
    case 'SCHEDULED':
      return { label: 'Scheduled', color: '#FF9141' };
    case 'LIVE':
      return { label: 'Live', color: '#39AF26' };
    default:
      return { label: 'Unknown', color: '#BABAC9' };
  }
};

export const getFriendlyPaymentStatus = (status: EPayoutStatus) => {
  switch (status) {
    case EPayoutStatus.PENDING:
      return { label: 'Pending', color: '#FF9141' };
    case EPayoutStatus.PAID:
      return { label: 'Paid', color: '#39AF26' };
    default:
      return { label: 'Unknown', color: '#BABAC9' };
  }
};
export const getFriendlyInvoiceStatus = (status: EInvoiceStatus) => {
  switch (status) {
    case EInvoiceStatus.PENDING:
      return { label: 'Pending', color: '#FF9141' };
    case EInvoiceStatus.PAID:
      return { label: 'Paid', color: '#39AF26' };
    case EInvoiceStatus.CANCELED:
      return { label: 'Canceled', color: '#ff6b6b' };
    default:
      return { label: 'Unknown', color: '#BABAC9' };
  }
};
