import React from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { Button } from '@app/ui-components';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { isNumber } from 'lodash';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { getFriendlyProjectStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { Status } from '@app/shared/ui-components/status/Status';
import { renderFriendlyFindingStatus } from '@app/domain/finding/findings.utils';
import { EFindingStatus } from '@app/swagger-types';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';

interface Props {
  project: OverrideProjectOutDto;
}

export const LiveProjectItem: React.FC<Props> = ({
  project: {
    id,
    name,
    productTitle,
    startDate,
    endDate,
    status,
    priceForTester,
    priceForClient,
    findingStats,
    usdToGbpRate,
    isD2NA,
  },
}) => {
  const navigate = useTypedNavigate();
  const { isTester, isClient } = useUserRole();

  const navigateToProjectPage = () => {
    navigate({ to: Routes.projects.project, params: { id } });
  };

  const renderStatus = (status: EFindingStatus, count?: number) => {
    const { label, color } = renderFriendlyFindingStatus(status);

    let chipBackgroundColor = '#424550';

    if (isTester && Boolean(count) && status === EFindingStatus.RETEST) {
      chipBackgroundColor = '#a40013';
    } else if (isClient && Boolean(count) && status === EFindingStatus.REQUIRES_ATTENTION) {
      chipBackgroundColor = '#a40013';
    }

    return (
      <Button className="group relative mr-8 duration-300">
        <Status label={label} color={color} className="grayscale group-hover:grayscale-0" />
        <span
          className="absolute top-[-9px] right-[-9px] flex h-[17px] w-[17px] items-center justify-center rounded-full text-xxs"
          style={{
            backgroundColor: chipBackgroundColor,
          }}
        >
          {count || 0}
        </span>
      </Button>
    );
  };

  return (
    <span
      role="button"
      onClick={navigateToProjectPage}
      onKeyDown={navigateToProjectPage}
      tabIndex={0}
      className="flex w-full items-center justify-between border-b border-b-grey4 pt-6  pb-6 first:pt-0 last:border-b-0 last:pb-0"
    >
      <div>
        <div className="mb-3 flex items-end">
          <Typography className="mr-3 text-m font-bold">{name}</Typography>
          <Status {...getFriendlyProjectStatus(status)} />
        </div>
        <div className="flex items-center">
          <Typography className="text-xs">{productTitle}</Typography>
          {isClient && isNumber(priceForClient) && (
            <Typography className="flex items-center text-xs">
              <span className="mx-2.5 rounded-full bg-grey4" style={{ height: 4, width: 4 }} />
              {isD2NA ? createGBPformatter(usdToGbpRate).formatGBP(priceForClient) : formatDollars(priceForClient)}
            </Typography>
          )}
          {isTester && isNumber(priceForTester) && (
            <Typography className="flex items-center text-xs">
              <span className="mx-2.5 rounded-full bg-grey4" style={{ height: 4, width: 4 }} />
              {formatDollars(priceForTester)}
            </Typography>
          )}
          {startDate && endDate && (
            <Typography className="flex items-center text-xs">
              <span className="mx-2.5 rounded-full bg-grey4" style={{ height: 4, width: 4 }} />
              {`${format(new Date(startDate), DATE_FORMAT)} - ${format(new Date(endDate), DATE_FORMAT)}`}
            </Typography>
          )}
        </div>
      </div>
      <div>
        {renderStatus(EFindingStatus.REQUIRES_ATTENTION, findingStats?.requiresAttention)}
        {renderStatus(EFindingStatus.RETEST, findingStats?.retest)}
      </div>
    </span>
  );
};
