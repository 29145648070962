import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Typography } from '@mui/material';
import clsxm from '@app/lib/clsxm';

export const BaseStatBox: React.FC<{
  data?: {
    name: string;
    value: number;
    color: string;
  }[];
  disableAnimation?: boolean;
  className?: string;
  hideHeader?: boolean;
}> = ({ data, disableAnimation = false, className }) => {
  return (
    <div className={clsxm('flex h-full max-h-screen w-full flex-col rounded-[10px] bg-[#3E414F]/30', className)}>
      {!data && (
        <div className="flex h-full items-center justify-center text-xs font-light text-grey6">
          <Typography>No info yet</Typography>
        </div>
      )}
      {data && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            barCategoryGap="25%"
            margin={{
              top: 20,
              right: 48,
              bottom: 32,
            }}
          >
            <CartesianGrid vertical={false} stroke="#424550" />
            <XAxis
              dataKey="name"
              style={{
                fontSize: 15,
                fontWeight: 700,
              }}
              tickLine={false}
              axisLine={false}
              stroke={'#BABAC9'}
            />
            <YAxis
              allowDecimals={false}
              tickCount={8}
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: 10,
                fontWeight: 700,
              }}
              stroke={'#BABAC9'}
            />
            <Bar
              dataKey="value"
              radius={[5, 5, 0, 0]}
              label={{ fill: '#ffffff', fontSize: 10 }}
              isAnimationActive={!disableAnimation}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
