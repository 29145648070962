import { Outlet } from 'react-router-dom';
import { Typography } from '@mui/material';

import styles from './UIKitModule.module.scss';
import { TypedLink } from '@app/router';

export const UIKitModule = () => {
  return (
    <div className={styles.root}>
      <div className={styles['app-content']}>
        <main className={'w-full p-6'}>
          <TypedLink to="/" className="my-8 mx-auto block text-center text-sky-500 underline hover:ring-1">
            Go Home
          </TypedLink>
          <Typography variant={'h3'}>UI Kit samples</Typography>
          <Outlet />
        </main>
      </div>
    </div>
  );
};
