import { PaletteMode } from '@mui/material';

export enum DeviceBreakpointType {
  PHONE = 'phone',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  UNKNOWN = 'unknown',
}

export type MainUISlice = {
  navMobileOpen: boolean;
  setNavMobile: (navMobileOpen: boolean) => void;

  isGlobalLoading: boolean;
  setIsGlobalLoading: (isLoading: boolean) => void;
  theme: PaletteMode;
  setTheme: (theme: PaletteMode) => void;
  currentBreakpointDevice: {
    deviceType: DeviceBreakpointType;
    isMobile: boolean;
    isDesktop: boolean;
    isTablet: boolean;
  };
  updateBreakpoint: (deviceType: DeviceBreakpointType) => void;
};
