import React from 'react';

import { UIKitSection } from '../UIKitSection';
import { DatePickerRange } from '@app/shared/ui-components/date-picker-range/DatePickerRange';

export default function DemoForDatePickerRange() {
  return (
    <UIKitSection title="Date Picker Range">
      <div className="w-fit">
        <DatePickerRange />
      </div>
    </UIKitSection>
  );
}
