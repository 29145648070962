import { InferType, object, string } from 'yup';
import { email, url } from '@app/utils/yup.utils';

export const TesterAddressSchema = object({
  address: string().max(256, 'Address must be at most 256 characters').required('Required Field'),
});

export type TesterAddressSchema = InferType<typeof TesterAddressSchema>;

export const TesterProfileSchema = object({
  firstName: string().required('Required Field'),
  lastName: string().required('Required Field'),
  paypalEmail: email(),
  country: string().required('Required Field'),
  github: url(),
  linkedin: url(),
}).concat(TesterAddressSchema);

export type TesterProfileSchema = InferType<typeof TesterProfileSchema>;

export const ClientAddressSchema = object({
  companyAddress: string().max(256, 'Address must be at most 256 characters').required('Required Field'),
  companyName: string().max(256, 'Address must be at most 256 characters').required('Required Field'),
});

export type ClientAddressSchema = InferType<typeof ClientAddressSchema>;

export const ClientProfileSchema = object({
  firstName: string().required('Required Field'),
  lastName: string().required('Required Field'),
}).concat(ClientAddressSchema);

export type ClientProfileSchema = InferType<typeof ClientProfileSchema>;

export const CollaboratorProfileSchema = object({
  firstName: string().required('Required Field'),
  lastName: string().required('Required Field'),
  companyName: string().optional(),
});

export type CollaboratorProfileSchema = InferType<typeof CollaboratorProfileSchema>;

export const AdminProfileSchema = object({
  firstName: string().required('Required Field'),
  lastName: string().required('Required Field'),
});

export type AdminProfileSchema = InferType<typeof AdminProfileSchema>;
