import { ClientDto, CollaboratorOutSchema, GetUsersParams, TesterDto } from '@app/models/user';
import { pageableData } from '@app/utils/yup.utils';
import { InternalApiRequest, validateAndAssert } from '@app/services/api-request';
import {
  ERole,
  EUserStatus,
  InvitationDto,
  TesterInDto,
  ClientInDto,
  UserInDto,
  CollaboratorInDto,
} from '@app/swagger-types';
import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { UserDto } from '@app/models/auth';
import { SuperAdminDto } from '@app/models/super-admin';

export const UserApi = buildApi(
  class _UserApi extends ApiCollection {
    async getUsers(params: GetUsersParams) {
      return validateAndAssert(
        this.apiService.get('/private/users', {
          params,
        }),
        pageableData(UserDto)
      );
    }
    async getClients(params: Omit<GetUsersParams, 'role'>) {
      return validateAndAssert(
        this.apiService.get('/private/users', {
          params: { ...params, role: ERole.ROLE_CLIENT },
        }),
        pageableData(ClientDto)
      );
    }
    async inviteUser(Dto: InvitationDto) {
      return this.apiService.post<never, InvitationDto>('/private/users/invite', Dto);
    }
    async getTesters(params: Omit<GetUsersParams, 'role'>) {
      return validateAndAssert(
        this.apiService.get('/private/users', {
          params: { ...params, role: ERole.ROLE_TESTER },
        }),
        pageableData(TesterDto)
      );
    }
    async getTester(testerId: string) {
      return InternalApiRequest.get<TesterDto>(`/private/testers/${testerId}`);
    }
    async getClient(clientId: string) {
      return InternalApiRequest.get<ClientDto>(`/private/clients/${clientId}`);
    }
    async getAdmin(adminId: string) {
      return InternalApiRequest.get<SuperAdminDto>(`/private/admins/${adminId}`);
    }
    async getCollaborator(collaboratorId: string) {
      return InternalApiRequest.get<CollaboratorOutSchema>(`/private/collaborators/${collaboratorId}`);
    }
    async updateTesterProfile(testerId: string, Dto: Partial<TesterInDto>) {
      return this.apiService.patch<TesterDto, Partial<TesterInDto>>(`/private/testers/${testerId}`, Dto);
    }
    async updateClientProfile(clientId: string, Dto: Partial<ClientInDto>) {
      return this.apiService.patch<ClientDto, Partial<ClientInDto>>(`/private/clients/${clientId}`, Dto);
    }
    async updateCollaboratorProfile(collaboratorId: string, Dto: Partial<CollaboratorInDto>) {
      return this.apiService.patch<CollaboratorOutSchema>(`/private/collaborators/${collaboratorId}`, Dto);
    }
    async updateAdminProfile(adminId: string, Dto: Partial<UserInDto>) {
      return this.apiService.patch<SuperAdminDto, Partial<UserInDto>>(`/private/admins/${adminId}`, Dto);
    }
    async updateUserStatus(testerId: string, Dto: { status: EUserStatus }) {
      return this.apiService.patch<UserDto, { status: EUserStatus }>(`/private/users/${testerId}/status`, Dto);
    }
    async syncMySlack() {
      return this.apiService.patch<void>(`/private/auth/sync-my-slack`);
    }
  }
);
