import React from 'react';
import { Tab, TabProps } from '@mui/material';
import { isNumber } from 'lodash';

import { DATA_VARIANT_OUTLINED } from '@app/styles/data-variant.const';
import clsxm from '@app/lib/clsxm';

export const OutlinedTab: React.FC<TabProps & { count?: number; warn?: boolean }> = ({
  count,
  warn,
  label,
  ...props
}) => {
  return (
    <Tab
      {...props}
      data-variant={DATA_VARIANT_OUTLINED}
      label={
        isNumber(count) ? (
          <span className="flex items-center">
            {label}
            <span
              className={clsxm(
                'ml-1.5 flex h-[22px] w-[22px] items-center justify-center rounded-full bg-grey2 text-xxs',
                Boolean(warn) && 'bg-red'
              )}
              style={warn ? { color: '#ffffff' } : {}}
            >
              {count}
            </span>
          </span>
        ) : (
          label
        )
      }
      className="mr-5 rounded-[10px] border border-solid border-grey3 px-6 py-2.5 text-m font-normal duration-300 hover:bg-black/70"
    />
  );
};
