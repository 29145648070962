import { useMemo, useEffect, useCallback } from 'react';

import useStore from '@app/store/useStore.hook';

import { DarkMode, LightMode } from '@mui/icons-material';
import { createTheme, IconButton } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { getDesignTokens } from '@app/styles/material-theme';

const IS_THEME_CHANGE_SUPPORTED = false;

export const ThemeWrapper: React.FC = ({ children }) => {
  const { theme: theme, setTheme } = useStore(({ theme, setTheme }) => ({ theme, setTheme }));

  const muiTheme = useMemo(() => createTheme(getDesignTokens(theme)), [theme]);

  // TAILWIND RELATED
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  const handleChangeTheme = useCallback(() => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  }, [setTheme, theme]);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline enableColorScheme />
      <ThemeProvider theme={muiTheme}>
        {IS_THEME_CHANGE_SUPPORTED && (
          <IconButton onClick={handleChangeTheme} className="absolute right-4">
            {theme === 'dark' ? <DarkMode /> : <LightMode />}
          </IconButton>
        )}
        <div className={theme === 'dark' ? 'dark' : ''}>{children}</div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
