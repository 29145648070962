import { PaginatedResponse } from '@app/api/types';
import { AssignTesterInDto, ProjectInDto, TimelineInDto, EMethodologyStatus, InvitationDto } from '@app/swagger-types';
import { OverrideProjectOutDto, RejectProjectInDto } from '@app/swagger-override-types';
import { GetProjectsParams } from '@app/domain/project/project.type';
import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { validateAndAssert } from '@app/services/api-request';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { pageableData } from '@app/utils/yup.utils';

export const ProjectApi = buildApi(
  class _ProjectApi extends ApiCollection {
    async create(project: ProjectInDto) {
      return validateAndAssert(this.apiService.post('/private/projects', project), ProjectOutDtoSchema);
    }
    async delete(projectId: string) {
      return this.apiService.delete(`/private/projects/${projectId}`);
    }
    async getProjects(params?: GetProjectsParams) {
      return validateAndAssert(
        this.apiService.get<PaginatedResponse<OverrideProjectOutDto>>('/private/projects', { params }),
        pageableData(ProjectOutDtoSchema)
      );
    }
    async getMyProjects(params?: GetProjectsParams) {
      return validateAndAssert(
        this.apiService.get('/private/my-projects', { params }),
        pageableData(ProjectOutDtoSchema)
      );
    }

    async getNotAssignedProjects(params?: GetProjectsParams) {
      return validateAndAssert(
        this.apiService.get<PaginatedResponse<OverrideProjectOutDto>>('/private/not-assigned-projects', { params }),
        pageableData(ProjectOutDtoSchema)
      );
    }

    async checkAnyProjectCreated() {
      const data = await ProjectApi.getMyProjects({
        size: 1,
      });
      return data.total > 0;
    }

    async getProject(id: string) {
      return validateAndAssert(
        this.apiService.get<OverrideProjectOutDto>(`/private/projects/${id}`),
        ProjectOutDtoSchema
      );
    }

    async applyProject(id: string) {
      return this.apiService.post(`/private/projects/${id}/apply`);
    }

    async unapplyProject(id: string) {
      return this.apiService.post(`/private/projects/${id}/unapply`);
    }

    async editProject(id: string, Dto: Partial<ProjectInDto>) {
      return this.apiService.patch<OverrideProjectOutDto, Partial<ProjectInDto>>(`/private/projects/${id}`, Dto);
    }

    async setProjectTimeline(id: string, Dto: TimelineInDto) {
      return this.apiService.patch<OverrideProjectOutDto, TimelineInDto>(`/private/projects/${id}/timeline`, Dto);
    }

    async setChecklistItemStatus(id: string, checklistItemId: string, status: EMethodologyStatus) {
      return this.apiService.patch<never, { status: EMethodologyStatus }>(
        `/private/projects/${id}/checklistItems/${checklistItemId}/status`,
        { status }
      );
    }

    async setProjectStatus(id: string, Dto: RejectProjectInDto) {
      return this.apiService.patch<never, RejectProjectInDto>(`/private/projects/${id}/status`, Dto);
    }

    async assignTester(projectId: string, Dto: AssignTesterInDto) {
      return this.apiService.post<never, AssignTesterInDto>(`/private/projects/${projectId}/assign`, Dto);
    }

    async inviteMember(projectId: string, Dto: InvitationDto) {
      return this.apiService.post<never>(`/private/projects/${projectId}/invite`, Dto);
    }

    async deleteCollaboratorFromProject(projectId: string, collaboratorId: string) {
      return this.apiService.delete<never>(`/private/projects/${projectId}/collaborators/${collaboratorId}`);
    }
  }
);
