import React, { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';

import { Table, Button, TableColumn, IconButton } from '@app/shared/ui-components';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { DATE_FORMAT } from '@app/constants/date';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { getFriendlyProjectStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { Status } from '@app/shared/ui-components/status/Status';
import { TestersInfo } from '@app/shared/tables/projects-table/TestersInfo';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { GET_PROJECTS_QUERY } from '@app/constants/query-api-configs';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { NOT_ALLOWED_FOR_VIEW_PRICE } from '@app/constants/currency';
import { FindingStatsCell } from '@app/shared/tables/projects-table/FindingStatsCell';
import { useDeleteProject, useGetMyProjects } from '@app/domain/project/api/hooks/project.api.hook';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';
import { DeleteIcon } from '@app/assets/icons';
import { PAGE_SIZE } from '@app/constants/api';

export const MyProjectsTable: React.FC<{
  status?: OverrideProjectOutDto['status'];
}> = ({ status }) => {
  const navigate = useTypedNavigate();
  const { isClient, isCollaborator } = useUserRole();

  const { mutate: deleteProject, isLoading: isDeletingProject } = useDeleteProject();

  const navigateToProjectPage = ({ id }: OverrideProjectOutDto) => {
    navigate({ to: Routes.projects.project, params: { id } });
  };

  const navigateToCreateProjectPage = useCallback(() => {
    navigate({ to: Routes.projects.create });
  }, [navigate]);

  const {
    values: { page, sort = GET_PROJECTS_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      search: '',
    },
    prefix: GET_PROJECTS_QUERY.tablePrefix,
  });

  const { data, isFetching } = useGetMyProjects(
    {
      sort,
      page: Math.ceil((page || 1) - 1),
      size: PAGE_SIZE,
      status: status,
      term: search,
    },
    { keepPreviousData: true }
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <SearchInput placeholder="Search by name" className="w-64" value={search} onChange={handleSearchChange} />
      </div>
    );
  }, [handleSearchChange, search]);

  const rightContent = useMemo(() => {
    return (
      <div>
        {isClient && (
          <Button variant="outlined" color={'primary'} startIcon={<AddIcon />} onClick={navigateToCreateProjectPage}>
            Add a Project
          </Button>
        )}
      </div>
    );
  }, [navigateToCreateProjectPage, isClient]);

  const columns: TableColumn<OverrideProjectOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        align: 'left',
      },
      {
        headerName: 'Stats',
        field: 'findingStats',
        align: 'center',
        disableSort: true,
        render: ({ findingStats }) => <FindingStatsCell findingStats={findingStats} />,
      },
      {
        headerName: 'Project Type',
        field: 'productTitle',
        align: 'left',
      },
      {
        headerName: 'Cost',
        field: 'priceForClient',
        align: 'center',
        render: ({ priceForClient, isD2NA, usdToGbpRate }) => {
          if (isCollaborator) {
            return NOT_ALLOWED_FOR_VIEW_PRICE;
          }
          if (!priceForClient) {
            return '-';
          }
          return isD2NA ? createGBPformatter(usdToGbpRate).formatGBP(priceForClient) : formatDollars(priceForClient);
        },
      },
      {
        headerName: 'Timeline',
        field: 'startDate',
        align: 'center',
        disableSort: true,
        render: ({ startDate, endDate }) => {
          if (startDate && endDate) {
            return (
              <div>
                <div>{format(new Date(startDate), DATE_FORMAT)}</div>
                <div>{format(new Date(endDate), DATE_FORMAT)}</div>
              </div>
            );
          }

          return '-';
        },
      },
      {
        headerName: 'Testers',
        field: 'testers',
        align: 'center',
        disableSort: true,
        hide: !Boolean(status),
        render: ({ testers }) => {
          if (testers?.length) {
            return <TestersInfo testers={testers} countTestersToShow={2} />;
          }
          return '-';
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        align: 'left',
        width: 130,
        hide: Boolean(status),
        render: ({ status }) => <Status {...getFriendlyProjectStatus(status)} />,
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        width: 130,
        hide: !Boolean(status) || isCollaborator,
        disableSort: true,
        disableClick: true,
        render: ({ id, name }) => (
          <ConfirmDeleteDialog
            trigger={
              <IconButton>
                <DeleteIcon />
              </IconButton>
            }
            onConfirm={() => deleteProject(id)}
            headerTitle={`Delete ${name}`}
            title={`Are you sure you want to delete ${name}?`}
          />
        ),
      },
    ],
    [status, isCollaborator, deleteProject]
  );

  return (
    <Table
      cols={columns}
      tableData={data?.result || []}
      leftHeaderContent={leftContent}
      rightHeaderContent={rightContent}
      notFoundMessage="No projects"
      currentPage={page}
      loading={isFetching || isDeletingProject}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
      hidePageSize
      onItemSelect={navigateToProjectPage}
    />
  );
};
