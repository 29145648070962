import React from 'react';

import { LogsTable } from '@app/domain/action-log/LogsTable';

export const MySettingLoggingPage: React.FC = () => {
  return (
    <div>
      <LogsTable title="My Logs" myLogs />
    </div>
  );
};
