import React from 'react';
import { Typography } from '@mui/material';
import { useQuery } from 'react-query';

import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { FindingApi } from '@app/domain/finding/finding.api';
import { FindingPageBody } from '@app/domain/finding/FindingPageBody';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { GET_FINDING_QUERY } from '@app/constants/query-api-configs';
import { OverrideFindingOutDto } from '@app/swagger-override-types';
import { BackLink } from '@app/shared/ui-components/back-link/BackLink';

export const FindingPage = () => {
  const { projectId, findingId } = useTypedParams(Routes.projects.finding);

  const {
    data: finding,
    isFetching: loading,
    error,
  } = useQuery<OverrideFindingOutDto | undefined, Error>(
    [GET_FINDING_QUERY.name, { projectId, findingId }],
    async () => {
      if (projectId && findingId) {
        return await FindingApi.getFinding(projectId, findingId);
      }
    },
    { staleTime: GET_FINDING_QUERY.config.staleTime }
  );

  if (!projectId) {
    return null;
  }

  return (
    <>
      <div className="mb-10 flex flex-col items-start">
        <BackLink to={Routes.projects.project} params={{ id: projectId }} />
        <Typography className="mb-8 text-xxxl font-bold">Project page &mdash; Finding</Typography>
      </div>
      {(loading || error || !finding) && (
        <ContainerLoader loading={loading && !finding} error={error} noData={!finding} />
      )}
      {finding && <FindingPageBody finding={finding} projectId={projectId} />}
    </>
  );
};
