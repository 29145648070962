import { OverrideProjectOutDto } from '@app/swagger-override-types';
import {
  AnswerDto,
  EApplicationStatus,
  EPayoutStatus,
  EPenetrationType,
  EProjectStatus,
  ETypeOfAnswer,
  MyApplicationOutDto,
} from '@app/swagger-types';
import { createUiKitClient, createUiKitTester } from '@app/modules/ui-kit/pages/mock-data/team.data';

export const createUiKitAnswer = (override?: Partial<AnswerDto>): AnswerDto => ({
  id: '1',
  title: '1th answer title',
  value: '1th answer value',
  typeOfAnswer: ETypeOfAnswer.SHORT,
  createdDate: new Date().toJSON(),
  ...override,
});

export const createUiKitMyApplication = (override?: Partial<MyApplicationOutDto>): MyApplicationOutDto => ({
  payoutStatus: EPayoutStatus.PAID,
  id: '1',
  workingDays: 1,
  status: EApplicationStatus.ASSIGNED,
  createdDate: new Date().toJSON(),
  ...override,
});

const answers: AnswerDto[] = [
  createUiKitAnswer({
    id: '870b1d8a-1ad1-4f3f-91ae-b3caa1a1c351',
    title: '1th answer title',
    value: '1th answer value',
  }),
  createUiKitAnswer({
    id: '870b1d8a-1ad1-4f3f-91ae-b3caa1a1c352',
    title: '2th answer title',
    value: '2th answer value',
  }),
  createUiKitAnswer({
    id: '870b1d8a-1ad1-4f3f-91ae-b3caa1a1c353',
    title: '3th answer title',
    value: '3th answer value',
  }),
  createUiKitAnswer({
    id: '870b1d8a-1ad1-4f3f-91ae-b3caa1a1c358',
    title: '4th answer title',
    value: '4th answer value',
  }),
];

export const createUiKitProject = (overrides?: Partial<OverrideProjectOutDto>): OverrideProjectOutDto => ({
  id: '2b62966c-0342-485e-8622-95e8d6132aff',
  penetrationType: EPenetrationType.WEB_APP,
  productTitle: 'Web Application',
  name: 'Test project',
  status: EProjectStatus.COMPLETED,
  answers,
  priceForClient: 500,
  priceForTester: 200,
  myApplication: createUiKitMyApplication(),
  startDate: '1 jan 2022',
  endDate: '5 jan 2022',
  numOfRequests: 1,
  desiredTesterAssignment: 1,
  createdDate: new Date().toJSON(),
  desiredDeadline: new Date().toJSON(),
  workingDays: 5,
  client: createUiKitClient(),
  testers: [
    createUiKitTester({ id: '1' }),
    createUiKitTester({ id: '2', firstName: 'test', lastName: 'test' }),
    createUiKitTester({ id: '3' }),
    createUiKitTester({ id: '4' }),
    createUiKitTester({ id: '5' }),
  ],
  isD2NA: false,
  ...overrides,
});
