import { Routes } from '@app/constants/routes';

import { MySettingsModule } from './MySettingsModule';
import { TypedNavigate } from '@app/router';
import { MySettingLoggingPage } from '@app/modules/my-settings/pages/MySettingLogging.page';

export const MySettingsRoutes = {
  path: Routes.settings.index,
  element: <MySettingsModule />,
  children: [
    { path: Routes.settings.logging, element: <MySettingLoggingPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.settings.logging} /> },
  ],
};
