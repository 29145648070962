import { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { UIKitSection } from '../UIKitSection';
import { createUiKitProject } from '@app/modules/ui-kit/pages/mock-data/project.data';
import { EProjectStatus } from '@app/swagger-types';
import { ProjectProgressBox } from '@app/shared/ui-components/project-progress-box/ProjectProgressBox';

enum EMode {
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
  NO_TESTERS = 'NO_TESTERS',
}

export const DemoForProjectProgressBox: React.FC = () => {
  const [mode, setMode] = useState(EMode.PENDING);

  return (
    <UIKitSection title="ProjectProgressBox">
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setMode(e.currentTarget.value as EMode);
        }}
      >
        <FormControlLabel value={EMode.PENDING} control={<Radio />} label="PENDING" />
        <FormControlLabel value={EMode.SCHEDULED} control={<Radio />} label="SCHEDULED" />
        <FormControlLabel value={EMode.LIVE} control={<Radio />} label="LIVE" />
        <FormControlLabel value={EMode.COMPLETED} control={<Radio />} label="COMPLETED" />
        <FormControlLabel value={EMode.NO_TESTERS} control={<Radio />} label="NO TESTERS" />
      </RadioGroup>
      {mode === EMode.COMPLETED && (
        <ProjectProgressBox project={createUiKitProject({ status: EProjectStatus.COMPLETED })} />
      )}
      {mode === EMode.LIVE && <ProjectProgressBox project={createUiKitProject({ status: EProjectStatus.LIVE })} />}
      {mode === EMode.SCHEDULED && (
        <ProjectProgressBox project={createUiKitProject({ status: EProjectStatus.SCHEDULED })} />
      )}
      {mode === EMode.PENDING && (
        <ProjectProgressBox project={createUiKitProject({ status: EProjectStatus.PENDING })} />
      )}
      {mode === EMode.NO_TESTERS && (
        <ProjectProgressBox project={createUiKitProject({ status: EProjectStatus.PENDING, testers: [] })} />
      )}
    </UIKitSection>
  );
};
