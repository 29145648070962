import React from 'react';
import { Button } from '@app/ui-components';
import { TesterOutDto } from '@app/swagger-types';
import { TestersInfo } from '@app/shared/tables/projects-table/TestersInfo';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { preventEvent } from '@app/utils/event-handler.utils';

interface Props {
  onAssignTesters: () => void;
  testers?: TesterOutDto[];
  countTestersToShow?: number;
}

export const AssignTestersCell: React.FC<Props> = ({ testers, countTestersToShow = 2, onAssignTesters }) => {
  if (testers?.length) {
    return <TestersInfo testers={testers} countTestersToShow={countTestersToShow} />;
  }

  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<AddRoundedIcon className="text-xs" />}
      size="small"
      className="w-full"
      onClick={(e) => {
        preventEvent(e);
        onAssignTesters();
      }}
    >
      Assign
    </Button>
  );
};
