import React, { useMemo } from 'react';
import { GET_PROJECTS_QUERY } from '@app/constants/query-api-configs';
import { EProjectStatus } from '@app/swagger-types';
import { Typography } from '@mui/material';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { Button } from '@app/ui-components';
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { LiveProjectItem } from '@app/domain/project/components/LiveProjectItem';
import { useGetMyProjects } from '@app/domain/project/api/hooks/project.api.hook';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';

interface Props {
  title: string;
  noProjectsText: string;
}

export const MyProjectsBox: React.FC<Props> = ({ title, noProjectsText }) => {
  const { isFetching, data } = useGetMyProjects(
    {
      sort: GET_PROJECTS_QUERY.defaultSort,
    },
    { keepPreviousData: true }
  );

  const { projects, total } = useMemo(() => {
    const projects = data?.result || [];
    const liveProjects = projects.filter(({ status }) => status === EProjectStatus.LIVE);
    const scheduledProjects = projects.filter(({ status }) => status === EProjectStatus.SCHEDULED);
    const pendingProjects = projects.filter(({ status }) => status === EProjectStatus.PENDING);

    const groupedProjects = [...liveProjects, ...scheduledProjects, ...pendingProjects];
    return { projects: groupedProjects.slice(0, 2), total: groupedProjects.length };
  }, [data?.result]);

  const hasAnyProject = Boolean(projects.length);

  return (
    <div className={clsxm('h-64 rounded-[10px] bg-transparentBackground p-8', sharedStyle.shadowBorder)}>
      <div className="mb-7 flex justify-between">
        <div className="flex items-center">
          <Typography className="text-xxl font-bold">{title}</Typography>
          <Typography className="ml-2.5 flex h-[22px] w-[22px] items-center justify-center rounded-full bg-grey2 text-xs">
            {total}
          </Typography>
        </div>
        {hasAnyProject && (
          <TypedLink to={Routes.projects.index}>
            <Button
              variant="text"
              className="flex items-center p-0 text-xs font-bold hover:bg-transparent hover:underline"
              endIcon={<ChevronUpIcon className="h-4 rotate-90" />}
            >
              View All
            </Button>
          </TypedLink>
        )}
      </div>
      <div>
        {isFetching && <ContainerLoader loading={isFetching} />}
        {!isFetching && !hasAnyProject && (
          <Typography className="text-xs font-light italic text-grey6">{noProjectsText}</Typography>
        )}
        {!isFetching &&
          hasAnyProject &&
          projects.map((project) => <LiveProjectItem key={project.id} project={project} />)}
      </div>
    </div>
  );
};
