import { Routes } from '@app/constants/routes';

import { TypedNavigate } from '@app/router';
import { ERole } from '@app/swagger-types';
import { ProjectPaymentsPage } from '@app/modules/tester/modules/payments/pages/ProjectPayments.page';
import { ReTestBonusesPage } from '@app/domain/finding/ReTestBonuses.page';
import { PaymentsModule } from '@app/modules/tester/modules/payments/PaymentsModule';

export const PaymentsRoutes = {
  path: Routes.payments.index,
  element: <PaymentsModule />,
  role: [ERole.ROLE_TESTER],
  children: [
    { path: Routes.payments.projects, element: <ProjectPaymentsPage /> },
    { path: Routes.payments.retestBonuses, element: <ReTestBonusesPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.payments.projects} /> },
  ],
};
