import React, { useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Label, Legend, Surface, Symbols } from 'recharts';
import { formatDollars } from '@app/utils/currency.utils';

type PieType = {
  name: string;
  value: number;
  color?: string;
};

export const CustomPieChart: React.FC<{
  data: PieType[];
  label: string | number;
  width?: string | number;
  height?: string | number;
  dollarsFormat?: boolean;
}> = ({ data, label, width = '100%', height = '100%', dollarsFormat }) => {
  const filteredData = useMemo(() => data.filter(({ value }) => value), [data]);

  const isEmpty = filteredData.length === 0;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {dollarsFormat ? formatDollars(value) : value}
      </text>
    );
  };

  const renderCustomizedLegend = () => {
    return (
      <div className="flex w-full justify-center">
        {filteredData.map((entry, index) => {
          const { name, color } = entry;

          return (
            <span key={index} className="mr-3 flex items-center last:mr-0">
              <Surface width={9} height={9}>
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
              </Surface>
              <span className="ml-1.5 text-grey6">{name}</span>
            </span>
          );
        })}
      </div>
    );
  };

  const renderEmptyPieChart = () => (
    <PieChart width={300} height={300}>
      <Pie
        data={[{ value: 1 }]}
        innerRadius={'50%'}
        outerRadius={'70%'}
        dataKey="value"
        labelLine={false}
        isAnimationActive={false}
      >
        <Label value="No Info yet" position="center" fill="#BABAC9" className="text-xs font-light italic" />
        <Cell key={`cell-${0}`} fill="#424550" stroke="#424550" />
      </Pie>
    </PieChart>
  );

  return (
    <ResponsiveContainer width={width} height={height}>
      {isEmpty ? (
        renderEmptyPieChart()
      ) : (
        <PieChart width={300} height={300}>
          <Legend
            verticalAlign="bottom"
            iconType="circle"
            iconSize={9}
            wrapperStyle={{ fontWeight: 700, fontSize: 12, marginTop: 10, position: 'relative' }}
            content={renderCustomizedLegend}
          />
          <Pie
            data={filteredData}
            innerRadius={'50%'}
            outerRadius={'70%'}
            dataKey="value"
            label={renderCustomizedLabel}
            labelLine={false}
            isAnimationActive={false}
          >
            <Label value={label} position="center" fill="#ffffff" />
            {filteredData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} stroke={entry.color} />
            ))}
          </Pie>
        </PieChart>
      )}
    </ResponsiveContainer>
  );
};
