import styles from './Tooltip.module.scss';
import clsx from 'clsx';

import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import { Fade } from '@mui/material';
import { preventEvent } from '@app/utils/event-handler.utils';

export type TooltipProps = Omit<MuiTooltipProps, 'title'> & {
  variant?: 'light' | 'dark';
} & Partial<Pick<MuiTooltipProps, 'title'>>;

const CustomFade: typeof Fade = ({ children, ...props }) => {
  return (
    <Fade onClick={preventEvent} {...props}>
      {children}
    </Fade>
  );
};

export const Tooltip: React.FC<TooltipProps> = ({ variant = 'dark', placement = 'top', title = '', ...props }) => {
  return (
    <MuiTooltip
      title={title}
      placement={placement}
      {...props}
      TransitionComponent={CustomFade}
      classes={{
        ...props?.classes,
        tooltip: clsx(styles.Tooltip, variant === 'light' ? styles.Light : styles.Dark, props.classes?.tooltip),
      }}
    />
  );
};
