import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Button } from '@app/ui-components';
import AddIcon from '@mui/icons-material/Add';

export const ButtonExamples = () => {
  return (
    <>
      <Typography variant={'h4'} className="py-4">
        Buttons
      </Typography>
      <Grid container>
        <Grid item xs>
          <Typography variant={'h5'} className="py-2">
            Primary{' '}
          </Typography>
          <Box className="my-1">
            <Button variant="outlined" color={'primary'} size={'small'}>
              Small
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'primary'} size={'medium'}>
              Medium
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'primary'} size={'large'}>
              Large
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'primary'} startIcon={<AddIcon />}>
              Button
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'primary'} disabled>
              Disabled
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'primary'} loading>
              Loading button
            </Button>
          </Box>
        </Grid>
        <Grid item xs>
          <Typography variant={'h5'} className="py-2">
            Secondary{' '}
          </Typography>
          <Box className="my-1">
            <Button variant="outlined" color={'secondary'} size={'small'}>
              Small
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'secondary'} size={'medium'}>
              Medium
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'secondary'} size={'large'}>
              Large
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'secondary'} startIcon={<AddIcon />}>
              Button
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'secondary'} disabled>
              Disabled
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'secondary'} loading>
              Loading button
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Typography variant={'h5'} className="py-2">
            Gold
          </Typography>
          <Box className="my-1">
            <Button variant="outlined" color={'gold'} size={'small'}>
              Small
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'gold'} size={'medium'}>
              Medium
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'gold'} size={'large'}>
              Large
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'gold'} startIcon={<AddIcon />}>
              Button
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'gold'} disabled>
              Disabled
            </Button>
          </Box>
          <Box className="my-1">
            <Button variant="outlined" color={'gold'} loading>
              Loading button
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
