import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { EFindingStatus, FindingInDto, FindingOutDto } from '@app/swagger-types';
import {
  GET_ALL_PENDING_RETEST_FINDINGS_QUERY,
  GET_FINDING_QUERY,
  GET_FINDING_STATISTIC_QUERY,
  GET_FINDINGS_QUERY,
  GET_PENDING_RETEST_FINDINGS_QUERY,
  GET_PROJECTS_QUERY,
  GET_RETEST_BONUSES_QUERY,
  GET_PROJECT_QUERY,
} from '@app/constants/query-api-configs';
import { FindingApi } from '@app/domain/finding/finding.api';
import { FindingByProjectIdParams, FindingParams } from '@app/domain/finding/findings.type';
import { PaginatedResponse } from '@app/api/types';

type GetFindingsByProjectIdParams = { disabledRequest?: boolean } & FindingByProjectIdParams;

export const useGetRetestBonuses = (
  params: FindingParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<FindingOutDto>,
    (typeof GET_RETEST_BONUSES_QUERY.name | FindingParams)[]
  >
) => {
  return useQuery(
    [GET_RETEST_BONUSES_QUERY.name, params],
    async () => {
      return await FindingApi.getFindings({
        withBonuses: true,
        ...params,
      });
    },
    {
      staleTime: GET_RETEST_BONUSES_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetFindingsByProjectId = (
  { disabledRequest, ...params }: GetFindingsByProjectIdParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<FindingOutDto> | undefined,
    (typeof GET_FINDINGS_QUERY.name | GetFindingsByProjectIdParams)[]
  >
) => {
  return useQuery(
    [GET_FINDINGS_QUERY.name, params],
    async () => {
      if (disabledRequest) {
        return;
      }
      return await FindingApi.getFindingsByProjectId({
        ...params,
      });
    },
    {
      staleTime: GET_FINDINGS_QUERY.config.staleTime,
      ...options,
    }
  );
};

// TOTAL

export const useGetRetestBonusesTotal = (): number => {
  const { data } = useGetRetestBonuses({
    size: 1,
  });
  return data?.total || 0;
};

export const useGetFindingsByProjectIdTotal = (params: GetFindingsByProjectIdParams): number => {
  const { data } = useGetFindingsByProjectId({
    size: 1,
    ...params,
  });
  return data?.total || 0;
};

// MUTATIONS

export const useUpdateFindingStatus = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, { findingId: string; projectId: string; status: EFindingStatus }> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ findingId, projectId, status }) => {
      return await FindingApi.changeStatus(projectId, findingId, status);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_RETEST_BONUSES_QUERY.name);
        await queryClient.invalidateQueries(GET_FINDINGS_QUERY.name);
        await queryClient.invalidateQueries(GET_PROJECTS_QUERY.name);
        await queryClient.invalidateQueries(GET_FINDING_STATISTIC_QUERY.name);
        await queryClient.invalidateQueries(GET_PENDING_RETEST_FINDINGS_QUERY.name);
        await queryClient.invalidateQueries(GET_ALL_PENDING_RETEST_FINDINGS_QUERY.name);
        await queryClient.invalidateQueries([
          GET_FINDING_QUERY.name,
          { findingId: variables.findingId, projectId: variables.projectId },
        ]);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useDeleteFinding = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, { findingId: string; projectId: string }> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ findingId, projectId }) => {
      return await FindingApi.deleteFinding(findingId, projectId);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_RETEST_BONUSES_QUERY.name);
        await queryClient.invalidateQueries(GET_FINDINGS_QUERY.name);
        await queryClient.invalidateQueries(GET_PROJECTS_QUERY.name);
        await queryClient.invalidateQueries(GET_FINDING_STATISTIC_QUERY.name);
        await queryClient.invalidateQueries(GET_PENDING_RETEST_FINDINGS_QUERY.name);
        await queryClient.invalidateQueries(GET_ALL_PENDING_RETEST_FINDINGS_QUERY.name);
        await queryClient.invalidateQueries([GET_PROJECT_QUERY.name, { id: variables.projectId }]);
        queryClient.removeQueries([
          GET_FINDING_QUERY.name,
          { findingId: variables.findingId, projectId: variables.projectId },
        ]);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useEditFinding = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, { findingId: string; projectId: string; Dto: FindingInDto }> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ findingId, projectId, Dto }) => {
      return await FindingApi.editFinding(projectId, findingId, Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_RETEST_BONUSES_QUERY.name);
        await queryClient.invalidateQueries(GET_FINDING_STATISTIC_QUERY.name);
        await queryClient.invalidateQueries(GET_FINDINGS_QUERY.name);
        await queryClient.invalidateQueries(GET_PENDING_RETEST_FINDINGS_QUERY.name);
        await queryClient.invalidateQueries(GET_ALL_PENDING_RETEST_FINDINGS_QUERY.name);
        queryClient.removeQueries([
          GET_FINDING_QUERY.name,
          { findingId: variables.findingId, projectId: variables.projectId },
        ]);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};
