import React, { FocusEvent, useState } from 'react';
import { FormControlLabel } from '@mui/material';
import { InputProps } from '@app/ui-components';
import { StyledRadio } from '@app/shared/ui-components/radio';
import { NumberInput } from '@app/shared/ui-components/fields/number-input/NumberInput';
import { NumberFormatProps } from 'react-number-format';

interface Props extends Omit<NumberFormatProps<InputProps>, 'ref'> {
  inputClassName?: string;
  groupValue?: string;
  onSetValue: (e: FocusEvent) => void;
}

export const RadioNumberInput = React.forwardRef<HTMLLabelElement, Props>(
  ({ className, inputClassName, groupValue, onSetValue, ...props }, ref) => {
    const [value, setValue] = useState('');

    return (
      <FormControlLabel
        ref={ref}
        className={className}
        value={value}
        control={<StyledRadio checked={groupValue === value} />}
        label={
          <NumberInput
            {...props}
            className={inputClassName}
            onFocus={onSetValue}
            onBlur={(e) => {
              onSetValue(e);
              setValue(e.target.value);
            }}
          />
        }
      />
    );
  }
);
