import { guardForClientDto } from '@app/models/auth';
import useStore from '@app/store/useStore.hook';

export const useMaybeCurrentClient = () => {
  const currentUser = useStore.useCurrentUser();
  if (guardForClientDto(currentUser)) {
    return currentUser;
  }
  return;
};
