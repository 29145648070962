import React, { cloneElement, useMemo, useState, useCallback } from 'react';
import { Modal, Typography } from '@mui/material';
import styles from './ConfirmDialog.module.scss';
import { Button, IconButton } from '..';
import clsxm from '@app/lib/clsxm';
import CloseIcon from '@mui/icons-material/Close';
import { QuestionIcon } from '@app/assets/icons';

export interface ConfirmDialogProps {
  trigger: React.ReactElement;
  onConfirm: () => void;
  headerTitle?: string;
  title?: string;
  text?: string;
  confirmText?: string;
  icon?: React.ReactNode;
  confirmIcon?: React.ReactNode;
  cancelText?: string;
  disabled?: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  trigger,
  headerTitle,
  title = 'Are you sure?',
  text,
  confirmText = 'Confirm',
  confirmIcon,
  cancelText = 'Cancel',
  disabled,
  onConfirm,
  icon,
}) => {
  const [open, setOpen] = useState(false);
  const clickTrigger = useMemo(() => {
    return cloneElement(trigger, {
      onClick: (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        if (disabled) {
          return;
        }
        setOpen(true);
      },
    });
  }, [trigger, setOpen, disabled]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    onConfirm();
    handleClose();
  }, [handleClose, onConfirm]);

  return (
    <>
      {clickTrigger}
      <Modal open={open} onClose={handleClose} onClick={(e) => e.stopPropagation()}>
        <div className={clsxm(styles.Root, 'rounded-md p-7')}>
          <div className="mb-6 flex justify-between border-b border-solid border-grey5/20 pb-5">
            <Typography className="text-xs">{headerTitle}</Typography>
            <IconButton onClick={handleClose} className="absolute right-4 top-4">
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.Content}>
            <div className="flex h-[38px] w-[38px] items-center justify-center rounded-full bg-grey2">
              {icon || <QuestionIcon />}
            </div>
            <Typography className="mt-7 mb-6 text-center text-xl font-bold">{title}</Typography>
            <div className={styles.InfoSection}>
              <Typography className={styles.Text}>{text}</Typography>
            </div>
            <div className="mt-3.5 flex w-full justify-evenly">
              <Button onClick={handleClose} variant="outlined" color="secondary">
                {cancelText}
              </Button>
              <Button onClick={handleSubmit} variant="outlined" color="primary" startIcon={confirmIcon}>
                {confirmText}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
