import React, { useCallback } from 'react';
import { useAsync } from 'react-use';

import { useTypedNavigate, useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { ProjectApi } from '@app/domain/project/project.api';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { FindingForm } from '@app/domain/finding/FindingForm';
import { FindingInDto } from '@app/swagger-types';
import { FindingApi } from '@app/domain/finding/finding.api';
import Notification from '@app/shared/ui-components/notifications/Notifications';
import { useEditFinding } from '@app/domain/finding/api/hooks/finding.api.hook';

export const EditFindingPage: React.FC = () => {
  const { projectId, findingId } = useTypedParams(Routes.projects.editFinding);
  const navigate = useTypedNavigate();

  const navigateToProjectPage = useCallback(() => {
    if (projectId) {
      navigate({ to: Routes.projects.project, params: { id: projectId } });
    }
  }, [navigate, projectId]);

  const {
    loading: projectLoading,
    error,
    value: project,
  } = useAsync(async () => {
    if (projectId) {
      return await ProjectApi.getProject(projectId);
    }
  }, [projectId]);

  const {
    loading: findingLoading,
    error: findingError,
    value: finding,
  } = useAsync(async () => {
    if (findingId && projectId) {
      return await FindingApi.getFinding(projectId, findingId);
    }
  }, [projectId, findingId]);

  const { mutate: handleEditFinding, isLoading: editLoading } = useEditFinding();

  if (!project || !finding || projectLoading || findingLoading || error || findingError) {
    return (
      <ContainerLoader
        loading={projectLoading || findingLoading}
        error={error || findingError}
        noData={!project || !finding}
      />
    );
  }

  const handleSubmit = (formData: FindingInDto) => {
    handleEditFinding(
      { projectId: project.id, findingId: finding.id, Dto: formData },
      {
        onSuccess: () => {
          navigateToProjectPage();
          Notification.showSuccess('Finding has been successfully edited');
        },
      }
    );
  };

  return (
    <FindingForm
      project={project}
      finding={finding}
      onConfirm={handleSubmit}
      loading={editLoading}
      title={<>Project Page &mdash; Edit finding</>}
    />
  );
};
