import React from 'react';
import { Typography } from '@mui/material';
import TimeAgo from 'react-timeago';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { isNumber } from 'lodash';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { ProjectApplicationStatusButton } from '@app/domain/project/components/ProjectApplicationStatusButton';
import { formatDollars } from '@app/utils/currency.utils';

interface Props {
  onApply: (id: string) => void;
  onUnapply: (id: string) => void;
  project: OverrideProjectOutDto;
  loading?: boolean;
  disabled?: boolean;
}

export const FindProjectItem: React.FC<Props> = ({ project, onApply, onUnapply, loading, disabled }) => {
  const { name, productTitle, startDate, endDate, createdDate, priceForTester, isNew, myApplication } = project;
  return (
    <div className="mb-6 flex w-full items-center justify-between rounded-md border-2 border-solid border-grey4 bg-[#3E414F]/30 p-7 backdrop-blur-xl">
      <div>
        <div className="mb-3 flex items-center">
          {isNew && <Typography className="mr-2.5 text-xs font-bold text-orange2 opacity-60">New</Typography>}
          <Typography className="text-xxxl font-bold">{name}</Typography>
          <Typography className="ml-2.5 text-xs text-grey5">{createdDate && <TimeAgo date={createdDate} />}</Typography>
        </div>
        <div className="flex items-center">
          <Typography className="text-l">{productTitle}</Typography>
          {isNumber(priceForTester) && (
            <Typography className="flex items-center text-l">
              <span className="mx-2.5 rounded-full bg-grey4" style={{ height: 4, width: 4 }} />
              {formatDollars(priceForTester)}
            </Typography>
          )}
          {startDate && endDate && (
            <Typography className="flex items-center text-l">
              <span className="mx-2.5 rounded-full bg-grey4" style={{ height: 4, width: 4 }} />
              {`${format(new Date(startDate), DATE_FORMAT)} - ${format(new Date(endDate), DATE_FORMAT)}`}
            </Typography>
          )}
        </div>
      </div>
      <div>
        <ProjectApplicationStatusButton
          project={project}
          onApply={onApply}
          onUnapply={onUnapply}
          appStatus={myApplication?.status}
          loading={loading}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
