import React from 'react';
import { Typography } from '@mui/material';
import { FinanceStatisticBox } from '@app/domain/project/components/FinanceStatisticBox';
import { PendingRetestsTable } from '@app/domain/project/components/PendingRetestsTable';
import { MyProjectsBox } from '@app/domain/project/components/MyProjectsBox';

export const TesterDashboardPage: React.FC = () => {
  return (
    <div>
      <Typography className="mb-8 text-xxxl font-bold">Dashboard</Typography>
      <div className="flex flex-col gap-6">
        <MyProjectsBox title="Assigned Projects" noProjectsText="No Assigned Projects yet" />
        <div className="flex gap-6">
          <div className="flex-[7]">
            <PendingRetestsTable />
          </div>
          <div className="flex-[5]">
            <FinanceStatisticBox />
          </div>
        </div>
      </div>
    </div>
  );
};
