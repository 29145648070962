import { Routes } from '@app/constants/routes';
import { TypedLink } from '@app/router';
import { Button } from '@app/ui-components';
import PrintIcon from '@mui/icons-material/Print';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';

const checkIsInsideIframe = () => window.location !== window.parent.location;

export const TacFixedHeader: React.FC = ({}) => {
  const isInsideIframe = checkIsInsideIframe();

  return (
    <>
      {/* extra gap */}
      <div className="pt-10 print:hidden"></div>
      <div className="fixed top-0 z-50 flex w-full items-center justify-center gap-4 bg-transparentBackground py-4 backdrop-blur-sm print:hidden">
        {!isInsideIframe && (
          <TypedLink to={Routes.index}>
            <Button variant="outlined">Home Page</Button>
          </TypedLink>
        )}
        <Button variant="outlined" color={'primary'} startIcon={<PrintIcon />} onClick={() => window.print()}>
          Print PDF
        </Button>
      </div>
    </>
  );
};

export const InvoiceFixedHeader: React.FC<{ projectId: string }> = ({ projectId }) => {
  const { isClient, isTester } = useUserRole();
  return (
    <>
      {/* extra gap */}
      <div className="pt-10 print:hidden"></div>
      <div className="fixed top-0 z-50 flex w-full items-center justify-center gap-4 bg-transparentBackground py-4 backdrop-blur-sm print:hidden">
        {isClient && (
          <TypedLink to={Routes.finance.index}>
            <Button variant="outlined">Invoices Page</Button>
          </TypedLink>
        )}
        {isTester && (
          <TypedLink to={Routes.payments.index}>
            <Button variant="outlined">Payments Page</Button>
          </TypedLink>
        )}
        <TypedLink to={Routes.projects.project} params={{ id: projectId }}>
          <Button variant="outlined">Project Page</Button>
        </TypedLink>
        <Button variant="outlined" color={'primary'} startIcon={<PrintIcon />} onClick={() => window.print()}>
          Print PDF
        </Button>
      </div>
    </>
  );
};
