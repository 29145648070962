import React, { useState, useMemo, useCallback } from 'react';
import { ListItemIcon, Menu, MenuItem, Typography, CircularProgress } from '@mui/material';
import { isNumber } from 'lodash';
import { ChevronRightRounded, MoreHoriz } from '@mui/icons-material';

import { Button, IconButton, Tooltip } from '@app/ui-components';
import clsxm from '@app/lib/clsxm';
import { Status } from '@app/shared/ui-components/status/Status';
import {
  getFriendlyPaymentStatus,
  getFriendlyProjectStatus,
} from '@app/shared/ui-components/project-info-box/project-info.utils';
import { DatePickerIcon, DeleteIcon, EditIcon, HighPriorityIcon } from '@app/assets/icons';
import styles from '@app/shared/ui-components/layout/header/user-header-info/UserHeaderInfo.module.scss';
import { EditProjectDialog } from '@app/domain/project/components/EditProjectDialog';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { EApplicationStatus } from '@app/swagger-types';
import { createGBPformatter, renderDollarAmount } from '@app/utils/currency.utils';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { TruncatedTypography } from '@app/shared/ui-components/truncated-typography/TruncatedTypography';
import { TypedLink, useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { NOT_ALLOWED_FOR_VIEW_PRICE } from '@app/constants/currency';
import { sharedStyle } from '@app/styles/sharedStyle';
import { useDeleteProject } from '@app/domain/project/api/hooks/project.api.hook';
import Notification from '@app/shared/ui-components/notifications/Notifications';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';

export const ProjectInfoBox: React.FC<{
  project: OverrideProjectOutDto;
  onSetUp: () => void;
}> = ({ project, onSetUp }) => {
  const [openEditProjectDialog, setEditProjectDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isTester, isClient, isAdmin, isCollaborator } = useUserRole();
  const {
    productTitle,
    name,
    status,
    id,
    priceForTester,
    priceForClient,
    myApplication,
    startDate,
    usdToGbpRate,
    isD2NA,
  } = project;
  const navigate = useTypedNavigate();

  const isDisabledQuestionnaire = useMemo(
    () => project.myApplication?.status !== EApplicationStatus.ASSIGNED && isTester,
    [project.myApplication?.status, isTester]
  );

  const { mutate: handleDeleteProject, isLoading: isDeleting } = useDeleteProject({
    onSuccess: () => {
      navigate({ to: Routes.projects.index });
      Notification.showSuccess('The project has been successfully deleted');
    },
  });

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenEditProjectDialog = useCallback(() => {
    setEditProjectDialog(true);
    handleClose();
  }, [handleClose]);

  const handleOpenSetTimelineDialog = useCallback(() => {
    onSetUp();
    handleClose();
  }, [handleClose, onSetUp]);

  const price = useMemo(() => {
    if (isClient) {
      if (isD2NA) {
        return createGBPformatter(usdToGbpRate).formatGBP(priceForClient || 0);
      }
      return renderDollarAmount(priceForClient);
    }
    if (isTester) {
      return renderDollarAmount(priceForTester);
    }
    if (isCollaborator) {
      return NOT_ALLOWED_FOR_VIEW_PRICE;
    }

    return null;
  }, [priceForTester, priceForClient, isClient, isTester, isCollaborator, usdToGbpRate, isD2NA]);

  return (
    <>
      <div
        className={clsxm(
          'relative flex h-full flex-col justify-between rounded-[10px] bg-transparentBackground py-6 px-8 backdrop-blur-lg',
          sharedStyle.shadowBorder
        )}
      >
        <div>
          <div className="mb-3 flex justify-start ">
            <TruncatedTypography className="mr-3 text-xxxl font-bold" value={name} />
            {status && <Status {...getFriendlyProjectStatus(status)} />}
          </div>
          {isAdmin && (
            <IconButton onClick={handleClick} className="absolute top-[12px] right-[18px] bg-grey2">
              <MoreHoriz />
            </IconButton>
          )}
          <Typography className="flex h-3 items-center text-xxs font-bold text-grey6">
            {`Project ID: ${id.split('-')[0]} `}
            {myApplication?.payoutStatus && !isTester && (
              <>
                (
                {myApplication.payoutStatus === 'PAID' ? (
                  'Paid'
                ) : (
                  <span className="flex items-center">
                    <HighPriorityIcon />
                    Not paid
                  </span>
                )}
                )
              </>
            )}
          </Typography>
        </div>
        <div className="mt-9 flex items-end justify-between">
          <div>
            <TruncatedTypography className="mb-2.5 text-xl" value={productTitle} />
            <Typography className="text-xs font-bold text-grey7">Project Type</Typography>
          </div>
          <div className="w-14">
            {status === 'COMPLETED' && myApplication?.payoutStatus && isTester && (
              <div className="mb-1">
                <Status {...getFriendlyPaymentStatus(myApplication.payoutStatus)} />
              </div>
            )}
            {!isAdmin && <Typography className="mb-2.5 text-xl">{price}</Typography>}
            {isAdmin && (
              <>
                {isNumber(priceForClient) && isNumber(priceForTester) ? (
                  <>
                    <Tooltip title="Client" placement="right">
                      <Typography className="mb-2.5 flex items-end text-xl">
                        {renderDollarAmount(priceForClient)}
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Tester" placement="right">
                      <Typography className="mb-2.5 flex items-end text-xl">
                        {renderDollarAmount(priceForTester)}
                      </Typography>
                    </Tooltip>
                  </>
                ) : status === 'SCHEDULED' ? (
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    className="mb-2.5 w-20 text-xxs font-bold"
                    onClick={onSetUp}
                  >
                    Set Up
                  </Button>
                ) : (
                  <Typography className="mb-2.5 flex items-end text-xl">-</Typography>
                )}
              </>
            )}
            <Typography className="text-xs font-bold text-grey7">{isTester ? 'Income' : 'Cost'}</Typography>
          </div>
          <div>
            <TypedLink to={Routes.projects.questionnaire} params={{ id: id }} disabled={isDisabledQuestionnaire}>
              <Button
                variant="outlined"
                endIcon={<ChevronRightRounded className="text-xxs" />}
                color="secondary"
                size="small"
                className="mb-2.5 text-xxs font-bold"
                disabled={isDisabledQuestionnaire}
              >
                View
              </Button>
            </TypedLink>
            <Typography
              className={clsxm('text-xs font-bold text-grey7', {
                'opacity-40': isDisabledQuestionnaire,
              })}
            >
              Questionnaire
            </Typography>
          </div>
        </div>
      </div>
      <Menu
        elevation={0}
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.Paper }}
      >
        <MenuItem onClick={handleOpenEditProjectDialog} className={styles.MenuItem} disabled={isDeleting}>
          <ListItemIcon className={styles.ListIcon}>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenSetTimelineDialog} className={styles.MenuItem} disabled={!startDate || isDeleting}>
          <ListItemIcon className={styles.ListIcon}>
            <DatePickerIcon />
          </ListItemIcon>
          Extend
        </MenuItem>
        <ConfirmDeleteDialog
          trigger={
            <MenuItem className={clsxm(styles.MenuItem, styles.Error)} disabled={isDeleting}>
              {isDeleting ? (
                <CircularProgress size={17} className="mr-3.5" />
              ) : (
                <ListItemIcon className={styles.ListIcon}>
                  <DeleteIcon className="stroke-error" />
                </ListItemIcon>
              )}
              Delete
            </MenuItem>
          }
          onConfirm={() => handleDeleteProject(project.id)}
          headerTitle={`Delete ${project.name}`}
          title={`Are you sure you want to delete ${project.name}?`}
        />
      </Menu>
      <EditProjectDialog
        open={openEditProjectDialog}
        onClose={() => setEditProjectDialog(false)}
        id={id}
        project={project}
      />
    </>
  );
};
