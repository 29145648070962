import { Routes } from '@app/constants/routes';

import { AuthStatus } from '@app/models/auth';
import { MyRouteObject } from '@app/models/router';

import { AuthModule } from './AuthModule';
import { ForgotPassword } from './pages/ForgotPassword.page';
import { SignIn } from './pages/SignIn.page';
import { SignUp } from './pages/SignUp.page';
import { TypedNavigate } from '@app/router';
import { SignUpTesterPage } from '@app/modules/auth/pages/SignUpTester.page';
import { SignUpCollaboratorPage } from '@app/modules/auth/pages/SignUpCollaborator.page';

export const AuthRoutes: MyRouteObject = {
  path: Routes.auth.index,
  element: <AuthModule />,
  authStatus: AuthStatus.UNAUTHORIZED,
  children: [
    { path: Routes.auth.sign_in, element: <SignIn /> },
    { path: Routes.auth.sign_up, element: <SignUp /> },
    { path: Routes.auth.sign_up_tester, element: <SignUpTesterPage /> },
    { path: Routes.auth.sign_up_collaborator, element: <SignUpCollaboratorPage /> },
    { path: Routes.auth.forgot_password, element: <ForgotPassword /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.auth.sign_in} /> },
  ],
};
