import React, { useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useMutation } from 'react-query';

import { IconButton, Table, TableColumn, Tooltip } from '@app/ui-components';
import { GET_RETEST_BONUSES_QUERY } from '@app/constants/query-api-configs';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { Status } from '@app/shared/ui-components/status/Status';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { Routes } from '@app/constants/routes';
import { TypedLink, useTypedNavigate } from '@app/router';
import { OverrideFindingOutDto } from '@app/swagger-override-types';
import { renderFriendlyFindingPaymentStatus } from '@app/domain/finding/findings.utils';
import { formatDollars } from '@app/utils/currency.utils';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { ERetestPaymentStatus } from '@app/swagger-types';
import { FindingApi } from '@app/domain/finding/finding.api';
import { PaymentStatusDropdown } from '@app/domain/finding/PaymentStatusDropdown';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { useGetRetestBonuses } from '@app/domain/finding/api/hooks/finding.api.hook';
import { PAGE_SIZE } from '@app/constants/api';
import { Visibility } from '@mui/icons-material';

export const ReTestBonusesPage: React.FC = () => {
  const navigate = useTypedNavigate();
  const { isAdmin, isTester } = useUserRole();

  const navigateToFindingPage = useCallback(
    ({ id: findingId, project: { id: projectId } }: OverrideFindingOutDto) => {
      navigate({ to: Routes.projects.finding, params: { projectId, findingId } });
    },
    [navigate]
  );

  const {
    values: { page, sort = GET_RETEST_BONUSES_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
    },
    prefix: GET_RETEST_BONUSES_QUERY.tablePrefix,
  });

  const { isFetching, data, refetch } = useGetRetestBonuses(
    {
      sort,
      page: Math.ceil((page || 1) - 1),
      size: PAGE_SIZE,
      term: search,
    },
    { keepPreviousData: true }
  );

  const { mutate: handleSetStatus } = useMutation(
    async ({
      findingId,
      projectId,
      status,
    }: {
      findingId: string;
      projectId: string;
      status: ERetestPaymentStatus;
    }) => {
      await FindingApi.changePaymentStatus(projectId, findingId, status);
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const columns: TableColumn<OverrideFindingOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Project Name',
        field: 'project.name',
        align: 'left',
        render: ({ project: { name } }) => name,
      },
      {
        headerName: 'Finding title',
        field: 'title',
        align: 'left',
      },
      {
        headerName: 'Finding Type',
        field: 'methodology',
        align: 'left',
        render: ({ methodology }) => methodology?.sectionTitle || 'Other',
      },
      {
        headerName: 'Client',
        field: 'project.client.firstName',
        align: 'left',
        disableSort: true,
        disableClick: true,
        render: ({ project: { client } }) => <UserBlock user={client} />,
      },
      {
        headerName: 'Re-test Bonus',
        field: 'retestBonus',
        align: 'center',
        render: ({ retestBonus }) =>
          retestBonus ? (
            <Typography className="text-xs font-medium text-green">{formatDollars(retestBonus)}</Typography>
          ) : (
            '-'
          ),
      },
      {
        headerName: 'Status',
        field: 'retestPaymentStatus',
        align: 'left',
        disableClick: isAdmin,
        width: 140,
        render: ({ retestPaymentStatus, id, project: { id: projectId } }) => {
          if (retestPaymentStatus) {
            return isAdmin ? (
              <PaymentStatusDropdown
                id={id}
                projectId={projectId}
                activeStatus={retestPaymentStatus}
                onChangeStatus={handleSetStatus}
              />
            ) : (
              <Status {...renderFriendlyFindingPaymentStatus(retestPaymentStatus)} />
            );
          } else {
            return <Status {...renderFriendlyFindingPaymentStatus(ERetestPaymentStatus.NONE)} />;
          }
        },
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'center',
        disableClick: true,
        disableSort: true,
        hide: !isTester,
        render: ({ project: { id: projectId } }) => (
          <Tooltip title="View PDF Invoice">
            <div>
              <TypedLink to={Routes.pdf.testerInvoice} params={{ projectId }}>
                <IconButton>
                  <Visibility />
                </IconButton>
              </TypedLink>
            </div>
          </Tooltip>
        ),
      },
    ],
    [isAdmin, isTester, handleSetStatus]
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <SearchInput placeholder="Search by name" className="w-64" onChange={handleSearchChange} />
      </div>
    );
  }, [handleSearchChange]);

  return (
    <div>
      <Table
        cols={columns}
        tableData={data?.result || []}
        loading={isFetching}
        notFoundMessage="No Re-test Bonuses"
        leftHeaderContent={leftContent}
        currentPage={page}
        total={data?.total}
        onPagination={handlePageChange}
        currentSort={sort}
        onSortChanged={handleSortChange}
        onItemSelect={navigateToFindingPage}
        hidePageSize
      />
    </div>
  );
};
