import React from 'react';
import { useQuery } from 'react-query';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { ProjectApi } from '@app/domain/project/project.api';
import { GET_PROJECT_QUERY } from '@app/constants/query-api-configs';
import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { ProjectQuestionnairePageBody } from '@app/domain/project/components/ProjectQuestionnairePageBody';

export const ProjectQuestionnairePage: React.FC = () => {
  const { id } = useTypedParams(Routes.projects.project);
  const {
    isFetching: projectLoading,
    data: project,
    error,
  } = useQuery<ProjectOutDtoSchema | undefined, Error>(
    [GET_PROJECT_QUERY.name, { id }],
    async () => {
      if (id) {
        return await ProjectApi.getProject(id);
      }
    },
    { keepPreviousData: true, staleTime: GET_PROJECT_QUERY.config.staleTime }
  );

  if (!project || (projectLoading && !project) || error) {
    return <ContainerLoader loading={projectLoading} error={error} noData={!project} />;
  }

  return <ProjectQuestionnairePageBody project={project} />;
};
