import styles from './SearchInput.module.scss';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Input, InputProps } from '../input/Input';
import { IconButton } from '@app/ui-components';
import { useUncontrolled } from '@app/shared/hooks/useUncontrolled.hook';
import { SearchIcon } from '@app/assets/icons';
import ClearIcon from '@mui/icons-material/Clear';

export interface SearchInputProps extends Omit<InputProps, 'onChange' | 'size'> {
  debounce?: number;
  className?: string;
  onDebounce?: (text: string) => void;
  onChange?: (text: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  debounce = 400,
  className,
  value,
  defaultValue,
  onChange,
  onDebounce,
  ...props
}) => {
  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    rule: (val) => typeof val === 'string',
    onChange,
  });

  const debounced = useDebouncedCallback((value) => {
    if (onDebounce) {
      onDebounce(value);
    }
  }, debounce);
  const searchIcon = useMemo(() => {
    return (
      <div className={styles.SearchIconWrapper}>
        <div className={styles.SearchIcon}>
          <SearchIcon />
        </div>
      </div>
    );
  }, []);
  return (
    <Input
      onChange={(e) => {
        handleChange(e.target.value);
        if (onDebounce) {
          debounced(e.target.value);
        }
      }}
      className={clsx(styles.SearchInput, className)}
      inputSize="small"
      startAdornment={searchIcon}
      endAdornment={
        value ? (
          <IconButton
            className="mr-2"
            onClick={() => {
              handleChange('');
              if (onDebounce) {
                debounced('');
              }
            }}
          >
            <ClearIcon className="fill-grey5" />
          </IconButton>
        ) : undefined
      }
      value={_value}
      {...props}
    />
  );
};
