import { useQuery, UseQueryOptions } from 'react-query';
import { InvoiceOutDto } from '@app/swagger-types';
import { GET_INVOICES_QUERY } from '@app/constants/query-api-configs';
import { PaginatedResponse } from '@app/api/types';
import { InvoiceApi } from '@app/domain/invoice/invoice.api';
import { InvoiceAPIQueryParams } from '@app/domain/invoice/invoice.type';

export const useGetInvoices = (
  params: InvoiceAPIQueryParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<InvoiceOutDto>,
    (typeof GET_INVOICES_QUERY.name | InvoiceAPIQueryParams)[]
  >
) => {
  return useQuery(
    [GET_INVOICES_QUERY.name, params],
    async () => {
      return await InvoiceApi.getInvoices({
        ...params,
      });
    },
    {
      staleTime: GET_INVOICES_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetInvoicesTotal = (): number => {
  const { data } = useGetInvoices({
    size: 1,
  });
  return data?.total || 0;
};
