import React from 'react';
import { EView, useToggleViewButtons } from '@app/shared/ui-components/toggle-view-buttons/useToggleViewButtons';
import { MyProjectList } from '@app/domain/project/components/MyProjectList';
import { EProjectStatus } from '@app/swagger-types';
import { MyProjectsTable } from '@app/shared/tables/projects-table/MyProjectsTable';

export const ClientProjectsPageBody: React.FC<{ filterStatus?: EProjectStatus }> = ({ filterStatus }) => {
  const [view] = useToggleViewButtons();
  return (
    <div className="mt-14">
      {view === EView.LIST && <MyProjectList filterStatus={filterStatus} />}
      {view === EView.TABLE && <MyProjectsTable status={filterStatus} />}
    </div>
  );
};
