import React, { useEffect, useMemo } from 'react';

import MUIPagination, { PaginationProps } from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';

interface PropTypes extends Omit<PaginationProps, 'onChange' | 'page' | 'count' | 'size' | 'variant'> {
  currentPage: number;
  pageSize?: number;
  total?: number;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onPagination?: (newPage: number) => void;
}

export const Pagination: React.FC<PropTypes> = ({
  currentPage,
  pageSize = 10,
  total = 0,
  size = 'small',
  className,
  onPagination,
  ...rest
}) => {
  const pagesCount = useMemo(() => {
    return Math.ceil(total / pageSize);
  }, [pageSize, total]);

  useEffect(() => {
    if (currentPage > pagesCount && onPagination) {
      onPagination(pagesCount);
    }
  }, [currentPage, pagesCount, onPagination]);

  return (
    <div className={className}>
      <MUIPagination
        page={currentPage}
        size={size}
        onChange={(event: React.ChangeEvent<unknown>, newPage: number) => {
          if (onPagination) {
            onPagination(newPage);
          }
        }}
        count={pagesCount}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{
              selected: 'text-[#E17954] bg-transparent',
            }}
          />
        )}
        {...rest}
      />
    </div>
  );
};
