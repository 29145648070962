import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { BreakUnit } from '../../PdfPager';
import styles from '../../pdf-salus.module.scss';
import clsxm from '@app/lib/clsxm';
import { PaginatedResponse } from '@app/api/types';
import { FindingOutSchema } from '@app/domain/finding/finding.schema';
import React from 'react';
import { SeverityBadge } from '@app/domain/finding/SeverityBadge';
import { renderFriendlyFindingStatus } from '@app/domain/finding/findings.utils';
import { Typography } from '@mui/material';
import { MethodologyStatusButtons } from '@app/shared/ui-components/project-work-box/MethodologyStatusButtons';
import { NotFound } from '@app/shared/ui-components/table/components/not-found/NotFound';
import { NoFindingDiscoveredParagraph } from '../shared';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { PDFUtils } from '../../pdf.utils';

/**
 * A linear interpolator for hexadecimal colors
 * @example
 * // returns #7F7F7F
 * lerpColor('#000000', '#ffffff', 0.5)
 */
const lerpColor = (a: string, b: string, amount: number): string => {
  const ah = parseInt(a.replace(/#/g, ''), 16),
    ar = ah >> 16,
    ag = (ah >> 8) & 0xff,
    ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ''), 16),
    br = bh >> 16,
    bg = (bh >> 8) & 0xff,
    bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

  return `#${(((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)}`;
};

const cell5: React.CSSProperties = {
  background: '#C00001',
  fontWeight: 'bold',
};
cell5.color = lerpColor(String(cell5.background), '#ffffff', 0.85);

const cell4: React.CSSProperties = {
  background: '#FF0000',
  fontWeight: 'bold',
};
cell4.color = lerpColor(String(cell4.background), '#ffffff', 0.85);

const cell3: React.CSSProperties = {
  background: '#FFC001',
  fontWeight: 'bold',
};
cell3.color = lerpColor(String(cell3.background), '#000000', 0.6);

const cell2: React.CSSProperties = {
  background: '#FFE599',
  fontWeight: 'bold',
};
cell2.color = lerpColor(String(cell2.background), '#000000', 0.6);

const cell1: React.CSSProperties = {
  background: '#92D050',
  fontWeight: 'bold',
};
cell1.color = lerpColor(String(cell1.background), '#000000', 0.6);

export const ReportSectionAppendix: React.FC<{
  project: ProjectOutDtoSchema;
  findings: PaginatedResponse<FindingOutSchema>;
  // should be false for Public and Management reports
  allDetails?: boolean;
}> = ({ project, findings, allDetails = true }) => {
  const { methodologies } = project;
  const noFindings = findings.result.length < 1;

  return (
    <>
      <BreakUnit pageBreak />
      <BreakUnit header>
        <div className={styles.content}>
          <div className={styles.pageHeader} id="appendix">
            Appendix
          </div>
          <div className={styles.divider} />
        </div>
      </BreakUnit>
      {allDetails && (
        <>
          <BreakUnit>
            <div className={styles.content}>
              <div className={clsxm(styles.p, 'font-bold')}>Findings Summary Including Fixed Reports</div>
            </div>
          </BreakUnit>
          {noFindings ? (
            <NoFindingDiscoveredParagraph />
          ) : (
            <>
              <BreakUnit>
                <div className={styles.content}>
                  <div className={styles.p}>
                    The table below details all findings, including what was found and fixed during the course of this
                    engagement.
                  </div>
                </div>
              </BreakUnit>
              <BreakUnit>
                <div className={clsxm(styles.content, 'flex pt-4 font-bold text-white')}>
                  <div className={'flex-[2]'}>Status</div>
                  <div className={'flex-[4]'}>Vulnerability Title</div>
                  <div className={'flex-1 text-center'}>Status</div>
                </div>
              </BreakUnit>
              {findings.result.map((e, i, arr) => {
                const isNotLast = i !== arr.length - 1;
                const status = renderFriendlyFindingStatus(e.status);
                return (
                  <React.Fragment key={i}>
                    <BreakUnit>
                      <div className={styles.content}>
                        <div className="flex py-2">
                          <div className={'flex flex-[2] items-center'} style={{ color: status.color }}>
                            {status.label}
                          </div>
                          <div className={'flex flex-[4] items-center'}>
                            <TypedLink
                              params={{ projectId: project.id, findingId: e.id }}
                              to={Routes.projects.finding}
                              className="hover:underline"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {e.title}
                            </TypedLink>
                          </div>
                          <div className={'flex flex-1 justify-center'}>
                            <SeverityBadge cvssScore={e.cvssScore} />
                          </div>
                        </div>
                        {isNotLast && <div className={clsxm(styles.gradientDivider, 'rotate-180')} />}
                      </div>
                    </BreakUnit>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </>
      )}
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Penetration Testing Methodology</b>
            <br />
            Salus penetration testing methodology uses <b>OWASP</b>, <b>PTES</b> and <b>CIS</b> standards at its core.
            All tests conducted by our assessors will follow the elements outlined by these standards. Assessments are
            different per organization and for that we aim to tailor every test accordingly.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Pre-Engagement Analysis</b>
            <br />
            The first phase of every engagement focuses to ensure appropriate scoping is done. All sides understand the
            agreed terms and what the test entails before commencing of the penetration test.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Intelligence Gathering</b>
            <br />
            Gathering of passive information is the second executed phase in every engagement. This phase utilises the
            agreed scope to establish a baseline of what assets must be investigated. Accumulating the information
            collected from the sources above helps in mapping an overall view of infrastructure.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Vulnerability Analysis</b>
            <br />
            After mapping the scope from the previous phases, assessors will attempt an active reconnaissance. The goal
            of this stage is to find what types of vulnerabilities exist on the scoped environment of the target assets
            and organization.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Exploitation</b>
            <br />
            Upon discovering a vulnerability, assessors will exploit the issue to examine how impactful the finding is
            to the system/application. The goal of this phase is to confirm the existence of the vulnerability and
            affirm severity.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Post Exploitation</b>
            <br />
            Often exploiting a vulnerable system/application does not result is Administrator level permissions.
            Assessors will use all available means and techniques to gain the highest privileges on the vulnerable
            targets. Assessors will avoid this phase if the client does not provide permission to proceed.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            A detailed breakdown of the methodologies used for this{' '}
            {PDFUtils.normalizeProductTitle(project.productTitle)} engagement is provided below.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={clsxm(styles.p, 'font-bold')}>
            {PDFUtils.normalizeProductTitle(project.productTitle)} Methodology Breakdown
          </div>
        </div>
      </BreakUnit>
      {Boolean(methodologies?.length) ? (
        methodologies?.map(({ sectionTitle, checklist }, index) => (
          <React.Fragment key={index}>
            <BreakUnit>
              <div className={clsxm(styles.content, 'mt-5')}>
                <Typography className="mb-5 text-l font-bold">{sectionTitle}</Typography>
                <div className={styles.divider} />
              </div>
            </BreakUnit>
            {checklist?.map(({ name, id, status }, i, arr) => {
              const isNotLast = i !== arr.length - 1;
              return (
                <BreakUnit key={id}>
                  <div className={clsxm(styles.content, 'flex items-center justify-between py-2')}>
                    <Typography className="text-m text-grey6">{name}</Typography>
                    <MethodologyStatusButtons status={status} methodologyId={id} projectId={project.id} disabled />
                  </div>
                  {isNotLast && (
                    <div className={styles.content}>
                      <div className={clsxm(styles.gradientDivider, 'rotate-180')} />
                    </div>
                  )}
                </BreakUnit>
              );
            })}
          </React.Fragment>
        ))
      ) : (
        <BreakUnit>
          <NotFound notFoundMessage="No methodologies" />
        </BreakUnit>
      )}
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Risk Rating Methodology</b>
            <br />
            Salus uses the OWASP Risk Rating Methodology to estimate the risk of vulnerability reports and help clients
            prioritise remediation efforts. A common standard to calculate risk is to use the following formula:
            <ul className={clsxm(styles.ul, 'pl-4')}>
              <li>Risk = Likelihood * Impact</li>
            </ul>
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Estimating Impact</b>
            <br />
            Testers in charge of identifying and reporting vulnerabilities will allocate an impact rating to each
            finding. Impact is calculated by estimating the worst potential outcome of a reported vulnerability to
            clients and their end users.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            Technical factors aiding in calculating impact include, Loss of Confidentiality, Loss of Integrity, Loss of
            Availability, Loss of Accountability.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            Business Factors include Financial Damage, Reputation Damage, Non-Compliance, Privacy Violation.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Estimating Likelihood</b>
            <br />
            Likelihood is a high level measure of how possible it is for an issue to be exploited by attackers. Several
            factors are involved in calculating the correct estimate, including Skill Level, Motive, Opportunity and
            Size.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            Factors such Ease of Discovery, Ease of Exploit, Awareness, Intrusion Detection are also considered when
            estimating likelihood.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.p}>
            <b>Calculating Risk</b>
            <br />
            Once assessors calculated the Impact and Likelihood of a vulnerability, an overall risk rating must be
            issued using the risk rating formula.
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <table className={clsxm(styles.table, styles.greyText)}>
            <tbody>
              <tr>
                <th colSpan={5} className={'font-bold'}>
                  Overall Risk Severity
                </th>
              </tr>
              <tr>
                <td rowSpan={4} className={'font-bold'}>
                  Impact
                </td>
                <td>HIGH</td>
                <td style={cell3}>Medium</td>
                <td style={cell4}>High</td>
                <td style={cell5}>Critical</td>
              </tr>
              <tr>
                <td>MEDIUM</td>
                <td style={cell2}>Low</td>
                <td style={cell3}>Medium</td>
                <td style={cell4}>High</td>
              </tr>
              <tr>
                <td>LOW</td>
                <td style={cell1}>Note</td>
                <td style={cell2}>Low</td>
                <td style={cell3}>Medium</td>
              </tr>
              <tr>
                <td></td>
                <td>LOW</td>
                <td>MEDIUM</td>
                <td>HIGH</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={4} className={'font-bold'}>
                  Likelihood
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BreakUnit>
    </>
  );
};
