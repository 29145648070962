import { oneOfEnum } from '@app/utils/yup.utils';
import { object, InferType, string, array, mixed, boolean } from 'yup';
import { JsonConfigBaseSchema } from './base-question-config.schema';

const TextOptionLiteralType = 'text' as const;
const TextOptionSchema = object({
  type: oneOfEnum([TextOptionLiteralType] as const).required(),
  selectedByDefault: boolean(),
  value: string().required(),
});
export type TextOptionSchema = InferType<typeof TextOptionSchema>;

const InputOptionLiteralType = 'input' as const;

const InputOptionSchema = object({
  type: oneOfEnum([InputOptionLiteralType] as const).required(),
  inputType: oneOfEnum(['text', 'number', 'long'] as const),
  allowNegative: boolean(),
  selectedByDefault: boolean(),
  placeholder: string().required(),
});
export type InputOptionSchema = InferType<typeof InputOptionSchema>;

export const RadioQuestionConfigSchema = JsonConfigBaseSchema.concat(
  object({
    type: oneOfEnum(['radio'] as const).required(),
    options: array()
      .of(
        mixed((obj: any): obj is TextOptionSchema | InputOptionSchema => {
          if (obj.type === TextOptionLiteralType) {
            return TextOptionSchema.isType(obj);
          }
          if (obj.type === InputOptionLiteralType) {
            return InputOptionSchema.isType(obj);
          }
          return false;
        }).required()
      )
      .required(),
  })
);
export type RadioQuestionConfigSchema = InferType<typeof RadioQuestionConfigSchema>;

export const narrowRadioQuestionConfig = (config: unknown) => {
  if (!config || typeof config !== 'object') {
    throw new Error('invalid radio question config');
  }
  return RadioQuestionConfigSchema.validateSync(config);
};
