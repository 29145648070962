import React from 'react';

import { Dialog } from '@app/hoc';
import { Typography } from '@mui/material';
import { Button, Form, Input } from '@app/ui-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { RejectProjectValidationSchema } from '@app/domain/project/project.schema';
import { UseRejectProject } from '@app/domain/project/api/hooks/project.api.hook';
import { EProjectStatus } from '@app/swagger-types';
import { UseMutateFunction } from 'react-query/types/react/types';

interface Props {
  open: boolean;
  projectId: string;
  onClose: () => void;
  onConfirm: UseMutateFunction<unknown, Error, UseRejectProject>;
  isLoading?: boolean;
}

export const RejectProjectDialog: React.FC<Props> = ({ open, onClose, onConfirm, projectId, isLoading }) => {
  const { control, handleSubmit, register, clearErrors, reset } = useForm<RejectProjectValidationSchema>({
    resolver: yupResolver(RejectProjectValidationSchema),
  });

  const onSubmit: SubmitHandler<RejectProjectValidationSchema> = ({ rejectReason }) => {
    onConfirm(
      { id: projectId, Dto: { rejectReason, status: EProjectStatus.REJECTED } },
      {
        onSuccess: () => {
          clearErrors();
          reset();
          onClose();
        },
      }
    );
  };

  return (
    <Dialog open={open} paperClassName="p-7 w-full" headerTitle="Reason of reject" onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)} control={control}>
        <div className="px-7">
          <Typography className="mb-6 text-m font-medium">Please describe the reason of reject</Typography>
          <Input {...register('rejectReason')} placeholder="Write here" multiline rows={4} />
        </div>
        <div className="mb-3 mt-12 flex justify-center">
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            size="large"
            className="mr-4"
            disabled={isLoading}
          >
            Close
          </Button>
          <Button variant="outlined" color="primary" size="large" type="submit" loading={isLoading}>
            Send
          </Button>
        </div>
      </Form>
    </Dialog>
  );
};
