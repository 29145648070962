import { array, InferType, object, string } from 'yup';
import { EPenetrationType, ETypeOfAnswer } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { JsonConfigBaseSchema } from '@app/modules/admin/create-project/narrow-config-utils/base-question-config.schema';
import { PredefinedReportSchema } from '@app/domain/project/project.schema';

export const ChecklistBlueprintSchema = object({
  id: string().required(),
  name: string().required(),
  predefinedReportId: string().required(),
  predefinedReport: PredefinedReportSchema.required(),
});

export type ChecklistBlueprintSchema = InferType<typeof ChecklistBlueprintSchema>;

export const MethodologyBlueprintSchema = object({
  sectionTitle: string().required(),
  checklist: array().of(ChecklistBlueprintSchema).required(),
});

export type MethodologyBlueprintSchema = InferType<typeof MethodologyBlueprintSchema>;

export const QuestionSchema = object({
  id: string().required(),
  title: string().required(),
  typeOfAnswer: oneOfEnum(ETypeOfAnswer).required(),
  createdDate: string().required(),
  jsonConfig: JsonConfigBaseSchema,
});

export type QuestionSchema = InferType<typeof QuestionSchema>;

export const AnswerSchema = object({
  id: string().required(),
  title: string().required(),
  value: string().optional().default(undefined),
  typeOfAnswer: oneOfEnum(ETypeOfAnswer).required(),
  createdDate: string().required(),
  jsonConfig: JsonConfigBaseSchema.optional().default(undefined),
});

export type AnswerSchema = InferType<typeof AnswerSchema>;

export const ProductOutSchema = object({
  id: string().required(),
  title: string().required(),
  penetrationType: oneOfEnum(EPenetrationType).required(),
  methodologyTitle: string().optional().default(''),
  questionnaireTitle: string().optional().default(''),
  questions: array().of(QuestionSchema).optional().default([]),
  methodologies: array().of(MethodologyBlueprintSchema).optional().default([]),
});

export type ProductOutSchema = InferType<typeof ProductOutSchema>;
