import { Outlet } from 'react-router-dom';
import useStore from '@app/store/useStore.hook';
import { SetTesterAddressDialog } from '@app/domain/user-profile/SetTesterAddressDialog';
import { ERole } from '@app/swagger-types';

export const TesterModule = () => {
  const { currentUser } = useStore();
  return (
    <>
      <Outlet />
      <SetTesterAddressDialog open={currentUser?.role === ERole.ROLE_TESTER && !Boolean(currentUser.address)} />
    </>
  );
};
