import React from 'react';
import Flag from 'react-world-flags';
import { getCode } from 'country-list';

import clsxm from '@app/lib/clsxm';
import { TruncatedTypography } from '@app/shared/ui-components/truncated-typography/TruncatedTypography';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

export const CountryInfo: React.FC<{
  countryName: string;
  className?: string;
  maxLength?: number;
  placement?: TooltipProps['placement'];
}> = ({ countryName, className, maxLength = 100, placement }) => {
  return (
    <div className="flex items-center">
      <Flag code={getCode(countryName)} className={clsxm('mr-2 h-4 w-4', className)} />
      <TruncatedTypography className="text-xs" value={countryName} limit={maxLength} placement={placement} />
    </div>
  );
};
