import { Outlet } from 'react-router-dom';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { Tabs, Tab } from '@mui/material';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';

const tabRoutes = mutable([
  Routes.admin.user_management.nested.page_1,
  Routes.admin.user_management.nested.page_2,
] as const);

export const NestedModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);
  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to });
  };

  return (
    <div>
      <h3>NestedModule</h3>
      <div className="space-x-4 p-[20px] ">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="page-1" value={tabRoutes[0]} />
          <Tab label="page-1" value={tabRoutes[1]} />
        </Tabs>
      </div>
      <div className="p-[20px]">
        <Outlet />
      </div>
    </div>
  );
};
