import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, Typography } from '@mui/material';

import { GET_TESTERS_QUERY } from '@app/constants/query-api-configs';

import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { Table, TableColumn, Button, Tooltip } from '@app/ui-components';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ConfirmDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDialog';
import { EApplicationStatus, EUserStatus } from '@app/swagger-types';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { UseMutateFunction } from 'react-query/types/react/types';
import { CountryInfo } from '@app/shared/ui-components/country-info/CountryInfo';
import { AppTesterOutDto } from '@app/domain/application/application.type';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { useGetTesters } from '@app/domain/user/api/hooks/user.api.hook';
import { PAGE_SIZE } from '@app/constants/api';
import { UseAssignTestersToProjectParams } from '@app/domain/project/api/hooks/project.api.hook';

export const AllTestersTable: React.FC<{
  assignedTesterIds: string[];
  rejectedTesterIds: string[];
  projectId: string;
  rightHeaderContent?: React.ReactNode;
  loading: boolean;
  onAssignTester: UseMutateFunction<unknown, Error, UseAssignTestersToProjectParams>;
}> = ({ onAssignTester, rightHeaderContent, assignedTesterIds, rejectedTesterIds, loading, projectId }) => {
  const [assignId, setAssignId] = useState<string>();

  const {
    values: { page, sort = GET_TESTERS_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleResetQuery, handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      sort: undefined,
      search: '',
    },
    prefix: GET_TESTERS_QUERY.tablePrefix,
  });

  useEffect(() => {
    return handleResetQuery;
  }, [handleResetQuery]);

  const { data, isFetching } = useGetTesters(
    {
      sort,
      page: Math.ceil((page || 1) - 1),
      size: PAGE_SIZE,
      status: EUserStatus.ACTIVE,
      term: search,
    },
    { keepPreviousData: true }
  );

  const [renderList, setRenderList] = useState<AppTesterOutDto[]>([]);

  useEffect(() => {
    if (data?.result) {
      setRenderList(
        data.result.map(({ id, ...tester }) => ({
          id,
          ...tester,
          appStatus: assignedTesterIds.includes(id)
            ? EApplicationStatus.ASSIGNED
            : rejectedTesterIds.includes(id)
            ? EApplicationStatus.REJECTED
            : EApplicationStatus.REQUESTED,
        }))
      );
    }
  }, [data?.result, assignedTesterIds, rejectedTesterIds]);

  const handleAssignTester = useCallback(
    (testerId: string) => {
      setAssignId(testerId);
      onAssignTester(
        { testerId, projectId },
        {
          onSettled: () => {
            setAssignId(undefined);
          },
        }
      );
    },
    [onAssignTester, projectId]
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <Typography className="mb-6 text-xl font-bold">All Testers</Typography>
        <SearchInput placeholder="Search by name" className="w-64" value={search} onChange={handleSearchChange} />
      </div>
    );
  }, [handleSearchChange, search]);

  const columns: TableColumn<AppTesterOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'firstName',
        align: 'left',
        render: (tester) => <UserBlock user={tester} />,
      },
      {
        headerName: 'Certifications',
        field: 'certifications',
        align: 'left',
        disableSort: true,
        render: ({ certifications }) => (
          <div className="flex items-center">
            {certifications?.slice(0, 2).map(({ name, id, abbreviation }) => (
              <Tooltip key={id} title={name}>
                <Chip label={abbreviation} className="rounded-[4px]" />
              </Tooltip>
            ))}
            {certifications && Boolean(certifications.length > 2) && (
              <Chip label={`+${certifications.length - 2}`} className="rounded-[4px]" />
            )}
          </div>
        ),
      },
      {
        headerName: 'Country',
        field: 'country',
        align: 'left',
        render: ({ country }) => (country ? <CountryInfo countryName={country} /> : null),
      },
      {
        headerName: 'Skills',
        field: 'skills',
        align: 'left',
        disableSort: true,
        render: ({ skills }) => (
          <div className="flex items-center">
            {skills?.slice(0, 2).map(({ name, id }) => (
              <Chip key={id} label={name} />
            ))}
            {skills && Boolean(skills.length > 2) && <Chip label={`+${skills.length - 2}`} />}
          </div>
        ),
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableClick: true,
        disableSort: true,
        render: ({ id, firstName, lastName, appStatus }) => {
          if (appStatus === 'ASSIGNED') {
            return (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                className="w-24 cursor-default border-green/30 bg-green/5 text-green"
              >
                Assigned
              </Button>
            );
          } else if (appStatus === 'REJECTED') {
            return (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                className="w-24 cursor-default border-[#CA3434]/80 bg-[#3F2B33]/60 text-error"
              >
                Rejected
              </Button>
            );
          } else {
            return (
              <ConfirmDialog
                trigger={
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<AddRoundedIcon className="text-xs" />}
                    size="small"
                    className="w-24"
                    disabled={assignId !== id && Boolean(assignId)}
                    loading={assignId === id}
                  >
                    Assign
                  </Button>
                }
                onConfirm={() => handleAssignTester(id)}
                headerTitle="Assign tester"
                title={`Do you want to assign ${firstName} ${lastName}?`}
              />
            );
          }
        },
      },
    ],
    [handleAssignTester, assignId]
  );

  return (
    <Table
      cols={columns}
      tableData={renderList}
      leftHeaderContent={leftContent}
      rightHeaderContent={rightHeaderContent}
      notFoundMessage="No testers"
      currentPage={page}
      loading={isFetching || loading}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
      cardHeaderClassName="flex items-start"
      hidePageSize
      noShadow
      wrapperClassName="min-w-[999px] min-h-[700px]"
      transparentBackground={false}
    />
  );
};
