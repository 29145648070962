import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Tabs, Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { OutlinedTab } from '@app/shared/ui-components/tabs/OutlinedTab';
import { useGetProjectsTotal, useGetProjects } from '@app/domain/project/api/hooks/project.api.hook';
import { EProjectStatus } from '@app/swagger-types';
import { ToggleViewButtons } from '@app/shared/ui-components/toggle-view-buttons/ToggleViewButtons';

const tabRoutes = mutable([
  Routes.projects.allProjects,
  Routes.projects.pendingProjects,
  Routes.projects.scheduledProjects,
  Routes.projects.liveProjects,
  Routes.projects.completedProjects,
] as const);

export const AdminProjectsModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);
  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to });
  };

  const allProjectsTotal = useGetProjectsTotal();
  const pendingProjectsTotal = useGetProjectsTotal(EProjectStatus.PENDING);
  const { data: scheduledProjects } = useGetProjects({ status: EProjectStatus.SCHEDULED });
  const { data: liveProjects } = useGetProjects({ status: EProjectStatus.LIVE });
  const completedProjectsTotal = useGetProjectsTotal(EProjectStatus.COMPLETED);

  const countScheduledProjectsWithoutTesters = useMemo(
    () => scheduledProjects?.result.filter((project) => !Boolean(project.testers?.length)).length || 0,
    [scheduledProjects?.result]
  );

  const countLiveProjectsWithoutTesters = useMemo(
    () => liveProjects?.result.filter((project) => !Boolean(project.testers?.length)).length || 0,
    [liveProjects?.result]
  );

  return (
    <>
      <div>
        <Typography className="mb-8 text-xxxl font-bold">Project Management</Typography>
        <div className="my-5 flex items-end justify-between">
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <OutlinedTab label="All" value={tabRoutes[0]} count={allProjectsTotal} />
            <OutlinedTab
              label="Pending"
              value={tabRoutes[1]}
              count={pendingProjectsTotal}
              warn={Boolean(pendingProjectsTotal)}
            />
            <OutlinedTab
              label="Scheduled"
              value={tabRoutes[2]}
              count={countScheduledProjectsWithoutTesters || scheduledProjects?.total || 0}
              warn={Boolean(countScheduledProjectsWithoutTesters)}
            />
            <OutlinedTab
              label="Live"
              value={tabRoutes[3]}
              count={countLiveProjectsWithoutTesters || liveProjects?.total || 0}
              warn={Boolean(countLiveProjectsWithoutTesters)}
            />
            <OutlinedTab label="Completed" value={tabRoutes[4]} count={completedProjectsTotal} />
          </Tabs>
          <ToggleViewButtons />
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
