import clsxm from '@app/lib/clsxm';

export const BadgeD2NA: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div
      className={clsxm(
        'shrink cursor-default rounded p-1 text-xs text-gold opacity-50 ring-1 ring-gold transition hover:opacity-100',
        className
      )}
    >
      D2NA
    </div>
  );
};
