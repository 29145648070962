import React, { useCallback } from 'react';
import ReactQuill, { Quill } from 'react-quill';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ImageResize from 'quill-image-resize-module-react';

import 'react-quill/dist/quill.snow.css';

import './RichEditor.scss';

Quill.register('modules/imageResize', ImageResize);

const toolbar = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  ['link'],
  ['image'],
  ['clean'],
];

interface Props {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  errorMessage?: string;
}

export const RichEditor: React.FC<Props> = ({ value, onChange, className, placeholder, errorMessage }) => {
  const handleChange = useCallback(
    (value: string) => {
      if (value === '<p><br></p>') {
        onChange('');
      } else {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        className={className}
        modules={{
          toolbar,
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
          imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize'],
          },
        }}
        placeholder={placeholder}
      />
      {errorMessage && <p className="mt-2 text-xxs text-error">{errorMessage}</p>}
    </>
  );
};
