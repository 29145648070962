import React from 'react';
import { Typography } from '@mui/material';

import { AnswerDto } from '@app/swagger-types';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';
import { matchTextByWords } from '@app/utils/string.utils';

interface Props {
  title: string;
  answers: AnswerDto[];
  wrapperClassName?: string;
}

export const ProjectQuestionnaire: React.FC<Props> = ({ title, answers, wrapperClassName }) => {
  return (
    <div className={clsxm('rounded-lg bg-transparentBackground p-7', sharedStyle.shadowBorder, wrapperClassName)}>
      <Typography className="mb-9 text-xl font-bold">{title}</Typography>
      {answers.map((answer) => {
        if (!answer.value) {
          return null;
        }
        const isQuestionAboutUrlsInScope = matchTextByWords(answer.title, ['what', 'urls', 'in', 'scope']);
        return (
          <div key={answer.id} className="mb-7 last:mb-0">
            <Typography className="mb-4 text-l font-bold">{answer.title}</Typography>
            <Typography className={clsxm('text-s text-grey6', isQuestionAboutUrlsInScope && 'whitespace-pre-wrap')}>
              {answer.value}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
