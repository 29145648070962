import React, { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { Dialog } from '@app/hoc';
import { Button, Form, Input } from '@app/ui-components';
import { TesterAddressSchema } from '@app/domain/user-profile/user-profile.form';
import { useMutation, useQueryClient } from 'react-query';
import { TesterDto } from '@app/models/user';
import { TesterInDto } from '@app/swagger-types';
import { UserProfileApi } from '@app/domain/user-profile/user-profile.api';
import { GET_MY_PROFILE_QUERY } from '@app/constants/query-api-configs';
import useStore from '@app/store/useStore.hook';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { Typography } from '@mui/material';

interface Props {
  open: boolean;
}

export const SetTesterAddressDialog: React.FC<Props> = ({ open }) => {
  const { setCurrentUser } = useStore();
  const queryClient = useQueryClient();
  const { control, handleSubmit, register, clearErrors, formState } = useForm<TesterAddressSchema>({
    resolver: yupResolver(TesterAddressSchema),
  });

  const {
    mutate: updateMyTesterProfile,
    isLoading,
    error,
  } = useMutation<TesterDto, Error, Partial<TesterInDto>>(
    async (Dto) => {
      const { data } = await UserProfileApi.updateMyTesterProfile(Dto);
      return data;
    },
    {
      onSuccess: (newTester) => {
        queryClient.invalidateQueries(GET_MY_PROFILE_QUERY.name);
        setCurrentUser(newTester);
      },
    }
  );

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: isLoading,
      };
    };
    return fn;
  }, [register, formState, isLoading]);

  const onSubmit: SubmitHandler<TesterAddressSchema> = (formData) => {
    clearErrors();

    updateMyTesterProfile(formData);
  };

  return (
    <Dialog open={open} paperClassName="p-7 w-[436px]" headerTitle="Set Tester Address">
      <Form onSubmit={handleSubmit(onSubmit)} control={control}>
        <div className="p-7">
          <Typography className="mb-10 text-center text-xl font-medium text-grey5">
            Please, provide your address
          </Typography>
          <Input {...inputRegister('address')} label="Address" placeholder="Enter Address" />
        </div>
        <div className="pb-3 pt-10 text-center">
          <Button variant="outlined" color="primary" type="submit" loading={isLoading}>
            Save
          </Button>
          {maybeRenderError(error)}
        </div>
      </Form>
    </Dialog>
  );
};
