import React from 'react';

import { Routes } from '@app/constants/routes';

import { SidebarRouteLink } from '@app/models/router';

import {
  FilePresent,
  KeyboardCommandKey,
  ManageAccounts,
  ManageSearch,
  MonetizationOnOutlined,
  Search,
  Settings,
} from '@mui/icons-material/';
import { DrawerProps as MuiDrawerProps } from '@mui/material';

import { NavSidebar } from '@app/shared/ui-components/layout/nav-sidebar/NavSidebar';
import { ERole } from '@app/swagger-types';

const navItems: SidebarRouteLink[] = [
  {
    path: Routes.admin.user_management.index,
    name: 'User Management',
    icon: <ManageAccounts />,
    tooltip: 'You can manage users here',
    role: [ERole.ROLE_ADMIN],
  },
  {
    path: Routes.dashboard.index,
    name: 'Dashboard',
    icon: <ManageAccounts />,
    role: [ERole.ROLE_CLIENT, ERole.ROLE_TESTER, ERole.ROLE_COLLABORATOR],
  },
  {
    path: Routes.projects.index,
    name: 'Project Management',
    icon: <ManageSearch />,
    role: [ERole.ROLE_TESTER, ERole.ROLE_ADMIN],
  },
  {
    path: Routes.projects.index,
    name: 'My Projects',
    icon: <ManageSearch />,
    role: [ERole.ROLE_CLIENT, ERole.ROLE_COLLABORATOR],
  },
  {
    path: Routes.findProjects.index,
    name: 'Find Projects',
    icon: <Search />,
    role: [ERole.ROLE_TESTER],
  },
  {
    path: Routes.payments.index,
    name: 'Payments',
    icon: <MonetizationOnOutlined />,
    role: [ERole.ROLE_TESTER],
  },
  {
    path: Routes.finance.index,
    name: 'Finance',
    icon: <MonetizationOnOutlined />,
    role: [ERole.ROLE_ADMIN, ERole.ROLE_CLIENT],
  },
  {
    path: Routes.productsReports.index,
    name: 'Products & Reports',
    icon: <KeyboardCommandKey />,
    role: [ERole.ROLE_ADMIN],
  },
  {
    path: Routes.settings.index,
    name: 'Settings',
    icon: <Settings />,
    tooltip: 'Settings page here',
  },
];

const bottomNavItems: SidebarRouteLink[] = [
  {
    path: Routes.tac,
    name: 'Terms And Conditions',
    icon: <FilePresent />,
    role: [ERole.ROLE_CLIENT, ERole.ROLE_TESTER],
  },
];

export const SidebarMenu: React.FC<MuiDrawerProps> = (props) => {
  return <NavSidebar navItems={navItems} bottomNavItems={bottomNavItems} {...props} />;
};
