import { InferType, object, string } from 'yup';

const MAX_TITLE_LENGTH = 128;

export const CreateFindingValidationSchema = object({
  title: string().required('Required Field').max(MAX_TITLE_LENGTH, `Too long. Max. ${MAX_TITLE_LENGTH} characters`),
  affectedHost: string().required('Required Field'),
  reportDescription: string().required('Required Field'),
  stepsReplicate: string().required('Required Field'),
  remediationRecommendation: string().required('Required Field'),
  affectedHttpRequest: string().required('Required Field'),
  // managementReportSummary: string().required('Required Field'),
});

export type CreateFindingValidationSchema = InferType<typeof CreateFindingValidationSchema>;
