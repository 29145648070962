import { object, string, InferType, boolean, number, array } from 'yup';

import { UserBaseSchema } from '@app/models/auth/auth.shared';
import { ERole } from '@app/swagger-types';

export const ClientDto = UserBaseSchema.concat(
  object({
    role: string().oneOf([ERole.ROLE_CLIENT]).required(),
    companyName: string().required(),
    companyAddress: string().optional().default(undefined),
    numOfProjects: number().required(),
    isD2NA: boolean().optional().default(false),
  })
);

export type ClientDto = InferType<typeof ClientDto>;

export const CollaboratorOutSchema = UserBaseSchema.concat(
  object({
    role: string().oneOf([ERole.ROLE_COLLABORATOR]).required(),
    companyName: string().required(),
    isD2NA: boolean().optional().default(false),
  })
);

export type CollaboratorOutSchema = InferType<typeof CollaboratorOutSchema>;

export const TesterDto = UserBaseSchema.concat(
  object({
    role: string().oneOf([ERole.ROLE_TESTER]).defined(),
    acceptedTac: boolean().defined(),
    acceptedTacDate: string().defined(),
    isAvailable: boolean().defined(),
    numOfProjects: number().defined(),
    paypalEmail: string().optional().default(''),
    country: string().optional().default(''),
    github: string().optional().default(''),
    linkedin: string().optional().default(''),
    address: string().optional().default(undefined),
    skills: array()
      .of(
        object({
          id: string().defined(),
          name: string().defined(),
        })
      )
      .optional()
      .default([]),
    certifications: array()
      .of(
        object({
          id: string().defined(),
          abbreviation: string().defined(),
          name: string().defined(),
        })
      )
      .optional()
      .default([]),
  })
);

export type TesterDto = InferType<typeof TesterDto>;
