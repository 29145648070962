import { useState, useCallback } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { UIKitSection } from '../UIKitSection';
import { ProjectInfoBox } from '@app/shared/ui-components/project-info-box/ProjectInfoBox';
import { createUiKitProject } from '@app/modules/ui-kit/pages/mock-data/project.data';
import { EApplicationStatus, EPayoutStatus, EProjectStatus } from '@app/swagger-types';

enum EMode {
  COMPLETED = 'COMPLETED',
  LIVE = 'LIVE',
  SCHEDULED = 'SCHEDULED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  NO_ANSWERS = 'NO_ANSWERS',
  NOT_PAID = 'NOT_PAID',
  NO_PRICE = 'NO_PRICE',
}

export default function DemoForProjectInfoBox() {
  const [mode, setMode] = useState(EMode.COMPLETED);

  const handleSetUp = useCallback(() => {
    console.log('on Set Up');
  }, []);

  return (
    <UIKitSection title="ProjectInfoBox">
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setMode(e.currentTarget.value as EMode);
        }}
      >
        <FormControlLabel value={EMode.COMPLETED} control={<Radio />} label="COMPLETED" />
        <FormControlLabel value={EMode.LIVE} control={<Radio />} label="LIVE" />
        <FormControlLabel value={EMode.SCHEDULED} control={<Radio />} label="SCHEDULED" />
        <FormControlLabel value={EMode.REJECTED} control={<Radio />} label="REJECTED" />
        <FormControlLabel value={EMode.PENDING} control={<Radio />} label="PENDING" />
        <FormControlLabel value={EMode.APPROVED} control={<Radio />} label="APPROVED" />
        <FormControlLabel value={EMode.NO_ANSWERS} control={<Radio />} label="NO ANSWERS" />
        <FormControlLabel value={EMode.NOT_PAID} control={<Radio />} label="NOT PAID" />
        <FormControlLabel value={EMode.NO_PRICE} control={<Radio />} label="NO PRICE" />
      </RadioGroup>
      {mode === EMode.COMPLETED && (
        <ProjectInfoBox project={createUiKitProject({ status: EProjectStatus.COMPLETED })} onSetUp={handleSetUp} />
      )}
      {mode === EMode.LIVE && (
        <ProjectInfoBox project={createUiKitProject({ status: EProjectStatus.LIVE })} onSetUp={handleSetUp} />
      )}
      {mode === EMode.SCHEDULED && (
        <ProjectInfoBox project={createUiKitProject({ status: EProjectStatus.SCHEDULED })} onSetUp={handleSetUp} />
      )}
      {mode === EMode.REJECTED && (
        <ProjectInfoBox project={createUiKitProject({ status: EProjectStatus.REJECTED })} onSetUp={handleSetUp} />
      )}
      {mode === EMode.PENDING && (
        <ProjectInfoBox project={createUiKitProject({ status: EProjectStatus.PENDING })} onSetUp={handleSetUp} />
      )}
      {mode === EMode.APPROVED && (
        <ProjectInfoBox project={createUiKitProject({ status: EProjectStatus.APPROVED })} onSetUp={handleSetUp} />
      )}
      {mode === EMode.NO_ANSWERS && (
        <ProjectInfoBox project={createUiKitProject({ answers: [] })} onSetUp={handleSetUp} />
      )}
      {mode === EMode.NO_PRICE && (
        <ProjectInfoBox
          project={createUiKitProject({
            priceForClient: undefined,
            priceForTester: undefined,
          })}
          onSetUp={handleSetUp}
        />
      )}
      {mode === EMode.NOT_PAID && (
        <ProjectInfoBox
          project={createUiKitProject({
            myApplication: {
              payoutStatus: EPayoutStatus.PENDING,
              id: '1',
              workingDays: 1,
              status: EApplicationStatus.ASSIGNED,
              createdDate: '1 Jul 2021',
            },
          })}
          onSetUp={handleSetUp}
        />
      )}
    </UIKitSection>
  );
}
