import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Button, Tooltip } from '@app/ui-components';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import styles from './DownloadPdfReportsDropdown.module.scss';
import { PdfPrerenderApi } from '@app/domain/pdf-prerender/api/pdf-prerender.api';
import { Routes } from '@app/constants/routes';
import { generatePath } from 'react-router-dom';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { normalizeFileNameForPDF } from '@app/domain/pdf-prerender/pdf.util';

export const DownloadPdfReportsDropdown: React.FC<{
  project: ProjectOutDtoSchema;
  projectId: string;
  disabled: boolean;
}> = ({ project, projectId, disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadReport = (url: string, fileName = '') => {
    try {
      setLoading(true);
      window.location.href = PdfPrerenderApi.getDownloadPdfLink({
        url,
        projectId,
        fileName,
      });
    } finally {
      handleClose();
      setTimeout(() => {
        setLoading(false);
      }, 8e3);
    }
  };

  const companyName = project.client.companyName;
  const projectEndDate = project.endDate && format(new Date(project.endDate), DATE_FORMAT);

  return (
    <div>
      <Tooltip title={disabled ? 'Available once project is completed' : undefined}>
        <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClick}
            endIcon={<ChevronDownIcon />}
            className="w-[170px]"
            disabled={disabled || loading}
            loading={loading}
          >
            Download Reports
          </Button>
        </div>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} classes={{ paper: styles.Paper }}>
        <MenuItem
          onClick={() =>
            downloadReport(
              generatePath(Routes.pdf.technicalReport, { projectId }),
              normalizeFileNameForPDF(`Technical Report ${companyName} ${project.name} ${projectEndDate}`)
            )
          }
          className="gap-1 text-xs"
        >
          <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-grey3">
            <FileDownloadOutlinedIcon className="w-[12px] fill-white" />
          </div>
          Technical Report
        </MenuItem>
        <MenuItem
          onClick={() =>
            downloadReport(
              generatePath(Routes.pdf.managementReport, { projectId }),
              normalizeFileNameForPDF(`Management Report ${companyName} ${project.name} ${projectEndDate}`)
            )
          }
          className="gap-1 text-xs"
        >
          <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-grey3">
            <FileDownloadOutlinedIcon className="w-[12px] fill-white" />
          </div>
          Management Report
        </MenuItem>
        <MenuItem
          onClick={() =>
            downloadReport(
              generatePath(Routes.pdf.publicReport, { projectId }),
              normalizeFileNameForPDF(`Public Report ${project.name} ${projectEndDate}`)
            )
          }
          className="gap-1 text-xs"
        >
          <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-grey3">
            <FileDownloadOutlinedIcon className="w-[12px] fill-white" />
          </div>
          Public Report
        </MenuItem>
      </Menu>
    </div>
  );
};
