import React from 'react';
import { StepIconProps } from '@mui/material';

import clsxm from '@app/lib/clsxm';
import { ActiveStepIcon, CompletedStepIcon, NonCompletedStepIcon } from '@app/assets/icons';

export const StyledStepIcon: React.FC<StepIconProps> = ({ active, className, completed }) => {
  return (
    <div className={clsxm(className, 'flex h-6 items-center')}>
      {active ? <ActiveStepIcon /> : completed ? <CompletedStepIcon /> : <NonCompletedStepIcon />}
    </div>
  );
};
