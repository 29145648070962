import React from 'react';
import { Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';

import { Status } from '@app/shared/ui-components/status/Status';
import { getFriendlyUserStatus } from '@app/domain/user/user.utils';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';
import { EUserStatus, UserInDto } from '@app/swagger-types';
import { GET_ADMIN_QUERY } from '@app/constants/query-api-configs';
import { UserApi } from '@app/domain/user/user.api';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { UserInfoBox } from '@app/domain/user-profile/UserInfoBox';
import { SuperAdminDto } from '@app/models/super-admin';

export const AdminProfilePage: React.FC = () => {
  const { id: adminId } = useTypedParams(Routes.admin.user_management.admin_profile);

  const {
    data: admin,
    isFetching,
    error,
    refetch,
  } = useQuery<SuperAdminDto | undefined, Error>(
    [GET_ADMIN_QUERY.name, { adminId }],
    async () => {
      if (adminId) {
        const { data } = await UserApi.getAdmin(adminId);
        return data;
      }
    },
    { keepPreviousData: true, staleTime: GET_ADMIN_QUERY.config.staleTime }
  );

  const { mutate: updateAdminProfile, isLoading } = useMutation<SuperAdminDto | undefined, Error, Partial<UserInDto>>(
    async (Dto) => {
      if (adminId) {
        const { data } = await UserApi.updateAdminProfile(adminId, Dto);
        return data;
      }
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  if (!admin || (isFetching && !admin) || error) {
    return <ContainerLoader loading={isFetching} error={error} noData={!admin} />;
  }

  const { status, firstName, lastName } = admin;

  const isDisabled = [EUserStatus.BANNED, EUserStatus.LOCKED, EUserStatus.DELETED].includes(status);

  return (
    <>
      <div>
        <div className="mb-32 flex items-center">
          <Typography className="mr-3.5 text-xxxl font-bold">{`${firstName} ${lastName}`}</Typography>
          <Status {...getFriendlyUserStatus(status)} />
        </div>
        <div className={clsxm('w-80 rounded-[10px] bg-transparentBackground', sharedStyle.shadowBorder)}>
          <UserInfoBox
            user={admin}
            disabled={isDisabled}
            onAdminClient={updateAdminProfile}
            isEditLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
