import React, { useState } from 'react';
import styles from '../pdf-salus.module.scss';
import clsxm from '@app/lib/clsxm';
import { BreakUnit, Footer, IPdfRenderResult, PdfPager } from '../PdfPager';
import { CLASSNAME_FOR_PDF_READY } from '../constants';
import { InvoiceFixedHeader } from './tac-shared';
import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { useQuery } from 'react-query';
import { GET_FINDINGS_QUERY, GET_REQUESTED_APPLICATIONS_QUERY } from '@app/constants/query-api-configs';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { AppLogo } from '@app/assets/app-logo/AppLogo';
import { formatDollars } from '@app/utils/currency.utils';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { ApplicationApi } from '@app/domain/application/application.api';
import { PaginatedResponse } from '@app/api/types';
import { ApplicationOutDtoSchema } from '@app/domain/application/application.schema';
import { FindingApi } from '@app/domain/finding/finding.api';
import { FindingOutSchema } from '@app/domain/finding/finding.schema';
import { TesterInvoiceBody } from '@app/modules/pdf/pages/tac/TesterInvoiceBody';
import { TesterOutDto } from '@app/swagger-types';

export const TesterInvoicePage: React.FC = () => {
  const { projectId } = useTypedParams(Routes.pdf.testerInvoice);
  const [result, setResult] = useState<IPdfRenderResult | null>(null);

  const { data: applications, error: applicationsError } = useQuery<
    PaginatedResponse<ApplicationOutDtoSchema> | undefined,
    Error
  >(
    [GET_REQUESTED_APPLICATIONS_QUERY.name, { projectId }],
    async () => {
      if (projectId) {
        return await ApplicationApi.getApplications({
          projectId,
        });
      }
    },
    { keepPreviousData: true, staleTime: GET_REQUESTED_APPLICATIONS_QUERY.config.staleTime }
  );

  const { data: findings, error: findingsError } = useQuery<PaginatedResponse<FindingOutSchema> | undefined, Error>(
    [GET_FINDINGS_QUERY.name, { projectId }],
    async () => {
      if (projectId) {
        return await FindingApi.getFindingsByProjectId({
          projectId,
          withBonuses: true,
        });
      }
    },
    { keepPreviousData: true, staleTime: GET_FINDINGS_QUERY.config.staleTime }
  );

  const application = applications?.result[0];

  const anyError = findingsError || applicationsError;

  if (!application || !projectId || !findings || anyError) {
    return (
      <div className="h-screen">
        <ContainerLoader loading={!application || !projectId || !findings} error={anyError} />
      </div>
    );
  }

  const renderPriceItem = ({
    title,
    unitPrice,
    qty,
    total,
  }: {
    title: string | number;
    unitPrice: string | number;
    qty: string | number;
    total: string | number;
  }) => (
    <div className="flex justify-between gap-3">
      <div className="flex-1">
        <div className={styles.p}>{title}</div>
      </div>
      <div className="flex-1">
        <div className={clsxm(styles.p, 'flex gap-1')}>
          <div className="flex-1 text-left">{formatDollars(unitPrice)}</div>
          <div className="flex-1 text-left">{qty}</div>
          <div className="flex-1 text-right">{formatDollars(total)}</div>
        </div>
      </div>
    </div>
  );

  const { extraPay } = application;
  const { firstName, lastName, email, address } = application.tester;
  const { workingDays, dayRateForTester } = application.project;

  const retestUnitPrice = findings.result[0]?.retestBonus || 0;
  const retestQTY = findings.result.length;
  const retestTotal = retestQTY * retestUnitPrice;

  const amountPrice = formatDollars(dayRateForTester * workingDays + extraPay + retestTotal);
  const dateInvoiceGenerated = format(new Date(), DATE_FORMAT);
  const fullName = `${firstName} ${lastName}`;

  return (
    <div className={clsxm(styles.mainWrap, result && CLASSNAME_FOR_PDF_READY)}>
      <InvoiceFixedHeader projectId={projectId} />

      <div className={clsxm(styles.page, 'bg-white pt-0')}>
        <BreakUnit>
          <div className={clsxm(styles.content, 'bg-[#191819] py-[20mm]')}>
            <div className="flex justify-between gap-3">
              <div className="flex-1">
                <AppLogo withName height={160} width={72} />
              </div>
              <div className="flex-1">
                <b className={styles.tacHeader}>Invoice</b>
                <div className="py-3">
                  <b className="text-white">Project Number:</b>
                  <div>{projectId}</div>
                </div>
                <div className="py-3">
                  <b className="text-white">Date:</b>
                  <div>{dateInvoiceGenerated}</div>
                </div>
              </div>
            </div>
          </div>
        </BreakUnit>
        <BreakUnit>
          <div className={styles.content}>
            <div className="flex justify-between gap-3 py-5">
              <div className="flex-1">
                <b className={clsxm(styles.p, 'text-black')}>Bill To:</b>
                <div className={styles.p}>Salus Experts Limited</div>
                <div className={styles.p}>Intershore Chambers</div>
                <div className={styles.p}>Road Town, Tortola, Virgin Islands - UK</div>
              </div>
              <div className="flex-1">
                <b className={clsxm(styles.p, 'text-black')}>Bill From:</b>
                <div className={styles.p}>{fullName}</div>
                <div className={styles.p}>{email}</div>
                <div className={styles.p}>{address}</div>
              </div>
            </div>
          </div>
        </BreakUnit>
        <BreakUnit>
          <div
            className={clsxm(
              styles.content,
              'flex justify-between gap-3 bg-[#F8F7FA] py-3 font-bold uppercase text-black'
            )}
          >
            <div className="flex-1">Item</div>
            <div className="flex flex-1 gap-1">
              <div className="flex-1 text-left">Unit Price</div>
              <div className="flex-1 text-left">Qty</div>
              <div className="flex-1 text-right">Total</div>
            </div>
          </div>
        </BreakUnit>
        <BreakUnit>
          <div className={clsxm(styles.content, ' py-3 text-black')}>
            {renderPriceItem({
              title: 'Base Payment For Project',
              unitPrice: dayRateForTester,
              qty: workingDays,
              total: dayRateForTester * workingDays,
            })}
            {renderPriceItem({
              title: 'Bonus Payment For Project',
              unitPrice: extraPay,
              qty: extraPay ? 1 : 0,
              total: extraPay,
            })}
            {renderPriceItem({
              title: 'Re-test Payment For Project',
              unitPrice: retestUnitPrice,
              qty: retestQTY,
              total: retestTotal,
            })}
          </div>
        </BreakUnit>
        <BreakUnit>
          <div className={clsxm(styles.content, 'flex justify-between gap-3 py-3 text-black')}>
            <div className="flex-1"></div>
            <div className="flex-1">
              <hr className="mb-5" />
              <div className="flex justify-between font-bold text-black">
                <div>Amount Due</div>
                <div>{amountPrice} USD</div>
              </div>
            </div>
          </div>
        </BreakUnit>
        <Footer pageNumber={1} isD2NA={false} />
      </div>

      <MemoPdf {...{ setResult, tester: application.tester }} />
    </div>
  );
};

// memoization is critical to avoid infinite loop rerendering
const MemoPdf = React.memo<{
  setResult: React.Dispatch<React.SetStateAction<IPdfRenderResult | null>>;
  tester: TesterOutDto;
}>(({ setResult, tester }) => {
  return (
    <PdfPager indexShift={1} onResult={setResult} headerSelector={`.${styles.pageHeader}`} isD2NA={false}>
      <TesterInvoiceBody tester={tester} />
    </PdfPager>
  );
});
