export const Routes = {
  index: '/',
  tac: 'terms-and-conditions',
  admin: {
    index: '/admin',
    user_management: {
      index: '/admin/user-management',
      clients: '/admin/user-management/clients',
      admins: '/admin/user-management/admins',
      collaborators: '/admin/user-management/collaborators',
      testers: '/admin/user-management/testers',
      my_profile: '/admin/user-management/my-profile',
      edit_my_profile: '/admin/user-management/my-profile/edit',
      tester_profile: '/admin/user-management/testers/:id',
      client_profile: '/admin/user-management/clients/:id',
      admin_profile: '/admin/user-management/admins/:id',
      collaborator_profile: '/admin/user-management/collaborators/:id',
      edit_tester_profile: '/admin/user-management/testers/:id/edit',
      nested: {
        index: '/admin/user-management/nested',
        page_1: '/admin/user-management/nested/page-1',
        page_2: '/admin/user-management/nested/page-2',
      },
    },
  },
  auth: {
    index: '/auth',
    sign_up: '/auth/sign-up',
    sign_up_tester: '/auth/invitation/tester-sign-up',
    sign_up_collaborator: '/auth/invitation/collaborator-sign-up',
    sign_in: '/auth/sign-in',
    forgot_password: '/auth/forgot-password',
    reset_password: '/auth/reset-password',
  },
  projects: {
    index: '/projects',
    allProjects: '/projects/all',
    pendingProjects: '/projects/pending',
    scheduledProjects: '/projects/scheduled',
    liveProjects: '/projects/live',
    completedProjects: '/projects/completed',
    project: '/projects/:id',
    questionnaire: '/projects/:id/questionnaire',
    addFinding: '/projects/:projectId/add-finding',
    editFinding: '/projects/:projectId/edit-finding/:findingId',
    finding: '/projects/:projectId/findings/:findingId',
    create: '/projects/create-project',
  },
  findProjects: {
    index: '/find-projects',
    all: '/find-projects/all',
    applied: '/find-projects/applied',
  },
  payments: {
    index: '/payments',
    projects: '/payments/projects',
    retestBonuses: '/payments/re-test-bonuses',
  },
  dashboard: {
    index: '/dashboard',
  },
  settings: {
    index: '/settings',
    workingDays: '/settings/working-days',
    findings: '/settings/findings',
    currency: '/settings/currency',
    logging: '/settings/logging',
    adminsLogging: '/settings/logging/admins',
    testersLogging: '/settings/logging/testers',
    clientsLogging: '/settings/logging/clients',
  },
  finance: {
    index: '/finance',
    invoices: '/finance/invoices',
    needToPay: '/finance/need-to-pay',
    paidOut: '/finance/paid-out',
    retestBonuses: '/finance/re-test-bonuses',
  },
  productsReports: {
    index: '/products-and-reports',
  },
  uiKit: {
    index: '/ui-kit',
  },
  pdf: {
    index: '/pdf',
    technicalReport: '/pdf/tech-report/:projectId',
    publicReport: '/pdf/public-report/:projectId',
    managementReport: '/pdf/management-report/:projectId',
    testerTac: '/pdf/tester-terms-and-conditions',
    clientTac: '/pdf/client-terms-and-conditions',
    clientInvoice: '/pdf/client-invoice/:invoiceId',
    clientQuoteEditor: '/pdf/client-quote-editor/:projectId',
    testerInvoice: '/pdf/tester-invoice/:projectId',
  },
} as const;
