import { Routes } from '@app/constants/routes';

import { NestedModule } from './NestedModule';
import { NestedPage1 } from './pages/NestedPage1';
import { NestedPage2 } from './pages/NestedPage2';
import { TypedNavigate } from '@app/router';

export const NestedRoutes = {
  path: Routes.admin.user_management.nested.index,
  element: <NestedModule />,
  children: [
    { path: Routes.admin.user_management.nested.page_1, element: <NestedPage1 /> },
    { path: Routes.admin.user_management.nested.page_2, element: <NestedPage2 /> },

    { index: true, path: '*', element: <TypedNavigate to={Routes.admin.user_management.nested.page_1} /> },
  ],
};
