import { Routes } from '@app/constants/routes';

import { TypedNavigate } from '@app/router';
import { AdminsLoggingPage } from '@app/modules/admin/modules/settings/nested/setting-logging/pages/AdminsLogging.page';
import { ClientsLoggingPage } from '@app/modules/admin/modules/settings/nested/setting-logging/pages/ClientsLogging.page';
import { TestersLoggingPage } from '@app/modules/admin/modules/settings/nested/setting-logging/pages/TestersLogging.page';
import { SettingLoggingModule } from '@app/modules/admin/modules/settings/nested/setting-logging/SettingLoggingModule';
import { ERole } from '@app/swagger-types';

export const SettingsLoggingRoutes = {
  path: Routes.settings.logging,
  element: <SettingLoggingModule />,
  role: [ERole.ROLE_ADMIN],
  children: [
    { path: Routes.settings.adminsLogging, element: <AdminsLoggingPage /> },
    { path: Routes.settings.testersLogging, element: <TestersLoggingPage /> },
    { path: Routes.settings.clientsLogging, element: <ClientsLoggingPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.settings.clientsLogging} /> },
  ],
};
