import React, { useMemo } from 'react';
import { format } from 'date-fns';
import pluralize from 'pluralize';

import { GET_PROJECTS_QUERY } from '@app/constants/query-api-configs';

import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';

import { Table, TableColumn } from '@app/shared/ui-components';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { DATE_FORMAT } from '@app/constants/date';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { Status } from '@app/shared/ui-components/status/Status';
import { getFriendlyProjectStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { isNumber } from 'lodash';
import { formatDollars } from '@app/utils/currency.utils';
import { useGetMyProjects } from '@app/domain/project/api/hooks/project.api.hook';
import { FindingStatsCell } from '@app/shared/tables/projects-table/FindingStatsCell';
import { PAGE_SIZE } from '@app/constants/api';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';

export const TesterProjectsTable: React.FC<{
  status?: OverrideProjectOutDto['status'];
}> = ({ status }) => {
  const navigate = useTypedNavigate();

  const navigateToProjectPage = ({ id }: OverrideProjectOutDto) => {
    navigate({ to: Routes.projects.project, params: { id } });
  };
  const {
    values: { page, sort = GET_PROJECTS_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      search: '',
    },
    prefix: GET_PROJECTS_QUERY.tablePrefix,
  });

  const { data, isFetching } = useGetMyProjects(
    {
      sort,
      page: Math.ceil((page || 1) - 1),
      size: PAGE_SIZE,
      status,
      term: search,
    },
    { keepPreviousData: true }
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <SearchInput placeholder="Search by name" className="w-64" value={search} onChange={handleSearchChange} />
      </div>
    );
  }, [handleSearchChange, search]);

  const columns: TableColumn<OverrideProjectOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Project Name',
        field: 'name',
        align: 'left',
      },
      {
        headerName: 'Stats',
        field: 'findingStats',
        align: 'center',
        disableSort: true,
        render: ({ findingStats }) => <FindingStatsCell findingStats={findingStats} />,
      },
      {
        headerName: 'Project Type',
        field: 'productTitle',
        align: 'left',
      },
      {
        headerName: 'Duration',
        field: 'workingDays',
        align: 'center',
        render: ({ workingDays }) => (isNumber(workingDays) ? pluralize('day', workingDays, true) : '-'),
      },
      {
        headerName: 'Income',
        field: 'priceForTester',
        align: 'center',
        render: ({ priceForTester }) => (isNumber(priceForTester) ? formatDollars(priceForTester) : '-'),
      },
      {
        headerName: 'Timeline',
        field: 'startDate',
        align: 'left',
        render: ({ startDate, endDate }: OverrideProjectOutDto) => {
          if (startDate && endDate) {
            return (
              <div>
                <div>{format(new Date(startDate), DATE_FORMAT)}</div>
                <div>{format(new Date(endDate), DATE_FORMAT)}</div>
              </div>
            );
          }

          return '-';
        },
      },
      {
        headerName: 'Client',
        field: 'client.firstName',
        align: 'left',
        disableClick: true,
        render: ({ client }) => <UserBlock user={client} hideName />,
      },
      {
        headerName: 'Status',
        field: 'status',
        align: 'left',
        width: 130,
        render: ({ status }) => <Status {...getFriendlyProjectStatus(status)} />,
      },
    ],
    []
  );

  return (
    <Table
      cols={columns}
      tableData={data?.result || []}
      leftHeaderContent={leftContent}
      notFoundMessage="No projects"
      currentPage={page}
      loading={isFetching}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
      hidePageSize
      onItemSelect={navigateToProjectPage}
    />
  );
};
