import React from 'react';
import { Avatar } from '@app/ui-components';
import { Box } from '@mui/material';
import { UserDto } from '@app/models/auth';

interface Props {
  countUsersToShow?: number;
  users: UserDto[];
}

export const UsersAvatarBlock: React.FC<Props> = ({ users, countUsersToShow = 2 }) => {
  return (
    <Box className="flex w-fit items-center">
      {users.slice(0, countUsersToShow).map(({ avatarId, id }) => (
        <Avatar key={id} width={30} height={30} imageId={avatarId} className="border-2 border-grey1 first:mr-[-10px]" />
      ))}

      {Boolean(users.length > countUsersToShow) && <span className="pl-2">+{users.length - countUsersToShow}</span>}
    </Box>
  );
};
