import React, { useMemo, useCallback } from 'react';

import { GET_FINDINGS_QUERY } from '@app/constants/query-api-configs';

import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';

import { Table, TableColumn, Avatar } from '@app/shared/ui-components';
import styles from '@app/shared/ui-components/layout/header/user-header-info/UserHeaderInfo.module.scss';
import { OverrideFindingOutDto } from '@app/swagger-override-types';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { StatusCell } from '@app/shared/tables/findings-table/StatusCell';
import { EApplicationStatus } from '@app/swagger-types';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import Notification from '@app/shared/ui-components/notifications/Notifications';
import useStore from '@app/store/useStore.hook';
import { renderFriendlyFindingStatus } from '@app/domain/finding/findings.utils';
import { Status } from '@app/shared/ui-components/status/Status';
import { FindingActions } from '@app/domain/finding/FindingActions';
import { SeverityBadge } from '@app/domain/finding/SeverityBadge';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { TruncatedTypography } from '@app/shared/ui-components/truncated-typography/TruncatedTypography';
import clsxm from '@app/lib/clsxm';
import {
  useDeleteFinding,
  useGetFindingsByProjectId,
  useUpdateFindingStatus,
} from '@app/domain/finding/api/hooks/finding.api.hook';
import { PAGE_SIZE } from '@app/constants/api';

export const FindingsTable: React.FC<{
  project: ProjectOutDtoSchema;
}> = ({ project }) => {
  const projectId = project.id;
  const currentUser = useStore.useCurrentUser();
  const { isTester } = useUserRole();
  const navigate = useTypedNavigate();

  const navigateToEditFindingPage = useCallback(
    (findingId: string) => {
      navigate({ to: Routes.projects.editFinding, params: { projectId, findingId } });
    },
    [projectId, navigate]
  );

  const navigateToFindingPage = useCallback(
    ({ id }: OverrideFindingOutDto) => {
      navigate({ to: Routes.projects.finding, params: { projectId, findingId: id } });
    },
    [projectId, navigate]
  );

  const {
    values: { page, sort = GET_FINDINGS_QUERY.defaultSort },
    handlers: { handlePageChange, handleSortChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
    },
    prefix: GET_FINDINGS_QUERY.tablePrefix,
  });

  const { isFetching, data } = useGetFindingsByProjectId(
    {
      disabledRequest: isTester && project.myApplication?.status !== EApplicationStatus.ASSIGNED,
      sort,
      page: Math.ceil((page || 1) - 1),
      size: PAGE_SIZE,
      projectId,
    },
    { keepPreviousData: true }
  );

  const { mutate: handleDelete, isLoading: isDeleting } = useDeleteFinding({
    onSuccess: () => {
      Notification.showSuccess('Finding has been successfully deleted');
    },
  });

  const { mutate: handleSetStatus, isLoading: isStatusChanging } = useUpdateFindingStatus();

  const tableColumns = useMemo(() => {
    const columns: TableColumn<OverrideFindingOutDto>[] = [
      {
        headerName: 'Title',
        field: 'title',
        align: 'left',
      },
      {
        headerName: 'Type',
        field: 'methodology',
        align: 'left',
        render: ({ methodology }) => (
          <TruncatedTypography limit={12} className="text-xs" value={methodology?.sectionTitle || 'Other'} />
        ),
      },
      {
        headerName: 'Severity',
        field: 'severity',
        align: 'left',
        render: ({ cvssScore }) => <SeverityBadge cvssScore={cvssScore} />,
      },
      {
        headerName: 'CVSS Score',
        field: 'cvssScore',
        align: 'center',
      },
      {
        headerName: 'Tester',
        field: 'tester.firstName',
        align: 'center',
        render: ({ tester }) => (
          <Avatar
            className={clsxm(styles.Avatar, 'mx-auto')}
            id="header-avatar-filter"
            width={30}
            height={30}
            imageId={tester.avatarId}
          />
        ),
      },
      {
        headerName: 'Status',
        field: 'status',
        align: 'left',
        disableClick: true,
        width: 185,
        render: ({ status, id, tester, isStatusChangedByClient }) => {
          if (isTester && tester.id !== currentUser?.id) {
            return <Status {...renderFriendlyFindingStatus(status)} />;
          }
          return (
            <StatusCell
              id={id}
              projectId={projectId}
              activeStatus={status}
              onChangeStatus={handleSetStatus}
              isStatusChangedByClient={isStatusChangedByClient}
            />
          );
        },
      },
    ];

    if (isTester) {
      columns.push({
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableSort: true,
        disableClick: true,
        render: ({ id, title, tester }) => (
          <FindingActions
            findingTitle={title}
            tester={tester}
            onEdit={() => navigateToEditFindingPage(id)}
            onDelete={() => handleDelete({ projectId, findingId: id })}
            disabled={isDeleting || isStatusChanging}
          />
        ),
      });
    }
    return columns;
  }, [
    isTester,
    currentUser,
    handleDelete,
    projectId,
    handleSetStatus,
    navigateToEditFindingPage,
    isDeleting,
    isStatusChanging,
  ]);

  return (
    <Table
      cols={tableColumns}
      tableData={data?.result || []}
      notFoundMessage="No Findings yet"
      currentPage={page}
      loading={isFetching || isStatusChanging || isDeleting}
      hidePageSize
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
      cardHeaderClassName="items-start"
      onItemSelect={navigateToFindingPage}
      wrapperClassName="!bg-transparent"
      noBorder
      noShadow
    />
  );
};
