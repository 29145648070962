import React, { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { UIKitSection } from '../UIKitSection';
import { CustomPieChart } from '@app/shared/ui-components/pie-chart/CustomPieChart';

enum EMode {
  HAS_DATA = 'HAS_DATA',
  NO_DATA = 'NO_DATA',
}

const data = [
  { name: 'Group A', value: 400, color: '#0088FE' },
  { name: 'Group B', value: 300, color: '#00C49F' },
  { name: 'Group C', value: 300, color: '#FFBB28' },
  { name: 'Group D', value: 200, color: '#FF8042' },
];

export default function DemoForPieChart() {
  const [mode, setMode] = useState(EMode.HAS_DATA);

  return (
    <UIKitSection title="PieChart" wrapperClassName="pb-4">
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setMode(e.currentTarget.value as EMode);
        }}
      >
        <FormControlLabel value={EMode.HAS_DATA} control={<Radio />} label="Has data" />
        <FormControlLabel value={EMode.NO_DATA} control={<Radio />} label="No data" />
      </RadioGroup>
      <div className="h-[45vh]">
        {mode === EMode.HAS_DATA && <CustomPieChart data={data} label={'$1,000'} />}
        {mode === EMode.NO_DATA && <CustomPieChart data={[]} label={''} />}
      </div>
    </UIKitSection>
  );
}
