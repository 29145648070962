import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import clsxm from '@app/lib/clsxm';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { inferIdentity } from '@app/utils/type.utils';
import { CREATE_FINDING_PAGE_PARAMS } from '@app/modules/projects/pages/CreateFindingPageParams';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { EMethodologyStatus } from '@app/swagger-types';
import { useHandler } from '@app/hooks/useHandler.hook';

interface Props {
  status: EMethodologyStatus;
  methodologyId: string;
  projectId: string;
  disabled?: boolean;
  onStatusChange?: (payload: { id: string; status: EMethodologyStatus }) => void;
}

export const MethodologyStatusButtons: React.FC<Props> = ({
  status,
  projectId,
  methodologyId,
  onStatusChange,
  disabled,
}) => {
  const navigate = useTypedNavigate();
  const { isTester } = useUserRole();

  const handleChange = useHandler((event: React.MouseEvent<HTMLElement>, newStatus: EMethodologyStatus | null) => {
    if (disabled) {
      return;
    }
    if (newStatus === EMethodologyStatus.FAIL) {
      navigate({
        to: Routes.projects.addFinding,
        params: { projectId },
        queryParams: inferIdentity<Record<keyof typeof CREATE_FINDING_PAGE_PARAMS, string>>()({
          checklistItemId: methodologyId,
        }),
      });
    } else {
      onStatusChange?.({ id: methodologyId, status: newStatus || EMethodologyStatus.TODO });
    }
  });

  return (
    <ToggleButtonGroup
      className="h-7"
      value={status}
      exclusive
      onChange={handleChange}
      disabled={!isTester || disabled}
    >
      <ToggleButton
        value={EMethodologyStatus.PASS}
        className={clsxm('rounded-l-md rounded-r-none text-xxs capitalize', {
          'bg-green/20': status === EMethodologyStatus.PASS,
        })}
      >
        Pass
      </ToggleButton>
      <ToggleButton
        value={EMethodologyStatus.FAIL}
        className={clsxm('rounded-none text-xxs capitalize', {
          'bg-error/20': status === EMethodologyStatus.FAIL,
        })}
      >
        Fail
      </ToggleButton>
      <ToggleButton
        value={EMethodologyStatus.N_A}
        className={clsxm('rounded-r-md rounded-l-none text-xxs capitalize', {
          'bg-grey2': status === EMethodologyStatus.N_A,
        })}
      >
        N/A
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
