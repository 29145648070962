import React from 'react';
import { AdminProjectsTable } from '@app/shared/tables/projects-table/AdminProjectsTable';
import { EView, useToggleViewButtons } from '@app/shared/ui-components/toggle-view-buttons/useToggleViewButtons';
import { AdminProjectList } from '@app/domain/project/components/AdminProjectList';
import { EProjectStatus } from '@app/swagger-types';

export const AdminProjectsPageBody: React.FC<{ filterStatus?: EProjectStatus }> = ({ filterStatus }) => {
  const [view] = useToggleViewButtons();
  return (
    <div className="mt-14">
      {view === EView.LIST && <AdminProjectList filterStatus={filterStatus} />}
      {view === EView.TABLE && <AdminProjectsTable status={filterStatus} />}
    </div>
  );
};
