import React, { useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { FormLabel, Typography } from '@mui/material';

import { AnswerDto, ERole, ProjectInDto } from '@app/swagger-types';
import { ProductApi } from '@app/domain/product/product.api';
import { CreateProjectStep } from '@app/modules/admin/create-project/CreateProjectStep';
import clsxm from '@app/lib/clsxm';
import { StyledRadio } from '@app/shared/ui-components/radio';
import { CreateProjectQuestionnaire } from '@app/modules/admin/create-project/CreateProjectQuestionnaire';
import { SubmitProject } from '@app/modules/admin/create-project/SubmitProject';
import { ReviewQuestionnaireDialog } from '@app/shared/ui-components/dialogs/ReviewQuestionnaireDialog';
import {
  CHECK_ANY_PROJECT_CREATED_QUERY,
  GET_PRODUCTS_QUERY,
  GET_PROJECTS_QUERY,
} from '@app/constants/query-api-configs';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { sharedStyle } from '@app/styles/sharedStyle';
import useStore from '@app/store/useStore.hook';
import { SetCompanyInfoStep } from '@app/modules/admin/create-project/SetCompanyInfoStep';
import { ProjectSubmittedStepProject } from '@app/modules/admin/create-project/ProjectSubmittedStep';

export const CreateProjectPage: React.FC = () => {
  const [type, setType] = useState('');
  const [projectId, setProjectId] = useState<string>();
  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5>(1);
  const [answers, setAnswers] = useState<AnswerDto[]>([]);
  const [openQuestionnaireDialog, setOpenQuestionnaireDialog] = useState(false);
  const [projectPayload, setProjectPayload] = useState<ProjectInDto>();
  const queryClient = useQueryClient();
  const navigate = useTypedNavigate();
  const { currentUser } = useStore();

  const navigateToProjectsPage = useCallback(() => {
    navigate({ to: Routes.projects.index });
  }, [navigate]);

  const handleReset = useCallback(() => {
    setType('');
    setStep(1);
    setAnswers([]);
    setProjectPayload(undefined);
  }, []);

  const handleCancel = useCallback(() => {
    handleReset();
    navigateToProjectsPage();
  }, [navigateToProjectsPage, handleReset]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  }, []);

  const handleSetAnswers = useCallback((answers: AnswerDto[]) => {
    setAnswers(answers);
  }, []);

  const { isFetching, data: products } = useQuery(
    [GET_PRODUCTS_QUERY.name],
    async () => {
      const data = await ProductApi.getProducts();
      return data.result;
    },
    { keepPreviousData: true, staleTime: GET_PRODUCTS_QUERY.config.staleTime }
  );

  const currentProduct = useMemo(() => {
    if (type && products?.length) {
      return products.find((p) => p.penetrationType === type);
    }

    return undefined;
  }, [products, type]);

  const handleSubmit = useCallback(() => {
    queryClient.invalidateQueries(GET_PROJECTS_QUERY.name);
    queryClient.invalidateQueries(CHECK_ANY_PROJECT_CREATED_QUERY.name);
    setStep(5);
  }, [queryClient]);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <div
        className={clsxm(
          'mx-auto max-w-[800px] rounded-[10px] bg-transparentBackground px-[150px] py-[50px]',
          sharedStyle.shadowBorder
        )}
      >
        {step === 1 && (
          <CreateProjectStep
            activeStep={1}
            title="Select a Penetration Test Type"
            onCancel={handleCancel}
            onConfirm={() => setStep(2)}
            disabledConfirm={!Boolean(type) || isFetching}
            disabledCancel={isFetching}
          >
            {isFetching ? (
              <div className="min-h-[100px]">
                <ContainerLoader loading={isFetching} />
              </div>
            ) : (
              <div className="mx-auto max-w-fit">
                {products?.map((product) => {
                  const isChecked = type === product.penetrationType;
                  return (
                    <FormLabel
                      key={product.id}
                      className={clsxm(
                        'mx-0 mb-9 flex w-80 items-center justify-between rounded-md  border-2 border-solid p-2.5 pl-5 duration-300 hover:border-grey6 hover:bg-[#3E414F]/30',
                        {
                          'border-grey3': !isChecked,
                          'border-grey6': isChecked,
                          'text-white': isChecked,
                          'bg-[#3E414F]/30': isChecked,
                        },
                        'cursor-pointer'
                      )}
                    >
                      <Typography className="text-s">{product.title}</Typography>
                      <StyledRadio
                        checked={isChecked}
                        onChange={handleChange}
                        value={product.penetrationType}
                        name="penetrationType"
                        inputProps={{ 'aria-label': product.penetrationType }}
                      />
                    </FormLabel>
                  );
                })}
              </div>
            )}
          </CreateProjectStep>
        )}
        {step === 2 && currentProduct && (
          <CreateProjectQuestionnaire
            activeStep={2}
            currentProduct={currentProduct}
            onBack={() => setStep(1)}
            onConfirm={() => {
              if (currentUser.role === ERole.ROLE_CLIENT && (!currentUser.companyName || !currentUser.companyAddress)) {
                setStep(4);
              } else {
                setStep(3);
              }
            }}
            onSetAnswers={handleSetAnswers}
            projectPayload={projectPayload}
            onSetProjectPayload={setProjectPayload}
          />
        )}
        {step === 3 && currentProduct && (
          <SubmitProject
            activeStep={3}
            onSubmit={handleSubmit}
            currentProduct={currentProduct}
            onBack={() => setStep(2)}
            projectPayload={projectPayload}
            onReset={handleReset}
            onSetProjectId={setProjectId}
            onReview={() => setOpenQuestionnaireDialog(true)}
          />
        )}
        {step === 4 && <SetCompanyInfoStep activeStep={4} onBack={() => setStep(2)} onConfirm={() => setStep(3)} />}
        {step === 5 && <ProjectSubmittedStepProject activeStep={5} projectId={projectId} />}
      </div>
      {currentProduct && (
        <ReviewQuestionnaireDialog
          title={currentProduct.title}
          open={openQuestionnaireDialog}
          answers={answers}
          onClose={() => setOpenQuestionnaireDialog(false)}
        />
      )}
    </>
  );
};
