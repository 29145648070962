import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { EUserStatus } from '@app/swagger-types';
import { UserApi } from '@app/domain/user/user.api';
import { invalidateAllAndSpecificUserProfileById } from '../util/user-profile.util';

export const useDeleteUser = ({ onSuccess, ...options }: UseMutationOptions<unknown, Error, string> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (userId) => {
      const { data } = await UserApi.updateUserStatus(userId, { status: EUserStatus.DELETED });
      return data;
    },
    {
      onSuccess: async (data, userId, context) => {
        await invalidateAllAndSpecificUserProfileById(queryClient, userId);
        onSuccess && (await onSuccess(data, userId, context));
      },
      ...options,
    }
  );
};

export const useBlockUser = ({ onSuccess, ...options }: UseMutationOptions<unknown, Error, string> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (userId) => {
      const { data } = await UserApi.updateUserStatus(userId, { status: EUserStatus.BANNED });
      return data;
    },
    {
      onSuccess: async (data, userId, context) => {
        await invalidateAllAndSpecificUserProfileById(queryClient, userId);
        onSuccess && (await onSuccess(data, userId, context));
      },
      ...options,
    }
  );
};

export const useActivateUser = ({ onSuccess, ...options }: UseMutationOptions<unknown, Error, string> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (userId) => {
      const { data } = await UserApi.updateUserStatus(userId, { status: EUserStatus.ACTIVE });
      return data;
    },
    {
      onSuccess: async (data, userId, context) => {
        await invalidateAllAndSpecificUserProfileById(queryClient, userId);
        onSuccess && (await onSuccess(data, userId, context));
      },
      ...options,
    }
  );
};
