import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Typography } from '@mui/material';
import { getDateWithUTCOffset } from '@app/utils/date.utils';
import { DAY_TIME_FORMAT } from '@app/constants/date';

export const HeaderClock = () => {
  const [date, setDate] = useState(getDateWithUTCOffset(new Date()));

  useEffect(() => {
    const ID = setInterval(() => {
      const date = getDateWithUTCOffset(new Date());
      setDate(date);
    }, 1e3);

    return () => {
      clearInterval(ID);
    };
  }, []);
  return <Typography className="text-l font-bold text-white/60">{format(date, DAY_TIME_FORMAT)} UTC</Typography>;
};
