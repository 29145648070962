import { MethodologyStatBox } from '@app/shared/ui-components/methodology-stat-box/MethodologyStatBox';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { UIKitSection } from '../UIKitSection';
import { uiKitMockMethodologies } from '../mock-data/methodology.data';
import { EMethodologyStatus } from '@app/swagger-types';

enum EMode {
  EMPTY = 'EMPTY',
  SOME_DATA = 'SOME_DATA',
  ALL_PASS = 'ALL_PASS',
  ALL_FAIL = 'ALL_FAIL',
  ALL_TODO = 'ALL_TODO',
  ALL_N_A = 'ALL_N_A',
}

export default function DemoForMethodologyStatBox() {
  const [mode, setMode] = useState(EMode.EMPTY);

  return (
    <UIKitSection title="MethodologyStatBox">
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setMode(e.currentTarget.value as EMode);
        }}
      >
        <FormControlLabel value={EMode.EMPTY} control={<Radio />} label="Empty" />
        <FormControlLabel value={EMode.SOME_DATA} control={<Radio />} label="Some Data" />
        <FormControlLabel value={EMode.ALL_PASS} control={<Radio />} label="ALL_PASS" />
        <FormControlLabel value={EMode.ALL_FAIL} control={<Radio />} label="ALL_FAIL" />
        <FormControlLabel value={EMode.ALL_TODO} control={<Radio />} label="ALL_TODO" />
        <FormControlLabel value={EMode.ALL_N_A} control={<Radio />} label="ALL_N_A" />
      </RadioGroup>
      <div className="h-[400px]">
        {mode === EMode.EMPTY && <MethodologyStatBox />}
        {mode === EMode.SOME_DATA && <MethodologyStatBox methodologies={uiKitMockMethodologies} />}
        {mode === EMode.ALL_PASS && <MethodologyStatBox methodologies={allPass} />}
        {mode === EMode.ALL_FAIL && <MethodologyStatBox methodologies={allFail} />}
        {mode === EMode.ALL_N_A && <MethodologyStatBox methodologies={allN_A} />}
        {mode === EMode.ALL_TODO && <MethodologyStatBox methodologies={allTODO} />}
      </div>
    </UIKitSection>
  );
}

const makeAllWithStatus = (desiredStatus: EMethodologyStatus) =>
  uiKitMockMethodologies.map((s) => {
    return {
      ...s,
      checklist: s.checklist?.map((c) => {
        return {
          ...c,
          status: desiredStatus,
        };
      }),
    };
  });

const allPass = makeAllWithStatus(EMethodologyStatus.PASS);
const allFail = makeAllWithStatus(EMethodologyStatus.FAIL);
const allTODO = makeAllWithStatus(EMethodologyStatus.TODO);
const allN_A = makeAllWithStatus(EMethodologyStatus.N_A);
