import { Routes } from '@app/constants/routes';

import { MyRouteObject } from '@app/models/router';
import { UIKitModule } from './UIKitModule';
import { UIKitPage } from './pages/UIKitPage';
import { IS_PROD_ENV } from '@app/environment/typed-env';

export const UIKitRoutes: MyRouteObject = {
  path: Routes.uiKit.index,
  element: <UIKitModule />,
  disabled: IS_PROD_ENV,
  children: [{ path: Routes.uiKit.index, element: <UIKitPage /> }],
};
