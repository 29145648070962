import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useQueryParams } from 'use-query-params';

import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { GET_PROJECT_QUERY } from '@app/constants/query-api-configs';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { createMockInvoice } from '@app/domain/invoice/invoice.mock';
import { createUiKitProject } from '@app/modules/ui-kit/pages/mock-data/project.data';
import { ProjectApi } from '@app/domain/project/project.api';
import { ClientInvoice } from '@app/modules/pdf/pages/tac/ClientInvoice';
import { ProjectOutDto } from '@app/swagger-types';
import { CLIENT_QUOTE_EDITOR_PAGE_PARAMS } from '@app/modules/pdf/pages/tac/ClientQuoteEditorPageParams';

export const ClientQuoteEditorPage: React.FC = () => {
  const { projectId } = useTypedParams(Routes.pdf.clientQuoteEditor);
  const [{ startDate, endDate, workingDays: queryWorkingDays, usdToGbpRate, isD2NA }] = useQueryParams(
    CLIENT_QUOTE_EDITOR_PAGE_PARAMS
  );

  const { data: project, error } = useQuery<ProjectOutDto | undefined, Error>(
    [GET_PROJECT_QUERY.name, { projectId }],
    async () => {
      if (projectId) {
        return await ProjectApi.getProject(projectId);
      }
    },
    { keepPreviousData: true, staleTime: GET_PROJECT_QUERY.config.staleTime }
  );
  const workingDays: ProjectOutDto['workingDays'] = Number(queryWorkingDays) || project?.workingDays || 1;
  const dayRateForClient = project?.dayRateForClient || 3000;
  const desiredTesterAssignment = project?.desiredTesterAssignment || 1;
  const amountCoin = dayRateForClient * workingDays * desiredTesterAssignment;

  const invoice = useMemo(
    () =>
      createMockInvoice({
        id: 'TBD',
        amountCoin,
        createdDate: new Date().toISOString(),
        project: createUiKitProject({
          ...project,
          workingDays,
          dayRateForClient,
          startDate: startDate || project?.startDate || new Date().toISOString(),
          endDate: endDate || project?.endDate || new Date().toISOString(),
          usdToGbpRate: usdToGbpRate ?? undefined,
          isD2NA: isD2NA ?? undefined,
        }),
        usdToGbpRate: usdToGbpRate ?? undefined,
        isD2NA: isD2NA ?? undefined,
      }),
    [project, workingDays, dayRateForClient, amountCoin, startDate, endDate, usdToGbpRate, isD2NA]
  );

  if (!project || error) {
    return (
      <div className="h-screen">
        <ContainerLoader loading={!project} error={error} />
      </div>
    );
  }

  console.log(invoice);
  return <ClientInvoice invoice={invoice} editableFirstPage showErrors={false} />;
};
