import React, { useMemo, useCallback } from 'react';
import { Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { Dialog } from '@app/hoc';
import { Button, Form, Input } from '@app/ui-components';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { InviteUserSchema } from '@app/domain/user/user.form';
import { ERole, InvitationDto } from '@app/swagger-types';
import { SendIcon } from '@app/assets/icons';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { UserApi } from '@app/domain/user/user.api';
import Notification from '@app/shared/ui-components/notifications/Notifications';
import { GET_TESTER_INVITATIONS_QUERY } from '@app/constants/query-api-configs';
import { useUserInvitationsQueryControls } from '@app/shared/tables/users-table/useUserInvitationsQueryControls';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const InviteTesterDialog: React.FC<Props> = ({ open, onClose }) => {
  const { control, handleSubmit, register, clearErrors, reset, formState } = useForm<InviteUserSchema>({
    resolver: yupResolver(InviteUserSchema),
  });
  const queryClient = useQueryClient();
  const {
    handlers: { handleResetQuery },
  } = useUserInvitationsQueryControls();

  const {
    mutate: inviteTester,
    isLoading,
    error,
  } = useMutation<unknown, Error, Omit<InvitationDto, 'role'>>(async (Dto) => {
    return await UserApi.inviteUserSilent({ ...Dto, role: ERole.ROLE_TESTER });
  });

  const handleClose = useCallback(() => {
    clearErrors();
    reset();
    onClose();
  }, [clearErrors, reset, onClose]);

  const onSubmit: SubmitHandler<InviteUserSchema> = (formData) => {
    inviteTester(formData, {
      onSuccess: () => {
        handleClose();
        queryClient.invalidateQueries(GET_TESTER_INVITATIONS_QUERY.name);
        handleResetQuery();
        Notification.showSuccess('The invitation has been successfully sent');
      },
    });
  };

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: isLoading,
      };
    };
    return fn;
  }, [register, formState, isLoading]);

  return (
    <Dialog open={open} paperClassName="p-7 w-[440px]" headerTitle="Add Tester" onClose={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)} control={control}>
        <div className="px-12">
          <div>
            <Typography className="mb-10 text-center text-xs font-medium text-grey5">
              Enter the email of the Tester and we will send him an invitation to the platform
            </Typography>
            <Input {...inputRegister('email')} placeholder="Enter email" label="Tester email" />
          </div>
          <div className="mb-3 mt-10 flex flex-col items-center">
            <div>
              <Button variant="outlined" color="primary" size="large" type="submit" startIcon={<SendIcon />}>
                Send
              </Button>
            </div>
            {formState.isSubmitted && maybeRenderError(error)}
          </div>
        </div>
      </Form>
    </Dialog>
  );
};
