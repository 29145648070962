import { EInvoiceStatus, InvoiceOutDto } from '@app/swagger-types';
import { createUiKitProject } from '@app/modules/ui-kit/pages/mock-data/project.data';
import { DEFAULT_USD_TO_GBP_RATE } from '@app/constants/currency';

export const createMockInvoice = (override?: Partial<InvoiceOutDto>): InvoiceOutDto => ({
  id: '1',
  project: createUiKitProject(),
  amountCoin: 0,
  status: EInvoiceStatus.PENDING,
  createdDate: new Date().toISOString(),
  usdToGbpRate: DEFAULT_USD_TO_GBP_RATE,
  isD2NA: false,
  ...override,
});
