import React from 'react';
import { Button } from '@app/ui-components';
import { preventEvent } from '@app/utils/event-handler.utils';
import { EUserInvitationStatus } from '@app/swagger-types';

export const InvitationStatusButton: React.FC<{
  invitationStatus: EUserInvitationStatus;
  onCancelUserInvite: () => void;
}> = ({ invitationStatus, onCancelUserInvite }) => {
  if (invitationStatus === EUserInvitationStatus.CANCELED) {
    return (
      <Button
        variant="outlined"
        color="secondary"
        className="w-32 cursor-default !border border-[#CA3434]/80 bg-[#3F2B33]/60 text-error"
        onClick={preventEvent}
      >
        Canceled
      </Button>
    );
  } else if (invitationStatus === EUserInvitationStatus.REGISTERED) {
    return (
      <Button
        variant="outlined"
        color="secondary"
        className="w-32 cursor-default !border border-green/30 bg-green/5 text-green"
        onClick={preventEvent}
      >
        Approved
      </Button>
    );
  }

  return (
    <Button
      variant="outlined"
      color="secondary"
      className="w-32 !border border-[#CA3434]/80 bg-[#3F2B33]/60 text-error hover:bg-[#CA3434]/30"
      onClick={onCancelUserInvite}
    >
      Cancel invite
    </Button>
  );
};
