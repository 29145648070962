import React from 'react';

import { Button, Tooltip } from '@app/ui-components';
import { preventEvent } from '@app/utils/event-handler.utils';
import clsxm from '@app/lib/clsxm';

export const RejectedProjectBadge: React.FC<{ rejectReason?: string; className?: string }> = ({
  rejectReason,
  className,
}) => {
  return (
    <div>
      <Tooltip title={rejectReason && `Reject reason: ${rejectReason}`}>
        <Button
          variant="outlined"
          color="secondary"
          className={clsxm('!border border-[#CA3434]/80 bg-[#3F2B33]/60 text-error', className, {
            'cursor-help': Boolean(rejectReason),
            'cursor-auto': !Boolean(rejectReason),
          })}
          onClick={preventEvent}
        >
          Rejected
        </Button>
      </Tooltip>
    </div>
  );
};
