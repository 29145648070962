/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
  message?: string;
}

export interface ErrorResponse {
  /** @format int64 */
  timestamp: number;

  /** @format int32 */
  status: number;
  errors: Error[];
}

export interface TesterInDto {
  firstName: string;
  lastName: string;

  /** @format uuid */
  avatarId?: string;
  paypalEmail?: string;

  /** @example Ukraine */
  country?: string;
  address?: string;
  github?: string;
  linkedin?: string;
  skills?: string[];
  certifications?: string[];
}

export interface CertificationDto {
  /** @format uuid */
  id: string;
  name: string;
  abbreviation: string;
}

export enum ERole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_TESTER = 'ROLE_TESTER',
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_COLLABORATOR = 'ROLE_COLLABORATOR',
}

export enum EUserStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  BANNED = 'BANNED',
  DELETED = 'DELETED',
}

export interface SkillDto {
  /** @format uuid */
  id: string;
  name: string;
}

export interface TesterOutDto {
  /** @format uuid */
  id: string;
  firstName: string;
  lastName: string;
  slackName?: string;
  slackDirectMessageURL?: string;
  email: string;
  role: ERole;

  /** @format uuid */
  avatarId?: string;
  status: EUserStatus;
  acceptedTac: boolean;

  /** @format date-time */
  acceptedTacDate: string;
  slackId?: string;

  /** @format date-time */
  createdDate: string;
  paypalEmail?: string;
  country?: string;
  address?: string;
  github?: string;
  linkedin?: string;

  /** @format int64 */
  numOfProjects: number;
  isAvailable: boolean;
  skills?: SkillDto[];
  certifications?: CertificationDto[];
}

export enum ESeverityLevel {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  INFORMATIVE = 'INFORMATIVE',
}

export interface ReportConfigDto {
  /** @format uuid */
  id: string;
  severityLevel: ESeverityLevel;

  /** @format int64 */
  priceCoin: number;
}

export enum EAttackComplexity {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum EAttackVector {
  NETWORK = 'NETWORK',
  ADJACENT = 'ADJACENT',
  LOCAL = 'LOCAL',
  PHYSICAL = 'PHYSICAL',
}

export enum EAvailability {
  NONE = 'NONE',
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum EConfidentiality {
  NONE = 'NONE',
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum EIntegrity {
  NONE = 'NONE',
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum EPrivilegesRequired {
  NONE = 'NONE',
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum EScope {
  UNCHANGED = 'UNCHANGED',
  CHANGED = 'CHANGED',
}

export enum EUserInteraction {
  NONE = 'NONE',
  REQUIRED = 'REQUIRED',
}

export interface FindingInDto {
  title: string;
  cvssAttackVector: EAttackVector;
  cvssAttackComplexity: EAttackComplexity;
  privilegesRequired: EPrivilegesRequired;
  cvssUserInteraction: EUserInteraction;
  cvssScope: EScope;
  cvssConfidentiality: EConfidentiality;
  cvssIntegrity: EIntegrity;
  cvssAvailability: EAvailability;
  affectedHost?: string;
  reportDescription?: string;
  stepsReplicate?: string;
  remediationRecommendation?: string;
  managementReportSummary?: string;
  affectedHttpRequest?: string;
  severityVector?: string;

  /** @format uuid */
  checklistItemId?: string;
}

export interface AnswerDto {
  /** @format uuid */
  id: string;
  title: string;
  value?: string;
  jsonConfig?: JsonNode;
  typeOfAnswer: ETypeOfAnswer;

  /** @format date-time */
  createdDate: string;
}

export interface BonusOutDto {
  severityLevel: ESeverityLevel;

  /** @format int64 */
  quantity: number;

  /** @format int64 */
  amountCoin: number;
}

export interface ChecklistOutDto {
  /** @format uuid */
  id: string;
  name: string;
  status: EMethodologyStatus;
  tester?: TesterOutDto;

  /** @format uuid */
  predefinedReportId: string;
  predefinedReport: PredefinedReport;
}

export interface ClientOutDto {
  /** @format uuid */
  id: string;
  firstName: string;
  lastName: string;
  slackName?: string;
  slackDirectMessageURL?: string;
  email: string;
  role: ERole;

  /** @format uuid */
  avatarId?: string;
  status: EUserStatus;
  acceptedTac: boolean;

  /** @format date-time */
  acceptedTacDate: string;
  slackId?: string;

  /** @format date-time */
  createdDate: string;
  companyName: string;
  companyAddress?: string;

  /** @format int64 */
  numOfProjects: number;
  isD2NA: boolean;
}

export interface CollaboratorOutDto {
  /** @format uuid */
  id: string;
  firstName: string;
  lastName: string;
  slackName?: string;
  slackDirectMessageURL?: string;
  email: string;
  role: ERole;

  /** @format uuid */
  avatarId?: string;
  status: EUserStatus;
  acceptedTac: boolean;

  /** @format date-time */
  acceptedTacDate: string;
  slackId?: string;

  /** @format date-time */
  createdDate: string;
  companyName: string;
  isD2NA: boolean;
}

export enum EApplicationStatus {
  REQUESTED = 'REQUESTED',
  ASSIGNED = 'ASSIGNED',
  REJECTED = 'REJECTED',
}

export enum EFindingStatus {
  REQUIRES_ATTENTION = 'REQUIRES_ATTENTION',
  RETEST = 'RETEST',
  FIXED = 'FIXED',
  INFORMATIVE = 'INFORMATIVE',
}

export enum EInvoiceStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
}

export enum EMethodologyStatus {
  TODO = 'TODO',
  PASS = 'PASS',
  FAIL = 'FAIL',
  N_A = 'N_A',
}

export enum EPayoutStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
}

export enum EPenetrationType {
  WEB_APP = 'WEB_APP',
  MOBILE = 'MOBILE',
  AWS_REVIEW = 'AWS_REVIEW',
  GCP_REVIEW = 'GCP_REVIEW',
  EXTERNAL_INFRASTRUCTURE = 'EXTERNAL_INFRASTRUCTURE',
  THICK_CLIENT = 'THICK_CLIENT',
  API = 'API',
  AZURE_REVIEW = 'AZURE_REVIEW',
  BLOCKCHAIN_AUDIT = 'BLOCKCHAIN_AUDIT',
}

export enum EProjectStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  SCHEDULED = 'SCHEDULED',
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
}

export enum ERetestPaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  NONE = 'NONE',
}

export enum ETypeOfAnswer {
  SHORT = 'SHORT',
  LONG = 'LONG',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  RADIO = 'RADIO',
}

export interface ExtraPayOutDto {
  /** @format int64 */
  totalAmountCoin: number;
  bonuses: BonusOutDto[];
}

export interface FindingMethodologyDto {
  sectionTitle: string;
  checklistItem: ChecklistOutDto;
}

export interface FindingOutDto {
  title: string;
  cvssAttackVector: EAttackVector;
  cvssAttackComplexity: EAttackComplexity;
  privilegesRequired: EPrivilegesRequired;
  cvssUserInteraction: EUserInteraction;
  cvssScope: EScope;
  cvssConfidentiality: EConfidentiality;
  cvssIntegrity: EIntegrity;
  cvssAvailability: EAvailability;
  affectedHost?: string;
  reportDescription?: string;
  stepsReplicate?: string;
  remediationRecommendation?: string;
  managementReportSummary?: string;
  affectedHttpRequest?: string;
  severityVector?: string;

  /** @format uuid */
  checklistItemId?: string;

  /** @format uuid */
  id: string;
  tester: TesterOutDto;
  project: ProjectOutDto;
  methodology?: FindingMethodologyDto;
  status: EFindingStatus;

  /** @format double */
  cvssScore: number;
  severity: ESeverityLevel;

  /** @format int32 */
  retestBonus?: number;
  retestPaymentStatus?: ERetestPaymentStatus;

  /** @format date-time */
  retestDeadline?: string;
  isStatusChangedByClient: boolean;

  /** @format date-time */
  updatedDate?: string;

  /** @format date-time */
  createdDate: string;
}

export interface FindingStatsOutDto {
  /** @format int64 */
  requiresAttention: number;

  /** @format int64 */
  retest: number;

  /** @format int64 */
  fixed: number;

  /** @format int64 */
  informative: number;
}

export interface InvoiceProjectDto {
  /** @format uuid */
  id: string;

  /** @format int64 */
  amountCoin: number;
  status: EInvoiceStatus;

  /** @format double */
  usdToGbpRate?: number;
  isD2NA: boolean;

  /** @format date-time */
  createdDate: string;
}

export type JsonNode = object;

export interface MethodologyDto {
  sectionTitle: string;
  checklist?: ChecklistOutDto[];
}

export interface MyApplicationOutDto {
  /** @format uuid */
  id: string;
  status: EApplicationStatus;
  payoutStatus: EPayoutStatus;

  /** @format int32 */
  workingDays?: number;

  /** @format int64 */
  extraPay?: number;

  /** @format date-time */
  createdDate: string;
}

export interface PredefinedReport {
  /** @format uuid */
  id: string;

  /** @format date-time */
  createdDate: string;
  title?: string;
  reportDescription?: string;
  remediationRecommendation?: string;
  managementReportSummary?: string;
}

export interface ProjectOutDto {
  /** @format uuid */
  id: string;
  penetrationType: EPenetrationType;
  name: string;

  /** @format date-time */
  desiredDeadline: string;
  comment?: string;
  isD2NA: boolean;

  /** @format double */
  usdToGbpRate?: number;
  productTitle: string;
  answers?: AnswerDto[];
  client: ClientOutDto;
  rejectReason?: string;

  /** @format int32 */
  desiredTesterAssignment: number;

  /** @format date */
  startDate?: string;

  /** @format date */
  endDate?: string;

  /** @format int32 */
  workingDays?: number;

  /** @format int32 */
  priceForClient?: number;

  /** @format int32 */
  dayRateForClient?: number;

  /** @format int32 */
  dayRateForTester?: number;

  /** @format int64 */
  numOfRequests?: number;
  invoices?: InvoiceProjectDto[];
  myBonus?: ExtraPayOutDto;

  /** @format int32 */
  priceForTester?: number;
  myApplication?: MyApplicationOutDto;
  status: EProjectStatus;
  findingStats?: FindingStatsOutDto;
  isNew?: boolean;
  methodologies?: MethodologyDto[];
  testers?: TesterOutDto[];
  collaborators?: CollaboratorOutDto[];

  /** @format date-time */
  createdDate: string;

  /** @format int64 */
  totalOtherFindings?: number;
}

export interface ProjectInDto {
  penetrationType: EPenetrationType;

  /** @example Cortex */
  name: string;

  /** @format date-time */
  desiredDeadline: string;

  /** @example Personal Project */
  comment?: string;

  /**
   * @format int32
   * @example 1
   */
  desiredTesterAssignment?: number;
  isD2NA?: boolean;

  /** @format double */
  usdToGbpRate?: number;
  answers: AnswerDto[];
}

export interface PredefinedReportDto {
  /** @format uuid */
  id: string;
  title: string;
  reportDescription?: string;
  remediationRecommendation?: string;
  managementReportSummary?: string;

  /** @format date-time */
  createdDate: string;
}

export interface DailyRateConfigDto {
  /** @format uuid */
  id: string;
  dailyRateFor: EDailyRateFor;

  /** @format int64 */
  priceCoin: number;
}

export enum EDailyRateFor {
  CLIENT = 'CLIENT',
  TESTER = 'TESTER',
}

export interface CurrencyConfigInDto {
  /** @format double */
  toUsdRate: number;
  currencyType: ECurrencyType;
}

export enum ECurrencyType {
  GBP = 'GBP',
}

export interface CurrencyConfigOutDto {
  /** @format uuid */
  id: string;
  currencyType: ECurrencyType;

  /** @format double */
  toUsdRate: number;
}

export interface CurrencyConfigGBPInDto {
  /** @format double */
  toUsdRate: number;
}

export interface CollaboratorInDto {
  firstName: string;
  lastName: string;

  /** @format uuid */
  avatarId?: string;

  /** @example OSDB */
  companyName?: string;
}

export interface ClientInDto {
  firstName: string;
  lastName: string;

  /** @format uuid */
  avatarId?: string;

  /** @example OSDB */
  companyName?: string;
  companyAddress?: string;
  isD2NA: boolean;
}

export interface BonusConfigInDto {
  /** @format int32 */
  priceCoin: number;
  bonusType: EBonusType;
}

export enum EBonusType {
  RETEST = 'RETEST',
}

export interface BonusConfigOutDto {
  /** @format uuid */
  id: string;
  bonusType: EBonusType;

  /** @format int32 */
  priceCoin: number;
}

export interface UserInDto {
  firstName: string;
  lastName: string;

  /** @format uuid */
  avatarId?: string;
}

export interface UserOutDto {
  /** @format uuid */
  id: string;
  firstName: string;
  lastName: string;
  slackName?: string;
  slackDirectMessageURL?: string;
  email: string;
  role: ERole;

  /** @format uuid */
  avatarId?: string;
  status: EUserStatus;
  acceptedTac: boolean;

  /** @format date-time */
  acceptedTacDate: string;
  slackId?: string;

  /** @format date-time */
  createdDate: string;
}

export interface SignUpStepTwoDto {
  /** @example ssmith@osdb.io */
  email: string;
  emailActivationCode: string;
}

export interface SignUpStepOneDto {
  /** @example ssmith@osdb.io */
  email: string;

  /** @example ssmith@osdb.io11Sep2017 */
  password?: string;

  /** @example Simon */
  firstName: string;

  /** @example Smith */
  lastName: string;

  /** @example OSDB */
  companyName?: string;
  companyAddress?: string;

  /** @example false */
  isD2NA?: boolean;
}

export interface SignInDto {
  /** @example dberchiyan@osdb.io */
  email: string;

  /** @example dberchiyan@osdb.io11Sep2017 */
  password: string;

  /** @example false */
  is2faEnabled?: boolean;
}

export interface SignInStepTwoInDto {
  /** @example dberchiyan@osdb.io */
  email: string;
  confirmCode: string;
}

export interface InvitationSignUpDto {
  /** @format uuid */
  invitationToken: string;

  /** @example John */
  firstName: string;

  /** @example Bastian */
  lastName: string;

  /** @example jbastian@osdb.io11Sep2017 */
  password?: string;
  paypalEmail?: string;

  /** @example Ukraine */
  country?: string;
  address?: string;
  github?: string;
  linkedin?: string;
  skills?: string[];
  certifications?: string[];
}

export interface InvitationCollaboratorSignUpDto {
  /** @format uuid */
  invitationToken: string;

  /** @example Steve */
  firstName: string;

  /** @example Jobs */
  lastName: string;

  /** @example sjobs@osdb.io11Sep2017 */
  password?: string;

  /** @example OSDB */
  companyName?: string;
}

export interface ResetPasswordDto {
  confirmCode: string;

  /** @example dberchiyan@osdb.io */
  email: string;

  /** @example dberchiyan@osdb.io11Sep2018 */
  newPassword?: string;
}

export interface ForgotPasswordDto {
  /** @example dberchiyan@osdb.io */
  email: string;
}

export interface InvitationDto {
  /** @example jbastian@osdb.io */
  email: string;
  role: ERole;
}

export interface AssignTesterInDto {
  /** @format uuid */
  testerId: string;
}

export interface CommentInDto {
  /** @example Lorem Ipsum is simply dummy text of the printing and typesetting industry. */
  text: string;
}

export interface CommentOutDto {
  /** @example Lorem Ipsum is simply dummy text of the printing and typesetting industry. */
  text: string;

  /** @format uuid */
  id: string;
  user: UserOutDto;

  /** @format uuid */
  findingId: string;

  /** @format date-time */
  createdDate: string;
}

export interface FileDto {
  /** @format uuid */
  id: string;
  fileName: string;

  /** @format date-time */
  createdDate: string;
}

export interface UserStatusDto {
  /**
   * @pattern ACTIVE|BANNED|DELETED
   * @example BANNED
   */
  status: string;
}

export interface InvitationStatusDto {
  /**
   * @pattern PENDING|CANCELED
   * @example PENDING
   */
  status: string;
}

export interface FindingStatusInDto {
  status: EFindingStatus;
}

export interface FindingRetestPaymentStatusInDto {
  /**
   * @pattern PENDING|PAID
   * @example PAID
   */
  retestPaymentStatus: string;
}

export interface ChecklistStatusInDto {
  status: EMethodologyStatus;
}

export interface TimelineInDto {
  /** @format date */
  startDate: string;

  /** @format date */
  endDate: string;

  /** @format int32 */
  workingDays: number;

  /** @format int32 */
  priceForClient: number;

  /** @format int32 */
  priceForTester: number;
  fileName?: string;
  isD2NA?: boolean;

  /** @format double */
  usdToGbpRate?: number;
}

export interface ProjectStatusInDto {
  /**
   * @pattern REJECTED
   * @example REJECTED
   */
  status: string;
  rejectReason?: string;
}

export interface DayRateInDto {
  /** @format int32 */
  dayRateForClient: number;

  /** @format int32 */
  dayRateForTester: number;
}

export interface InvoiceStatusInDto {
  /**
   * @pattern PENDING|PAID
   * @example PAID
   */
  status: string;
}

export interface ChangePasswordDto {
  /** @example dberchiyan@osdb.io11Sep2017 */
  currPassword: string;

  /** @example dberchiyan@osdb.io11Sep2018 */
  newPassword?: string;
}

export interface AcceptedTacInDto {
  acceptedTac: boolean;
}

export interface ApplicationStatusInDto {
  /**
   * @pattern ASSIGNED|REJECTED
   * @example ASSIGNED
   */
  status: string;
}

export interface ApplicationPayoutStatusInDto {
  /**
   * @pattern PENDING|PAID
   * @example PAID
   */
  status: string;
}

export interface CurrencyDto {
  rates: CurrencyConfigOutDto[];
}

export interface PageResponseUserOutDto {
  result: UserOutDto[];

  /** @format int64 */
  total: number;
}

export enum EFileType {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
}

export enum EUserInvitationStatus {
  PENDING = 'PENDING',
  REGISTERED = 'REGISTERED',
  CANCELED = 'CANCELED',
}

export interface PageResponseUserInvitationDto {
  result: UserInvitationDto[];

  /** @format int64 */
  total: number;
}

export interface UserInvitationDto {
  /** @format uuid */
  id: string;
  email: string;
  role: ERole;
  status: EUserInvitationStatus;
  invitedBy: UserOutDto;

  /** @format uuid */
  projectId?: string;

  /** @format date-time */
  createdDate: string;
}

export interface ReportConfigOutDto {
  configs: ReportConfigDto[];
}

export interface PageResponseProjectOutDto {
  result: ProjectOutDto[];

  /** @format int64 */
  total: number;
}

export interface PageResponseFindingOutDto {
  result: FindingOutDto[];

  /** @format int64 */
  total: number;
}

export interface DayRateOutDto {
  /** @format int32 */
  dayRateForClient: number;

  /** @format int32 */
  dayRateForTester: number;
}

export interface ChecklistBlueprintDto {
  /** @format uuid */
  id: string;
  name: string;

  /** @format uuid */
  predefinedReportId: string;
  predefinedReport: PredefinedReport;
}

export interface MethodologyBlueprintDto {
  sectionTitle: string;
  checklist: ChecklistBlueprintDto[];
}

export interface PageResponseProductOutDto {
  result: ProductOutDto[];

  /** @format int64 */
  total: number;
}

export interface ProductOutDto {
  /** @format uuid */
  id: string;
  title: string;
  penetrationType: EPenetrationType;
  methodologyTitle?: string;
  questionnaireTitle?: string;
  methodologies?: MethodologyBlueprintDto[];
  questions?: QuestionDto[];

  /** @format date-time */
  createdDate: string;
}

export interface QuestionDto {
  /** @format uuid */
  id: string;
  title: string;
  jsonConfig: JsonNode;
  typeOfAnswer: string;

  /** @format date-time */
  createdDate: string;
}

export interface PageResponsePredefinedReportDto {
  result: PredefinedReportDto[];

  /** @format int64 */
  total: number;
}

export interface InvoiceOutDto {
  /** @format uuid */
  id: string;
  project: ProjectOutDto;

  /** @format int64 */
  amountCoin: number;
  status: EInvoiceStatus;

  /** @format double */
  usdToGbpRate: number;
  isD2NA: boolean;

  /** @format date-time */
  createdDate: string;
}

export interface PageResponseInvoiceOutDto {
  result: InvoiceOutDto[];

  /** @format int64 */
  total: number;
}

export interface PageResponseCommentOutDto {
  result: CommentOutDto[];

  /** @format int64 */
  total: number;
}

export interface ClientFindingStatsOutDto {
  /** @format int64 */
  requiresAttention: number;

  /** @format int64 */
  retest: number;

  /** @format int64 */
  fixed: number;

  /** @format int64 */
  informative: number;

  /** @format int64 */
  total: number;
}

export interface TesterFinanceStatsOutDto {
  /** @format int64 */
  extraPay: number;

  /** @format int64 */
  priceForTester: number;

  /** @format int64 */
  retestBonus: number;

  /** @format int64 */
  total: number;
}

export interface DailyRateConfigOutDto {
  configs: DailyRateConfigDto[];
}

export interface BonusDto {
  bonuses: BonusConfigOutDto[];
}

export interface ApplicationOutDto {
  /** @format uuid */
  id: string;
  project: ProjectOutDto;
  tester: TesterOutDto;
  status: EApplicationStatus;
  payoutStatus: EPayoutStatus;

  /** @format int32 */
  workingDays?: number;

  /** @format int64 */
  extraPay?: number;

  /** @format date-time */
  createdDate: string;
}

export interface PageResponseApplicationOutDto {
  result: ApplicationOutDto[];

  /** @format int64 */
  total: number;
}

export enum EActionLogType {
  SIGN_IN = 'SIGN_IN',
  FP_PASSWORD = 'FP_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  GENERATE_REPORT = 'GENERATE_REPORT',
}

export interface ActionLogOutDto {
  /** @format uuid */
  id: string;
  user: UserOutDto;
  metaInfo?: JsonNode;

  /** @format int32 */
  statusCode: number;
  message?: string;
  type: EActionLogType;
  ipAddress: string;
  country?: string;

  /** @format date-time */
  createdDate: string;
}

export interface PageResponseActionLogOutDto {
  result: ActionLogOutDto[];

  /** @format int64 */
  total: number;
}
