import React, { useState, useCallback } from 'react';
import { Typography } from '@mui/material';

import { Dialog } from '@app/hoc';
import { Button } from '@app/ui-components';
import { MethodologyBlueprintSchema } from '@app/domain/product/product.schema';
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PredefinedReportSchema } from '@app/domain/project/project.schema';
import { PredefinedReportDialog } from '@app/domain/product/PredefinedReportDialog';

interface Props {
  open: boolean;
  onClose: () => void;
  methodologies?: MethodologyBlueprintSchema[];
  methodologyTitle?: string;
}

export const ProductMethodologiesDialog: React.FC<Props> = ({
  open,
  onClose,
  methodologies = [],
  methodologyTitle,
}) => {
  const [predefinedReport, setPredefinedReport] = useState<PredefinedReportSchema>();

  const handleOpenPredefinedReportDialog = useCallback((report: PredefinedReportSchema) => {
    setPredefinedReport(report);
  }, []);

  const handleClosePredefinedReportDialog = useCallback(() => {
    setPredefinedReport(undefined);
  }, []);

  return (
    <>
      <Dialog open={open} paperClassName="w-[530px] p-7 max-w-none" headerTitle={methodologyTitle} onClose={onClose}>
        <div className="max-h-[70vh] overflow-y-auto p-6 pt-0">
          <Typography className="text-xxl font-bold">{methodologyTitle}</Typography>
          {methodologies.map(({ sectionTitle, checklist }, index) => (
            <div key={index}>
              <Typography className="mb-4 mt-6 text-xl font-bold">{sectionTitle}</Typography>
              {checklist.map(({ id, name, predefinedReport }) => (
                <div key={id} className="mb-3.5 flex justify-between">
                  <Typography className="max-w-[60%] text-xs text-grey6">{name}</Typography>
                  <Button
                    variant="text"
                    className="!bg-transparent p-0 text-xs font-normal hover:underline"
                    endIcon={<ChevronUpIcon className="h-4 rotate-90" />}
                    onClick={() => handleOpenPredefinedReportDialog(predefinedReport)}
                  >
                    Pre-defined report
                  </Button>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="mb-3 mt-10 flex justify-center">
          <Button onClick={onClose} variant="outlined" color="secondary" size="large">
            Close
          </Button>
        </div>
      </Dialog>
      <PredefinedReportDialog
        open={Boolean(predefinedReport)}
        onClose={handleClosePredefinedReportDialog}
        predefinedReport={predefinedReport}
      />
    </>
  );
};
