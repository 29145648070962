import React from 'react';
import { Typography } from '@mui/material';

import { Dialog } from '@app/hoc';
import { Button } from '@app/ui-components';
import { PredefinedReportSchema } from '@app/domain/project/project.schema';

interface Props {
  open: boolean;
  onClose: () => void;
  predefinedReport?: PredefinedReportSchema;
}

export const PredefinedReportDialog: React.FC<Props> = ({ open, onClose, predefinedReport }) => {
  const renderInfoBlock = (title: string, value: string) => (
    <div className="mb-10 last:mb-0">
      <Typography className="mb-4 text-m font-bold">{title}</Typography>
      <Typography className="text-xs text-grey6">{value}</Typography>
    </div>
  );
  return (
    <Dialog open={open} paperClassName="w-[730px] p-7 max-w-none" headerTitle="Pre-defined report" onClose={onClose}>
      <div className="max-h-[70vh] overflow-y-auto rounded-lg bg-grey1 p-7">
        {predefinedReport?.reportDescription &&
          renderInfoBlock('Report Description', predefinedReport.reportDescription)}
        {predefinedReport?.remediationRecommendation &&
          renderInfoBlock('Remediation Recommendation', predefinedReport.remediationRecommendation)}
        {/*{predefinedReport?.managementReportSummary &&*/}
        {/*  renderInfoBlock('Management Report Summary', predefinedReport.managementReportSummary)}*/}
      </div>
      <div className="mb-3 mt-10 flex justify-center">
        <Button onClick={onClose} variant="outlined" color="secondary" size="large">
          Close
        </Button>
      </div>
    </Dialog>
  );
};
