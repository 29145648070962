import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

import { TableCard } from '@app/shared/ui-components/table-card';
import { CustomPieChart } from '@app/shared/ui-components/pie-chart/CustomPieChart';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { useQuery } from 'react-query';
import { GET_FINDING_STATISTIC_QUERY } from '@app/constants/query-api-configs';
import { DashboardApi } from '@app/domain/dashboard/dashboard.api';

export const FindingsStatisticBox: React.FC = () => {
  const { isFetching, data: totalFindings } = useQuery(
    GET_FINDING_STATISTIC_QUERY.name,
    async () => {
      return await DashboardApi.getFindingStats();
    },
    { keepPreviousData: true, staleTime: GET_FINDING_STATISTIC_QUERY.config.staleTime }
  );

  const data = useMemo(() => {
    const data = [];
    if (totalFindings?.retest !== undefined) {
      data.push({ name: 'Re-test', value: totalFindings.retest, color: '#6E9FFF' });
    }
    if (totalFindings?.fixed !== undefined) {
      data.push({ name: 'Fixed', value: totalFindings.fixed, color: '#6CD950' });
    }
    if (totalFindings?.requiresAttention !== undefined) {
      data.push({ name: 'Requires Attention', value: totalFindings.requiresAttention, color: '#FF9141' });
    }
    if (totalFindings?.informative !== undefined) {
      data.push({ name: 'Informative', value: totalFindings.informative, color: '#FFFFFF' });
    }
    return data;
  }, [totalFindings?.retest, totalFindings?.fixed, totalFindings?.requiresAttention, totalFindings?.informative]);

  const total = totalFindings?.total || 0;

  return (
    <TableCard wrapperClassName="p-8 h-full" cardContentClassName="h-full min-h-full" hidePageSize hideFooter noShadow>
      <Typography className="mb-4 text-xxl font-bold">
        <span className="flex items-center">
          Total Findings
          <span className="ml-1.5 flex items-center justify-center rounded-full bg-grey2 py-1 px-2 text-xs">
            {total}
          </span>
        </span>
      </Typography>
      {isFetching && <ContainerLoader loading={isFetching} />}
      {!isFetching && <CustomPieChart data={data} label={total} height="75%" />}
    </TableCard>
  );
};
