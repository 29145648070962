import * as React from 'react';

import { CommonOpenableEntityProps } from '@app/services/openable-entity-manager';

import { VerificationDialogContent } from './dialog-content/VerificationDialogContent';
import { Dialog } from '@app/hoc';

type PropTypes = CommonOpenableEntityProps;

export const VerificationDialog: React.FC<PropTypes> = (props) => {
  return (
    <Dialog {...props} fullWidth>
      <VerificationDialogContent {...props} />
    </Dialog>
  );
};
