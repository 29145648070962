import React, { useState } from 'react';
import { Chip, Typography } from '@mui/material';

import { Avatar, IconButton, Tooltip } from '@app/ui-components';
import clsxm from '@app/lib/clsxm';
import { EditIcon, EmailIcon, FolderIcon, SlackIcon, WalletIcon } from '@app/assets/icons';
import { CountryInfo } from '@app/shared/ui-components/country-info/CountryInfo';
import { UserDto } from '@app/models/auth';
import { ClientInDto, CollaboratorInDto, ERole, UserInDto } from '@app/swagger-types';
import { UseMutateFunction } from 'react-query/types/react/types';
import { EditClientDialog } from '@app/domain/user-profile/EditClientDialog';
import { EditAdminDialog } from '@app/domain/user-profile/EditAdminDialog';
import { renderFriendlyRole } from '../user/user.utils';
import SyncIcon from '@mui/icons-material/Sync';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import { useSlackSync } from '../user/useSlackSync.hook';
import useStore from '@app/store/useStore.hook';
import { EditCollaboratorDialog } from '@app/domain/user-profile/EditCollaboratorDialog';
import { TruncatedTypography } from '@app/shared/ui-components/truncated-typography/TruncatedTypography';

interface Props {
  user: UserDto;
  disabled?: boolean;
  isEditLoading?: boolean;
  onEditClient?: UseMutateFunction<unknown, Error, Partial<ClientInDto>>;
  onEditCollaborator?: UseMutateFunction<unknown, Error, Partial<CollaboratorInDto>>;
  onAdminClient?: UseMutateFunction<unknown, Error, Partial<UserInDto>>;
}

export const UserInfoBox: React.FC<Props> = ({
  user,
  disabled,
  onEditClient,
  onAdminClient,
  isEditLoading,
  onEditCollaborator,
}) => {
  const { avatarId, firstName, lastName, email } = user;
  const [openDialog, setOpenDialog] = useState(false);
  const { sync, isLoading } = useSlackSync();
  const { currentUser } = useStore();

  const isMyProfile = currentUser?.id === user.id;
  const isTester = user.role === ERole.ROLE_TESTER;
  const isClient = user.role === ERole.ROLE_CLIENT;
  const isCollaborator = user.role === ERole.ROLE_COLLABORATOR;
  const isAdmin = user.role === ERole.ROLE_ADMIN;

  return (
    <>
      <div className=" relative flex flex-col items-center py-10 px-16">
        <Avatar height={162} width={162} imageId={avatarId} className="mt-[-121px] mb-5" />
        <Typography className="mb-4 text-xxxl font-bold">{`${firstName} ${lastName}`}</Typography>
        <Chip label={renderFriendlyRole(user.role)} className="mr-1 mb-6 h-6 text-xxs text-white/60" />
        <div
          className={clsxm({
            'pointer-events-none opacity-20': disabled,
          })}
        >
          {(onEditClient || onAdminClient || onEditCollaborator) && (
            <IconButton onClick={() => setOpenDialog(true)} className="absolute top-[24px] right-[24px] bg-grey2">
              <EditIcon />
            </IconButton>
          )}
          <div className="group relative">
            {isMyProfile && (
              <div className={clsxm('absolute -left-8 -top-1 pr-6 group-hover:flex', !isLoading && 'hidden')}>
                <Tooltip title="Sync Slack profile">
                  <div
                    className={clsxm('cursor-pointer', isLoading && 'animate-spin cursor-wait')}
                    onClick={() => !isLoading && sync()}
                    aria-hidden
                  >
                    <SyncIcon />
                  </div>
                </Tooltip>
              </div>
            )}
            <a
              className="mb-3.5 flex items-center"
              href={user.slackDirectMessageURL}
              onClick={(e) => !user.slackDirectMessageURL && e.preventDefault()}
              target="_blank"
              rel="noreferrer"
            >
              <SlackIcon className="mr-3.5 h-[13px] w-[15px]" />
              <Typography className="text-m">{user.slackName || 'No Slack info'}</Typography>
            </a>
          </div>
          {(isClient || isCollaborator) && user.companyName && (
            <div className="mb-3.5 flex items-center">
              <Tooltip title="Company Name">
                <FolderIcon className="mr-3.5 h-[13px] w-[15px]" />
              </Tooltip>
              <Typography className="text-m">{user.companyName}</Typography>
            </div>
          )}
          {isTester && user.paypalEmail && (
            <div className="mb-3.5 flex items-center">
              <Tooltip title="PayPal account">
                <WalletIcon className="mr-3.5 h-[15px] w-[15px]" />
              </Tooltip>
              <Typography className="text-m">{user.paypalEmail}</Typography>
            </div>
          )}
          <div className="mb-3.5 flex items-center">
            <EmailIcon className="mr-3.5 h-[13px] w-[15px]" />
            <Typography className="text-m">{email}</Typography>
          </div>
          {isClient && user.companyAddress && (
            <div className="mb-3.5 flex items-center">
              <Tooltip title="Company Address">
                <BusinessIcon className="mr-3.5 h-[15px] w-[15px] fill-grey6" />
              </Tooltip>
              <TruncatedTypography value={user.companyAddress} className="text-m" />
            </div>
          )}
          {isTester && user.address && (
            <div className="mb-3.5 flex items-center">
              <Tooltip title="Address">
                <HomeIcon className="mr-3.5 h-[15px] w-[15px] fill-grey6" />
              </Tooltip>
              <TruncatedTypography value={user.address} className="text-m" />
            </div>
          )}
          {isTester && user.country && <CountryInfo countryName={user.country} className="mr-3.5" />}
        </div>
      </div>
      {isClient && onEditClient && (
        <EditClientDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          client={user}
          onConfirm={onEditClient}
          isLoading={isEditLoading}
        />
      )}
      {isCollaborator && onEditCollaborator && (
        <EditCollaboratorDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          collaborator={user}
          onConfirm={onEditCollaborator}
          isLoading={isEditLoading}
        />
      )}
      {isAdmin && onAdminClient && (
        <EditAdminDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          admin={user}
          onConfirm={onAdminClient}
          isLoading={isEditLoading}
        />
      )}
    </>
  );
};
