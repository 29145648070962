import { useMemo } from 'react';
import { TableColumn } from '@app/ui-components';
import { OverrideFindingOutDto } from '@app/swagger-override-types';
import { getDayDifference } from '@app/utils/date.utils';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { formatDollars } from '@app/utils/currency.utils';
import { Typography } from '@mui/material';

export const usePendingRetestColumns = () => {
  const { isTester } = useUserRole();
  return useMemo(() => {
    const columns: TableColumn<OverrideFindingOutDto>[] = [
      {
        headerName: 'Report name',
        field: 'title',
        align: 'left',
      },
      {
        headerName: 'Project name',
        field: 'project.name',
        align: 'left',
        render: ({ project: { name } }) => name,
      },
      {
        headerName: 'Days left',
        field: 'retestDeadline',
        align: 'center',
        render: ({ retestDeadline }) => (retestDeadline ? getDayDifference(new Date(retestDeadline), new Date()) : 0),
      },
    ];

    if (isTester) {
      columns.push({
        headerName: 'Re-test Bonus',
        field: 'retestBonus',
        align: 'center',
        render: ({ retestBonus }) =>
          retestBonus ? (
            <Typography className="text-xs font-medium text-green">+{formatDollars(retestBonus)}</Typography>
          ) : (
            '-'
          ),
      });
    }

    return columns;
  }, [isTester]);
};
