import { DialogActions, DialogContent, Typography } from '@mui/material';
import { CommonOpenableEntityProps } from '@app/services/openable-entity-manager';

import { ReactComponent as LinkSentImg } from '@app/assets/images/link-sent.svg';
import { Button, VerificationInput } from '@app/shared/ui-components';

type PropTypes = CommonOpenableEntityProps;
export const VerificationDialogContent: React.FC<PropTypes> = ({ onClose }) => {
  return (
    <>
      <DialogContent className="flex w-full flex-col items-center space-y-6  p-20 pb-12">
        <LinkSentImg />
        <Typography variant="xxxl" className="font-bold">
          Verify your email address
        </Typography>
        <Typography align="center" className="w-2/3 text-lightText dark:text-white">
          Enter the 6 digit code we sent to your email to verify your account
        </Typography>
        <VerificationInput length={6} containerClassName="w-3/4" />
      </DialogContent>
      <DialogActions className="w-full justify-center p-20 pt-0">
        <div className="flex w-3/4 flex-row space-x-4">
          <Button variant="outlined" color="primary" fullWidth onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" fullWidth>
            Verify
          </Button>
        </div>
      </DialogActions>
    </>
  );
};
