import { identityQueryConfig } from '@app/services/api-request/react-query.utils';
import { buildSortParam } from '@app/utils/api.util';
import {
  ApplicationOutDto,
  InvoiceOutDto,
  TesterOutDto,
  UserInvitationDto,
  CertificationDto,
  SkillDto,
  ActionLogOutDto,
  CommentOutDto,
} from '@app/swagger-types';
import { OverrideProjectOutDto, OverrideFindingOutDto } from '@app/swagger-override-types';
import { UserDto } from '@app/models/auth';

export const GET_USERS_QUERY = identityQueryConfig({
  name: 'get-users',
  defaultSort: buildSortParam<UserDto>('email', 'asc'),
  tablePrefix: 'gut',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_FINDINGS_QUERY = identityQueryConfig({
  name: 'get-findings',
  defaultSort: buildSortParam<OverrideFindingOutDto>('createdDate', 'desc'),
  tablePrefix: 'gft',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_PROJECTS_QUERY = identityQueryConfig({
  name: 'get-projects',
  defaultSort: buildSortParam<OverrideProjectOutDto>('createdDate', 'desc'),
  tablePrefix: 'gpt',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_TESTERS_QUERY = identityQueryConfig({
  name: 'get-testers',
  defaultSort: buildSortParam<TesterOutDto>('firstName', 'asc'),
  tablePrefix: 'gtt',
  config: {
    staleTime: 60000,
  },
} as const);
export const GET_REQUESTED_APPLICATIONS_QUERY = identityQueryConfig({
  name: 'get-requested-app',
  defaultSort: buildSortParam<ApplicationOutDto>('createdDate', 'desc'),
  tablePrefix: 'grat',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_ASSIGNED_APPLICATIONS_QUERY = identityQueryConfig({
  name: 'get-assigned-app',
  defaultSort: buildSortParam<ApplicationOutDto>('createdDate', 'desc'),
  tablePrefix: 'gaat',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_TESTER_INVITATIONS_QUERY = identityQueryConfig({
  name: 'get-tester-invitations-app',
  defaultSort: buildSortParam<UserInvitationDto>('createdDate', 'desc'),
  tablePrefix: 'gtit',
  config: {
    staleTime: 60000,
  },
} as const);
export const GET_INVOICES_QUERY = identityQueryConfig({
  name: 'get-invoices-app',
  defaultSort: buildSortParam<InvoiceOutDto>('createdDate', 'desc'),
  tablePrefix: 'git',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_INVOICE_BY_ID_QUERY = identityQueryConfig({
  name: 'get-invoice-by-id-app',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_FINANCES_QUERY = identityQueryConfig({
  name: 'get-finances-app',
  defaultSort: buildSortParam<ApplicationOutDto>('createdDate', 'desc'),
  tablePrefix: 'gaft',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_PROJECT_QUERY = identityQueryConfig({
  name: 'get-project',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_MY_PROFILE_QUERY = identityQueryConfig({
  name: 'get-my-profile',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_CERTIFICATES_QUERY = identityQueryConfig({
  name: 'get-certificates',
  defaultSort: buildSortParam<CertificationDto>('name', 'asc'),
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_SKILLS_QUERY = identityQueryConfig({
  name: 'get-skills',
  defaultSort: buildSortParam<SkillDto>('name', 'asc'),
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_TESTER_QUERY = identityQueryConfig({
  name: 'get-tester',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_CLIENT_QUERY = identityQueryConfig({
  name: 'get-client',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_ADMIN_QUERY = identityQueryConfig({
  name: 'get-admin',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_COLLABORATOR_QUERY = identityQueryConfig({
  name: 'get-collaborator',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_SETTINGS_WORKING_DAYS_QUERY = identityQueryConfig({
  name: 'get-settings-working-days',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_SETTINGS_FINDING_CONFIGS_QUERY = identityQueryConfig({
  name: 'get-settings-finding-configs',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_SETTINGS_FINDING_BONUSES_QUERY = identityQueryConfig({
  name: 'get-settings-finding-bonuses',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_ACTION_LOGS_QUERY = identityQueryConfig({
  name: 'get-action-logs',
  defaultSort: buildSortParam<ActionLogOutDto>('createdDate', 'desc'),
  tablePrefix: 'gal',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_NOT_ASSIGNED_PROJECTS_QUERY = identityQueryConfig({
  name: 'get-all-scheduled-projects',
  defaultSort: buildSortParam<OverrideProjectOutDto>('createdDate', 'desc'),
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_APPLIED_PROJECTS_QUERY = identityQueryConfig({
  name: 'get-my-scheduled-projects',
  defaultSort: buildSortParam<OverrideProjectOutDto>('createdDate', 'desc'),
  config: {
    staleTime: 60000,
  },
} as const);

export const CHECK_ANY_PROJECT_CREATED_QUERY = identityQueryConfig({
  name: 'check-any-project-created',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_FINANCE_STATISTIC_QUERY = identityQueryConfig({
  name: 'get-finance-statistic',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_FINDING_STATISTIC_QUERY = identityQueryConfig({
  name: 'get-finding-statistic',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_PENDING_RETEST_FINDINGS_QUERY = identityQueryConfig({
  name: 'get-pending-retest-findings',
  defaultSort: buildSortParam<OverrideFindingOutDto>('updatedDate', 'desc'),
  tablePrefix: 'gprf',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_ALL_PENDING_RETEST_FINDINGS_QUERY = identityQueryConfig({
  name: 'get-all-pending-retest-findings',
  defaultSort: buildSortParam<OverrideFindingOutDto>('updatedDate', 'desc'),
  tablePrefix: 'gaprf',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_RETEST_BONUSES_QUERY = identityQueryConfig({
  name: 'get-retest-bonuses',
  defaultSort: buildSortParam<OverrideFindingOutDto>('updatedDate', 'desc'),
  tablePrefix: 'grb',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_FINDING_QUERY = identityQueryConfig({
  name: 'get-finding',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_PRODUCTS_QUERY = identityQueryConfig({
  name: 'get-products',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_COLLABORATOR_INVITATIONS_QUERY = identityQueryConfig({
  name: 'get-collaborator-invitations-app',
  defaultSort: buildSortParam<UserInvitationDto>('createdDate', 'desc'),
  tablePrefix: 'gcit',
  config: {
    staleTime: 60000,
  },
} as const);

export const GET_FINDING_COMMENTS_QUERY = identityQueryConfig({
  name: 'get-finding-comments',
  defaultSort: buildSortParam<CommentOutDto>('createdDate', 'asc'),
  config: {
    staleTime: 60000,
  },
} as const);
