import React, { useCallback } from 'react';

import { Form, Input } from '@app/ui-components';
import { CreateProjectStep } from '@app/modules/admin/create-project/CreateProjectStep';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ClientAddressSchema } from '@app/domain/user-profile/user-profile.form';
import useStore from '@app/store/useStore.hook';
import { ClientInDto, ERole } from '@app/swagger-types';
import { UserProfileApi } from '@app/domain/user-profile/user-profile.api';
import { useMutation } from 'react-query';
import { ClientDto } from '@app/models/user';

export const SetCompanyInfoStep: React.FC<{
  onBack: () => void;
  activeStep: number;
  onConfirm: () => void;
}> = ({ onBack, onConfirm, activeStep }) => {
  const { currentUser, setCurrentUser } = useStore();
  const { control, formState, handleSubmit, register, clearErrors } = useForm<ClientAddressSchema>({
    defaultValues:
      currentUser?.role === ERole.ROLE_CLIENT
        ? {
            companyName: currentUser.companyName,
            companyAddress: currentUser.companyAddress,
          }
        : undefined,
  });

  const {
    mutate: updateMyClientProfile,
    isLoading,
    error,
  } = useMutation<ClientDto, Error, Partial<ClientInDto>>(async (values) => {
    const { data } = await UserProfileApi.updateMyClientProfile(values);
    return data;
  });

  const onSubmit: SubmitHandler<ClientAddressSchema> = useCallback(
    (formData) => {
      clearErrors();
      updateMyClientProfile(formData, {
        onSuccess: (client) => {
          setCurrentUser(client);
          onConfirm();
        },
      });
    },
    [clearErrors, onConfirm, updateMyClientProfile, setCurrentUser]
  );

  const inputRegister: typeof register = (key, ...args) => {
    return {
      ...register(key, ...args),
      errorMessage: formState.errors[key]?.message,
    };
  };

  return (
    <CreateProjectStep
      activeStep={activeStep}
      title="Company Info"
      subTitle="Please provide your company legal name and address"
      onCancel={onBack}
      cancelText="Back"
      confirmButtonType="submit"
      disabledCancel={isLoading}
      confirmLoading={isLoading}
      error={error}
      render={({ header, actionButtons }) => (
        <Form control={control} onSubmit={handleSubmit(onSubmit)}>
          {header}
          <Input
            {...inputRegister('companyName', { required: true, minLength: 2 })}
            className="mb-5"
            label="Company Name"
            placeholder="Enter Company Name"
          />
          <Input
            {...inputRegister('companyAddress', { required: true, minLength: 2 })}
            className="mb-5"
            label="Company Address"
            placeholder="Enter Company Address"
          />
          {actionButtons}
        </Form>
      )}
    />
  );
};
