import React, { useCallback, useMemo } from 'react';
import { LinearProgress, Tabs, Typography } from '@mui/material';
import { format } from 'date-fns';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useQuery } from 'react-query';

import { Table, TableColumn, Tooltip } from '@app/ui-components';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { getPercentagesBetweenTwoDates } from '@app/utils/date.utils';
import { DATE_FORMAT } from '@app/constants/date';
import { TestersInfo } from '@app/shared/tables/projects-table/TestersInfo';
import { OutlinedTab } from '@app/shared/ui-components/tabs/OutlinedTab';
import { GET_PROJECTS_QUERY } from '@app/constants/query-api-configs';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { ProjectApi } from '@app/domain/project/project.api';
import { EProjectStatus } from '@app/swagger-types';
import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { isNumber } from 'lodash';
import { NOT_ALLOWED_FOR_VIEW_PRICE } from '@app/constants/currency';

enum ETabs {
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
}

export const AssignedProjectsTable: React.FC<{ testerId?: string; clientId?: string }> = ({ testerId, clientId }) => {
  const [{ tab }, setQuery] = useQueryParams({
    tab: withDefault(StringParam, ETabs.LIVE),
  });
  const navigate = useTypedNavigate();
  const { isTester, isCollaborator, isClient } = useUserRole();

  const navigateToProjectPage = ({ id }: OverrideProjectOutDto) => {
    navigate({ to: Routes.projects.project, params: { id } });
  };

  const handleSetTab = useCallback(
    (_: React.SyntheticEvent, tab: ETabs) => {
      setQuery({ tab }, 'replaceIn');
    },
    [setQuery]
  );

  const {
    values: { sort = GET_PROJECTS_QUERY.defaultSort },
    handlers: { handleSortChange },
  } = useBasicQueryControls({
    prefix: GET_PROJECTS_QUERY.tablePrefix,
  });

  const { data: projectsData, isFetching: loadingProjects } = useQuery(
    [GET_PROJECTS_QUERY.name, { sort, clientId, testerId }],
    async () => {
      if (clientId || testerId) {
        return await ProjectApi.getProjects({
          sort,
          page: 0,
          size: 999,
          clientId,
          testerId,
        });
      }
      return await ProjectApi.getMyProjects({
        sort,
        page: 0,
        size: 999,
      });
    },
    { keepPreviousData: true, staleTime: GET_PROJECTS_QUERY.config.staleTime }
  );

  const { liveProjects, completedProjects } = useMemo(() => {
    let liveProjects: OverrideProjectOutDto[] = [];
    let completedProjects: OverrideProjectOutDto[] = [];

    if (projectsData?.result) {
      liveProjects = projectsData.result.filter((p) => p.status === EProjectStatus.LIVE);
      completedProjects = projectsData.result.filter((p) => p.status === EProjectStatus.COMPLETED);
    }

    return { liveProjects, completedProjects };
  }, [projectsData?.result]);

  const leftContent = useMemo(() => {
    return (
      <div>
        <Typography className="mb-8 text-xxxl font-bold">Projects assigned</Typography>
        <Tabs
          value={tab}
          className="my-5"
          onChange={handleSetTab}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <OutlinedTab label="Live" count={liveProjects.length} value={ETabs.LIVE} />
          <OutlinedTab label="Completed" value={ETabs.COMPLETED} count={completedProjects.length} />
        </Tabs>
      </div>
    );
  }, [tab, handleSetTab, liveProjects.length, completedProjects.length]);

  const columns: TableColumn<OverrideProjectOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Company',
        field: 'client.companyName',
        align: 'left',
        render: ({ client: { companyName } }) => companyName,
      },
      {
        headerName: 'Project Name',
        field: 'name',
        align: 'left',
      },
      {
        headerName: 'Project Type',
        field: 'productTitle',
        align: 'left',
      },
      {
        headerName: isTester ? 'Income' : 'Price',
        field: 'priceForTester',
        align: 'center',
        render: ({ priceForTester, priceForClient, isD2NA, usdToGbpRate }) => {
          if (isTester && isNumber(priceForTester)) {
            return formatDollars(priceForTester);
          }
          if (isClient && isNumber(priceForClient)) {
            return isD2NA ? createGBPformatter(usdToGbpRate).formatGBP(priceForClient) : formatDollars(priceForClient);
          }
          if (isCollaborator) {
            return NOT_ALLOWED_FOR_VIEW_PRICE;
          }
          return '-';
        },
      },
      {
        headerName: 'Timeline',
        field: 'startDate',
        align: 'left',
        disableSort: true,
        render: ({ startDate, endDate }) => {
          if (startDate && endDate) {
            const percentages = getPercentagesBetweenTwoDates(new Date(startDate), new Date(endDate), new Date());

            return (
              <Tooltip
                title={`${format(new Date(startDate), DATE_FORMAT)} - ${format(new Date(endDate), DATE_FORMAT)}`}
                placement="top"
              >
                <LinearProgress
                  color="primary"
                  className="h-1.5 w-full bg-[#EEEEEE]"
                  variant="determinate"
                  value={percentages}
                />
              </Tooltip>
            );
          }

          return '-';
        },
      },
      {
        headerName: 'Testers',
        field: 'testers',
        disableSort: true,
        align: 'center',
        render: ({ testers }) => {
          if (testers?.length) {
            return <TestersInfo testers={testers} countTestersToShow={2} />;
          }
          return '-';
        },
      },
    ],
    [isTester, isClient, isCollaborator]
  );

  return (
    <Table
      cols={columns}
      tableData={tab === ETabs.LIVE ? liveProjects : completedProjects}
      hidePageSize
      onSortChanged={handleSortChange}
      loading={loadingProjects}
      leftHeaderContent={leftContent}
      currentSort={sort}
      notFoundMessage="No projects yet"
      cardContentClassName="min-h-[30vh] overflow-auto"
      onItemSelect={navigateToProjectPage}
    />
  );
};
