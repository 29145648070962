import { JsonConfigBaseSchema } from './narrow-config-utils/base-question-config.schema';
import { RadioQuestionConfigSchema } from './narrow-config-utils/radio-question-config.schema';

export enum EPenetrationType {
  WEB_APP = 'WEB_APP',
  MOBILE = 'MOBILE',
  EXTERNAL_INFRASTRUCTURE = 'EXTERNAL_INFRASTRUCTURE',
  THICK_CLIENT = 'THICK_CLIENT',
  API = 'API',
  AZURE_REVIEW = 'AZURE_REVIEW',
  AWS_REVIEW = 'AWS_REVIEW',
  GCP_REVIEW = 'GCP_REVIEW',
}

export enum ETypeOfAnswer {
  SHORT = 'SHORT',
  LONG = 'LONG',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',

  // new type
  RADIO = 'RADIO',
}

// TODO replace with QuestionOutDto
export interface IQuestion {
  // id: string;
  title: string;
  typeOfAnswer: ETypeOfAnswer;
  // e.g. list of checkbox options
  jsonConfig: JsonConfigBaseSchema;
}

interface IPredefinedReport {
  title: string;
  reportDescription: string;
  remediationRecommendation: string;
}

interface IMethodologySectionBlueprint {
  sectionTitle: string;
  checklist: {
    name: string;
    predefinedReport: IPredefinedReport;
  }[];
}

// This is the main interface to exchange with BE API
// TODO use DTO from API
export interface ProductDto {
  title: string;
  penetrationType: EPenetrationType;
  methodologyTitle: string;
  methodologyBlueprint: IMethodologySectionBlueprint[];
  questionnaireTitle: string;
  questions: IQuestion[];
}

///////////////////////////////////////////

/*

For Web - DONE

For Mobile - DONE

External Infrastructure - DONE

Thick Client - DONE

API - DONE

Azure Review - DONE

AWS Review - DONE

GCP Review - DONE

*/

// date of compatible version
export const QUESTION_CONFIG_VERSION = 'Apr-13-2022';

// TODO validate conditional union
export type UnionQuestionConfig = RadioQuestionConfigSchema;
