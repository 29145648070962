import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { v4 as uuid } from 'uuid';
import { range } from 'lodash';

import { Form, Input, Select, OptionItem } from '@app/ui-components';
import { DATE_FORMAT } from '@app/constants/date';
import clsxm from '@app/lib/clsxm';
import { CreateProjectStep } from '@app/modules/admin/create-project/CreateProjectStep';
import { ETypeOfAnswer } from './product.type';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PROJECT_FIELDS } from '@app/domain/project/project.constant';
import { DatePickerInput } from '@app/shared/ui-components/date-picker-input/DatePickerInput';
import { AnswerDto, ProjectInDto } from '@app/swagger-types';
import { ProductOutSchema } from '@app/domain/product/product.schema';
import { useRenderProductQuestion } from '@app/domain/product/useRenderProductQuestion';
import { ProductQuestionnaireForm } from '@app/domain/product/product.form';
import { ScopingCalculator } from '@app/modules/admin/create-project/ScopingCalculator';
import { useMaybeCurrentClient } from '@app/domain/user/user.hook';
import { IS_D2NA_DOMAIN } from '@app/domain/d2na/domain.util';
import { usePublicGPBRateQuery } from '@app/domain/settings/currency/currency.query';

const _INITIAL_DEADLINE = new Date();
_INITIAL_DEADLINE.setDate(_INITIAL_DEADLINE.getDate() + 1);
const INITIAL_DEADLINE = _INITIAL_DEADLINE.toJSON();
const MIN_DATE = new Date(INITIAL_DEADLINE);

const DEFAULT_DESIRED_TESTER_ASSIGNMENT = 1;

export const CreateProjectQuestionnaire: React.FC<{
  currentProduct: ProductOutSchema;
  onBack: () => void;
  onConfirm: () => void;
  activeStep: number;
  onSetAnswers: (answers: AnswerDto[]) => void;
  onSetProjectPayload: (payload?: ProjectInDto) => void;
  projectPayload?: ProjectInDto;
}> = ({ currentProduct, activeStep, onBack, onConfirm, onSetAnswers, onSetProjectPayload, projectPayload }) => {
  // TODO add yup validation (now for field 'answers' doesn't validate)
  const { control, formState, handleSubmit, register, clearErrors, reset, watch } = useForm<ProductQuestionnaireForm>({
    defaultValues: projectPayload,
  });
  const maybeClient = useMaybeCurrentClient();

  const projectAnswers: AnswerDto[] = currentProduct.questions.map((q, index) => ({
    ...q,
    value: watch().answers ? watch().answers[index].value : '',
  }));

  const onSubmit: SubmitHandler<ProductQuestionnaireForm> = useCallback(
    (formData) => {
      clearErrors();
      const project: ProjectInDto = {
        ...formData,
        penetrationType: currentProduct.penetrationType,
        answers: currentProduct.questions.map((q, index) => {
          return {
            ...q,
            value: formData.answers[index]?.value || '',
          };
        }),
      };

      const desiredDeadline = project[PROJECT_FIELDS.desiredDeadline];
      const desiredTesterAssignment = project[PROJECT_FIELDS.desiredTesterAssignment];

      const answers: AnswerDto[] = [
        {
          id: uuid(),
          title: 'What is the name of project?',
          value: project[PROJECT_FIELDS.name],
          typeOfAnswer: ETypeOfAnswer.SHORT,
          createdDate: new Date().toJSON(),
        },
        {
          id: uuid(),
          title: 'When would you like this project done by?',
          value: desiredDeadline ? format(new Date(desiredDeadline), DATE_FORMAT) : undefined,
          typeOfAnswer: ETypeOfAnswer.DATE,
          createdDate: new Date().toJSON(),
        },
        {
          id: uuid(),
          title: 'How many penetration testers would you like for this engagement?',
          value: desiredTesterAssignment ? String(desiredTesterAssignment) : undefined,
          typeOfAnswer: ETypeOfAnswer.SHORT,
          createdDate: new Date().toJSON(),
        },
        ...(project.answers || []),
        {
          id: uuid(),
          title: 'Any special instructions? ',
          value: project[PROJECT_FIELDS.comment],
          typeOfAnswer: ETypeOfAnswer.LONG,
          createdDate: new Date().toJSON(),
        },
      ];

      onConfirm();
      onSetAnswers(answers);
      onSetProjectPayload(project);
    },
    [clearErrors, onSetProjectPayload, currentProduct, onSetAnswers, onConfirm]
  );

  const inputRegister: typeof register = (key, ...args) => {
    return {
      ...register(key, ...args),
      // TODO fix "as"
      errorMessage: formState.errors[key as keyof Omit<ProductQuestionnaireForm, 'answers'>]?.message,
    };
  };

  const renderAnswerInput = useRenderProductQuestion({
    register,
    control,
    formState,
    questions: currentProduct.questions,
  });

  const gbpCurrency = usePublicGPBRateQuery();

  return (
    <CreateProjectStep
      activeStep={activeStep}
      title="Project Scoping Questionnaire"
      subTitle="Please answer the questions below so the admin can evaluate your project"
      onCancel={() => {
        onSetProjectPayload();
        reset(undefined);
        onBack();
      }}
      cancelText="Back"
      confirmButtonType="submit"
      render={({ header, actionButtons }) => (
        <Form control={control} onSubmit={handleSubmit(onSubmit)}>
          {header}
          <Typography className="mb-7 text-xxl font-bold">{currentProduct.title}</Typography>
          <Input
            {...inputRegister(PROJECT_FIELDS.name, { required: 'Required Field' })}
            className="my-1"
            label="What is the name of project?"
            placeholder="Enter name"
          />
          <Controller
            name={PROJECT_FIELDS.desiredDeadline}
            control={control}
            defaultValue={INITIAL_DEADLINE}
            render={({ field: { onChange, value } }) => (
              <DatePickerInput
                inputProps={{
                  label: 'When would you like this project done by?',
                  className: clsxm('mt-6'),
                }}
                selected={new Date(value)}
                minDate={MIN_DATE}
                shouldCloseOnSelect
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="desiredTesterAssignment"
            control={control}
            defaultValue={DEFAULT_DESIRED_TESTER_ASSIGNMENT}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                inputProps={{
                  label: 'How many penetration testers would you like for this engagement?',
                  className: 'mt-4',
                  errorMessage: fieldState.error?.message,
                }}
              >
                {range(1, 11).map((option) => (
                  <OptionItem key={option} value={option}>
                    {option}
                  </OptionItem>
                ))}
              </Select>
            )}
          />
          {currentProduct.questions?.map((q) => {
            return (
              <div key={q.id} className="mt-4">
                {renderAnswerInput(q)}
              </div>
            );
          })}
          <Input
            {...inputRegister(PROJECT_FIELDS.comment)}
            className="mb-5 mt-4"
            label={
              <span>
                Any special instructions? <span className="text-grey5">(optional)</span>
              </span>
            }
            multiline
            minRows={3}
            placeholder="Enter text"
          />
          {gbpCurrency.data && (
            <ScopingCalculator
              penetrationType={currentProduct.penetrationType}
              answers={projectAnswers}
              countOfTesters={watch().desiredTesterAssignment || DEFAULT_DESIRED_TESTER_ASSIGNMENT}
              isD2NA={maybeClient?.isD2NA || IS_D2NA_DOMAIN}
              usdToGbpRate={gbpCurrency.data.usdToGbpRate}
            />
          )}
          {actionButtons}
        </Form>
      )}
    />
  );
};
