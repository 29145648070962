import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Input, InputProps } from '@app/ui-components';

type Props = NumberFormatProps<InputProps>;

export const NumberInput: React.FC<Props> = ({ children, ...props }) => {
  return (
    <NumberFormat customInput={Input} {...props}>
      {children}
    </NumberFormat>
  );
};
