import { Routes } from '@app/constants/routes';

import { TechnicalReportPage } from './pages/reports/TechnicalReportPage';
import { TypedNavigate } from '@app/router';
import { PublicReportPage } from './pages/reports/PublicReportPage';
import { ManagementReportPage } from './pages/reports/ManagementReportPage';
import { MyRouteObject } from '@app/models/router';
import { TesterTermsAndConditionsPage } from './pages/tac/TesterTermsAndConditions.page';
import { ClientTermsAndConditionsPage } from './pages/tac/ClientTermsAndConditions.page';
import { ClientInvoicePage } from '@app/modules/pdf/pages/tac/ClientInvoice.page';
import { TesterInvoicePage } from '@app/modules/pdf/pages/tac/TesterInvoice.page';
import { ClientQuoteEditorPage } from '@app/modules/pdf/pages/tac/ClientQuoteEditor.page';

export const PdfRoutes: MyRouteObject = {
  path: Routes.pdf.index,
  children: [
    { index: true, path: '*', element: <TypedNavigate to={Routes.pdf.index} /> },
    { path: Routes.pdf.technicalReport, element: <TechnicalReportPage /> },
    { path: Routes.pdf.publicReport, element: <PublicReportPage /> },
    { path: Routes.pdf.managementReport, element: <ManagementReportPage /> },
    { path: Routes.pdf.testerTac, element: <TesterTermsAndConditionsPage /> },
    { path: Routes.pdf.clientTac, element: <ClientTermsAndConditionsPage /> },
    { path: Routes.pdf.clientInvoice, element: <ClientInvoicePage /> },
    { path: Routes.pdf.clientQuoteEditor, element: <ClientQuoteEditorPage /> },
    { path: Routes.pdf.testerInvoice, element: <TesterInvoicePage /> },
  ],
};
