import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { format } from 'date-fns';
import { DAY_TIME_FORMAT } from '@app/constants/date';
import { getFriendlyInvoiceStatus } from '@app/shared/ui-components/project-info-box/project-info.utils';
import { Status } from '@app/shared/ui-components/status/Status';
import { OverrideInvoiceStatusInDto } from '@app/swagger-override-types';
import { sharedStyle } from '@app/styles/sharedStyle';
import clsxm from '@app/lib/clsxm';
import { InvoiceStatusDropdown } from '@app/domain/invoice/InvoiceStatusDropdown';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { InvoiceProjectDto } from '@app/swagger-types';
import { Button } from '@app/ui-components';
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ProjectInvoicesDialog } from '@app/domain/invoice/ProjectInvoicesDialog';
import { NOT_ALLOWED_FOR_VIEW_PRICE } from '@app/constants/currency';
import { ConditionalWrapper } from '@app/hoc';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';

export const ClientsPaymentsBox: React.FC<{
  projectId?: string;
  onInvoiceStatusChange: (payload: { id: string; Dto: OverrideInvoiceStatusInDto }) => void;
  invoices?: InvoiceProjectDto[];
}> = ({ invoices, onInvoiceStatusChange, projectId = '' }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { isAdmin, isClient, isTester, isCollaborator } = useUserRole();

  const handleCloseDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setIsOpenDialog(true);
  }, []);

  const isEmpty = !invoices || !Boolean(invoices.length);
  const canViewInvoices = !isTester && !isCollaborator;

  return (
    <>
      <div className={clsxm('rounded-[10px] bg-transparentBackground px-9 pt-6 pb-7', sharedStyle.shadowBorder)}>
        <div className="mb-7 flex items-center justify-between">
          <Typography className="text-xl font-bold">{isClient ? 'Billing' : "Client's Payments"}</Typography>
          {!isEmpty && isClient && (
            <Button
              variant="text"
              onClick={handleOpenDialog}
              className="flex items-center p-0 text-xs font-bold transition-all hover:bg-transparent hover:underline"
              endIcon={<ChevronUpIcon className="h-4 rotate-90" />}
            >
              View All
            </Button>
          )}
          {isAdmin && (
            <TypedLink to={Routes.pdf.clientQuoteEditor} params={{ projectId }}>
              <Button
                variant="text"
                className="flex items-center p-0 text-xs font-bold transition-all hover:bg-transparent hover:underline"
                endIcon={<ChevronUpIcon className="h-4 rotate-90" />}
              >
                Quote Editor
              </Button>
            </TypedLink>
          )}
        </div>
        {!isEmpty && (
          <div className="h-28 overflow-y-auto pr-[10px]">
            {invoices
              .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
              .slice(0, 2)
              .map(({ id, amountCoin, createdDate, status, usdToGbpRate, isD2NA }) => {
                const smartFormatter =
                  isD2NA && Boolean(usdToGbpRate) ? createGBPformatter(usdToGbpRate).formatGBP : formatDollars;
                return (
                  <div key={id} className="flex items-end justify-between py-3">
                    <div className="flex items-end">
                      <div className="mr-10">
                        <Typography className="mb-0.5 text-xxs font-medium text-grey5">
                          {format(new Date(createdDate), DAY_TIME_FORMAT)}
                        </Typography>
                        <ConditionalWrapper
                          condition={isClient || isAdmin}
                          wrapper={(children) => (
                            <TypedLink
                              to={Routes.pdf.clientInvoice}
                              params={{ invoiceId: id }}
                              className="hover:underline"
                            >
                              {children}
                            </TypedLink>
                          )}
                        >
                          <Typography className="text-xs">Project payment</Typography>
                        </ConditionalWrapper>
                      </div>
                      {isAdmin ? (
                        <InvoiceStatusDropdown id={id} activeStatus={status} onChangeStatus={onInvoiceStatusChange} />
                      ) : (
                        <Status {...getFriendlyInvoiceStatus(status)} />
                      )}
                    </div>
                    <Typography className="text-xs font-bold">
                      {canViewInvoices ? smartFormatter(amountCoin) : NOT_ALLOWED_FOR_VIEW_PRICE}
                    </Typography>
                  </div>
                );
              })}
          </div>
        )}
        {isEmpty && (
          <div className="h-28">
            <Typography className="text-xs font-light italic text-grey6">No Payments yet</Typography>
          </div>
        )}
      </div>
      {isClient && (
        <ProjectInvoicesDialog title="Billing" projectId={projectId} open={isOpenDialog} onClose={handleCloseDialog} />
      )}
    </>
  );
};
