import { Outlet, useMatch } from 'react-router-dom';

import { Routes } from '@app/constants/routes';

import { AuthStatus } from '@app/models/auth';

import useStore from '@app/store/useStore.hook';

import { TypedNavigate } from '@app/router';
import { AppModule } from '@app/modules/components/AppModule';

export const RootModule = () => {
  const authStatus = useStore.useAuthStatus();
  const currentUser = useStore.useCurrentUser();
  const isRoot = useMatch(Routes.index);

  if (authStatus === AuthStatus.UNKNOWN) {
    return null;
  }

  if (authStatus === AuthStatus.UNAUTHORIZED && isRoot) {
    return <TypedNavigate to={Routes.auth.index} />;
  }

  if (authStatus === AuthStatus.SIGNED_IN && currentUser) {
    if (isRoot) {
      return (
        <AppModule>
          <TypedNavigate to={Routes.projects.index} />
        </AppModule>
      );
    }
  }

  if (currentUser) {
    return (
      <AppModule>
        <Outlet />
      </AppModule>
    );
  }

  return <Outlet />;
};
