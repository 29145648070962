import { Routes } from '@app/constants/routes';

import { MyProjectsModule } from './MyProjectsModule';
import { TypedNavigate } from '@app/router';
import { AllMyProjectsPage } from '@app/modules/client/my-projects/pages/AllMyProjects.page';
import { ERole } from '@app/swagger-types';
import { MyPendingProjectsPage } from '@app/modules/client/my-projects/pages/MyPendingProjects.page';
import { MyScheduledProjectsPage } from '@app/modules/client/my-projects/pages/MyScheduledProjects.page';
import { MyLiveProjectsPage } from '@app/modules/client/my-projects/pages/MyLiveProjects.page';
import { MyCompletedProjectsPage } from '@app/modules/client/my-projects/pages/MyCompletedProjects.page';

export const MyProjectsRoutes = {
  path: Routes.projects.index,
  element: <MyProjectsModule />,
  role: [ERole.ROLE_CLIENT, ERole.ROLE_COLLABORATOR],
  children: [
    { path: Routes.projects.allProjects, element: <AllMyProjectsPage /> },
    { path: Routes.projects.pendingProjects, element: <MyPendingProjectsPage /> },
    { path: Routes.projects.scheduledProjects, element: <MyScheduledProjectsPage /> },
    { path: Routes.projects.liveProjects, element: <MyLiveProjectsPage /> },
    { path: Routes.projects.completedProjects, element: <MyCompletedProjectsPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.projects.allProjects} /> },
  ],
};
