import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ProjectApi } from '@app/domain/project/project.api';
import { useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { TimelineBox } from '@app/shared/ui-components/timeline-box/TimelineBox';
import { MethodologyStatBox } from '@app/shared/ui-components/methodology-stat-box/MethodologyStatBox';
import { ProjectTeamBox } from '@app/shared/ui-components/project-team-box/ProjectTeamBox';
import { BonusInfoBox } from '@app/shared/ui-components/bonus-info-box/BonusInfoBox';
import { ProjectInfoBox } from '@app/shared/ui-components/project-info-box/ProjectInfoBox';
import { ProjectWorkBox } from '@app/shared/ui-components/project-work-box/ProjectWorkBox';
import { ContainerLoader } from '@app/shared/ui-components/container-loader/ContainerLoader';
import { EApplicationStatus, EMethodologyStatus, EProjectStatus } from '@app/swagger-types';
import { ClientsPaymentsBox } from '@app/shared/ui-components/clients-payments-box/ClientsPaymentsBox';
import { InvoiceApi } from '@app/domain/invoice/invoice.api';
import { OverrideInvoiceStatusInDto } from '@app/swagger-override-types';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { ProjectProgressBox } from '@app/shared/ui-components/project-progress-box/ProjectProgressBox';
import { GET_INVOICES_QUERY, GET_PROJECT_QUERY } from '@app/constants/query-api-configs';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { ProjectStatusButtons } from '@app/domain/project/components/ProjectStatusButtons';
import { RejectProjectDialog } from '@app/domain/project/components/RejectProjectDialog';
import { SetUpProjectDialog } from '@app/domain/project/components/SetUpProjectDialog';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@app/ui-components';
import { ProjectMembersDialog } from '@app/domain/user/ProjectMembersDialog';
import { UsersAvatarBlock } from '@app/shared/tables/projects-table/UsersAvatarBlock';
import {
  useAssignTestersToProject,
  useRejectProject,
  useSetProjectTimeline,
} from '@app/domain/project/api/hooks/project.api.hook';
import { BadgeD2NA } from '@app/domain/d2na/BadgeD2NA';

export const ProjectPage = () => {
  const [idForRejecting, setIdForRejecting] = useState<string>();
  const [openSetUpProjectDialog, setOpenSetUpProjectDialog] = useState(false);
  const [openProjectMembersDialog, setOpenProjectMembersDialog] = useState(false);
  const queryClient = useQueryClient();
  const { id } = useTypedParams(Routes.projects.project);
  const { isTester, isClient, isAdmin } = useUserRole();

  const handleOpenSetUpProjectDialog = useCallback(() => {
    setOpenSetUpProjectDialog(true);
  }, []);

  const handleCloseSetUpProjectDialog = useCallback(() => {
    setOpenSetUpProjectDialog(false);
  }, []);

  const handleOpenProjectMembersDialog = useCallback(() => {
    setOpenProjectMembersDialog(true);
  }, []);

  const handleCloseProjectMembersDialog = useCallback(() => {
    setOpenProjectMembersDialog(false);
  }, []);

  const GET_PROJECT_QUERY_KEY = [GET_PROJECT_QUERY.name, { id }];

  const {
    isFetching: projectLoading,
    data: project,
    error,
    refetch,
  } = useQuery<ProjectOutDtoSchema | undefined, Error>(
    GET_PROJECT_QUERY_KEY,
    async () => {
      if (id) {
        return await ProjectApi.getProject(id);
      }
    },
    { keepPreviousData: true, staleTime: GET_PROJECT_QUERY.config.staleTime }
  );

  const { mutate: setChecklistItemStatus } = useMutation<
    unknown,
    Error,
    { id: string; status: EMethodologyStatus },
    { previousData: ProjectOutDtoSchema | undefined }
  >(
    async ({ id: checklistItemId, status }) => {
      if (id) {
        return ProjectApi.setChecklistItemStatus(id, checklistItemId, status);
      }
    },
    {
      onMutate: async ({ id, status }) => {
        await queryClient.cancelQueries(GET_PROJECT_QUERY_KEY);

        const previousData = queryClient.getQueryData<ProjectOutDtoSchema | undefined>(GET_PROJECT_QUERY_KEY);

        queryClient.setQueryData<ProjectOutDtoSchema | undefined>(GET_PROJECT_QUERY_KEY, (project) =>
          project
            ? {
                ...project,
                methodologies: project.methodologies.map(({ sectionTitle, checklist }) => ({
                  sectionTitle,
                  checklist: checklist.map((checklistItem) =>
                    checklistItem.id === id ? { ...checklistItem, id, status } : checklistItem
                  ),
                })),
              }
            : undefined
        );

        return { previousData };
      },
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(GET_PROJECT_QUERY_KEY, context?.previousData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(GET_PROJECT_QUERY_KEY);
      },
    }
  );

  const { mutate: handleInvoiceStatusChange } = useMutation(
    async ({ id, Dto }: { id: string; Dto: OverrideInvoiceStatusInDto }) => {
      return await InvoiceApi.updateStatus(id, Dto);
    },
    {
      onSuccess: () => {
        refetch();
        queryClient.removeQueries(GET_INVOICES_QUERY.name);
      },
    }
  );

  const { mutate: handleTimelineChange, isLoading: isSettingLoading } = useSetProjectTimeline();

  const { mutate: handleAssignTestersToProject } = useAssignTestersToProject();

  const { mutate: handleRejectProject, isLoading: isRejecting } = useRejectProject();

  if (!project || (projectLoading && !project) || error) {
    return <ContainerLoader loading={projectLoading} error={error} noData={!project} />;
  }

  return (
    <>
      <div className="mb-10 flex items-end justify-between">
        <div className="flex items-center gap-4">
          <Typography className="text-xxxl font-bold">Project Page</Typography>
          {isAdmin && project.isD2NA && <BadgeD2NA />}
        </div>

        <div className="flex items-center gap-6">
          {Boolean(project.collaborators.length) && (
            <Box className="mr-7 flex cursor-pointer items-center" onClick={handleOpenProjectMembersDialog}>
              <Typography className="mr-2.5 text-xs text-grey6">Team Members:</Typography>
              <UsersAvatarBlock users={project.collaborators} />
            </Box>
          )}
          {isAdmin && !Boolean(project.collaborators.length) && (
            <Box className="mr-7 flex cursor-pointer items-center" onClick={handleOpenProjectMembersDialog}>
              <Typography className="mr-2.5 text-xs text-grey6">No Team Members</Typography>
            </Box>
          )}
          {isClient && project.status !== EProjectStatus.REJECTED && (
            <Button
              variant="outlined"
              color={'secondary'}
              startIcon={<AddIcon />}
              onClick={handleOpenProjectMembersDialog}
            >
              Invite Team Member
            </Button>
          )}
          {!isTester && (
            <ProjectStatusButtons
              project={project}
              onApprove={handleOpenSetUpProjectDialog}
              onReject={() => setIdForRejecting(project.id)}
              loadingReject={isRejecting}
              className="w-28"
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-[40%_60%]">
        <div>
          <ProjectInfoBox project={project} onSetUp={handleOpenSetUpProjectDialog} />
        </div>
        <div>
          {isClient ? (
            <ProjectProgressBox project={project} />
          ) : (
            <ProjectTeamBox project={project} onAssignTester={handleAssignTestersToProject} />
          )}
        </div>
        <div>
          <TimelineBox project={project} onEdit={handleOpenSetUpProjectDialog} />
        </div>
        <div className="row-span-2">
          <MethodologyStatBox methodologies={project.methodologies} />
        </div>
        <div>
          {isTester ? (
            <BonusInfoBox
              bonus={project.myApplication?.status === EApplicationStatus.ASSIGNED ? project.myBonus : undefined}
            />
          ) : (
            <ClientsPaymentsBox
              projectId={project.id}
              invoices={project.invoices}
              onInvoiceStatusChange={handleInvoiceStatusChange}
            />
          )}
        </div>
        <div className="lg:col-span-2">
          <ProjectWorkBox project={project} onChecklistItemStatusChange={setChecklistItemStatus} />
        </div>
      </div>
      {isAdmin && (
        <RejectProjectDialog
          projectId={project.id}
          open={Boolean(idForRejecting)}
          onClose={() => setIdForRejecting(undefined)}
          onConfirm={handleRejectProject}
        />
      )}
      {isAdmin && (
        <SetUpProjectDialog
          open={openSetUpProjectDialog}
          onClose={handleCloseSetUpProjectDialog}
          project={project}
          onConfirm={handleTimelineChange}
          isLoading={isSettingLoading}
        />
      )}
      <ProjectMembersDialog
        project={project}
        open={openProjectMembersDialog}
        onClose={handleCloseProjectMembersDialog}
      />
    </>
  );
};
