import React, { useMemo, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { Dialog } from '@app/hoc';
import { Button, Form, Input } from '@app/ui-components';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { InviteUserSchema } from '@app/domain/user/user.form';
import { ERole, InvitationDto } from '@app/swagger-types';
import AddIcon from '@mui/icons-material/Add';
import Notification from '@app/shared/ui-components/notifications/Notifications';
import { ProjectApi } from '@app/domain/project/project.api';
import clsxm from '@app/lib/clsxm';
import { GET_COLLABORATOR_INVITATIONS_QUERY, GET_PROJECT_QUERY } from '@app/constants/query-api-configs';
import { TeamMembersTable } from '@app/shared/tables/users-table/TeamMembersTable';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';

interface Props {
  open: boolean;
  onClose: () => void;
  project: ProjectOutDtoSchema;
}

export const ProjectMembersDialog: React.FC<Props> = ({ open, onClose, project }) => {
  const queryClient = useQueryClient();
  const { control, handleSubmit, register, clearErrors, reset, formState } = useForm<InviteUserSchema>({
    resolver: yupResolver(InviteUserSchema),
  });
  const { isClient } = useUserRole();

  const { mutate: inviteTester, isLoading } = useMutation<unknown, Error, Omit<InvitationDto, 'role'>>(async (Dto) => {
    return await ProjectApi.inviteMemberSilent(project.id, { ...Dto, role: ERole.ROLE_COLLABORATOR });
  });

  const handleClose = useCallback(() => {
    clearErrors();
    reset();
    onClose();
  }, [clearErrors, reset, onClose]);

  const onSubmit: SubmitHandler<InviteUserSchema> = (formData) => {
    inviteTester(formData, {
      onSuccess: async () => {
        reset();
        clearErrors();
        Notification.showSuccess('The invitation has been successfully sent');
        await queryClient.invalidateQueries(GET_COLLABORATOR_INVITATIONS_QUERY.name);
        await queryClient.invalidateQueries(GET_PROJECT_QUERY.name);
      },
    });
  };

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: isLoading,
      };
    };
    return fn;
  }, [register, formState, isLoading]);

  return (
    <Dialog open={open} paperClassName="p-7 w-[600px]" headerTitle="Team Members" onClose={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)} control={control}>
        {isClient && (
          <div className="mb-6 flex justify-between">
            <Input {...inputRegister('email')} className="w-[60%]" placeholder="Enter email to invite" />
            <div>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    className="w-28"
                    disabled={!field.value}
                    startIcon={
                      <AddIcon
                        className={clsxm({
                          invisible: isLoading,
                        })}
                      />
                    }
                    loading={isLoading}
                  >
                    Invite
                  </Button>
                )}
              />
            </div>
          </div>
        )}
        <TeamMembersTable members={project.collaborators || []} projectId={project.id} />
      </Form>
    </Dialog>
  );
};
