import localforage from 'localforage';

import { AuthStatus } from '@app/models/auth';

import { StoreSlice } from '../storeTypes';
import { AuthSlice } from './types';
import { AuthApi } from '@app/auth/auth.api';
import { AUTH_REF_TOKEN_KEY, AUTH_TOKEN_KEY } from '@app/auth/constant';
import { IS_AUTH_METHOD_COOKIE, IS_AUTH_METHOD_TOKEN } from '@app/environment/typed-env';
import { takeLatestAsync } from '@app/utils/async.utils';

const createAuthSlice: StoreSlice<AuthSlice> = (set, get) => ({
  currentUser: null,
  authStatus: AuthStatus.UNKNOWN,

  signOut: async (skipCookieSignOut) => {
    set({ currentUser: null, authStatus: AuthStatus.UNAUTHORIZED });
    if (IS_AUTH_METHOD_TOKEN) {
      await localforage.clear();
    }
    if (IS_AUTH_METHOD_COOKIE) {
      if (skipCookieSignOut) {
        return;
      }
      await AuthApi.signOut();
    }
  },

  setCurrentUser: async (user) => {
    const { signOut } = get();

    if (user) {
      if (IS_AUTH_METHOD_COOKIE) {
        set({ currentUser: user, authStatus: AuthStatus.SIGNED_IN });
        return;
      }
      if (IS_AUTH_METHOD_TOKEN) {
        const { accessToken, refreshToken, ...safeUser } = user;
        if (!accessToken || !refreshToken) {
          signOut();
          return;
        }
        await localforage.setItem(AUTH_TOKEN_KEY, accessToken);
        await localforage.setItem(AUTH_REF_TOKEN_KEY, refreshToken);
        set({ currentUser: safeUser, authStatus: AuthStatus.SIGNED_IN });
        return;
      }
      console.error('unsupported auth method');
    } else {
      signOut();
    }
  },

  getMe: async () => {
    const { signOut } = get();

    // TODO avoid checking token here. It is job for interceptor.
    if (IS_AUTH_METHOD_TOKEN) {
      const token = await localforage.getItem(AUTH_TOKEN_KEY);

      if (!token) {
        signOut();
        return;
      }
    }

    const { result, staleError, error } = await takeLatestAsync(AuthApi.me(), 'authGetMe');
    if (staleError) {
      return;
    }
    if (error) {
      signOut(true);
      return;
    }
    if (!result) {
      return;
    }
    const me = result.data;
    set({ currentUser: me, authStatus: AuthStatus.SIGNED_IN });
  },
});

export default createAuthSlice;
