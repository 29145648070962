import { ClientOutDto, ERole, EUserStatus, TesterOutDto } from '@app/swagger-types';

export const createUiKitClient = (override?: Partial<ClientOutDto>): ClientOutDto => ({
  companyName: 'OSDB',
  createdDate: '2022-04-22T09:11:47.074941Z',
  email: 'bguriev@osdb.io',
  firstName: 'Bohdan',
  id: '26fd281b-553c-4536-b921-3e5097a2f97d',
  lastName: 'Guriev',
  numOfProjects: 4,
  role: ERole.ROLE_CLIENT,
  status: EUserStatus.ACTIVE,
  avatarId: undefined,
  acceptedTac: true,
  acceptedTacDate: new Date().toJSON(),
  isD2NA: false,
  ...override,
});

export const createUiKitTester = (override?: Partial<TesterOutDto>): TesterOutDto => ({
  id: 'c37c1081-d249-43e8-bbae-548dddbc39d6',
  firstName: 'Marianne',
  lastName: 'Woolley',
  email: 'mwoolley@osdb.io',
  role: ERole.ROLE_TESTER,
  avatarId: '2b62966c-0342-485e-8622-95e8d6132aff',
  status: EUserStatus.ACTIVE,
  createdDate: '2022-04-22T09:11:47.074941Z',
  paypalEmail: 'd3d6861e207b@paypal.com',
  github: 'https://github.com/d3d6861e207b',
  linkedin: 'https://www.linkedin.com/d3d6861e207b',
  numOfProjects: 4,
  skills: [],
  certifications: [],
  isAvailable: true,
  acceptedTac: true,
  acceptedTacDate: new Date().toJSON(),
  ...override,
});
