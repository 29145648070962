import React, { useMemo } from 'react';

import { TableColumn } from '@app/shared/ui-components';
import { UserDto } from '@app/models/auth';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';

export const useTableColumns = () => {
  const tableColumns: TableColumn<UserDto>[] = useMemo(() => {
    return [
      {
        headerName: 'Name',
        field: 'firstName',
        align: 'left',
        render: (user) => <UserBlock user={user} disableNavigation />,
      },

      {
        headerName: 'Email',
        field: 'email',
        align: 'left',
      },
    ];
  }, []);

  return tableColumns;
};
