import React from 'react';
import { Typography } from '@mui/material';

import { ApiValidationError } from '@app/services/error-manager';

export const maybeRenderError = (error?: Error | null) => {
  if (!error) {
    return;
  }
  let msg = 'Something Went Wrong';
  if (error instanceof ApiValidationError) {
    msg = error.errors[0]?.message || msg;
  } else {
    msg = error.message || msg;
  }
  // TODO variant error
  return (
    <div className="flex justify-center">
      <Typography className="mt-3 text-center text-xs text-error" style={{ wordBreak: 'break-word' }}>
        {msg}
      </Typography>
    </div>
  );
};
