import { mapValues } from 'lodash';

import { parseNumbersFromString } from '@app/utils/string.utils';

import { PaletteMode, ThemeOptions } from '@mui/material';

import tailwindTheme from './tailwind-theme-generated';
import { DATA_VARIANT_OUTLINED } from './data-variant.const';

type CustomTypographyVariantsKeys = keyof typeof tailwindTheme.fontSize;

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    '2xl': true;
  }

  interface TypographyVariants extends Record<CustomTypographyVariantsKeys, React.CSSProperties> {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends Record<CustomTypographyVariantsKeys, React.CSSProperties> {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends Record<CustomTypographyVariantsKeys, true> {}
}

const mapFontSizes = () => {
  const mapped = mapValues(tailwindTheme.fontSize, (fontSize: [string, { lineHeight: string }]) => ({
    fontSize: fontSize[0],
    lineHeight: fontSize[1].lineHeight,
  }));

  return mapped as unknown as Record<CustomTypographyVariantsKeys, React.CSSProperties>;
};

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: tailwindTheme.colors.white,
          },
          text: {
            primary: tailwindTheme.colors.darkText,
            secondary: tailwindTheme.colors.lightText,
          },
        }
      : {
          // palette values for dark mode

          primary: {
            main: tailwindTheme.colors.white,
          },
          background: {
            paper: tailwindTheme.colors.grey1,
          },
          text: {
            primary: tailwindTheme.colors.white,
            secondary: tailwindTheme.colors.grey5,
          },
        }),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: parseNumbersFromString(tailwindTheme.screens.sm),
      md: parseNumbersFromString(tailwindTheme.screens.md),
      lg: parseNumbersFromString(tailwindTheme.screens.lg),
      xl: parseNumbersFromString(tailwindTheme.screens.xl),
      '2xl': parseNumbersFromString(tailwindTheme.screens['2xl']),
    },
  },
  typography: {
    fontFamily: tailwindTheme.fontFamily.sans.join(','),
    ...mapFontSizes(),
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          xl: 'h4',
          xxl: 'h3',
          xxxl: 'h2',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          [`&.Mui-selected[data-variant="${DATA_VARIANT_OUTLINED}"]`]: {
            background: 'rgba(36, 37, 45, 0.7) !important',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          backgroundColor: tailwindTheme.colors.grey3,
        },
        barColorPrimary: { backgroundColor: tailwindTheme.colors.green },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: 10,
          background: tailwindTheme.colors.grey2,
          border: `1px solid ${tailwindTheme.colors.grey3}`,
          borderRadius: 55,
          fontSize: 10,
          marginLeft: 6,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: 10,
          color: tailwindTheme.colors.grey5,
          [`&.Mui-completed`]: {
            color: tailwindTheme.colors.grey5,
            fontWeight: 400,
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: tailwindTheme.colors.grey4,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 6,
        },
        list: {
          background: 'rgb(54, 56, 64)',
          borderRadius: 6,
        },
      },
    },
  },
});
