import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import { Typography } from '@mui/material';
import { isNumber } from 'lodash';

import { useTypedNavigate } from '@app/router';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { GET_FINANCES_QUERY } from '@app/constants/query-api-configs';
import { IconButton, Table, TableColumn } from '@app/ui-components';
import { ApplicationOutDto, EPayoutStatus } from '@app/swagger-types';
import { DATE_FORMAT } from '@app/constants/date';
import { formatDollars } from '@app/utils/currency.utils';
import { Routes } from '@app/constants/routes';
import { ApplicationApi } from '@app/domain/application/application.api';
import { AppPaymentStatusDropdown } from '@app/domain/application/AppPaymentStatusDropdown';
import { DeleteIcon } from '@app/assets/icons';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { useGetFinances, useUpdatePayoutStatus } from '@app/domain/application/api/hooks/application.api.hook';
import { PAGE_SIZE } from '@app/constants/api';

export const ApplicationFinancesTable: React.FC<{
  payoutStatus: ApplicationOutDto['payoutStatus'];
}> = ({ payoutStatus }) => {
  const navigate = useTypedNavigate();

  const {
    values: { page, sort = GET_FINANCES_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      search: '',
    },
    prefix: GET_FINANCES_QUERY.tablePrefix,
  });

  const { data, isFetching, refetch } = useGetFinances(
    {
      size: PAGE_SIZE,
      page: Math.ceil((page || 1) - 1),
      sort,
      payoutStatus,
      testerName: search,
    },
    { keepPreviousData: true, refetchOnMount: 'always' }
  );

  const [renderList, setRenderList] = useState(data?.result || []);

  useEffect(() => {
    setRenderList(data?.result || []);
  }, [data?.result]);

  const { mutate: updatePayoutStatus, isLoading: invoiceStatusLoading } = useUpdatePayoutStatus({
    onSuccess: (_, { applicationId, Dto }) => {
      setRenderList((prevApps) =>
        prevApps.map((app) => {
          if (app.id === applicationId) {
            const { status } = Dto;
            return { ...app, payoutStatus: status };
          }

          return app;
        })
      );
    },
  });
  const { mutate: deleteApplication, isLoading: deleteApplicationLoading } = useMutation(
    async (applicationId: string) => {
      return await ApplicationApi.delete(applicationId);
    },
    {
      onSuccess: async () => {
        await refetch();
      },
    }
  );

  const handleChangePayoutStatus = useCallback(
    (applicationId, Dto) => {
      updatePayoutStatus({ applicationId, Dto });
    },
    [updatePayoutStatus]
  );

  const leftHeaderContent = useMemo(() => {
    return (
      <div>
        <SearchInput placeholder="Search by tester" className="w-64" value={search} onChange={handleSearchChange} />
      </div>
    );
  }, [handleSearchChange, search]);

  const columns: TableColumn<ApplicationOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Tester',
        field: 'tester.firstName',
        align: 'left',
        disableSort: true,
        disableClick: true,
        render: ({ tester }) => <UserBlock user={tester} />,
      },
      {
        headerName: 'Project',
        field: 'project.name',
        align: 'left',
        render: ({ project: { name } }) => name,
      },
      {
        headerName: 'Project Ended',
        field: 'project.endDate',
        align: 'left',
        render: ({ project: { endDate } }) => (endDate ? format(new Date(endDate), DATE_FORMAT) : '-'),
      },
      {
        headerName: 'Price',
        field: 'extraPay',
        align: 'left',
        disableSort: true,
        render: ({ project: { priceForTester }, extraPay }) => (
          <div>
            {isNumber(priceForTester) && (
              <Typography className="text-xs">Project: {formatDollars(priceForTester)}</Typography>
            )}
            {isNumber(extraPay) && <Typography className="text-xs">Bonus: {formatDollars(extraPay)}</Typography>}
          </div>
        ),
      },
      {
        headerName: 'Total amount',
        field: 'extraPay',
        align: 'center',
        disableSort: true,
        render: ({ project: { priceForTester }, extraPay }) => {
          let amount = priceForTester;
          if (isNumber(amount) && isNumber(extraPay)) {
            amount += extraPay;
          }
          return isNumber(amount) ? formatDollars(amount) : '-';
        },
      },
      {
        headerName: 'Status',
        field: 'payoutStatus',
        align: 'left',
        disableClick: true,
        width: 140,
        render: ({ payoutStatus, id }) => (
          <AppPaymentStatusDropdown id={id} activeStatus={payoutStatus} onChangeStatus={handleChangePayoutStatus} />
        ),
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableClick: true,
        disableSort: true,
        render: ({ id, tester: { firstName, lastName }, payoutStatus }) => {
          const payoutTitle = payoutStatus === EPayoutStatus.PAID ? 'Paid out' : 'Need to Pay';
          return (
            <ConfirmDeleteDialog
              trigger={
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              }
              onConfirm={() => deleteApplication(id)}
              headerTitle={`Delete ${payoutTitle}`}
              title={`Are you sure you want to delete ${payoutTitle} info for ${firstName} ${lastName}?`}
            />
          );
        },
      },
    ],
    [handleChangePayoutStatus, deleteApplication]
  );

  const navigateToProjectPage = ({ project: { id } }: ApplicationOutDto) => {
    navigate({ to: Routes.projects.project, params: { id } });
  };

  return (
    <Table
      cols={columns}
      tableData={renderList}
      loading={isFetching || invoiceStatusLoading || deleteApplicationLoading}
      notFoundMessage="No Payments"
      leftHeaderContent={leftHeaderContent}
      currentPage={page}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
      onItemSelect={navigateToProjectPage}
      hidePageSize
    />
  );
};
