import React, { useCallback, useMemo, useState } from 'react';
import { Tabs, Typography } from '@mui/material';
import { useQuery } from 'react-query';

import { Dialog } from '@app/hoc';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { OutlinedTab } from '@app/shared/ui-components/tabs/OutlinedTab';
import { RequestedTestersTable } from '@app/shared/tables/testers-table/RequestedTestersTable';
import { AllTestersTable } from '@app/shared/tables/testers-table/AllTestersTable';
import { GET_REQUESTED_APPLICATIONS_QUERY } from '@app/constants/query-api-configs';
import { ApplicationApi } from '@app/domain/application/application.api';
import { isNumber } from 'lodash';
import pluralize from 'pluralize';
import { formatDollars } from '@app/utils/currency.utils';
import { UseMutateFunction } from 'react-query/types/react/types';
import { ERole, EUserStatus } from '@app/swagger-types';
import { useGetUsersTotal } from '@app/domain/user/api/hooks/user.api.hook';
import { UseAssignTestersToProjectParams } from '@app/domain/project/api/hooks/project.api.hook';

enum ETabs {
  REQUESTS = 'REQUESTS',
  ALL = 'ALL',
}

interface Props {
  open: boolean;
  onClose: () => void;
  project: OverrideProjectOutDto;
  onAssignTester: UseMutateFunction<unknown, Error, UseAssignTestersToProjectParams>;
}

export const AssignTestersDialog: React.FC<Props> = ({
  open,
  project: { priceForTester, priceForClient, workingDays, id },
  onClose,
  onAssignTester,
}) => {
  const [tab, setTab] = useState(ETabs.REQUESTS);

  const activeTestersTotal = useGetUsersTotal(ERole.ROLE_TESTER, { status: EUserStatus.ACTIVE });

  const {
    data,
    isFetching,
    refetch: refetchApplications,
  } = useQuery(
    [GET_REQUESTED_APPLICATIONS_QUERY.name, { id }],
    async () => {
      return await ApplicationApi.getApplications({
        projectId: id,
        size: 999,
        sort: GET_REQUESTED_APPLICATIONS_QUERY.defaultSort,
      });
    },
    { keepPreviousData: true, staleTime: GET_REQUESTED_APPLICATIONS_QUERY.config.staleTime }
  );

  const { requested, rejected, assigned } = useMemo(() => {
    const appTesters =
      data?.result.map(({ tester, status }) => ({
        ...tester,
        appStatus: status,
      })) || [];

    return {
      requested: appTesters.filter(({ appStatus }) => appStatus === 'REQUESTED'),
      rejected: appTesters.filter(({ appStatus }) => appStatus === 'REJECTED'),
      assigned: appTesters.filter(({ appStatus }) => appStatus === 'ASSIGNED'),
    };
  }, [data?.result]);

  const assignedTesterIds = useMemo(
    () => assigned.filter(({ appStatus }) => appStatus === 'ASSIGNED').map(({ id }) => id),
    [assigned]
  );
  const rejectedTesterIds = useMemo(
    () => rejected.filter(({ appStatus }) => appStatus === 'REJECTED').map(({ id }) => id),
    [rejected]
  );

  const handleSetTab = useCallback(
    (_: React.SyntheticEvent, tab: ETabs) => {
      setTab(tab);
      refetchApplications();
    },
    [refetchApplications]
  );

  const handleClose = useCallback(() => {
    setTab(ETabs.REQUESTS);
    onClose();
  }, [onClose]);

  const rightHeaderContent = useMemo(() => {
    if (!priceForTester || !priceForClient || !isNumber(workingDays)) {
      return null;
    }

    return (
      <div className="flex">
        <Typography className="text-xs font-bold text-grey4">
          {'Project price: '}
          <span className="text-white">
            {`${formatDollars(priceForTester)}(tester) / `}
            {`${formatDollars(priceForClient)}(client)`}
          </span>
          {' | Project duration: '}
          <span className="text-white">{pluralize('day', workingDays, true)}</span>
        </Typography>
      </div>
    );
  }, [priceForTester, priceForClient, workingDays]);

  return (
    <Dialog open={open} paperClassName="p-7 max-w-[1300px]" headerTitle="Assign Testers" onClose={handleClose}>
      <Tabs
        value={tab}
        className="mb-5"
        onChange={handleSetTab}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <OutlinedTab label="Requests" value={ETabs.REQUESTS} count={requested.length} />
        <OutlinedTab label="All testers" value={ETabs.ALL} count={activeTestersTotal} />
      </Tabs>
      {tab === ETabs.REQUESTS && (
        <RequestedTestersTable
          onAssignTester={onAssignTester}
          testers={requested}
          loading={isFetching}
          projectId={id}
          rightHeaderContent={rightHeaderContent}
        />
      )}
      {tab === ETabs.ALL && (
        <AllTestersTable
          rightHeaderContent={rightHeaderContent}
          onAssignTester={onAssignTester}
          loading={isFetching}
          projectId={id}
          assignedTesterIds={assignedTesterIds}
          rejectedTesterIds={rejectedTesterIds}
        />
      )}
    </Dialog>
  );
};
