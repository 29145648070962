import { Routes } from '@app/constants/routes';

import { TesterProjectsModule } from './TesterProjectsModule';
import { TypedNavigate } from '@app/router';
import { ERole } from '@app/swagger-types';
import { AllTesterProjectsPage } from '@app/modules/tester/modules/projects/pages/AllTesterProjects.page';
import { TesterCompletedProjectsPage } from '@app/modules/tester/modules/projects/pages/TesterCompletedProjects.page';
import { TesterLiveProjectsPage } from '@app/modules/tester/modules/projects/pages/TesterLiveProjects.page';
import { TesterScheduledProjectsPage } from '@app/modules/tester/modules/projects/pages/TesterScheduledProjects.page';

export const TesterProjectsRoutes = {
  path: Routes.projects.index,
  element: <TesterProjectsModule />,
  role: [ERole.ROLE_TESTER],
  children: [
    { path: Routes.projects.allProjects, element: <AllTesterProjectsPage /> },
    { path: Routes.projects.scheduledProjects, element: <TesterScheduledProjectsPage /> },
    { path: Routes.projects.liveProjects, element: <TesterLiveProjectsPage /> },
    { path: Routes.projects.completedProjects, element: <TesterCompletedProjectsPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.projects.allProjects} /> },
  ],
};
