import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { EMethodologyStatus } from '@app/swagger-types';

export const calculateMethodologyStatistic = ({ methodologies }: Pick<OverrideProjectOutDto, 'methodologies'>) => {
  if (!methodologies || !methodologies.length) {
    return;
  }

  const checklist = methodologies.map((e) => e.checklist || []).flat();
  const passed = checklist.filter((e) => e.status === EMethodologyStatus.PASS).length;
  const failed = checklist.filter((e) => e.status === EMethodologyStatus.FAIL).length;
  const na = checklist.filter((e) => e.status === EMethodologyStatus.N_A).length;
  const anyTouched = checklist.filter((e) => e.status !== EMethodologyStatus.TODO).length;
  const progress = anyTouched / checklist.length;

  return {
    progress,
    data: [
      {
        name: 'Passed',
        value: passed,
        color: '#317729',
      },
      {
        name: 'Failed',
        value: failed,
        color: '#B25255',
      },
      {
        name: 'Not Applicable',
        value: na,
        color: '#595B6B',
      },
    ],
  };
};
