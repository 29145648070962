import React from 'react';
import { Tab, TabProps } from '@mui/material';
import { isNumber } from 'lodash';

export const StyledTab: React.FC<TabProps & { count?: number }> = ({ count, label, ...props }) => {
  return (
    <Tab
      {...props}
      label={
        isNumber(count) ? (
          <span className="flex items-center">
            {label}
            <span className="ml-1.5 flex h-[22px] w-[22px] items-center justify-center rounded-full bg-grey2 text-xxs">
              {count}
            </span>
          </span>
        ) : (
          label
        )
      }
    />
  );
};
