import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { BreakUnit } from '../../PdfPager';
import styles from '../../pdf-salus.module.scss';
import { PaginatedResponse } from '@app/api/types';
import { FindingOutSchema } from '@app/domain/finding/finding.schema';
import { EFindingStatus } from '@app/swagger-types';
import React from 'react';
import clsxm from '@app/lib/clsxm';
import { getSeverityInfo } from '@app/domain/settings/settings.utils';
import { renderFriendlySeverityVector } from '@app/shared/ui-components/cvss-calculator/cvss-calculator.utils';
import { NoFindingDiscoveredParagraph } from '../shared';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { RichEditorView } from '@app/shared/ui-components/rich-editor/RichEditorView';

export const ReportSectionTechnicalFindings: React.FC<{
  project: ProjectOutDtoSchema;
  findings: PaginatedResponse<FindingOutSchema>;
}> = ({ project, findings }) => {
  const filtered = findings.result.filter((e) => e.status === EFindingStatus.REQUIRES_ATTENTION);
  const noFindings = filtered.length < 1;

  return (
    <>
      <BreakUnit pageBreak />
      <BreakUnit header>
        <div className={styles.content}>
          <div className={styles.pageHeader} id="technical_findings">
            Technical Findings
          </div>
          <div className={styles.divider} />
        </div>
      </BreakUnit>
      {noFindings ? (
        <NoFindingDiscoveredParagraph />
      ) : (
        filtered.map((e, i) => {
          const { label, backgroundColor, borderColor } = getSeverityInfo(e.severity);
          const isNotFirst = i > 0;

          return (
            <React.Fragment key={i}>
              {isNotFirst && <BreakUnit pageBreak />}
              <BreakUnit>
                <div className={styles.content}>
                  <div className={styles.findingHeader}>
                    <TypedLink
                      params={{ projectId: project.id, findingId: e.id }}
                      to={Routes.projects.finding}
                      className="hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {e.title}
                    </TypedLink>
                  </div>
                  <div className="flex items-stretch text-white">
                    <div
                      className={clsxm('flex w-[84px] items-center justify-center px-2 text-xs')}
                      style={{ backgroundColor, borderColor }}
                    >
                      {label}
                    </div>
                    <div className="items-center bg-[#2B3146] px-2">
                      CVSS: {e.cvssScore} <span className="text-grey4">-</span>{' '}
                      {renderFriendlySeverityVector(e.severityVector)}
                    </div>
                  </div>
                  <div className={clsxm(styles.divider, 'mt-0')} />
                </div>
              </BreakUnit>
              <RichEditorView value={e.reportDescription} pdfFriendly className={styles.content} />
              <BreakUnit>
                <div className={styles.content}>
                  <div className={styles.sectionHeader}>Steps To Replicate</div>
                  <div className={styles.divider} />
                </div>
              </BreakUnit>
              <RichEditorView value={e.stepsReplicate} pdfFriendly className={styles.content} />
              <BreakUnit>
                <div className={styles.content}>
                  <div className={styles.sectionHeader}>Affected Hosts</div>
                  <div className={styles.divider} />
                  <pre className={clsxm(styles.p, styles.codeBlock)}>{e.affectedHost}</pre>
                </div>
              </BreakUnit>
              <BreakUnit>
                <div className={styles.content}>
                  <div className={styles.sectionHeader}>HTTP Request</div>
                  <div className={styles.divider} />
                  <pre className={clsxm(styles.p, styles.codeBlock)}>{e.affectedHttpRequest}</pre>
                </div>
              </BreakUnit>
              <BreakUnit>
                <div className={styles.content}>
                  <div className={styles.sectionHeader}>Fix Recommendations</div>
                  <div className={styles.divider} />
                </div>
              </BreakUnit>
              <RichEditorView value={e.remediationRecommendation} pdfFriendly className={styles.content} />
            </React.Fragment>
          );
        })
      )}
    </>
  );
};
