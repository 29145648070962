import styles from './NavSidebar.module.scss';
import React, { useMemo } from 'react';

import { SidebarRouteLink } from '@app/models/router/';

import { DrawerProps as MuiDrawerProps, List } from '@mui/material';

import { NavSidebarLink } from './nav-sidebar-link/NavSidebarLink';
import useStore from '@app/store/useStore.hook';
import { Routes } from '@app/constants/routes';
import { Engineering } from '@mui/icons-material';
import { IS_NOT_PROD_ENV } from '@app/environment/typed-env';
import { AppLogo } from '@app/assets/app-logo/AppLogo';
import { TypedLink } from '@app/router';
import { IS_D2NA_DOMAIN } from '@app/domain/d2na/domain.util';

type NavSidebarProps = {
  badgesData?: Record<string, number>;
  navItems: SidebarRouteLink[];
  bottomNavItems: SidebarRouteLink[];
} & MuiDrawerProps;

export const NavSidebar: React.FC<NavSidebarProps> = ({ badgesData, navItems, bottomNavItems }) => {
  const { currentUser } = useStore(({ currentUser }) => ({
    currentUser,
  }));

  const filteredNavItems = useMemo(
    () =>
      navItems.filter((navItem) => {
        if (navItem.role) {
          const userRole = currentUser?.role;
          if (!userRole) {
            return false;
          }

          return navItem.role.includes(userRole);
        }
        return true;
      }),
    [navItems, currentUser]
  );

  const filteredBottomNavItems = useMemo(
    () =>
      bottomNavItems.filter((navItem) => {
        if (navItem.role) {
          const userRole = currentUser?.role;
          if (!userRole) {
            return false;
          }

          return navItem.role.includes(userRole);
        }
        return true;
      }),
    [bottomNavItems, currentUser]
  );

  const navContent = useMemo(() => {
    return (
      <div className="flex h-full flex-col justify-between">
        <List className="mt-8 py-0 px-5">
          <TypedLink to={'#'} className="mb-4 flex justify-center">
            <AppLogo
              withName
              d2na={IS_D2NA_DOMAIN}
              width={IS_D2NA_DOMAIN ? 200 : undefined}
              height={IS_D2NA_DOMAIN ? 80 : undefined}
            />
          </TypedLink>
          <div className={styles.ItemsWrapper}>
            {filteredNavItems.map((menuItem) => {
              const badge = menuItem.badgeProp && badgesData ? badgesData[menuItem.badgeProp] : undefined;
              return (
                <NavSidebarLink
                  name={menuItem.name}
                  key={menuItem.name}
                  to={menuItem.path}
                  icon={menuItem.icon}
                  nestedLinks={menuItem.nestedLinks}
                  badge={badge}
                  badgesData={badgesData}
                  tooltip={menuItem.tooltip}
                />
              );
            })}
          </div>
        </List>
        <List className="mb-16 px-5">
          {filteredBottomNavItems.map((menuItem) => {
            const badge = menuItem.badgeProp && badgesData ? badgesData[menuItem.badgeProp] : undefined;
            return (
              <NavSidebarLink
                name={menuItem.name}
                key={menuItem.name}
                to={menuItem.path}
                icon={menuItem.icon}
                nestedLinks={menuItem.nestedLinks}
                badge={badge}
                badgesData={badgesData}
                tooltip={menuItem.tooltip}
              />
            );
          })}
          {IS_NOT_PROD_ENV && <NavSidebarLink name={'UI Kit'} to={Routes.uiKit.index} icon={<Engineering />} />}
        </List>
      </div>
    );
  }, [badgesData, filteredNavItems, filteredBottomNavItems]);

  return <div className="h-full">{navContent}</div>;
};
