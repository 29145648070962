import { InferType, number, object, string } from 'yup';
import { convertCentToUnit } from '@app/utils/currency.utils';
import { oneOfEnum } from '@app/utils/yup.utils';
import { EApplicationStatus, EPayoutStatus } from '@app/swagger-types';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { TesterDto } from '@app/models/user';

export const ApplicationOutDtoSchema = object({
  id: string().defined(),
  createdDate: string().defined(),
  workingDays: number().optional().default(0),
  status: oneOfEnum(EApplicationStatus).defined(),
  payoutStatus: oneOfEnum(EPayoutStatus).defined(),
  project: ProjectOutDtoSchema.defined(),
  tester: TesterDto.defined(),
  extraPay: number().transform(convertCentToUnit).optional().default(0),
});

export type ApplicationOutDtoSchema = InferType<typeof ApplicationOutDtoSchema>;
