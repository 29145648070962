import React, { useCallback, useMemo } from 'react';

import { Dialog } from '@app/hoc';
import { Typography } from '@mui/material';
import { Table } from '@app/ui-components';
import { useTypedNavigate } from '@app/router';
import { OverrideFindingOutDto } from '@app/swagger-override-types';
import { Routes } from '@app/constants/routes';
import { GET_ALL_PENDING_RETEST_FINDINGS_QUERY } from '@app/constants/query-api-configs';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { usePendingRetestColumns } from '@app/domain/project/hooks/usePendingRetestColumns';
import { useQuery } from 'react-query';
import { FindingApi } from '@app/domain/finding/finding.api';
import { EFindingStatus } from '@app/swagger-types';
import { PAGE_SIZE } from '@app/constants/api';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const PendingRetestDialog: React.FC<Props> = ({ open, onClose }) => {
  const navigate = useTypedNavigate();

  const navigateToFindingPage = useCallback(
    ({ id, project: { id: projectId } }: OverrideFindingOutDto) => {
      navigate({ to: Routes.projects.finding, params: { projectId, findingId: id } });
    },
    [navigate]
  );

  const {
    values: { page, sort = GET_ALL_PENDING_RETEST_FINDINGS_QUERY.defaultSort },
    handlers: { handlePageChange, handleSortChange, handleResetQuery },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      sort: undefined,
    },
    prefix: GET_ALL_PENDING_RETEST_FINDINGS_QUERY.tablePrefix,
  });

  const { isFetching, data } = useQuery(
    [GET_ALL_PENDING_RETEST_FINDINGS_QUERY.name, { page, sort }],
    async () => {
      return await FindingApi.getFindings({
        sort,
        page: Math.ceil((page || 1) - 1),
        size: PAGE_SIZE,
        withBonuses: true,
        findingStatus: EFindingStatus.RETEST,
      });
    },
    { keepPreviousData: true, staleTime: GET_ALL_PENDING_RETEST_FINDINGS_QUERY.config.staleTime }
  );

  const tableColumns = usePendingRetestColumns();

  const leftHeaderContent = useMemo(() => <Typography className="text-xxl font-bold">Pending Re-tests</Typography>, []);

  const handleClose = useCallback(() => {
    onClose();
    handleResetQuery();
  }, [handleResetQuery, onClose]);

  return (
    <Dialog open={open} paperClassName="p-7 w-full" headerTitle="Pending Re-tests" onClose={handleClose}>
      <Table
        cols={tableColumns}
        leftHeaderContent={leftHeaderContent}
        tableData={data?.result || []}
        notFoundMessage="No Pending Re-tests yet"
        currentPage={page}
        loading={isFetching}
        hidePageSize
        total={data?.total}
        onPagination={handlePageChange}
        currentSort={sort}
        onSortChanged={handleSortChange}
        onItemSelect={navigateToFindingPage}
        noShadow
      />
    </Dialog>
  );
};
