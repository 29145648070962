import clsxm from '@app/lib/clsxm';
import { Radio, RadioProps } from '@mui/material';
import React from 'react';

export const StyledRadio: React.FC<RadioProps> = ({ checked, ...props }) => {
  return (
    <Radio
      {...props}
      checked={checked}
      className={clsxm({
        'text-green': Boolean(checked),
      })}
    />
  );
};
