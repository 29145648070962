import { InternalApiRequest } from '@app/services/api-request';
import { CommentInDto, CommentOutDto, PageResponseCommentOutDto } from '@app/swagger-types';
import { buildSortParam } from '@app/utils/api.util';

export abstract class FindingCommentApi {
  static async create({ findingId, Dto }: { findingId: string; Dto: CommentInDto }) {
    return InternalApiRequest.post<CommentOutDto>(`/private/findings/${findingId}/comments`, Dto);
  }
  static async getComments(findingId: string) {
    return InternalApiRequest.get<PageResponseCommentOutDto>(`/private/findings/${findingId}/comments`, {
      params: {
        sort: buildSortParam<CommentOutDto>('createdDate', 'asc'),
      },
    });
  }
}
