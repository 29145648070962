import { Routes } from '@app/constants/routes';

import { AdminProjectsModule } from './AdminProjectsModule';
import { TypedNavigate } from '@app/router';
import { ERole } from '@app/swagger-types';
import { AllAdminProjectsPage } from '@app/modules/admin/modules/projects/pages/AllAdminProjects.page';
import { AdminScheduledProjectsPage } from '@app/modules/admin/modules/projects/pages/AdminScheduledProjects.page';
import { AdminLiveProjectsPage } from '@app/modules/admin/modules/projects/pages/AdminLiveProjects.page';
import { AdminCompletedProjectsPage } from '@app/modules/admin/modules/projects/pages/AdminCompletedProjects.page';
import { AdminPendingProjectsPage } from '@app/modules/admin/modules/projects/pages/AdminPendingProjects.page';

export const AdminProjectsRoutes = {
  path: Routes.projects.index,
  element: <AdminProjectsModule />,
  role: [ERole.ROLE_ADMIN],
  children: [
    { path: Routes.projects.allProjects, element: <AllAdminProjectsPage /> },
    { path: Routes.projects.pendingProjects, element: <AdminPendingProjectsPage /> },
    { path: Routes.projects.scheduledProjects, element: <AdminScheduledProjectsPage /> },
    { path: Routes.projects.liveProjects, element: <AdminLiveProjectsPage /> },
    { path: Routes.projects.completedProjects, element: <AdminCompletedProjectsPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.projects.allProjects} /> },
  ],
};
