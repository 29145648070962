import React, { useCallback, useMemo, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Typography } from '@mui/material';
import { useEffectOnce } from 'react-use';

import { OverrideFindingOutDto, OverrideProjectOutDto } from '@app/swagger-override-types';
import { DecodedCvssValues } from '@app/shared/ui-components/cvss-calculator/cvss-calculator.utils';
import { useTypedNavigate } from '@app/router';
import { CreateFindingValidationSchema } from '@app/domain/finding/finding.form';
import { FindingInDto, PredefinedReport } from '@app/swagger-types';
import { Routes } from '@app/constants/routes';
import { Button, Form, Input, InputProps } from '@app/ui-components';
import { CvssCalculator } from '@app/shared/ui-components/cvss-calculator/CvssCalculator';
import clsxm from '@app/lib/clsxm';
import { sharedStyle } from '@app/styles/sharedStyle';
import { DEFAULT_SEVERITY_VECTOR } from '@app/shared/ui-components/cvss-calculator/cvss-calculator.constant';
import { Autocomplete } from '@app/shared/ui-components/fields/autocomplete/Autocomplete';
import { isObject } from 'lodash';
import { BackLink } from '@app/shared/ui-components/back-link/BackLink';
import { RichEditor } from '@app/shared/ui-components/rich-editor/RichEditor';

type TypeOption = {
  label: string;
  value: string;
  predefinedReport?: PredefinedReport;
};

const OTHER_FIELD_VALUE = 'OTHER';

const getPredefinedReport = (project: OverrideProjectOutDto, methodologyItemId?: string | null) => {
  if (!methodologyItemId) {
    return;
  }
  let item: TypeOption['predefinedReport'];
  project.methodologies?.some((m) =>
    m.checklist?.some((c) => {
      if (c.id === methodologyItemId) {
        item = c.predefinedReport;
        return true;
      }
      return false;
    })
  );
  return item;
};

export const FindingForm: React.FC<{
  title: string | React.ReactNode;
  project: OverrideProjectOutDto;
  onConfirm: (data: FindingInDto) => void;
  finding?: OverrideFindingOutDto;
  loading?: boolean;
}> = ({ project, onConfirm, loading, title, finding }) => {
  const projectId = project.id;
  const [{ checklistItemId }, setQuery] = useQueryParams({
    checklistItemId: withDefault(StringParam, finding?.checklistItemId || OTHER_FIELD_VALUE),
  });

  const [isCalculatorValid, setIsCalculatorValid] = useState(false);
  const [cvssValues, setCvssValues] = useState<Partial<DecodedCvssValues>>({});
  const [cvssScore, setCvssScore] = useState(finding?.cvssScore ?? 0);
  const [severityVector, setSeverityVector] = useState(finding?.severityVector ?? DEFAULT_SEVERITY_VECTOR);
  const navigate = useTypedNavigate();
  const { control, formState, handleSubmit, register, clearErrors } = useForm<CreateFindingValidationSchema>({
    resolver: yupResolver(CreateFindingValidationSchema),
    defaultValues: finding
      ? {
          title: finding.title,
          reportDescription: finding.reportDescription,
          remediationRecommendation: finding.remediationRecommendation,
          // managementReportSummary: finding.managementReportSummary,
          affectedHost: finding.affectedHost,
          stepsReplicate: finding.stepsReplicate,
          affectedHttpRequest: finding.affectedHttpRequest,
        }
      : undefined,
  });

  const navigateToProjectPage = useCallback(() => {
    navigate({ to: Routes.projects.project, params: { id: projectId } });
  }, [navigate, projectId]);

  const handleTypeChange = useCallback(
    (checklistItemId: string) => {
      // void used to allow dependencies
      void project;
      void getPredefinedReport;
      // const predefinedReport = getPredefinedReport(project, checklistItemId);
      // TODO: return this logic when we have new templates
      // setValue('reportDescription', predefinedReport?.reportDescription ?? '');
      // setValue('remediationRecommendation', predefinedReport?.remediationRecommendation ?? '');
      // setValue('managementReportSummary', predefinedReport?.managementReportSummary ?? '');

      setQuery({ checklistItemId });
    },
    [setQuery, project]
  );

  useEffectOnce(() => {
    if (!finding && checklistItemId) {
      handleTypeChange(checklistItemId);
    }
  });

  const typeOptions = useMemo(() => {
    const options: TypeOption[] = [{ label: 'Other', value: OTHER_FIELD_VALUE, predefinedReport: undefined }];
    project.methodologies?.forEach((m) => {
      m.checklist?.forEach(({ name, id, predefinedReport }) => {
        options.push({ label: name, value: id, predefinedReport });
      });
    });
    return options;
  }, [project]);

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: formState.errors[key]?.message,
        disabled: loading,
      };
    };
    return fn;
  }, [register, formState, loading]);

  const onSubmit: SubmitHandler<CreateFindingValidationSchema> = (formData) => {
    clearErrors();
    onConfirm({
      checklistItemId: checklistItemId !== OTHER_FIELD_VALUE ? checklistItemId : undefined,
      ...formData,
      cvssScore,
      severityVector,
      // TODO refactor. Avoid any
      ...(cvssValues as any),
    });
  };

  const renderFormItem = ({
    title,
    key,
    inputProps,
  }: {
    title: string;
    key: keyof CreateFindingValidationSchema;
    inputProps: InputProps;
  }) => (
    <div className="border-b border-grey4 py-8 pl-12 pr-20">
      <Typography className="pb-5 text-xxl font-bold">{title}</Typography>
      <Input {...inputRegister(key)} {...inputProps} />
    </div>
  );

  const renderRichEditorItem = ({
    title,
    key,
    placeholder,
  }: {
    title: string;
    key: keyof CreateFindingValidationSchema;
    placeholder: string;
  }) => (
    <div className="border-b border-grey4 py-8 pl-12 pr-20">
      <Typography className="pb-5 text-xxl font-bold">{title}</Typography>
      <Controller
        name={key}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <RichEditor
            value={field.value || ''}
            onChange={field.onChange}
            placeholder={placeholder}
            errorMessage={error?.message}
          />
        )}
      />
    </div>
  );

  return (
    <>
      <div className="mb-10 flex flex-col items-start">
        <BackLink to={Routes.projects.project} params={{ id: projectId }} />
        <Typography className="mb-8 text-xxxl font-bold">{title}</Typography>
      </div>
      <div className={clsxm('rounded-xl bg-transparentBackground', sharedStyle.shadowBorder)}>
        <Form control={control} onSubmit={handleSubmit(onSubmit)}>
          {renderFormItem({
            title: 'Title',
            key: 'title',
            inputProps: {
              label: 'Finding title',
              placeholder: 'Enter Title',
              className: 'max-w-xl',
            },
          })}
          <div className="border-b border-grey4">
            <CvssCalculator
              onChange={setCvssValues}
              onScoreChange={setCvssScore}
              onValidationChange={setIsCalculatorValid}
              defaultSeverityVector={finding?.severityVector}
              defaultScore={finding?.cvssScore}
              onSeverityVectorChange={setSeverityVector}
            />
          </div>
          <div className="border-b border-grey4 py-8 pl-12 pr-20">
            <Typography className="pb-5 text-xxl font-bold">Type</Typography>
            <Autocomplete<TypeOption>
              browserAutocompleteOff
              asyncSearch
              value={typeOptions.find(({ value }) => value === checklistItemId)}
              onChange={(_, v) => {
                if (isObject(v)) {
                  handleTypeChange(v.value);
                }
              }}
              getOptions={async () => typeOptions}
              withTextMatchAccessor="label"
              InputProps={{
                placeholder: 'Select Type',
              }}
            />
          </div>
          {renderFormItem({
            title: 'Affected Host',
            key: 'affectedHost',
            inputProps: {
              placeholder: 'Enter URL',
            },
          })}
          {renderRichEditorItem({
            title: 'Finding Description',
            key: 'reportDescription',
            placeholder: 'Enter text',
          })}
          {renderRichEditorItem({
            title: 'Steps to Replicate',
            key: 'stepsReplicate',
            placeholder: 'Enter text',
          })}
          {renderRichEditorItem({
            title: 'Remediation Recommendation',
            key: 'remediationRecommendation',
            placeholder: 'Enter text',
          })}
          {renderFormItem({
            title: 'HTTP Request',
            key: 'affectedHttpRequest',
            inputProps: {
              placeholder: 'Enter URL',
              multiline: true,
            },
          })}
          {/*<div className="py-8 pl-12 pr-20">*/}
          {/*  <Typography className="pb-3.5 text-xxl font-bold">Management Finding Summary</Typography>*/}
          {/*  <Typography className="pb-5 text-xs text-grey6">*/}
          {/*    &quot;Management Finding Summary&quot; field will be only visible within the Management report*/}
          {/*  </Typography>*/}
          {/*  <Controller*/}
          {/*    name={'managementReportSummary'}*/}
          {/*    control={control}*/}
          {/*    render={({ field, fieldState: { error } }) => (*/}
          {/*      <RichEditor*/}
          {/*        value={field.value || ''}*/}
          {/*        onChange={field.onChange}*/}
          {/*        placeholder="Enter text"*/}
          {/*        errorMessage={error?.message}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="flex justify-end py-9 pr-20">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              type="button"
              disabled={loading}
              onClick={navigateToProjectPage}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              type="submit"
              className="ml-6"
              loading={loading}
              disabled={!isCalculatorValid}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
