import React from 'react';
import { Outlet } from 'react-router-dom';
import { Tabs, Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { OutlinedTab } from '@app/shared/ui-components/tabs/OutlinedTab';
import { useGetRetestBonusesTotal } from '@app/domain/finding/api/hooks/finding.api.hook';
import { useGetAssignedApplicationsTotal } from '@app/domain/application/api/hooks/application.api.hook';

const tabRoutes = mutable([Routes.payments.projects, Routes.payments.retestBonuses] as const);

export const PaymentsModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);

  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to });
  };

  const assignedApplicationsTotal = useGetAssignedApplicationsTotal();
  const retestBonusesTotal = useGetRetestBonusesTotal();

  return (
    <>
      <div>
        <Typography className="mb-8 text-xxxl font-bold">Payments</Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          className="my-5"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <OutlinedTab label="Projects" value={tabRoutes[0]} count={assignedApplicationsTotal} />
          <OutlinedTab label="Re-test Bonuses" value={tabRoutes[1]} count={retestBonusesTotal} />
        </Tabs>
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
