import React, { useCallback } from 'react';
import { LinearProgress, Tabs, Typography } from '@mui/material';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';

import { Button } from '@app/ui-components';
import { FindingsTable } from '@app/shared/tables/findings-table/FindingsTable';
import { calculateMethodologyStatistic } from '@app/shared/ui-components/methodology-stat-box/methodology.utils';
import { MethodologyStatusButtons } from '@app/shared/ui-components/project-work-box/MethodologyStatusButtons';
import { NotFound } from '@app/shared/ui-components/table/components/not-found/NotFound';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { EApplicationStatus, EProjectStatus, EMethodologyStatus } from '@app/swagger-types';
import { useUserRole } from '@app/shared/hooks/useUserRole.hook';
import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { IS_DEV_ENV } from '@app/environment/typed-env';
import { useGetFindingsByProjectIdTotal } from '@app/domain/finding/api/hooks/finding.api.hook';
import { StyledTab } from '@app/shared/ui-components/tabs/StyledTab';
import { DownloadPdfReportsDropdown } from '@app/shared/ui-components/project-work-box/DownloadPdfReportsDropdown';
import { TableCard } from '@app/shared/ui-components/table-card';

enum ETabs {
  REPORTS = 'REPORTS',
  METHODOLOGY = 'METHODOLOGY',
}

export const ProjectWorkBox: React.FC<{
  project: ProjectOutDtoSchema;
  onChecklistItemStatusChange: (payload: { id: string; status: EMethodologyStatus }) => void;
}> = ({ project, onChecklistItemStatusChange }) => {
  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, ETabs.REPORTS),
  });
  const navigate = useTypedNavigate();
  const { isTester, isAdmin, isClient } = useUserRole();
  const findingsByProjectIdTotal = useGetFindingsByProjectIdTotal({
    projectId: project.id,
    disabledRequest: isTester && project.myApplication?.status !== EApplicationStatus.ASSIGNED,
  });

  const navigateToCreateFindingPage = useCallback(() => {
    navigate({ to: Routes.projects.addFinding, params: { projectId: project.id } });
  }, [navigate, project.id]);

  const navigateToReportPage = useCallback(() => {
    navigate({ to: Routes.pdf.technicalReport, params: { projectId: project.id } });
  }, [navigate, project.id]);

  const { methodologies } = project;
  const calc = calculateMethodologyStatistic({ methodologies });
  const progress = Math.floor((calc?.progress ?? 0) * 100);

  const leftHeaderContent = (
    <div>
      <Typography className="mb-3 text-xl font-bold">Work</Typography>
      <Tabs value={query.tab} onChange={(e, tab) => setQuery({ tab }, 'replaceIn')} className="border-b border-b-grey2">
        <StyledTab label="Findings" value={ETabs.REPORTS} className="text-xs" count={findingsByProjectIdTotal} />
        <StyledTab label="Methodology" value={ETabs.METHODOLOGY} className="text-xs" />
      </Tabs>
    </div>
  );
  const isProjectCompleted = project.status === EProjectStatus.COMPLETED;

  const rightHeaderContent = (
    <div className="flex gap-4">
      {(isClient || isAdmin) && (
        <DownloadPdfReportsDropdown
          project={project}
          projectId={project.id}
          disabled={!isProjectCompleted && !IS_DEV_ENV}
        />
      )}
      {(isClient || isAdmin || IS_DEV_ENV) && (
        <Button variant="outlined" color={'secondary'} startIcon={<PrintIcon />} onClick={navigateToReportPage}>
          View PDF Report
        </Button>
      )}
      {isTester &&
      project.myApplication?.status === EApplicationStatus.ASSIGNED &&
      project.status === EProjectStatus.LIVE ? (
        <Button variant="outlined" color={'primary'} startIcon={<AddIcon />} onClick={navigateToCreateFindingPage}>
          Add Finding
        </Button>
      ) : null}
    </div>
  );

  return (
    <TableCard
      wrapperClassName="min-h-[655px]"
      leftHeaderContent={leftHeaderContent}
      rightHeaderContent={rightHeaderContent}
      hideFooter
      cardContentClassName="h-auto overflow-visible"
    >
      {query.tab === ETabs.REPORTS && <FindingsTable project={project} />}
      {query.tab === ETabs.METHODOLOGY && (
        <div className="px-8">
          <div className="flex justify-end">
            <div className="pb-5 text-right">
              <Typography className="mb-1.5 text-s font-light">{progress}% completed</Typography>
              <LinearProgress color="primary" className="h-2 w-28" variant="determinate" value={progress} />
            </div>
          </div>
          <div className="h-[415px] overflow-y-auto pr-6">
            {Boolean(methodologies?.length) ? (
              methodologies?.map(({ sectionTitle, checklist }, index) => (
                <div key={index} className="mt-5 first:mt-0">
                  <Typography className="mb-5 text-l font-bold">{sectionTitle}</Typography>
                  {checklist?.map(({ name, id, status }) => (
                    <div key={id} className="mb-2.5 flex items-center justify-between">
                      {/*TODO: add tester avatar*/}
                      <Typography className="text-m text-grey6">{name}</Typography>
                      <MethodologyStatusButtons
                        status={status as EMethodologyStatus}
                        methodologyId={id}
                        projectId={project.id}
                        onStatusChange={onChecklistItemStatusChange}
                      />
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <NotFound notFoundMessage="No methodologies" />
            )}
          </div>
        </div>
      )}
    </TableCard>
  );
};
