import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { SignUpFormValidationSchema } from '@app/models/auth';
import { AuthApi } from '@app/auth/auth.api';
import { SignUpForm } from '@app/modules/auth/components/SignUpForm';
import { ConfirmCodeForm } from '@app/modules/auth/components/ConfirmCodeForm';
import useStore from '@app/store/useStore.hook';
import { useResetAsyncFn } from '@app/shared/hooks/useResetAsyncFn.hook';
import { IS_D2NA_DOMAIN } from '@app/domain/d2na/domain.util';

export const SignUp = () => {
  const [email, setEmail] = useState<string>();
  const { setCurrentUser } = useStore();

  const [{ loading: signUpLoading, error: signUpError, value: signUpEmail }, handleSignUp, resetSignUpValue] =
    useResetAsyncFn(async (formData: SignUpFormValidationSchema) => {
      await AuthApi.signUpStep1Silent({ ...formData, isD2NA: IS_D2NA_DOMAIN });
      return formData.email;
    }, []);

  useEffect(() => {
    if (signUpEmail) {
      setEmail(signUpEmail);
      resetSignUpValue();
    }
  }, [signUpEmail, resetSignUpValue]);

  const [{ loading: submitCodeLoading, error: submitCodeError, value: user }, handleSubmitCode] = useAsyncFn(
    async (emailActivationCode: string) => {
      if (email) {
        const { data } = await AuthApi.signUpStep2Silent({ email, emailActivationCode });
        return data;
      }
    },
    [email]
  );

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user, setCurrentUser]);

  if (email) {
    return (
      <ConfirmCodeForm
        onSubmitCode={handleSubmitCode}
        loading={submitCodeLoading}
        error={submitCodeError}
        email={email}
      />
    );
  }
  return <SignUpForm onSignUp={handleSignUp} loading={signUpLoading} error={signUpError} />;
};
