import React, { useState } from 'react';
import { useAsyncFn } from 'react-use';

import { ConfirmPasswordReqDto, ForgotPasswordReqDto } from '@app/models/auth';
import { ForgotPasswordForm } from '@app/modules/auth/components/ForgotPasswordForm';
import { ConfirmPasswordForm } from '@app/modules/auth/components/ConfirmPasswordForm';
import { AuthApi } from '@app/auth/auth.api';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string | undefined>();
  const navigate = useTypedNavigate();

  const [{ loading: forgotPasswordLoading, error: forgotPasswordError }, handleForgotPassword] = useAsyncFn(
    async (formData: ForgotPasswordReqDto) => {
      await AuthApi.forgotPassword1(formData).then(() => {
        setEmail(formData.email);
      });
    },
    []
  );
  const [{ loading: confirmPasswordLoading, error: confirmPasswordError }, handleConfirmPassword] = useAsyncFn(
    async (formData: ConfirmPasswordReqDto) => {
      AuthApi.forgotPassword2(formData).then(() => {
        navigate({ to: Routes.auth.sign_in });
      });
    },
    []
  );

  return (
    <div className="space-y-4">
      {email ? (
        <ConfirmPasswordForm
          onConfirmPassword={handleConfirmPassword}
          loading={confirmPasswordLoading}
          error={confirmPasswordError}
          email={email}
        />
      ) : (
        <ForgotPasswordForm
          onForgotPassword={handleForgotPassword}
          loading={forgotPasswordLoading}
          error={forgotPasswordError}
        />
      )}
    </div>
  );
};
