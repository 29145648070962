import { boolean, InferType, number, object, string } from 'yup';
import { oneOfEnum } from '@app/utils/yup.utils';
import {
  EAttackComplexity,
  EAttackVector,
  EAvailability,
  EConfidentiality,
  EFindingStatus,
  EIntegrity,
  EPrivilegesRequired,
  ERetestPaymentStatus,
  EScope,
  ESeverityLevel,
  EUserInteraction,
} from '@app/swagger-types';
import { convertCentToUnit } from '@app/utils/currency.utils';
import { TesterDto } from '@app/models/user';
import { ChecklistItemOutSchema, ProjectOutDtoSchema } from '@app/domain/project/project.schema';

export const FindingMethodologyDtoSchema = object({
  sectionTitle: string().required(),
  checklistItem: ChecklistItemOutSchema.required(),
});

export const FindingOutSchema = object({
  id: string().required(),
  title: string().required(),
  createdDate: string().required(),
  cvssAttackVector: oneOfEnum(EAttackVector).required(),
  cvssAttackComplexity: oneOfEnum(EAttackComplexity).required(),
  privilegesRequired: oneOfEnum(EPrivilegesRequired).required(),
  cvssUserInteraction: oneOfEnum(EUserInteraction).required(),
  cvssScope: oneOfEnum(EScope).required(),
  cvssConfidentiality: oneOfEnum(EConfidentiality).required(),
  cvssIntegrity: oneOfEnum(EIntegrity).required(),
  cvssAvailability: oneOfEnum(EAvailability).required(),
  status: oneOfEnum(EFindingStatus).required(),
  severity: oneOfEnum(ESeverityLevel).required(),
  tester: TesterDto.required(),
  project: ProjectOutDtoSchema.required(),
  retestPaymentStatus: oneOfEnum(ERetestPaymentStatus).optional(),
  cvssScore: number().required(),
  isStatusChangedByClient: boolean().required(),
  updatedDate: string().optional(),
  retestDeadline: string().optional(),
  retestBonus: number().transform(convertCentToUnit).optional().default(0),
  checklistItemId: string().optional().default(''),
  severityVector: string().optional().default(''),
  affectedHttpRequest: string().optional().default(''),
  managementReportSummary: string().optional().default(''),
  remediationRecommendation: string().optional().default(''),
  stepsReplicate: string().optional().default(''),
  reportDescription: string().optional().default(''),
  affectedHost: string().optional().default(''),
  methodology: FindingMethodologyDtoSchema.optional().default(undefined),
});

export type FindingOutSchema = InferType<typeof FindingOutSchema>;
