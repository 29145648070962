import { object, string, InferType, boolean } from 'yup';

import { oneOfEnum } from '@app/utils/yup.utils';

import { ERole, EUserStatus } from '@app/swagger-types';

export const UserBaseSchema = object({
  id: string().required(),
  firstName: string().required(),
  lastName: string().required(),
  email: string().required(),
  role: oneOfEnum(ERole),
  status: oneOfEnum(EUserStatus),
  createdDate: string().required(),
  acceptedTac: boolean().required(),
  acceptedTacDate: string().required(),
  avatarId: string().optional().default(undefined),
  slackName: string().optional().default(undefined),
  slackDirectMessageURL: string().optional().default(undefined),
  slackId: string().optional().default(undefined),
});

export type UserBaseSchema = InferType<typeof UserBaseSchema>;
