import { useState, useMemo, useEffect } from 'react';

import { OpenableEntityManagerInterface, OpenEntityType, ValueType } from '../types';

interface PropTypes<OpenableEntityManager> {
  manager: OpenableEntityManager;
}

export function OpenableEntityRenderComponent<
  OpenableEntityManager extends OpenableEntityManagerInterface<
    Record<keyof OpenableEntityManager['entities'], ValueType>
  >
>({ manager }: PropTypes<OpenableEntityManager>) {
  const [openEntities, setOpenEntities] = useState<OpenEntityType<keyof OpenableEntityManager['entities']>[]>([]);
  useEffect(() => {
    manager.on(setOpenEntities);
    return () => manager.off(setOpenEntities);
  }, [manager]);

  const renderedEntities = useMemo(() => {
    return openEntities.map((entity) => {
      const { entityName, entityIsOpen } = entity;
      const EntityComponent = manager.entities[entityName].component;
      const entityProps = manager.getEntityProps(entityName);

      return (
        <EntityComponent
          {...entityProps}
          open={entityIsOpen}
          key={entityName}
          onClose={() => manager.close(entityName)}
        />
      );
    });
  }, [openEntities, manager]);

  return <>{renderedEntities}</>;
}
