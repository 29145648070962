import React, { useCallback, useEffect } from 'react';
import { differenceInDays, format } from 'date-fns';
import { InputAdornment, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';

import { ConditionalWrapper, Dialog } from '@app/hoc';
import { Button, Checkbox, Form, Input, Tooltip } from '@app/ui-components';
import { SetUpProjectValidationSchema } from '@app/domain/project/project.schema';
import styles from '@app/shared/ui-components/fields/input/Input.module.scss';
import { DATE_FORMAT } from '@app/constants/date';
import { OverrideProjectOutDto } from '@app/swagger-override-types';
import { DatePickerRangeInput } from '@app/shared/ui-components/date-picker-range-input/DatePickerRangeInput';
import { ISORange } from '@app/shared/ui-components/date-picker-range/DatePickerRange';
import { ConfirmDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDialog';
import { getDayDifference } from '@app/utils/date.utils';
import { convertCentToUnit, renderDollarAmount } from '@app/utils/currency.utils';
import { UseMutateFunction } from 'react-query/types/react/types';
import { ScopingCalculator } from '@app/modules/admin/create-project/ScopingCalculator';
import { useScopingCalculator } from '@app/modules/admin/create-project/useScopingCalculator';
import { UseSetProjectTimeline } from '@app/domain/project/api/hooks/project.api.hook';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { inferIdentity } from '@app/utils/type.utils';
import { CLIENT_QUOTE_EDITOR_PAGE_PARAMS } from '@app/modules/pdf/pages/tac/ClientQuoteEditorPageParams';
import { useHandler } from '@app/hooks/useHandler.hook';
import { usePublicGPBRateQuery } from '@app/domain/settings/currency/currency.query';

const TODAY_DATE = new Date();

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: UseMutateFunction<unknown, Error, UseSetProjectTimeline>;
  project?: OverrideProjectOutDto;
  isLoading?: boolean;
}

export const SetUpProjectDialog: React.FC<Props> = ({ project, ...props }) => {
  const gbpCurrency = usePublicGPBRateQuery();

  if (!project || !gbpCurrency.data) {
    return null;
  }

  return <SetUpProjectDialogBody project={project} {...props} defaultUsdToGbpRate={gbpCurrency.data.usdToGbpRate} />;
};

const SetUpProjectDialogBody: React.FC<
  Props & {
    project: OverrideProjectOutDto;
    defaultUsdToGbpRate: number;
  }
> = ({ open, project, onClose, onConfirm, isLoading, defaultUsdToGbpRate }) => {
  const {
    desiredDeadline,
    answers,
    penetrationType,
    workingDays,
    priceForClient,
    priceForTester,
    desiredTesterAssignment,
    dayRateForClient = 0,
    dayRateForTester = 0,
    id: projectId,
  } = project;

  const { duration } = useScopingCalculator({
    penetrationType,
    answers,
  });

  const { control, handleSubmit, clearErrors, reset, setValue, formState, watch } =
    useForm<SetUpProjectValidationSchema>({
      resolver: yupResolver(SetUpProjectValidationSchema),
      mode: 'all',
      defaultValues: {
        startDate: project.startDate,
        endDate: project.endDate,
        isD2NA: project.isD2NA || project.client.isD2NA,
        usdToGbpRate: project.usdToGbpRate || defaultUsdToGbpRate,
      },
    });

  const { startDate, endDate, usdToGbpRate, isD2NA } = watch();
  const vatMultiplier = isD2NA ? 1.2 : 1; // +20% for D2NA

  const isDatesLessThenToday = Boolean(
    (startDate && differenceInDays(new Date(startDate), TODAY_DATE)) < 0 ||
      (endDate && differenceInDays(new Date(endDate), TODAY_DATE)) < 0
  );

  useEffect(() => {
    if (isEmpty(formState.touchedFields)) {
      setValue('workingDays', workingDays || duration);
      setValue(
        'priceForClient',
        priceForClient || duration * desiredTesterAssignment * dayRateForClient * vatMultiplier
      );
      setValue('priceForTester', priceForTester || duration * dayRateForTester);
    }
  }, [
    formState.touchedFields,
    workingDays,
    duration,
    setValue,
    priceForClient,
    priceForTester,
    desiredTesterAssignment,
    dayRateForClient,
    dayRateForTester,
    vatMultiplier,
  ]);

  const handleVatChange = useHandler(() => {
    const workingDays = (() => {
      if (!startDate || !endDate) {
        return duration;
      }
      return getDayDifference(new Date(startDate), new Date(endDate), true);
    })();
    setValue('priceForClient', workingDays * desiredTesterAssignment * dayRateForClient * vatMultiplier, {
      shouldValidate: true,
    });
  });

  useEffect(() => {
    handleVatChange();
  }, [vatMultiplier, handleVatChange]);

  const handleSetDate = useCallback(
    ({ startDate, endDate }: ISORange) => {
      const workingDays = getDayDifference(new Date(startDate), new Date(endDate), true);

      setValue('startDate', startDate, { shouldValidate: true, shouldTouch: true });
      setValue('endDate', endDate, { shouldValidate: true, shouldTouch: true });
      setValue('workingDays', workingDays, {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue('priceForClient', workingDays * desiredTesterAssignment * dayRateForClient * vatMultiplier, {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue('priceForTester', workingDays * dayRateForTester, {
        shouldValidate: true,
        shouldTouch: true,
      });
    },
    [setValue, dayRateForClient, dayRateForTester, desiredTesterAssignment, vatMultiplier]
  );

  const handleClose = useCallback(() => {
    clearErrors();
    onClose();
  }, [onClose, clearErrors]);

  const handleCloseWithReset = useCallback(() => {
    handleClose();
    reset();
  }, [reset, handleClose]);

  const onSubmit: SubmitHandler<SetUpProjectValidationSchema> = (formData) => {
    onConfirm(
      { project, Dto: formData },
      {
        onSuccess: (_, { Dto: formData }) => {
          handleClose();
          reset({
            ...formData,
            priceForTester: convertCentToUnit(formData.priceForTester),
            priceForClient: convertCentToUnit(formData.priceForClient),
          });
        },
      }
    );
  };

  const overrideDuration = startDate && endDate ? watch().workingDays : undefined;

  return (
    <Dialog open={open} paperClassName="p-7" headerTitle="Set up price & timeline" onClose={handleCloseWithReset}>
      <Form onSubmit={handleSubmit(onSubmit)} control={control}>
        <div className="flex">
          <div className="flex-[6] border-b border-solid border-grey5/20 px-7 pb-7">
            <Controller
              name="workingDays"
              control={control}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <NumberFormat
                  {...field}
                  customInput={Input}
                  value={field.value || 0}
                  onValueChange={(values) => {
                    onChange(Math.max(Number(values.value), 0));
                  }}
                  thousandSeparator
                  readOnly
                  decimalScale={0}
                  errorMessage={fieldState.error?.message}
                  placeholder="Enter days"
                  label="How many days will the project take?"
                  allowNegative={false}
                  endAdornment={
                    <InputAdornment position="end" className={styles['end-adornment']}>
                      <Typography className="mr-4 text-xs">days</Typography>
                    </InputAdornment>
                  }
                />
              )}
            />
            <Typography className="mt-3.5 w-52 text-xs text-grey5">
              *Invoice for payment of the project days will be generated automatically
            </Typography>
          </div>
          <div className="flex-[6] border-b border-solid border-grey5/20 px-7 pb-7">
            <Controller
              name="priceForClient"
              control={control}
              render={({ field: { onChange: _, ...field }, fieldState }) => (
                <NumberFormat
                  {...field}
                  customInput={Input}
                  value={field.value || 0}
                  readOnly
                  thousandSeparator
                  decimalScale={0}
                  errorMessage={fieldState.error?.message}
                  placeholder="Enter amount"
                  label="Price per project for client"
                  allowNegative={false}
                  endAdornment={
                    <InputAdornment position="end" className={styles['end-adornment']}>
                      <Typography className="mr-4 text-xs">$</Typography>
                    </InputAdornment>
                  }
                />
              )}
            />
            <Typography className="mt-3.5 w-52 text-xs text-grey5">
              {`*1 day = `}
              {renderDollarAmount(dayRateForClient * desiredTesterAssignment)}
              {` (you can change it on the settings)`}
              {isD2NA && ' + 20% VAT included'}
            </Typography>
          </div>
        </div>
        <div className="flex">
          <div className="flex-[6] border-b border-solid border-grey5/20 p-7">
            <DatePickerRangeInput
              onConfirm={handleSetDate}
              minDate={new Date(project.createdDate)}
              appliedDate={startDate && endDate ? { startDate, endDate } : undefined}
              onReset={() => reset()}
              allowPastDates={false}
              inputProps={{
                label: 'Project Timeline',
                errorMessage: formState.errors.startDate?.message || formState.errors.endDate?.message,
              }}
            />
            {desiredDeadline && (
              <Typography className="mt-3.5 w-52 text-xs text-grey5">
                {`*The client would like to complete this project by ${format(new Date(desiredDeadline), DATE_FORMAT)}`}
              </Typography>
            )}
          </div>
          <div className="flex-[6] border-b border-solid border-grey5/20 p-7">
            <Controller
              name="priceForTester"
              control={control}
              render={({ field: { onChange: _, ...field }, fieldState }) => (
                <NumberFormat
                  {...field}
                  customInput={Input}
                  value={field.value || 0}
                  readOnly
                  thousandSeparator
                  decimalScale={0}
                  errorMessage={fieldState.error?.message}
                  placeholder="Enter amount"
                  label="Price per project for tester"
                  allowNegative={false}
                  endAdornment={
                    <InputAdornment position="end" className={styles['end-adornment']}>
                      <Typography className="mr-4 text-xs">$</Typography>
                    </InputAdornment>
                  }
                />
              )}
            />
            <Typography className="mt-3.5 w-52 text-xs text-grey5">
              {`*1 day = `}
              {renderDollarAmount(dayRateForTester)}
              {` (you can change it on the settings)`}
            </Typography>
          </div>
        </div>
        <ScopingCalculator
          penetrationType={penetrationType}
          answers={answers}
          countOfTesters={desiredTesterAssignment}
          overrideDuration={overrideDuration}
          usdToGbpRate={usdToGbpRate}
          isD2NA={isD2NA}
        />
        <div className="flex items-end justify-center gap-12">
          <Controller
            name="isD2NA"
            control={control}
            render={({ field: { value, ...field }, fieldState }) => (
              <Checkbox label="D2NA Customer" {...field} checked={value} errorMessage={fieldState.error?.message} />
            )}
          />
          <Controller
            name="usdToGbpRate"
            control={control}
            render={({ field: { onChange, ...field }, fieldState }) => (
              <NumberFormat
                {...field}
                customInput={Input}
                value={field.value || ''}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  onChange(parseFloat(e.target.value) || defaultUsdToGbpRate)
                }
                decimalScale={2}
                errorMessage={fieldState.error?.message}
                placeholder={`${defaultUsdToGbpRate}`}
                label="USD to GBP rate"
                allowNegative={false}
              />
            )}
          />
        </div>
        <div className="mb-3 mt-12 flex justify-center gap-4">
          <Button onClick={handleCloseWithReset} variant="outlined" color="secondary" size="large" disabled={isLoading}>
            Close
          </Button>
          <ConditionalWrapper
            condition={isDatesLessThenToday}
            wrapper={(children) => (
              <Tooltip title="Project Timeline cannot contain past dates">
                <div>{children}</div>
              </Tooltip>
            )}
          >
            <TypedLink
              to={Routes.pdf.clientQuoteEditor}
              params={{ projectId }}
              disabled={!formState.isValid || isDatesLessThenToday || isLoading}
              queryParams={inferIdentity<Record<keyof typeof CLIENT_QUOTE_EDITOR_PAGE_PARAMS, string>>()({
                startDate,
                endDate,
                workingDays: watch().workingDays?.toString(),
                usdToGbpRate: String(usdToGbpRate),
                isD2NA: isD2NA ? '1' : '0',
              })}
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                disabled={!formState.isValid || isDatesLessThenToday || isLoading}
              >
                Quote Editor
              </Button>
            </TypedLink>
          </ConditionalWrapper>
          <ConfirmDialog
            trigger={
              <Tooltip title={isDatesLessThenToday ? 'Project Timeline cannot contain past dates' : undefined}>
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={!formState.isValid || isDatesLessThenToday}
                    loading={isLoading}
                  >
                    Set up
                  </Button>
                </div>
              </Tooltip>
            }
            disabled={!formState.isValid || isDatesLessThenToday || isLoading}
            onConfirm={handleSubmit(onSubmit)}
            headerTitle="Confirm Project Set Up"
            title={'The client will be emailed with an invoice for the project'}
          />
        </div>
      </Form>
    </Dialog>
  );
};
