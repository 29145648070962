import React, { useMemo, useCallback } from 'react';
import { useMutation } from 'react-query';
import { format } from 'date-fns';

import { useTypedNavigate } from '@app/router';
import { useBasicQueryControls } from '@app/shared/hooks/useBasicQueryControls.hook';
import { GET_INVOICES_QUERY } from '@app/constants/query-api-configs';
import { IconButton, Table, TableColumn } from '@app/ui-components';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@app/constants/date';
import { createGBPformatter, formatDollars } from '@app/utils/currency.utils';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { Routes } from '@app/constants/routes';
import { InvoiceApi } from '@app/domain/invoice/invoice.api';
import { InvoiceStatusDropdown } from '@app/domain/invoice/InvoiceStatusDropdown';
import { OverrideInvoiceStatusInDto } from '@app/swagger-override-types';
import { DeleteIcon } from '@app/assets/icons';
import { InvoiceOutDto } from '@app/swagger-types';
import { ConfirmDeleteDialog } from '@app/shared/ui-components/confirm-dialog/ConfirmDeleteDialog';
import { UserBlock } from '@app/shared/ui-components/user-block/UserBlock';
import { useGetInvoices } from '@app/domain/invoice/api/hooks/invoice.api.hook';
import { PAGE_SIZE } from '@app/constants/api';

export const InvoicesPage: React.FC = () => {
  const navigate = useTypedNavigate();

  const {
    values: { page, sort = GET_INVOICES_QUERY.defaultSort, search },
    handlers: { handlePageChange, handleSortChange, handleSearchChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      search: '',
    },
    prefix: GET_INVOICES_QUERY.tablePrefix,
  });

  const { data, isFetching, refetch } = useGetInvoices(
    {
      size: PAGE_SIZE,
      page: Math.ceil((page || 1) - 1),
      sort,
      term: search,
    },
    { keepPreviousData: true }
  );

  const { mutate: updateInvoiceStatus, isLoading: invoiceStatusLoading } = useMutation(
    async ({ invoiceId, Dto }: { invoiceId: string; Dto: OverrideInvoiceStatusInDto }) => {
      return await InvoiceApi.updateStatus(invoiceId, Dto);
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );
  const { mutate: deleteInvoice, isLoading: deleteInvoiceLoading } = useMutation(
    async (invoiceId: string) => {
      return await InvoiceApi.delete(invoiceId);
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleInvoiceStatusChange = useCallback(
    ({ id: invoiceId, Dto }: { id: string; Dto: OverrideInvoiceStatusInDto }) => {
      updateInvoiceStatus({ invoiceId, Dto });
    },
    [updateInvoiceStatus]
  );

  const columns: TableColumn<InvoiceOutDto>[] = useMemo(
    () => [
      {
        headerName: 'Date & Timeline',
        field: 'createdDate',
        align: 'left',
        render: ({ createdDate }) => format(new Date(createdDate), DATE_TIME_FORMAT),
      },
      {
        headerName: 'Company',
        field: 'project.client.companyName',
        align: 'left',
        disableSort: true,
        render: ({
          project: {
            client: { companyName },
          },
        }) => companyName,
      },
      {
        headerName: 'Client',
        field: 'project.client.firstName',
        align: 'left',
        disableSort: true,
        disableClick: true,
        render: ({ project: { client } }) => <UserBlock user={client} hideName />,
      },
      {
        headerName: 'Project',
        field: 'project.name',
        align: 'left',
        render: ({ project: { name } }) => name,
      },
      {
        headerName: 'Amount',
        field: 'amountCoin',
        align: 'center',
        render: ({ amountCoin, usdToGbpRate, isD2NA }) =>
          isD2NA && Boolean(usdToGbpRate)
            ? createGBPformatter(usdToGbpRate).formatGBP(amountCoin)
            : formatDollars(amountCoin),
      },
      {
        headerName: 'Status',
        field: 'status',
        align: 'left',
        disableClick: true,
        width: 145,
        render: ({ status, id }) => (
          <InvoiceStatusDropdown id={id} activeStatus={status} onChangeStatus={handleInvoiceStatusChange} />
        ),
      },
      {
        headerName: 'Actions',
        field: 'id',
        align: 'right',
        disableClick: true,
        disableSort: true,
        render: ({ id, createdDate }) => (
          <ConfirmDeleteDialog
            trigger={
              <IconButton>
                <DeleteIcon />
              </IconButton>
            }
            onConfirm={() => deleteInvoice(id)}
            headerTitle="Delete Invoice"
            title={`Are you sure you want to delete Invoice for ${format(new Date(createdDate), DATE_FORMAT)}?`}
          />
        ),
      },
    ],
    [handleInvoiceStatusChange, deleteInvoice]
  );

  const leftContent = useMemo(() => {
    return (
      <div>
        <SearchInput placeholder="Search by client" className="w-64" value={search} onChange={handleSearchChange} />
      </div>
    );
  }, [handleSearchChange, search]);

  const navigateToProjectPage = ({ project: { id } }: InvoiceOutDto) => {
    navigate({ to: Routes.projects.project, params: { id } });
  };

  return (
    <Table
      cols={columns}
      tableData={data?.result || []}
      loading={isFetching || invoiceStatusLoading || deleteInvoiceLoading}
      notFoundMessage="No Invoices"
      leftHeaderContent={leftContent}
      currentPage={page}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
      onItemSelect={navigateToProjectPage}
      hidePageSize
    />
  );
};
