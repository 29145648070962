import { ApplicationParams } from '@app/domain/application/application.type';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { EApplicationStatus, EPayoutStatus, EProjectStatus, PageResponseApplicationOutDto } from '@app/swagger-types';
import {
  GET_APPLIED_PROJECTS_QUERY,
  GET_ASSIGNED_APPLICATIONS_QUERY,
  GET_FINANCES_QUERY,
} from '@app/constants/query-api-configs';
import { ApplicationApi } from '@app/domain/application/application.api';
import { OverrideApplicationPayoutStatusInDto } from '@app/swagger-override-types';

export const useGetAppliedProjects = (
  params: ApplicationParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PageResponseApplicationOutDto,
    (typeof GET_APPLIED_PROJECTS_QUERY.name | ApplicationParams)[]
  >
) => {
  return useQuery(
    [GET_APPLIED_PROJECTS_QUERY.name, params],
    async () => {
      return await ApplicationApi.getApplications({
        ...params,
      });
    },
    {
      staleTime: GET_APPLIED_PROJECTS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetAssignedApplications = (
  params: ApplicationParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PageResponseApplicationOutDto,
    (typeof GET_ASSIGNED_APPLICATIONS_QUERY.name | ApplicationParams)[]
  >
) => {
  return useQuery(
    [GET_ASSIGNED_APPLICATIONS_QUERY.name, params],
    async () => {
      return await ApplicationApi.getApplications({
        status: EApplicationStatus.ASSIGNED,
        ...params,
      });
    },
    {
      staleTime: GET_ASSIGNED_APPLICATIONS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetFinances = (
  params: ApplicationParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PageResponseApplicationOutDto,
    (typeof GET_FINANCES_QUERY.name | ApplicationParams)[]
  >
) => {
  return useQuery(
    [GET_FINANCES_QUERY.name, params],
    async () => {
      return await ApplicationApi.getApplications({
        projectStatus: EProjectStatus.COMPLETED,
        ...params,
      });
    },
    {
      staleTime: GET_FINANCES_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetAppliedProjectsTotal = (): number => {
  const { data } = useGetAppliedProjects(
    {
      size: 1,
      projectStatus: EProjectStatus.SCHEDULED,
      status: EApplicationStatus.REQUESTED,
    },
    {
      keepPreviousData: true,
    }
  );
  return data?.total || 0;
};

export const useGetAssignedApplicationsTotal = (): number => {
  const { data } = useGetAssignedApplications(
    {
      size: 1,
    },
    {
      keepPreviousData: true,
    }
  );
  return data?.total || 0;
};
export const useGetFinancesTotal = (payoutStatus: EPayoutStatus): number => {
  const { data } = useGetFinances(
    {
      payoutStatus,
      size: 1,
    },
    {
      keepPreviousData: true,
    }
  );
  return data?.total || 0;
};

// MUTATION HOOKS

export type UseUpdatePayoutStatus = { applicationId: string; Dto: OverrideApplicationPayoutStatusInDto };

export const useUpdatePayoutStatus = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, UseUpdatePayoutStatus> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ applicationId, Dto }) => {
      return await ApplicationApi.updatePayoutStatus(applicationId, Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        // invalidate info in tabs
        await queryClient.invalidateQueries([
          GET_FINANCES_QUERY.name,
          { size: 1, payoutStatus: EPayoutStatus.PENDING },
        ]);
        await queryClient.invalidateQueries([GET_FINANCES_QUERY.name, { size: 1, payoutStatus: EPayoutStatus.PAID }]);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};
