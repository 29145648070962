import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { UIKitSection } from '../UIKitSection';
import { ClientsPaymentsBox } from '@app/shared/ui-components/clients-payments-box/ClientsPaymentsBox';
import { createUiKitInvoice } from '@app/modules/ui-kit/pages/mock-data/payments.data';
import { OverrideInvoiceStatusInDto } from '@app/swagger-override-types';
import { EInvoiceStatus } from '@app/swagger-types';

enum EMode {
  HAS_DATA = 'HAS_DATA',
  NO_DATA = 'NO_DATA',
}

export default function DemoForClientsPaymentsBox() {
  const [invoices, setInvoices] = useState([
    createUiKitInvoice({ id: '1', status: EInvoiceStatus.PENDING }),
    createUiKitInvoice({ id: '2', status: EInvoiceStatus.PAID }),
    createUiKitInvoice({ id: '3', status: EInvoiceStatus.PENDING }),
    createUiKitInvoice({ id: '4', status: EInvoiceStatus.PAID }),
    createUiKitInvoice({ id: '5', status: EInvoiceStatus.PENDING }),
  ]);
  const [mode, setMode] = useState(EMode.HAS_DATA);

  const handleInvoiceStatusChange = ({ id: invoiceId, Dto }: { id: string; Dto: OverrideInvoiceStatusInDto }) => {
    setInvoices((prevInvoices) =>
      prevInvoices.map((invoice) => {
        if (invoice.id === invoiceId) {
          return { ...invoice, ...Dto };
        }
        return invoice;
      })
    );
  };

  return (
    <UIKitSection title="ClientsPaymentsBox">
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setMode(e.currentTarget.value as EMode);
        }}
      >
        <FormControlLabel value={EMode.HAS_DATA} control={<Radio />} label="Has data" />
        <FormControlLabel value={EMode.NO_DATA} control={<Radio />} label="No data" />
      </RadioGroup>
      {mode === EMode.HAS_DATA && (
        <ClientsPaymentsBox invoices={invoices} onInvoiceStatusChange={handleInvoiceStatusChange} />
      )}
      {mode === EMode.NO_DATA && <ClientsPaymentsBox onInvoiceStatusChange={handleInvoiceStatusChange} />}
    </UIKitSection>
  );
}
