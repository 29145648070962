import React from 'react';
import { QuestionSchema } from '@app/domain/product/product.schema';
import { ETypeOfAnswer } from '@app/modules/admin/create-project/product.type';
import { Input } from '@app/ui-components';
import { narrowRadioQuestionConfig } from '@app/modules/admin/create-project/narrow-config-utils/radio-question-config.schema';
import { FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { Control, Controller, Path } from 'react-hook-form';
import { StyledRadio } from '@app/shared/ui-components/radio';
import { RadioNumberInput } from '@app/shared/ui-components/radio-input/RadioNumberInput';
import { RadioInput } from '@app/shared/ui-components/radio-input/RadioInput';
import { FormState, UseFormRegister } from 'react-hook-form/dist/types/form';
import { ProductQuestionnaireForm } from '@app/domain/product/product.form';
import { NumberInput } from '@app/shared/ui-components/fields/number-input/NumberInput';

interface Props<T extends Pick<ProductQuestionnaireForm, 'answers'>> {
  questions: QuestionSchema[];
  register: UseFormRegister<T>;
  control: Control<T>;
  formState: FormState<T>;
}

export const useRenderProductQuestion = <T extends Pick<ProductQuestionnaireForm, 'answers'>>({
  questions,
  control,
  register,
  formState,
}: Props<T>) => {
  const getKeyAndIndexByQuestion = (question: QuestionSchema) => {
    const index = questions.indexOf(question);
    if (index === -1 || index === undefined) {
      throw new Error('this should not happen. Debug and bugfix. No index for question found');
    }
    return { key: `answers.${index}.value` as Path<T>, index };
  };

  const dynamicAnswerRegister: (question: QuestionSchema) =>
    | (ReturnType<typeof register> & {
        errorMessage?: string;
      })
    | null = (question) => {
    const { key, index } = getKeyAndIndexByQuestion(question);
    return {
      ...register(key, { required: !question.jsonConfig.isOptional && 'Required Field' }),
      errorMessage: formState.errors.answers?.[index]?.value?.message,
    };
  };

  return (q: QuestionSchema) => {
    if (q.typeOfAnswer === ETypeOfAnswer.SHORT) {
      if (q.jsonConfig.inputType === 'number') {
        const { key } = getKeyAndIndexByQuestion(q);

        return (
          <Controller
            name={key}
            control={control}
            // TODO: remove 'as any'
            defaultValue={q.jsonConfig.defaultValue as any}
            rules={{ required: !q.jsonConfig.isOptional && 'Required Field' }}
            render={({ field: { onChange, value, ...field }, fieldState }) => (
              <NumberInput
                {...field}
                value={String(value)}
                onValueChange={(values) => {
                  onChange(String(values.value));
                }}
                errorMessage={fieldState.error?.message}
                className="my-1"
                placeholder="Enter number"
                label={q.title}
                allowNegative={q.jsonConfig.allowNegative}
              />
            )}
          />
        );
      }

      return <Input {...dynamicAnswerRegister(q)} className="my-1" label={q.title} placeholder="Enter text" />;
    }
    if (q.typeOfAnswer === ETypeOfAnswer.LONG) {
      return (
        <Input
          {...dynamicAnswerRegister(q)}
          className="my-1"
          label={q.title}
          placeholder="Enter text"
          multiline
          minRows={3}
        />
      );
    }
    if (q.typeOfAnswer === ETypeOfAnswer.RADIO) {
      const config = narrowRadioQuestionConfig(q.jsonConfig);
      const index = questions.indexOf(q);

      if (index === -1 || index === undefined) {
        throw new Error('this should not happen. Debug and bugfix. No index for question found');
      }

      return (
        <FormControl>
          <Typography className="text-xs">{q.title}</Typography>
          <Controller
            name={`answers.${index}.value` as Path<T>}
            control={control}
            rules={{ required: 'Required Field' }}
            render={({ field, fieldState }) => (
              <RadioGroup {...field}>
                {config.options.map((option, index) => {
                  if (option.type === 'text') {
                    return (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        label={option.value}
                        componentsProps={{ typography: { className: 'text-xs' } }}
                        control={<StyledRadio checked={option.value === field.value} />}
                      />
                    );
                  } else if (option.type === 'input') {
                    const Input = option.inputType === 'number' ? RadioNumberInput : RadioInput;
                    return (
                      <Input
                        key={index}
                        className="my-2"
                        placeholder={option.placeholder}
                        multiline={option.inputType === 'long'}
                        minRows={3}
                        onSetValue={field.onChange}
                        allowNegative={option.allowNegative}
                        // TODO: remove 'as'
                        groupValue={field.value as string | undefined}
                      />
                    );
                  }
                })}
                <span className="text-xxs text-error">{fieldState.error?.message}</span>
              </RadioGroup>
            )}
          />
        </FormControl>
      );
    }
  };
};
