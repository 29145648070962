import React, { useCallback } from 'react';
import { useQueryParams } from 'use-query-params';
import { useMutation } from 'react-query';

import { SignUpInvitedUserFormValidationSchema } from '@app/models/auth';
import { AuthApi } from '@app/auth/auth.api';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { StringParam } from '@app/utils/use-query-params.utils';
import useStore from '@app/store/useStore.hook';
import { TesterDto } from '@app/models/user';
import { SignUpFromInvitationForm } from '@app/modules/auth/components/SignUpFromInvitationForm';

export const SignUpTesterPage = () => {
  const [{ token }] = useQueryParams({
    token: StringParam,
  });
  const navigate = useTypedNavigate();
  const { setCurrentUser } = useStore();

  const navigateToMyProfile = useCallback(() => {
    navigate({ to: Routes.admin.user_management.my_profile });
  }, [navigate]);

  const {
    isLoading,
    error,
    mutate: handleSignUp,
  } = useMutation<TesterDto | undefined, Error, SignUpInvitedUserFormValidationSchema>(
    async (formData) => {
      if (token) {
        const { data } = await AuthApi.signUpTesterSilent({ ...formData, invitationToken: token });
        return data;
      }
    },
    {
      onSuccess: (user) => {
        if (user) {
          setCurrentUser(user);
          navigateToMyProfile();
        }
      },
    }
  );

  return <SignUpFromInvitationForm onSignUp={handleSignUp} loading={isLoading} error={error} />;
};
