import { useQuery, UseQueryOptions } from 'react-query';
import { GET_TESTER_INVITATIONS_QUERY } from '@app/constants/query-api-configs';
import { UserInvitationApi } from '@app/domain/user-invitation/api/user-invitation.api';
import { ERole, PageResponseUserInvitationDto } from '@app/swagger-types';
import { UserInvitationQueryParams } from '@app/domain/user-invitation/api/user-invitation.type';

export const useGetTesterInvitations = (
  params: UserInvitationQueryParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PageResponseUserInvitationDto,
    (typeof GET_TESTER_INVITATIONS_QUERY.name | UserInvitationQueryParams)[]
  >
) => {
  return useQuery(
    [GET_TESTER_INVITATIONS_QUERY.name, params],
    async () => {
      const { data } = await UserInvitationApi.getInvitations({
        role: ERole.ROLE_TESTER,
      });
      return data;
    },
    {
      staleTime: GET_TESTER_INVITATIONS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetTesterInvitationsTotal = () => {
  const { data } = useGetTesterInvitations({ size: 1 });
  return data?.total || 0;
};
