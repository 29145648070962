import React from 'react';

import { Dialog } from '@app/hoc';
import { Button } from '@app/ui-components';
import { AnswerDto } from '@app/swagger-types';
import { ProjectQuestionnaire } from '@app/domain/project/components/ProjectQuestionnaire';

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  answers: AnswerDto[];
}

export const ReviewQuestionnaireDialog: React.FC<Props> = ({ open, onClose, title, answers }) => {
  return (
    <Dialog open={open} paperClassName="w-[730px] p-7 max-w-none" headerTitle="Questionnaire" onClose={onClose}>
      <ProjectQuestionnaire title={title} answers={answers} wrapperClassName="max-h-[70vh] overflow-y-auto" />
      <div className="mb-3 mt-12 flex justify-center">
        <Button onClick={onClose} variant="outlined" color="secondary" size="large">
          Close
        </Button>
      </div>
    </Dialog>
  );
};
