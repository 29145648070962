import { object, string, lazy, mixed, InferType, boolean } from 'yup';

import { SuperAdminDto } from '@app/models/super-admin';
import { TesterDto, ClientDto, CollaboratorOutSchema } from '@app/models/user';

import { email, oneOfEnum } from '@app/utils/yup.utils';

import { VerificationType } from './auth.type';
import { IS_AUTH_METHOD_TOKEN } from '@app/environment/typed-env';
import { ERole } from '@app/swagger-types';

const TokensDto = object({
  accessToken: IS_AUTH_METHOD_TOKEN ? string().required() : string(),
  refreshToken: IS_AUTH_METHOD_TOKEN ? string().required() : string(),
});
export type TokensDto = InferType<typeof TokensDto>;

export const UserDto = lazy<SuperAdminDto | ClientDto | TesterDto | CollaboratorOutSchema>((object) => {
  if (!object) {
    return mixed().nullable();
  }

  switch (object.role) {
    case ERole.ROLE_CLIENT:
      return ClientDto;
    case ERole.ROLE_ADMIN:
      return SuperAdminDto;
    case ERole.ROLE_TESTER:
      return TesterDto;
    case ERole.ROLE_COLLABORATOR:
      return CollaboratorOutSchema;
    default:
      return mixed();
  }
});
export type UserDto = InferType<typeof UserDto>;

export const guardForClientDto = (user?: any): user is ClientDto => {
  if (!user || typeof user !== 'object') {
    return false;
  }
  return user.role === ERole.ROLE_CLIENT && typeof user.id === 'string';
};

export type AuthResDto = UserDto & TokensDto;

// ===== SIGN IN DTO =====

export const SignInReqDto = object({
  email: email(),
  password: string().required('Required Field').min(6, 'Min 6 characters'),
  // only single auth type supported for now
  // authType: oneOfEnum(EAuthMethod),
});
export type SignInReqDto = InferType<typeof SignInReqDto>;

export const SignInReqVerificationDto = object({
  type: oneOfEnum(VerificationType).defined(),
  userId: string().defined(),
});
export type SignInReqVerificationDto = InferType<typeof SignInReqVerificationDto>;

export const SignInReqConfirmationDto = SignInReqVerificationDto.concat(
  object({
    code: string().defined(),
  })
);
export type SignInReqConfirmationDto = InferType<typeof SignInReqConfirmationDto>;

// ===== SIGN UP DTO =====

export const SignUpReqDto = object({
  firstName: string(),
  lastName: string(),
  email: email(),
  password: string().required('Required Field').min(6, 'Min 6 characters'),
  isD2NA: boolean().default(false),
});

export type SignUpReqDto = InferType<typeof SignUpReqDto>;

export const ConfirmSignUpCodeReqDto = object({
  email: string(),
  emailActivationCode: string(),
});

export type ConfirmSignUpCodeReqDto = InferType<typeof ConfirmSignUpCodeReqDto>;

// ===== FORGOT PASSWORD DTO =====

export const ForgotPasswordReqDto = object({
  email: email(),
});

export type ForgotPasswordReqDto = InferType<typeof ForgotPasswordReqDto>;

export const ConfirmPasswordReqDto = object({
  email: email(),
  confirmCode: string(),
  newPassword: string(),
});

export type ConfirmPasswordReqDto = InferType<typeof ConfirmPasswordReqDto>;

// ====== USER CODE =======

export const UserCodeDto = object({
  code: string().nullable(),
  id: string().uuid().defined(),
  createdDate: string().defined(),
  expiredDate: string().defined(),
});
export type UserCodeDto = InferType<typeof UserCodeDto>;
