import React, { useMemo } from 'react';

import { FindProjectList } from '@app/domain/project/components/FindProjectList';
import { EApplicationStatus, EProjectStatus } from '@app/swagger-types';
import { GET_APPLIED_PROJECTS_QUERY } from '@app/constants/query-api-configs';
import { useFindProjectsQueryControls } from '@app/domain/project/hooks/useFindProjectsQueryControls';
import { useGetAppliedProjects } from '@app/domain/application/api/hooks/application.api.hook';

export const AllAppliedProjectsPage: React.FC = () => {
  const {
    values: { search },
  } = useFindProjectsQueryControls();

  const { isFetching, data } = useGetAppliedProjects(
    {
      projectStatus: EProjectStatus.SCHEDULED,
      status: EApplicationStatus.REQUESTED,
      sort: GET_APPLIED_PROJECTS_QUERY.defaultSort,
      term: search,
    },
    { keepPreviousData: true }
  );

  const projects = useMemo(
    () =>
      data?.result?.map(({ project, tester: _, ...myApplication }) => ({
        ...project,
        myApplication,
      })),
    [data?.result]
  );

  return (
    <div>
      <FindProjectList projects={projects} loading={isFetching} />
    </div>
  );
};
