import { ReactNode } from 'react';
import clsxm from '@app/lib/clsxm';
import { BreakUnit } from '../PdfPager';
import styles from '../pdf-salus.module.scss';
import { SettingFindings } from '@app/modules/admin/modules/settings/pages/SettingFindings';

const GradientBorder: React.FC = () => (
  <div className={clsxm(styles.tacRow, 'flex')}>
    <div className={styles.tab} />
    <div className={styles.gradientDivider} />
  </div>
);

export const TesterTacBody: React.FC = () => {
  const renderItem = (tabs: string[], { title, text }: { title?: ReactNode; text?: ReactNode }) => (
    <BreakUnit>
      <div className={clsxm(styles.tacRow, 'flex')}>
        {tabs.map((tab, index) => (
          <div key={index} className={styles.tab}>
            {tab}
          </div>
        ))}
        {title && <b>{title}</b>}
        {text && <div>{text}</div>}
      </div>
    </BreakUnit>
  );

  const renderTwoColumnsItem = (
    { title, content }: { title: ReactNode; content: ReactNode },
    borderBottom?: boolean
  ) => (
    <BreakUnit>
      <GradientBorder />
      <div className={clsxm(styles.tacRow, 'flex')}>
        <div className={styles.tab} />
        <b className="min-w-[60mm] p-2 text-left">{title}</b>
        <div className="p-2">{content}</div>
      </div>
      {borderBottom && <GradientBorder />}
    </BreakUnit>
  );

  return (
    <>
      <BreakUnit>
        <div className={clsxm(styles.content, 'text-center')}>
          <div className={styles.tacHeader}>TERMS AND CONDITIONS</div>
        </div>
      </BreakUnit>
      {renderItem(['1'], { title: 'TERM' })}
      {renderItem([''], {
        text: (
          <>
            This Agreement commences on the Commencement Date and will continue unless terminated earlier in accordance
            with its terms (“<b>Term</b>”).
          </>
        ),
      })}
      {renderItem(['2'], { title: 'THE FREELANCER’S OBLIGATIONS' })}
      {renderItem(['2.1'], { title: 'Provision of Services' })}
      {renderItem([''], {
        text: (
          <>
            During the Term, the Freelancer must provide the Services to the Company in accordance with this Agreement.
          </>
        ),
      })}
      {renderItem(['2.2'], { title: 'Standard of Services' })}
      {renderItem([''], {
        text: <>The Freelancer must provide the Services to the Company: </>,
      })}
      {renderItem(['', '(a)'], {
        text: <>in accordance with the Company’s reasonable directions;</>,
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            in accordance with the Company’s policies as notified to the Freelancer from time to time, conduct and in
            accordance with all applicable Laws, tax obligations and industry standards;
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: <>with due care and skill and in a professional, punctual and diligent manner; and</>,
      })}
      {renderItem(['', '(d)'], {
        text: <>in a manner so that the Services are fit for their intended purpose</>,
      })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer warrants that they will perform the Services in accordance with any Laws applicable to the
            Services and has obtained all relevant licences.
          </>
        ),
      })}
      {renderItem(['2.3'], { title: 'Reports' })}
      {renderItem([''], {
        text: (
          <>
            If requested by the Company, the Freelancer must promptly provide a comprehensive report to the Company
            about any aspect of the provision of the Services, presented in a form and manner reasonably required by the
            Company.
          </>
        ),
      })}
      {renderItem(['2.4'], { title: 'Delivery Timeline' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            The Freelancer must ensure that the Services are performed at the Work Times and in accordance with any
            delivery timeframes as agreed between the parties from time to time (“<b>Delivery Schedule</b>”).
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            If there are delays in the Delivery Schedule caused by the Freelancer, the Company may (in its sole
            discretion) deduct an amount from the Fees payable for the Services to compensate the Company for any
            financial loss it may have suffered as a result of the delay. The Freelancer acknowledges and agrees that
            such deduction is reasonable.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            Once the Services timeline is past the Delivery Schedule, the Freelancer shall not be permitted to engage in
            any test activities other than retesting or patch verification, specifically requested by the Company or the
            client.
          </>
        ),
      })}
      {renderItem(['2.5'], { title: 'Retesting' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            At the client’s request, the Company may require the Freelancer to conduct a retesting of the application or
            network provided under this Agreement.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            In such an event, the Freelancer shall re-engage and perform the Services using the same or similar
            methodologies in testing of specified failure risks or vulnerabilities that were highlighted in the previous
            Outcome Report.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            The Company shall pay the Freelancer an additional sum in the manner envisaged in <b>Schedule 1</b> (“
            <b>Bonus</b>”).
          </>
        ),
      })}
      {renderItem(['2.6'], { title: 'No Conflict and Non-Solicitation' })}
      {renderItem(['', '(a)'], {
        text: (
          <>During the Term of the Agreement, the Freelancer must not, without the Company’s prior written consent:</>
        ),
      })}
      {renderItem(['', '', '(i)'], {
        text: (
          <>
            undertake work that conflicts or potentially conflicts with the {"Freelancer's"} obligations under this
            Agreement; or
          </>
        ),
      })}
      {renderItem(['', '', '(ii)'], {
        text: (
          <>
            employ or engage (or be knowingly involved in either employing or engaging) any officer, employee or
            contractor of the Company or client of the Company, which the Freelancer had any contact with during the
            Term.
          </>
        ),
      })}
      {renderItem(['2.7'], { title: 'Equipment' })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer is responsible for organizing, arranging and maintaining all equipment, tools and licences
            required to perform the Services.
          </>
        ),
      })}
      {renderItem(['2.8'], { title: 'Regulatory' })}
      {renderItem(['', '(a)'], {
        text: <>The Freelancer shall:</>,
      })}
      {renderItem(['', '', '(i)'], {
        text: (
          <>
            Comply with all applicable laws, regulations, codes and sanctions relating to anti-bribery and
            anti-corruption including but not limited to the UK Bribery Act 2010 (Relevant Requirements);
          </>
        ),
      })}
      {renderItem(['', '', '(ii)'], {
        text: (
          <>
            Not engage in any activity, practice or conduct which would constitute an offence under the UK Bribery Act
            2010 if such activity, practice or conduct had been carried out in the UK;
          </>
        ),
      })}
      {renderItem(['', '', '(iii)'], {
        text: (
          <>
            Comply with the Company’s ethics and policies including anti-bribery and anti- corruption policies, in each
            case as the Company may update them from time to time;
          </>
        ),
      })}
      {renderItem(['', '', '(v)'], {
        text: (
          <>
            Ensure that all persons associated with the Company or other person who are performing services in
            connection with this Agreement comply with this clause 2.8; and
          </>
        ),
      })}
      {renderItem(['', '', '(vi)'], {
        text: (
          <>
            Provide such supporting evidence of compliance with this clause 2.8 as the Company may reasonably request.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: <>Failure to comply with this clause 2.8 may result in the immediate termination of this Agreement. </>,
      })}
      {renderItem(['2.9'], { title: 'Suspension of Services' })}
      {renderItem([''], {
        text: (
          <>
            The Company may require the Freelancer to suspend working on a particular client project by notifying the
            Freelancer in writing. In such an event the Freelancer must not carry on or undertake any work relating to
            that client project, until further notification from the Company.
          </>
        ),
      })}
      {renderItem(['3'], { title: 'PAYMENT' })}
      {renderItem(['3.1'], { title: 'Fees and Bonus' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            In consideration for the {"Freelancer's"} performance of the Services, the Company shall pay the Freelancer
            the Fees
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The Freelancer may be entitled to a bonus payout in the event the Freelancer highlights vulnerabilities and
            severity of failure risks in the manner envisaged in <b>Schedule 1</b> (“
            <b>Bonus</b>”).
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            The Freelancer agrees that the Fees and the Bonus referred to in this clause 3.1 are the financial benefits
            that the Freelancer is entitled to in connection with this Agreement and no additional charges or
            entitlements may be made or claimed by the Freelancer.
          </>
        ),
      })}
      {renderItem(['3.2'], { title: 'Invoices' })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer must submit to the Company a tax invoice for Services provided by the Freelancer at the dates
            and times agreed by the parties in writing (or absent any agreement, once the Services are complete).
          </>
        ),
      })}
      {renderItem(['3.3'], { title: 'Tax and Expenses' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            The Freelancer acknowledges and agrees that it is responsible for managing its own taxation obligations and
            expenses in relation to the Services under this Agreement.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The Company recommends that the Freelancer obtains taxation advice and engages the services of a registered
            taxation professional.
          </>
        ),
      })}
      {renderItem(['4'], { title: 'RELATIONSHIP' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            The relationship of the Freelancer to the Company is of an independent contractor. Nothing in this Agreement
            constitutes or deems the Freelancer to be an employee, worker, agent or partner of the Company and the
            Freelancer shall not hold themselves out as such.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The Freelancer must not hold itself out as being entitled to contract or accept payment in the name of or on
            account of the Company.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>This Agreement constitutes a contract for the provision of services and not a contract of employment.</>
        ),
      })}
      {renderItem(['', '(d)'], {
        text: (
          <>
            The Company may, at its discretion, satisfy such indemnity (in whole or in part) by way of deduction from
            any payments due to the Freelancer.{' '}
          </>
        ),
      })}
      {renderItem(['5'], { title: 'SUBCONTRACTORS' })}
      {renderItem([''], {
        text: <>The Freelancer must not subcontract any part of the Services.</>,
      })}
      {renderItem(['6'], { title: 'CONFIDENTIALITY AND NON-DISCLOSURE' })}
      {renderItem(['6.1'], { title: 'Confidential Information' })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer shall not, during or after the Term, disclose Confidential Information directly or indirectly
            to any third party (including on any media or platforms) except:
          </>
        ),
      })}
      {renderItem(['', '(a)'], {
        text: <>with the Company’s prior written consent;</>,
      })}
      {renderItem(['', '(b)'], {
        text: <>as required by applicable Law.</>,
      })}
      {renderItem(['6.2'], { title: 'Breach' })}
      {renderItem([''], {
        text: (
          <>
            If the Freelancer becomes aware of a suspected or actual breach of this clause 6 by the Freelancer, the
            Freelancer must immediately notify the Company and take reasonable steps required to prevent, stop or
            mitigate the suspected or actual breach.
          </>
        ),
      })}
      {renderItem(['6.3'], { title: 'Use' })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer may only use the Confidential Information of the Company for the purposes of exercising its
            rights or performing its obligations under this Agreement.
          </>
        ),
      })}
      {renderItem(['6.4'], { title: 'Return' })}
      {renderItem([''], {
        text: (
          <>
            On termination of this Agreement, or on request by the Company, the Freelancer must promptly return to the
            Company or destroy all Confidential Information of the Company in the possession or control of the
            Freelancer.
          </>
        ),
      })}
      {renderItem(['7'], { title: 'INTELLECTUAL PROPERTY AND MORAL RIGHTS' })}
      {renderItem(['7.1'], { title: 'New Material' })}
      {renderItem([''], {
        text: (
          <>
            Intellectual Property Rights in New Material are immediately assigned to and vest in the Company as those
            rights are created.
          </>
        ),
      })}
      {renderItem(['7.2'], { title: 'Existing Material' })}
      {renderItem([''], {
        text: (
          <>
            Each party retains ownership of Intellectual Property Rights in Existing Material and nothing in this
            Agreement transfers ownership or assigns any Intellectual Property Rights in Existing Material of a party to
            the other party.
          </>
        ),
      })}
      {renderItem(['7.3'], { title: 'Licence' })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer must not use the New Material in any way without the Company’s prior written consent,
            including as part of the Freelancer’s portfolio or in the Freelancer’s marketing activities (such as social
            media communications).
          </>
        ),
      })}
      {renderItem(['7.4'], { title: 'Third Party Intellectual Property' })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer warrants that the Company’s receipt and use of the Services, New Materials [and the
            {"Freelancer's"} Existing Materials] will not infringe the Intellectual Property Rights of any third party.
          </>
        ),
      })}
      {renderItem(['7.5'], { title: 'Moral Rights' })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer waives any moral rights in the Works to which they are now or may at any future time be
            entitled under Chapter IV of the UK Copyright Designs and Patents Act 1988 or any similar provisions of law
            in any jurisdiction, including (but without limitation) the right to be identified, the right of integrity
            and the right against false attribution, and agrees not to institute, support, maintain or permit any action
            or claim to the effect that any treatment, exploitation or use of such Works or other materials infringes
            the Freelancer’s moral rights.
          </>
        ),
      })}
      {renderItem(['8'], { title: 'DATA PROTECTION' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            The Company will collect and process information relating to the Freelancer in accordance with the Company’s
            privacy notice.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            The Freelancer and the Company acknowledge that for the purposes of the Data Protection Legislation, the
            Company is the controller and the Freelancer is the processor.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: <>The Freelancer and the Company will comply with the Data Protection Legislation.</>,
      })}
      {renderItem(['', '(d)'], {
        text: (
          <>
            The Freelancer shall have personal liability for and shall indemnify the Company for any loss, liability,
            costs (including legal costs), damages, or expenses resulting from any breach by the Freelancer of the Data
            Protection Legislation.
          </>
        ),
      })}
      {renderItem(['9'], { title: 'INDEMNITY' })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer indemnifies the Company from and against all losses, claims, expenses, damages and
            liabilities (including any taxes, fees or costs) which arise out of:
          </>
        ),
      })}
      {renderItem(['', '(a)'], {
        text: <>any breach of this Agreement by the Freelancer;</>,
      })}
      {renderItem(['', '(b)'], {
        text: <>any negligent act or omission of the Freelancer in respect of the Services; or</>,
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            any claim that the Freelancer’s use of the Services, the New Material or the {"Freelancer's"} Existing
            Material infringes the Intellectual Property Rights of any third party.
          </>
        ),
      })}
      {renderItem(['10'], { title: 'TERMINATION' })}
      {renderItem(['10.1'], { title: 'Termination By the Freelancer' })}
      {renderItem([''], {
        text: (
          <>
            The Freelancer may terminate this Agreement in the event of sickness or its inability to perform the
            Services by providing an immediate written notice to the Company. All amounts accrued until the date of
            termination will be paid to the Freelancer.
          </>
        ),
      })}
      {renderItem(['10.2'], { title: 'Termination By the Company' })}
      {renderItem([''], {
        text: (
          <>
            The Company may terminate this Agreement immediately by notice to the Freelancer with no liability to make
            any further payment to the Freelancer (other than in respect of amounts accrued before the Termination Date)
            if:
          </>
        ),
      })}
      {renderItem(['', '(a)'], {
        text: <>the Freelancer is in default or breach of this Agreement;</>,
      })}
      {renderItem(['', '(b)'], {
        text: <>the Company determines that the Services provided are not of a satisfactory standard;</>,
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            the Freelancer is convicted of a criminal offence involving fraud or dishonesty or an offence which, in the
            opinion of the Company, affects the {"Freelancer's"} obligations under this Agreement.
          </>
        ),
      })}
      {renderItem(['10.3'], { title: 'Effect of Termination' })}
      {renderItem([''], {
        text: (
          <>
            Upon termination of this Agreement, the Freelancer must immediately deliver to the Company all property
            belonging to the Company or any clients of the Company (including any laptops, computers, security passes,
            credit or charge cards and keys) and materials comprising or containing any:
          </>
        ),
      })}
      {renderItem(['', '(a)'], {
        text: <>Confidential Information;</>,
      })}
      {renderItem(['', '(b)'], {
        text: <>any New Material or any other Material in which the Company has any Intellectual Property Rights; or</>,
      })}
      {renderItem(['', '(c)'], {
        text: <>any other property of the Company,</>,
      })}
      {renderItem([''], {
        text: (
          <>
            which is in the {"Freelancer's"} power, possession or control and the Freelancer must thereafter, subject to
            the Company’s data retention guidelines, irretrievably delete any information relating to the Business of
            the Company stored on any magnetic or optical disk or memory (including but not limited to any Confidential
            Information) and all matter derived from such sources which is in their possession or under their control
            outside the premises of the Company.
          </>
        ),
      })}
      {renderItem(['10.4'], { title: 'Revocation of Access' })}
      {renderItem([''], {
        text: (
          <>
            Upon termination of this Agreement, the Freelancer’s access to any digital information systems of the
            Company or the Company’s clients will be revoked and the Freelancer should cease to access these digital
            information systems with immediate effect regardless of whether access is still possible.
          </>
        ),
      })}
      {renderItem(['10.5'], { title: 'Survival' })}
      {renderItem([''], {
        text: (
          <>
            Any clause that by its nature would reasonably be expected to be performed after the termination or expiry
            of this Agreement will survive and be enforceable after such termination or expiry.
          </>
        ),
      })}
      {renderItem(['11'], { title: 'DISPUTE RESOLUTION' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            A party claiming that a dispute has arisen under or in connection with this Agreement must not commence
            court proceedings arising from or relating to the dispute, other than a claim for urgent interlocutory
            relief, unless that party has complied with the requirements of this clause.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            A party that requires resolution of a dispute which arises under or in connection with this Agreement must
            give the other party or parties to the dispute written notice containing reasonable details of the dispute
            and requiring its resolution under this clause.
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            Once the dispute notice has been given, each party to the dispute must then use its best efforts to resolve
            the dispute in good faith. If the dispute is not resolved within a period of 14 days (or such other period
            as agreed by the parties in writing) after the date of the notice, any party to the dispute may take legal
            proceedings to resolve the dispute.
          </>
        ),
      })}
      {renderItem(['12'], { title: 'NOTICES' })}
      {renderItem(['', '(a)'], {
        text: <>A notice or other communication to a party under this Agreement must be:</>,
      })}
      {renderItem(['', '', '(i)'], {
        text: <>in writing and in English; and</>,
      })}
      {renderItem(['', '', '(ii)'], {
        text: (
          <>
            delivered via email to the other party to the email address most regularly used by the parties to correspond
            regarding the subject matter of this Agreement as at the date of this agreement (“<b>Email Address</b>”).
            The parties may update their Email Address by notice to the other party.
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            Unless the party sending the notice knows or reasonably ought to suspect that an email was not delivered to
            the other {"party's"} Email Address, notice will be taken to be given:
          </>
        ),
      })}
      {renderItem(['', '', '(i)'], {
        text: <>24 hours after the email was sent; or</>,
      })}
      {renderItem(['', '', '(ii)'], {
        text: <>when replied to by the other party,</>,
      })}
      {renderItem(['', ''], {
        text: <>whichever is earlier.,</>,
      })}
      {renderItem(['13'], { title: 'GENERAL' })}
      {renderItem(['13.1'], { title: 'Governing Law and Jurisdiction' })}
      {renderItem([''], {
        text: (
          <>
            This Agreement is governed by the law of England and Wales. Each party irrevocably submits to the exclusive
            jurisdiction of the courts of England and Wales in respect of any proceedings arising out of or in
            connection with this agreement or its subject matter or formation (including non- contractual disputes or
            claims). Each party irrevocably waives any objection to the venue of any legal process on the basis that the
            process has been brought in an inconvenient forum.
          </>
        ),
      })}
      {renderItem(['13.2'], { title: 'Variation' })}
      {renderItem([''], {
        text: (
          <>
            No variation of this Agreement shall be effective unless it is in writing and signed by the parties (or
            their authorised representatives).
          </>
        ),
      })}
      {renderItem(['13.3'], { title: 'Waiver' })}
      {renderItem([''], {
        text: (
          <>
            No party to this Agreement may rely on the words or conduct of any other party as a waiver of any right
            unless the waiver is in writing and signed by the party granting the waiver.
          </>
        ),
      })}
      {renderItem(['13.4'], { title: 'Severance' })}
      {renderItem([''], {
        text: (
          <>
            Any term of this Agreement which is wholly or partially void or unenforceable is severed to the extent that
            it is void or unenforceable. The validity and enforceability of the remainder of this Agreement is not
            limited or otherwise affected.
          </>
        ),
      })}
      {renderItem(['13.5'], { title: 'Assignment' })}
      {renderItem([''], {
        text: (
          <>
            A party cannot assign, novate or otherwise transfer any of its rights or obligations under this Agreement
            without the prior written consent of the other party.
          </>
        ),
      })}
      {renderItem(['13.6'], { title: 'Third Party Rights' })}
      {renderItem([''], {
        text: (
          <>
            A person who is not a party to this agreement shall not have any rights under the{' '}
            <i>Contracts (Rights of Third Parties) Act 1999</i> to enforce any term of this Agreement.
          </>
        ),
      })}
      {renderItem(['13.7'], { title: 'Counterparts' })}
      {renderItem([''], {
        text: (
          <>
            This Agreement may be executed in any number of counterparts. Each counterpart constitutes an original of
            this Agreement and all together constitute one agreement.
          </>
        ),
      })}
      {renderItem(['13.8'], { title: 'Costs' })}
      {renderItem([''], {
        text: (
          <>
            Except as otherwise provided in this Agreement, each party must pay its own costs and expenses in connection
            with negotiating, preparing, executing and performing this Agreement.
          </>
        ),
      })}
      {renderItem(['13.9'], { title: 'Entire Agreement' })}
      {renderItem([''], {
        text: (
          <>
            This Agreement embodies the entire agreement between the parties and supersedes any prior negotiation,
            conduct, arrangement, understanding or agreement, express or implied, in relation to the subject matter of
            this Agreement.
          </>
        ),
      })}
      {renderItem(['13.10'], { title: 'Interpretation' })}
      {renderItem(['', '(a)'], {
        text: (
          <>
            (<b>singular and plural</b>) words in the singular includes the plural (and vice versa);
          </>
        ),
      })}
      {renderItem(['', '(b)'], {
        text: (
          <>
            (<b>gender</b>) words indicating a gender includes the corresponding words of any other gender;
          </>
        ),
      })}
      {renderItem(['', '(c)'], {
        text: (
          <>
            (<b>defined terms</b>) if a word or phrase is given a defined meaning, any other part of speech or
            grammatical form of that word or phrase has a corresponding meaning;
          </>
        ),
      })}
      {renderItem(['', '(d)'], {
        text: (
          <>
            (<b>person</b>) a reference to {'"person"'} or {'"you"'} includes an individual, the estate of an
            individual, a corporation, an authority, an association, consortium or joint venture (whether incorporated
            or unincorporated), a partnership, a trust and any other entity;
          </>
        ),
      })}
      {renderItem(['', '(e)'], {
        text: (
          <>
            (<b>party</b>) a reference to a party includes that {"party's"} executors, administrators, successors and
            permitted assigns, including persons taking by way of novation and, in the case of a trustee, includes any
            substituted or additional trustee;
          </>
        ),
      })}
      {renderItem(['', '(f)'], {
        text: (
          <>
            (<b>this Agreement</b>) a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure
            is a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure to or of this
            agreement, and a reference to this agreement includes all schedules, exhibits, attachments and annexures to
            it;
          </>
        ),
      })}
      {renderItem(['', '(g)'], {
        text: (
          <>
            (<b>document</b>) a reference to a document (including this Agreement) is to that document as varied,
            novated, ratified or replaced from time to time;
          </>
        ),
      })}
      {renderItem(['', '(h)'], {
        text: (
          <>
            (<b>headings</b>) headings and words in bold type are for convenience only and do not affect interpretation;
          </>
        ),
      })}
      {renderItem(['', '(i)'], {
        text: (
          <>
            (<b>includes</b>) the word {'"includes"'} and similar words in any form is not a word of limitation; and
          </>
        ),
      })}
      {renderItem(['', '(j)'], {
        text: (
          <>
            (<b>adverse interpretation</b>) no provision of this agreement will be interpreted adversely to a party
            because that party was responsible for the preparation of this agreement or that provision.
          </>
        ),
      })}
      {renderItem(['14'], { title: 'DEFINITIONS' })}
      {renderItem([''], {
        text: (
          <>
            In addition to capitalised terms defined in the Key Agreement Details above, capitalised terms used in this
            Agreement will have the following meanings:
          </>
        ),
      })}
      {renderTwoColumnsItem({
        title: 'Business Day',
        content: (
          <>
            means a day (other than a Saturday, Sunday or any other day which is a public holiday) on which banks are
            open for general business in London, United Kingdom and the Virgin Islands - UK.
          </>
        ),
      })}
      {renderTwoColumnsItem({
        title: 'Confidential Information',
        content: (
          <>
            <div>means:</div>
            <div className={'flex text-justify'}>
              <div className={styles.tab}>(a)</div>
              <div>
                information of or provided by the Company or its clients to the Freelancer that is by its nature
                confidential information, is designated by the Company as confidential, or the Freelancer knows or ought
                to know is confidential, but does not include information which is or becomes, without a breach of
                confidentiality, public knowledge; and
              </div>
            </div>
            <div className={'flex text-justify'}>
              <div className={styles.tab}>(b)</div>
              <div>any work performed in the course of performing the Services.</div>
            </div>
          </>
        ),
      })}
      {renderTwoColumnsItem({
        title: 'Data Protection Legislation',
        content: (
          <>
            means all applicable data protection and privacy legislation in force from time to time in the UK and EU
            including the General Data Protection Regulation ((EU) 2016/679) (GDPR), the retained EU law version of the
            General Data Protection Regulation ((EU) 2016/679) (UK GDPR), the Data Protection Act 2018 (and regulations
            made thereunder) or any successor legislation, and all other legislation and regulatory requirements in
            force from time to time which apply to a party relating to the use of personal data (including, without
            limitation, the privacy of electronic communications).
          </>
        ),
      })}
      {renderTwoColumnsItem({
        title: 'Existing Material',
        content: <>means Material, other than New Material.</>,
      })}
      {renderTwoColumnsItem({
        title: 'Intellectual Property Rights',
        content: (
          <>
            means any patents, utility models, rights to inventions, copyright and neighbouring and related rights,
            moral rights, trademarks and service marks, business names and domain names, rights in get-up and trade
            dress, goodwill and the right to sue for passing off, rights in designs, rights in computer software,
            database rights, rights to use, and protect the confidentiality of, confidential information (including
            know-how and trade secrets) and all other intellectual property rights, in each case whether registered or
            unregistered and including all applications and rights to apply for and be granted, renewals or extensions
            of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of
            protection which subsist or will subsist now or in the future in any part of the world.
          </>
        ),
      })}
      {renderTwoColumnsItem({
        title: 'Laws',
        content: (
          <>
            mean any applicable statute, regulation, by-law, ordinance or subordinate legislation in force from time to
            time in the relevant jurisdiction(s) where the Services are performed or received and includes any industry
            codes of conduct.
          </>
        ),
      })}
      {renderTwoColumnsItem({
        title: 'Material',
        content: (
          <>
            means tangible and intangible information, documents, reports, inventions, data and other materials in any
            media whatsoever.
          </>
        ),
      })}
      {renderTwoColumnsItem({
        title: 'New Material',
        content: (
          <>
            means Material that is created, written, developed or otherwise brought into existence by or on behalf of
            the Freelancer in the course of providing the Services.
          </>
        ),
      })}
      {renderTwoColumnsItem(
        {
          title: 'Works',
          content: (
            <>
              means all records, reports, documents, papers, drawings, designs, transparencies, photos, graphics, logos,
              typographical arrangements, software, and all other materials in whatever form, including but not limited
              to hard copy and electronic form, prepared by the Freelancer in the provision of the Services.
            </>
          ),
        },
        true
      )}
      <BreakUnit>
        <div className={clsxm(styles.content, 'py-[12mm]')}>
          <SettingFindings showDescription={false} />
        </div>
      </BreakUnit>
    </>
  );
};
