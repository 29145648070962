import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import {
  CHECK_ANY_PROJECT_CREATED_QUERY,
  GET_ALL_PENDING_RETEST_FINDINGS_QUERY,
  GET_APPLIED_PROJECTS_QUERY,
  GET_ASSIGNED_APPLICATIONS_QUERY,
  GET_FINANCE_STATISTIC_QUERY,
  GET_FINANCES_QUERY,
  GET_FINDING_STATISTIC_QUERY,
  GET_INVOICES_QUERY,
  GET_NOT_ASSIGNED_PROJECTS_QUERY,
  GET_PENDING_RETEST_FINDINGS_QUERY,
  GET_PROJECT_QUERY,
  GET_PROJECTS_QUERY,
  GET_REQUESTED_APPLICATIONS_QUERY,
  GET_RETEST_BONUSES_QUERY,
} from '@app/constants/query-api-configs';
import { ProjectApi } from '@app/domain/project/project.api';
import { GetProjectsParams } from '@app/domain/project/project.type';
import { AssignTesterInDto, EProjectStatus, ProjectOutDto, TimelineInDto } from '@app/swagger-types';
import { PaginatedResponse } from '@app/api/types';
import { UseInfiniteQueryOptions } from 'react-query/types/react/types';
import { PAGE_SIZE } from '@app/constants/api';
import { RejectProjectInDto } from '@app/swagger-override-types';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { normalizeFileNameForPDF } from '@app/domain/pdf-prerender/pdf.util';

export const useGetMyProjects = (
  params: GetProjectsParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<ProjectOutDto>,
    (typeof GET_PROJECTS_QUERY.name | GetProjectsParams)[]
  >
) => {
  return useQuery(
    [GET_PROJECTS_QUERY.name, params],
    async () => {
      return await ProjectApi.getMyProjects({
        ...params,
      });
    },
    {
      staleTime: GET_PROJECTS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetProjects = (
  params: GetProjectsParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<ProjectOutDto>,
    (typeof GET_PROJECTS_QUERY.name | GetProjectsParams)[]
  >
) => {
  return useQuery(
    [GET_PROJECTS_QUERY.name, params],
    async () => {
      return await ProjectApi.getProjects({
        ...params,
      });
    },
    {
      staleTime: GET_PROJECTS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useInfinityGetProjects = (
  params: GetProjectsParams,
  options?: UseInfiniteQueryOptions<
    PaginatedResponse<ProjectOutDto>,
    Error,
    PaginatedResponse<ProjectOutDto>,
    PaginatedResponse<ProjectOutDto>,
    (typeof GET_PROJECTS_QUERY.name | GetProjectsParams)[]
  >
) => {
  return useInfiniteQuery(
    [GET_PROJECTS_QUERY.name, params],
    async ({ pageParam = 0 }) => {
      return await ProjectApi.getProjects({
        ...params,
        size: PAGE_SIZE,
        page: pageParam,
      });
    },
    {
      staleTime: GET_PROJECTS_QUERY.config.staleTime,
      getNextPageParam: (lastPage, pages) => {
        const pagesSize = lastPage.total / PAGE_SIZE;
        if (pages.length <= pagesSize) {
          return pages.length;
        }
        return undefined;
      },
      ...options,
    }
  );
};

export const useInfinityGetMyProjects = (
  params: GetProjectsParams,
  options?: UseInfiniteQueryOptions<
    PaginatedResponse<ProjectOutDto>,
    Error,
    PaginatedResponse<ProjectOutDto>,
    PaginatedResponse<ProjectOutDto>,
    (typeof GET_PROJECTS_QUERY.name | GetProjectsParams)[]
  >
) => {
  return useInfiniteQuery(
    [GET_PROJECTS_QUERY.name, params],
    async ({ pageParam = 0 }) => {
      return await ProjectApi.getMyProjects({
        ...params,
        size: PAGE_SIZE,
        page: pageParam,
      });
    },
    {
      staleTime: GET_PROJECTS_QUERY.config.staleTime,
      getNextPageParam: (lastPage, pages) => {
        const pagesSize = lastPage.total / PAGE_SIZE;
        if (pages.length <= pagesSize) {
          return pages.length;
        }
        return undefined;
      },
      ...options,
    }
  );
};

export const useGetNotAssignedProjects = (
  params: GetProjectsParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<ProjectOutDto>,
    (typeof GET_NOT_ASSIGNED_PROJECTS_QUERY.name | GetProjectsParams)[]
  >
) => {
  return useQuery(
    [GET_NOT_ASSIGNED_PROJECTS_QUERY.name, params],
    async () => {
      return await ProjectApi.getNotAssignedProjects({
        status: EProjectStatus.SCHEDULED,
        ...params,
      });
    },
    {
      staleTime: GET_NOT_ASSIGNED_PROJECTS_QUERY.config.staleTime,
      ...options,
    }
  );
};

// GET TOTAL HOOKS

export const useGetMyProjectsTotal = (status?: EProjectStatus): number | undefined => {
  const { data } = useGetMyProjects({ status, size: 1 }, { keepPreviousData: true });
  return data?.total || 0;
};

export const useGetProjectsTotal = (status?: EProjectStatus): number => {
  const { data } = useGetProjects({ status, size: 1 }, { keepPreviousData: true });
  return data?.total || 0;
};

export const useGetNotAssignedProjectsTotal = (): number => {
  const { data } = useGetNotAssignedProjects({ size: 1 }, { keepPreviousData: true });
  return data?.total || 0;
};

// MUTATION HOOKS

export const useDeleteProject = ({ onSuccess, ...options }: UseMutationOptions<unknown, Error, string> = {}) => {
  const queryClient = useQueryClient();
  return useMutation((args) => ProjectApi.delete(args), {
    onSuccess: async (data, projectId, context) => {
      await queryClient.invalidateQueries(GET_PROJECTS_QUERY.name);
      await queryClient.invalidateQueries(GET_INVOICES_QUERY.name);
      await queryClient.invalidateQueries(CHECK_ANY_PROJECT_CREATED_QUERY.name);
      await queryClient.invalidateQueries(GET_RETEST_BONUSES_QUERY.name);
      await queryClient.invalidateQueries(GET_RETEST_BONUSES_QUERY.name);
      await queryClient.invalidateQueries(GET_ALL_PENDING_RETEST_FINDINGS_QUERY.name);
      await queryClient.invalidateQueries(GET_PENDING_RETEST_FINDINGS_QUERY.name);
      await queryClient.invalidateQueries(GET_FINDING_STATISTIC_QUERY.name);
      await queryClient.invalidateQueries(GET_FINANCE_STATISTIC_QUERY.name);
      await queryClient.invalidateQueries(GET_FINANCES_QUERY.name);
      await queryClient.invalidateQueries(GET_ASSIGNED_APPLICATIONS_QUERY.name);
      await queryClient.invalidateQueries(GET_REQUESTED_APPLICATIONS_QUERY.name);

      queryClient.removeQueries(GET_NOT_ASSIGNED_PROJECTS_QUERY.name);
      queryClient.removeQueries(GET_APPLIED_PROJECTS_QUERY.name);
      queryClient.removeQueries([GET_PROJECT_QUERY.name, { id: projectId }]);
      onSuccess && (await onSuccess(data, projectId, context));
    },
    ...options,
  });
};

export type UseAssignTestersToProjectParams = { projectId: string } & AssignTesterInDto;

export const useAssignTestersToProject = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, UseAssignTestersToProjectParams> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ projectId, testerId }) => {
      return await ProjectApi.assignTester(projectId, { testerId });
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_PROJECTS_QUERY.name);
        await queryClient.invalidateQueries([GET_PROJECT_QUERY.name, { id: variables.projectId }]);
        await queryClient.invalidateQueries(GET_REQUESTED_APPLICATIONS_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export type UseSetProjectTimeline = { project: ProjectOutDto; Dto: TimelineInDto };

export const useSetProjectTimeline = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, UseSetProjectTimeline> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ project, Dto }) => {
      const fileName = normalizeFileNameForPDF(
        `Invoice ${project.client.companyName} ${project.name} ${format(new Date(), DATE_FORMAT)}`
      );
      return await ProjectApi.setProjectTimeline(project.id, { fileName, ...Dto });
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_PROJECTS_QUERY.name);
        await queryClient.invalidateQueries([GET_PROJECT_QUERY.name, { id: variables.project.id }]);
        await queryClient.invalidateQueries(GET_INVOICES_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export type UseRejectProject = { id: string; Dto: RejectProjectInDto };

export const useRejectProject = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, UseRejectProject> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, Dto }) => {
      await ProjectApi.setProjectStatus(id, Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([GET_PROJECT_QUERY.name, { id: variables.id }]);
        // Invalidate data in 'ALL' tab
        queryClient.removeQueries({
          predicate: (query) =>
            query.queryKey[0] === GET_PROJECTS_QUERY.name &&
            !(query.queryKey[1] as Partial<Pick<ProjectOutDto, 'status'>>)?.status,
        });
        // Invalidate data in 'PENDING' tab
        await queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === GET_PROJECTS_QUERY.name &&
            (query.queryKey[1] as Partial<Pick<ProjectOutDto, 'status'>>)?.status === EProjectStatus.PENDING &&
            (query.queryKey[1] as { size?: number })?.size === 1,
        });
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useApplyToProject = (options: UseMutationOptions<unknown, Error, string> = {}) => {
  return useMutation(
    async (projectId) => {
      return await ProjectApi.applyProject(projectId);
    },
    {
      ...options,
    }
  );
};

export const useUnapplyFromProject = (options: UseMutationOptions<unknown, Error, string> = {}) => {
  return useMutation(
    async (projectId) => {
      return await ProjectApi.unapplyProject(projectId);
    },
    {
      ...options,
    }
  );
};
