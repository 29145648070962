import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SettingsApi } from '../settings.api';
import { CurrencyConfigOutSchema } from './currency.schema';

export const usePublicGPBRateQuery = () => {
  return useQuery<ExtendedCurrencyConfigDto>(
    CURRENCY_QUERY_KEYS.GET_PUBLIC_GBP_RATE,
    async () => {
      const data = await SettingsApi.getPublicGPBRate();
      return {
        ...data,
        usdToGbpRate: 1 / data.toUsdRate,
      };
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useSetGPBRateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(async (toUsdRate: number) => SettingsApi.setGPBRate(toUsdRate), {
    onSuccess: (data) => {
      queryClient.setQueriesData<ExtendedCurrencyConfigDto>(CURRENCY_QUERY_KEYS.GET_PUBLIC_GBP_RATE, {
        ...data,
        usdToGbpRate: 1 / data.toUsdRate,
      });
    },
  });
};

const CURRENCY_QUERY_KEYS = {
  GET_PUBLIC_GBP_RATE: 'GET_PUBLIC_GBP_RATE',
};

export type ExtendedCurrencyConfigDto = CurrencyConfigOutSchema & {
  usdToGbpRate: number;
};
