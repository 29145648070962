import React from 'react';
import { Button } from '@app/ui-components';
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CheckParamReq, TypedLink, TypedLinkProps } from '@app/router';
import clsxm from '@app/lib/clsxm';

export function BackLink<TPath extends string>({ className, ...linkProps }: CheckParamReq<TypedLinkProps<TPath>>) {
  return (
    <TypedLink {...linkProps}>
      <Button
        variant="text"
        className={clsxm(
          'mb-3 flex items-center p-0 text-m text-grey5 hover:bg-transparent hover:underline',
          className
        )}
        startIcon={<ChevronUpIcon className="h-4 -rotate-90 fill-grey5 stroke-grey5" />}
      >
        Back
      </Button>
    </TypedLink>
  );
}
