import React, { useCallback, useState } from 'react';

import { SignInFormValidationSchema, UserDto } from '@app/models/auth';

import useStore from '@app/store/useStore.hook';
import { AuthApi } from '@app/auth/auth.api';
import { SignInForm } from '@app/modules/auth/components/SignInForm';
import { IS_PROD_ENV } from '@app/environment/typed-env';
import { useMutation } from 'react-query';
import { ConfirmCodeForm } from '@app/modules/auth/components/ConfirmCodeForm';

export const SignIn = () => {
  const [is2FA, setIs2FA] = useState(IS_PROD_ENV);
  const [email, setEmail] = useState<string>();
  const { setCurrentUser } = useStore();

  const handleToggle2FA = useCallback(() => {
    setIs2FA((value) => !value);
  }, []);

  const {
    isLoading,
    error,
    mutate: action,
  } = useMutation<UserDto, Error, SignInFormValidationSchema>(
    async (formData) => {
      const { data } = await AuthApi.signInSilent({ ...formData, is2faEnabled: is2FA });
      return data;
    },
    {
      onSuccess: (user, { email }) => {
        if (!is2FA && user) {
          setCurrentUser(user);
        } else if (is2FA) {
          setEmail(email);
        }
      },
    }
  );

  const {
    isLoading: submitCodeLoading,
    error: submitCodeError,
    mutate: handleSubmitCode,
  } = useMutation<UserDto | undefined, Error, string>(
    async (confirmCode) => {
      if (email) {
        const { data } = await AuthApi.signIn2FASilent({ email, confirmCode });
        return data;
      }
    },
    {
      onSuccess: (user) => {
        if (user) {
          setCurrentUser(user);
        }
      },
    }
  );

  if (email) {
    return (
      <ConfirmCodeForm
        onSubmitCode={handleSubmitCode}
        loading={submitCodeLoading}
        error={submitCodeError}
        email={email}
        onBackToLogInPage={() => setEmail(undefined)}
      />
    );
  }

  return <SignInForm onSignUp={action} error={error} loading={isLoading} is2FA={is2FA} onToggle2FA={handleToggle2FA} />;
};
