import React from 'react';
import { StepIconProps } from '@mui/material';

import clsxm from '@app/lib/clsxm';
import { CompletedProjectStepIcon, NonCompletedProjectStepIcon } from '@app/assets/icons';

export const ProjectStepIcon: React.FC<StepIconProps> = ({ active, className, completed }) => {
  return (
    <div className={clsxm(className, 'flex h-6 items-center')}>
      {active ? (
        <CompletedProjectStepIcon />
      ) : completed ? (
        <CompletedProjectStepIcon />
      ) : (
        <NonCompletedProjectStepIcon />
      )}
    </div>
  );
};
