import { validateAndAssert } from '@app/services/api-request/';
import { DailyRateConfigDto, FindingBonusDto, FindingConfigDto } from '@app/domain/settings/settings.form';
import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { CurrencyConfigOutSchema } from './currency/currency.schema';

export const SettingsApi = buildApi(
  class _SettingsApi extends ApiCollection {
    async getDailyRates() {
      return validateAndAssert(this.apiService.get('/private/daily-rates'), DailyRateConfigDto);
    }
    async getFindingConfigs() {
      return validateAndAssert(this.apiService.get('/private/report-configs'), FindingConfigDto);
    }
    async getFindingBonuses() {
      return validateAndAssert(this.apiService.get('/private/bonuses'), FindingBonusDto);
    }
    /* #region  /**=========== currency =========== */
    async getPublicGPBRate() {
      return validateAndAssert(this.apiService.get('/public/currency/GBP'), CurrencyConfigOutSchema);
    }
    async setGPBRate(toUsdRate: number) {
      return validateAndAssert(this.apiService.put('/private/currency/GBP', { toUsdRate }), CurrencyConfigOutSchema);
    }
    /* #endregion  /**======== currency =========== */
  }
);
