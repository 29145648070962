import React from 'react';
import { Outlet } from 'react-router-dom';
import { Typography } from '@mui/material';

export const MySettingsModule = () => {
  return (
    <>
      <Typography className="mb-8 text-xxxl font-bold">My Settings</Typography>
      <div>
        <Outlet />
      </div>
    </>
  );
};
