import { InternalApiRequest } from '@app/services/api-request';
import { InvitationStatusDto, PageResponseUserInvitationDto } from '@app/swagger-types';
import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { UserInvitationQueryParams } from '@app/domain/user-invitation/api/user-invitation.type';

export const UserInvitationApi = buildApi(
  class _UserInvitationApi extends ApiCollection {
    async getInvitations(params: UserInvitationQueryParams) {
      return InternalApiRequest.get<PageResponseUserInvitationDto>('/private/user-invitations', {
        params,
      });
    }
    async updateStatus(invitationId: string, Dto: InvitationStatusDto) {
      return InternalApiRequest.patch<never, InvitationStatusDto>(
        `/private/user-invitations/${invitationId}/status`,
        Dto
      );
    }
  }
);
