import { Routes } from '@app/constants/routes';

import { UserManagementModule } from './UserManagementModule';
import { NestedRoutes } from './modules/nested/NestedRoutes';
import { AdminsPage } from './pages/Admins.page';
import { ClientsPage } from './pages/Clients.page';
import { TestersPage } from './pages/Testers.page';
import { TypedNavigate } from '@app/router';
import { CollaboratorsPage } from '@app/modules/admin/modules/user-management/pages/Collaborators.page';

export const UserManagementRoutes = {
  path: Routes.admin.user_management.index,
  element: <UserManagementModule />,
  children: [
    { path: Routes.admin.user_management.clients, element: <ClientsPage /> },
    { path: Routes.admin.user_management.collaborators, element: <CollaboratorsPage /> },
    { path: Routes.admin.user_management.testers, element: <TestersPage /> },
    { path: Routes.admin.user_management.admins, element: <AdminsPage /> },
    // { path: Routes.admin.user_management.admin_profile, element: <AdminProfile /> },
    NestedRoutes,
    { index: true, path: '*', element: <TypedNavigate to={Routes.admin.user_management.clients} /> },
  ],
};
