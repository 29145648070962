import { useQuery, UseQueryOptions } from 'react-query';
import { GET_TESTERS_QUERY, GET_USERS_QUERY } from '@app/constants/query-api-configs';
import { PaginatedResponse } from '@app/api/types';
import { UserApi } from '@app/domain/user/user.api';
import { ClientDto, GetUsersParams, TesterDto } from '@app/models/user';
import { UserDto } from '@app/models/auth';
import { ERole } from '@app/swagger-types';

export const useGetUsers = (
  params: GetUsersParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<UserDto>,
    (typeof GET_USERS_QUERY.name | GetUsersParams)[]
  >
) => {
  return useQuery(
    [GET_USERS_QUERY.name, params],
    async () => {
      return await UserApi.getUsers({
        ...params,
      });
    },
    {
      staleTime: GET_USERS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetClients = (
  params: Omit<GetUsersParams, 'role'>,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<ClientDto>,
    (typeof GET_USERS_QUERY.name | Omit<GetUsersParams, 'role'>)[]
  >
) => {
  return useQuery(
    [GET_USERS_QUERY.name, params],
    async () => {
      return await UserApi.getClients({
        ...params,
      });
    },
    {
      staleTime: GET_USERS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetTesters = (
  params: Omit<GetUsersParams, 'role'>,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<TesterDto>,
    (typeof GET_TESTERS_QUERY.name | Omit<GetUsersParams, 'role'>)[]
  >
) => {
  return useQuery(
    [GET_TESTERS_QUERY.name, params],
    async () => {
      return await UserApi.getTesters({
        ...params,
      });
    },
    {
      staleTime: GET_TESTERS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetUsersTotal = (role: ERole, params?: Omit<GetUsersParams, 'role'>): number => {
  const { data } = useGetUsers({ role, size: 1, ...params }, { keepPreviousData: true });
  return data?.total || 0;
};
