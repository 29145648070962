import { useQuery, UseQueryOptions } from 'react-query';
import { ActionLogOutDto, ERole } from '@app/swagger-types';
import { GET_ACTION_LOGS_QUERY } from '@app/constants/query-api-configs';
import { ActionLogApi } from '@app/domain/action-log/action-log.api';
import { GetActionLogsParams } from '@app/domain/action-log/action-log.type';
import { PaginatedResponse } from '@app/api/types';

export const useGetActionLogs = (
  params: GetActionLogsParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<ActionLogOutDto>,
    (typeof GET_ACTION_LOGS_QUERY.name | GetActionLogsParams)[]
  >
) => {
  return useQuery(
    [GET_ACTION_LOGS_QUERY.name, params],
    async () => {
      return await ActionLogApi.getLogs({
        ...params,
      });
    },
    {
      staleTime: GET_ACTION_LOGS_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useGetActionLogsTotal = (role: ERole): number => {
  const { data } = useGetActionLogs({
    role,
    size: 1,
  });
  return data?.total || 0;
};
