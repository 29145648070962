import { useRef, useMemo, useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

type ExtendDuoTupleWithItem<Tuple extends [unknown, unknown], Item> = [...Tuple, Item];

// useAsyncFn with extra handler to reset value to undefined when needed
export const useResetAsyncFn: (
  ...args: Parameters<typeof useAsyncFn>
) => ExtendDuoTupleWithItem<ReturnType<typeof useAsyncFn>, () => void> = (fn, deps) => {
  const [renderTrigger, setRenderTrigger] = useState(0);
  const resetRef = useRef(false);
  const [state, baseAction] = useAsyncFn(
    async (...args: Parameters<typeof fn>) => {
      if (resetRef.current) {
        return;
      }
      return fn(...args);
    },
    [...(deps ?? []), renderTrigger]
  );

  useEffect(() => {
    if (resetRef.current) {
      baseAction();
    }
  }, [baseAction]);

  const { reset, action } = useMemo(() => {
    const reset = () => {
      resetRef.current = true;
      setRenderTrigger((i) => i + 1);
    };
    const action = async (...args: Parameters<typeof fn>) => {
      resetRef.current = false;
      return baseAction(...args);
    };
    return { reset, action };
  }, [baseAction]);

  return [state, action, reset];
};
