import React from 'react';
import { Outlet } from 'react-router-dom';
import { Tabs, Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { SearchInput } from '@app/shared/ui-components/fields/search-input/SearchInput';
import { useFindProjectsQueryControls } from '@app/domain/project/hooks/useFindProjectsQueryControls';
import { StyledTab } from '@app/shared/ui-components/tabs/StyledTab';
import { useGetAppliedProjectsTotal } from '@app/domain/application/api/hooks/application.api.hook';
import { useGetNotAssignedProjectsTotal } from '@app/domain/project/api/hooks/project.api.hook';

const tabRoutes = mutable([Routes.findProjects.all, Routes.findProjects.applied] as const);

export const FindProjectsModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);

  const appliedProjectsTotal = useGetAppliedProjectsTotal();
  const notAssignedProjectsTotal = useGetNotAssignedProjectsTotal();

  const {
    values: { search },
    handlers: { handleSearchChange },
  } = useFindProjectsQueryControls();

  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to });
  };

  return (
    <>
      <div>
        <Typography className="mb-8 text-xxxl font-bold">Find projects</Typography>
        <div className="mb-14 flex items-end justify-between">
          <SearchInput
            placeholder="Search by name"
            className="w-64"
            value={search}
            onChange={handleSearchChange}
            withBackground
          />
          <Tabs value={value} onChange={handleChange} className="border-b border-b-grey2">
            <StyledTab label="All" value={tabRoutes[0]} className="text-xs" count={notAssignedProjectsTotal} />
            <StyledTab label="Applied" value={tabRoutes[1]} className="text-xs" count={appliedProjectsTotal} />
          </Tabs>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};
