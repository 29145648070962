import { buildApi } from '@app/services/api-request/api-builder';
import { ApiCollection } from '@app/services/api-request/api-collection';
import { typedEnv } from '@app/environment/typed-env';
import { createQueryString } from '@app/utils/api.util';

export const PdfPrerenderApi = buildApi(
  class _PdfPrerenderApi extends ApiCollection {
    getDownloadPdfLink({ projectId, url, fileName }: { url: string; projectId: string; fileName: string }) {
      return `${typedEnv.REACT_APP_API_BASE_URL}/private/load-as-pdf${createQueryString({
        url: `${
          window.location.hostname === 'salus-local.salus.expert' ? 'https://dev.salus.expert' : window.location.origin
        }${url}`,
        projectId,
        fileName,
      })}`;
    }
  }
);
