import { Routes } from '@app/constants/routes';

import { ERole } from '@app/swagger-types';
import { TesterModule } from './TesterModule';
import { ProjectPage } from '@app/modules/projects/pages/Project.page';
import { CreateFindingPage } from '@app/modules/projects/pages/CreateFindingPage';
import { FindingPage } from '@app/domain/pages/FindingPage';
import { TypedNavigate } from '@app/router';
import { ProfilePage } from '@app/modules/admin/modules/user-management/pages/Profile.page';
import { MyProfilePage } from '@app/modules/admin/modules/user-management/pages/MyProfile.page';
import { ClientProfilePage } from '@app/modules/admin/modules/user-management/pages/ClientProfile.page';
import { EditFindingPage } from '@app/modules/projects/pages/EditFindingPage';
import { TesterDashboardPage } from '@app/modules/tester/pages/TesterDashboard.page';
import { MySettingsRoutes } from '@app/modules/my-settings/MySettingsRoutes';
import { FindProjectsRoutes } from '@app/modules/tester/modules/find-projects/FindProjectsRoutes';
import { TesterProjectsRoutes } from '@app/modules/tester/modules/projects/TesterProjectsRoutes';
import { PaymentsRoutes } from '@app/modules/tester/modules/payments/PaymentsRoutes';
import { CollaboratorProfilePage } from '@app/modules/admin/modules/user-management/pages/CollaboratorProfile.page';
import { EditMyProfilePage } from '@app/modules/admin/modules/user-management/pages/EditMyProfilePage.page';
import { ProjectQuestionnairePage } from '@app/modules/projects/pages/ProjectQuestionnaire.page';

export const TesterRoutes = {
  path: Routes.index,
  element: <TesterModule />,
  role: [ERole.ROLE_TESTER],
  children: [
    { path: Routes.projects.project, element: <ProjectPage /> },
    { path: Routes.projects.questionnaire, element: <ProjectQuestionnairePage /> },
    { path: Routes.projects.addFinding, element: <CreateFindingPage /> },
    { path: Routes.projects.editFinding, element: <EditFindingPage /> },
    { path: Routes.projects.finding, element: <FindingPage /> },
    { path: Routes.admin.user_management.tester_profile, element: <ProfilePage /> },
    { path: Routes.admin.user_management.client_profile, element: <ClientProfilePage /> },
    { path: Routes.admin.user_management.collaborator_profile, element: <CollaboratorProfilePage /> },
    { path: Routes.admin.user_management.my_profile, element: <MyProfilePage /> },
    { path: Routes.admin.user_management.edit_my_profile, element: <EditMyProfilePage /> },
    { path: Routes.dashboard.index, element: <TesterDashboardPage /> },
    MySettingsRoutes,
    FindProjectsRoutes,
    TesterProjectsRoutes,
    PaymentsRoutes,
    { index: true, path: '*', element: <TypedNavigate to={Routes.projects.index} /> },
  ],
};
