import React from 'react';
import { Outlet } from 'react-router-dom';
import { Tabs, Typography, Tab } from '@mui/material';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';

const tabRoutes = mutable([
  Routes.settings.logging,
  Routes.settings.workingDays,
  Routes.settings.findings,
  Routes.settings.currency,
] as const);

export const SettingsModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);
  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to });
  };

  return (
    <>
      <div>
        <Typography className="mb-8 text-xxxl font-bold">Settings</Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="mb-8 border-b border-b-grey2"
        >
          <Tab label="Logging" value={tabRoutes[0]} />
          <Tab label="Working Days" value={tabRoutes[1]} />
          <Tab label="Findings" value={tabRoutes[2]} />
          <Tab label="Currency" value={tabRoutes[3]} />
        </Tabs>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};
