import { ProjectOutDtoSchema } from '@app/domain/project/project.schema';
import { BreakUnit } from '../../PdfPager';
import styles from '../../pdf-salus.module.scss';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@app/constants/date';
import { renderFullName } from '@app/domain/user/user.utils';

const TODAY_STRING = format(new Date(), DATE_FORMAT);

export const ReportSectionDocumentProperties: React.FC<{
  project: ProjectOutDtoSchema;
}> = ({ project }) => {
  return (
    <>
      <BreakUnit pageBreak />
      <BreakUnit header>
        <div className={styles.content}>
          <div className={styles.pageHeader} id="document_properties">
            Document Properties
          </div>
          <div className={styles.divider} />
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex pb-4">
            <div className="flex-1 font-bold">Title</div>
            <div className="flex-[3]">{project.name}</div>
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex pb-4">
            <div className="flex-1 font-bold">Client</div>
            <div className="flex-[3]">{renderFullName(project.client)}</div>
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex pb-4">
            <div className="flex-1 font-bold">Version</div>
            <div className="flex-[3]">V1.0</div>
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex pb-4">
            <div className="flex-1 font-bold">Penetration Tester(s)</div>
            <div className="flex-[3]">{project.testers.map((e) => `${e.firstName} ${e.lastName}`).join(', ')}</div>
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex pb-4">
            <div className="flex-1 font-bold">Classification</div>
            <div className="flex-[3]">Confidential</div>
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex pb-4">
            <div className="flex-1 font-bold">Date</div>
            <div className="flex-[3]">{TODAY_STRING}</div>
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className={styles.sectionHeader}>Distribution List</div>
          <div className={styles.divider} />
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex py-2">
            <div className="flex-1 font-bold">Name</div>
            <div className="flex-[2] font-bold">Company</div>
          </div>
        </div>
      </BreakUnit>
      <BreakUnit>
        <div className={styles.content}>
          <div className="flex py-2 text-white">
            <div className="flex-1">{renderFullName(project.client)}</div>
            <div className="flex-[2]">{project.client.companyName}</div>
          </div>
        </div>
      </BreakUnit>
      {project.collaborators.map((e, i) => (
        <BreakUnit key={i}>
          <div className={styles.content}>
            <div className="flex py-2 text-white">
              <div className="flex-1">{renderFullName(e)}</div>
              <div className="flex-[2]">{e.companyName}</div>
            </div>
          </div>
        </BreakUnit>
      ))}
    </>
  );
};
