import React from 'react';
import { Typography } from '@mui/material';
import { Button } from '@app/ui-components';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { ButtonProps } from '@app/shared/ui-components/buttons/button/Button';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { ProjectStepper } from '@app/modules/admin/create-project/stepper/ProjectStepper';

interface StepProps {
  activeStep: number;
  title?: string;
  subTitle?: string;
  isFinalStep?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  error?: Error | null;
  disabledConfirm?: boolean;
  disabledCancel?: boolean;
  confirmLoading?: boolean;
  cancelText?: string;
  confirmText?: string;
  confirmButtonType?: ButtonProps['type'];
  render?: (parts: { header: JSX.Element; actionButtons: JSX.Element }) => JSX.Element;
}

export const CreateProjectStep: React.FC<StepProps> = ({
  onCancel,
  onConfirm,
  children,
  title,
  subTitle,
  error,
  disabledConfirm,
  disabledCancel,
  confirmLoading,
  cancelText = 'Cancel',
  confirmText = 'Next',
  confirmButtonType = 'button',
  render,
  activeStep,
  isFinalStep,
}) => {
  const header = (
    <div className="mb-14">
      <ProjectStepper activeStep={activeStep} isFinalStep={isFinalStep} className="mx-auto mb-12 w-[150px]" />
      {title && <Typography className="text-center text-xxxl font-bold">{title}</Typography>}
      {subTitle && (
        <Typography className="mx-auto mt-3 max-w-xs text-center text-m text-subtitle">{subTitle}</Typography>
      )}
    </div>
  );

  const actionButtons = (
    <>
      <div className="flex justify-center">
        <Button
          onClick={onCancel}
          variant="outlined"
          startIcon={<ChevronLeftRounded />}
          color="secondary"
          className="m-2 w-28"
          size="large"
          disabled={disabledCancel}
        >
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          variant="outlined"
          endIcon={<ChevronRightRounded />}
          color="primary"
          className="m-2 w-28"
          size="large"
          type={confirmButtonType}
          disabled={disabledConfirm}
          loading={confirmLoading}
        >
          {confirmText}
        </Button>
      </div>
      {maybeRenderError(error)}
    </>
  );

  return render ? (
    render({ header, actionButtons })
  ) : (
    <>
      {header}
      {children}
      {actionButtons}
    </>
  );
};
